import React, {ComponentPropsWithRef, Fragment, memo} from 'react';

import { Avatar } from '@material-ui/core';
import { AvatarProps as OriginalAvatarProps } from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import clsx from "clsx";
import { getAssetPath } from '../../utils/routes';

interface IAvatarProps<T> extends ComponentPropsWithRef<'div'> {
	fallback?:string;
	src:string | null | undefined;
	disableFallback?:boolean;
}
export type AvatarProps = IAvatarProps<HTMLDivElement> & Omit<OriginalAvatarProps<'div'>, 'src'>;

const CustomAvatar = React.forwardRef<HTMLDivElement, AvatarProps>(({src, alt, fallback, className, disableFallback, children, ...props}, ref) => {
	const realAlt = src ? alt : undefined;
	const realSrc:string | undefined = src ? getAssetPath(src) : fallback;
	const usingFallback = !src && !disableFallback;
	
	return (
        <Avatar ref={ref} src={realSrc} alt={realAlt} className={clsx(className, usingFallback && 'fallback')} {...props}>
			<Fragment>
				{usingFallback && (<PersonIcon />)}
				{children}
			</Fragment>
		</Avatar>
    );
});

export default memo(CustomAvatar);