import { DOWNLOAD_REPORT, GET_REPORT_MEMBER, GET_REPORT_MEMBERS, IReportState, ReportTableColumnsMap, ReportTableKeys, ReportTableType, SELECT_REPORT_ORG_PERIOD, SET_REPORT_DAYS_COMPLETED_FILTER, SET_REPORT_LOADING, SET_REPORT_MEMBER, SET_REPORT_MEMBERS, SET_REPORT_ORDER, SET_REPORT_ORDER_BY, SET_REPORT_ORGS, SET_REPORT_PAGE, SET_REPORT_POSITION_FILTER, SET_REPORT_ROWS_PER_PAGE, SET_REPORT_SEARCH_FILTER, SET_REPORT_SORT, SET_REPORT_STATE, SET_REPORT_STATUS_FILTER, SET_REPORT_TABLE_COLUMNS, SET_REPORT_TABLE_TYPE, SHOW_REPORT_ERROR, TypeReportAction } from './report.types';
import { FILTER_DEFAULT_VALUE, RANGE_DEFAULT_VALUE } from '../dashboard/dashboard.types';

import { SET_ORG } from '../org/org.types';
import { SIGN_OUT_SUCCESS } from '../user/user.types';

export const INITIAL_REPORT_STATE:IReportState = {
    isLoading: true,
    members: undefined,
    error: undefined,
    page: 0,
	rowsPerPage: 25,
	positionFilterSelections: FILTER_DEFAULT_VALUE,
	statusFilterSelections: FILTER_DEFAULT_VALUE,
    daysCompletedRangeFilterSelections: RANGE_DEFAULT_VALUE,
    searchFilter: undefined,
    order: undefined,
    orderBy: undefined,
    tableType: ReportTableType.Compliance,
    columnsMap: undefined,
    selectedOrgIds: undefined,
    member: undefined,
    selectedOrgPeriod: undefined
};

const reportReducer = (state = INITIAL_REPORT_STATE, action:TypeReportAction):IReportState => {
    switch( action.type ){
        
        case SIGN_OUT_SUCCESS :
        case SET_ORG :
            return {
                ...INITIAL_REPORT_STATE,
                columnsMap: state.columnsMap,
                rowsPerPage: state.rowsPerPage
            };

        case GET_REPORT_MEMBERS :
            return {
                ...state,
                isLoading: true,
                error: undefined,
                tableType: action.payload.tableType
            }

        case SET_REPORT_MEMBERS :
            return {
                ...state,
                isLoading: false,
                members: action.payload,
                error: undefined
            }

        case GET_REPORT_MEMBER :
            return {
                ...state,
                isLoading: true,
                member: state.member && state.member.id===action.payload.memberId ? {
                    ...state.member,
                    assessmentRiskScore: undefined,
                    compliancePercent: undefined
                 } : undefined,
                error: undefined
            }

        case SET_REPORT_MEMBER :
            return {
                ...state,
                isLoading: false,
                member: action.payload,
                error: undefined
            }

        case DOWNLOAD_REPORT :
            return {
                ...state,
                isLoading: true,
                error: undefined
            }

        case SHOW_REPORT_ERROR :
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        
        case SET_REPORT_STATE :
            if(action.payload){
                return {
                    ...state,
                    ...action.payload
                }
            } else {
                return {
                    ...INITIAL_REPORT_STATE,
                    columnsMap: state.columnsMap,
                    rowsPerPage: state.rowsPerPage
                };
            }

        case SET_REPORT_PAGE :
            return {
                ...state,
                page: action.payload
            }

        case SET_REPORT_ROWS_PER_PAGE :
            return {
                ...state,
                rowsPerPage: action.payload,
                page: 0
            }

        case SET_REPORT_POSITION_FILTER :
            return {
                ...state,
                positionFilterSelections: action.payload
            }

        case SET_REPORT_STATUS_FILTER :
            return {
                ...state,
                statusFilterSelections: action.payload
            }

        case SET_REPORT_DAYS_COMPLETED_FILTER :
            return {
                ...state,
                daysCompletedRangeFilterSelections: action.payload
            }

        case SET_REPORT_SEARCH_FILTER :
            return {
                ...state,
                searchFilter: action.payload
            }

        case SET_REPORT_ORDER :
            return {
                ...state,
                order: action.payload
            }

        case SET_REPORT_ORDER_BY :
            return {
                ...state,
                orderBy: action.payload
            }

        case SET_REPORT_SORT :
            return {
                ...state,
                order: action.payload.order,
                orderBy: action.payload.orderBy
            }

        case SET_REPORT_TABLE_TYPE :
            return {
                ...state,
                tableType: action.payload
            }

        case SET_REPORT_TABLE_COLUMNS :
            const map:ReportTableColumnsMap = new Map<ReportTableType, ReportTableKeys[] | undefined>(state.columnsMap ? Array.from(state.columnsMap) : []);
            map.set(action.payload.tableType, action.payload.hiddenColumnKeys);
            return {
                ...state,
                columnsMap: map
            }

        case SET_REPORT_ORGS :
            return {
                ...state,
                selectedOrgIds: action.payload
            }

        case SET_REPORT_LOADING :
            return {
                ...state,
                isLoading: action.payload
            }

        case SELECT_REPORT_ORG_PERIOD :
            return {
                ...state,
                selectedOrgPeriod: action.payload
            }

        default :
            return state;
    }
};

export default reportReducer;