import { IStatusState, SNACKBAR_CLOSE, SNACKBAR_OPEN, TypeStatusAction } from './status.types';

import { SIGN_OUT_SUCCESS } from '../user/user.types';

export const INITIAL_STATE:IStatusState = {
	snackbarOpen: false,
	snackbarSeverity: 'success',
	snackbarMessage: '',
	snackbarAutoHideDuration: 5000
};

const statusReducer = (state = INITIAL_STATE, action:TypeStatusAction):IStatusState => {
    switch( action.type ){
        
        case SIGN_OUT_SUCCESS :
            return {
                ...INITIAL_STATE
            };
        
        case SNACKBAR_OPEN :
            return {
				...state,
				snackbarOpen: true,
                snackbarSeverity: action.payload.snackbarSeverity,
                snackbarMessage: action.payload.snackbarMessage,
                snackbarAutoHideDuration: (action.payload.snackbarAutoHideDuration!==undefined) ? action.payload.snackbarAutoHideDuration : INITIAL_STATE.snackbarAutoHideDuration
            };
            
        case SNACKBAR_CLOSE :
            return {
                ...state,
                snackbarOpen: false
			};

        default :
            return state;
    }
};

export default statusReducer;