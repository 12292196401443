import { DashboardTooltip, DashboardTooltipContent, GET_DASH_TOOLTIPS_CONTENT, GET_PSCORE_LEGEND_CONTENT } from './content.types';
import {all, call, put, takeLatest} from 'redux-saga/effects';
import { getDashboardTooltipsDoc, getPScoreLegendDoc } from '../../services/PMotionApi';
import { setDashboardTooltips, setPScoreLegendContent, showContentError } from './content.actions';

import { StatusColor } from '../player/player.types';
import marked from 'marked';
import { startsWith } from 'lodash';

export function* callGetPscoreLegendContent() {
	try {
		const requestData = yield getPScoreLegendDoc();
		if(requestData && requestData.status && requestData.status<300 && requestData.data){
			// make sure markdown is valid (error on CDN returns an HTML page)
			if( startsWith((requestData.data as string).trim(), '#') ){
				const convertedHtml = marked(requestData.data);
				yield put(setPScoreLegendContent(convertedHtml));
			} else {
				throw new Error(`Improper markdown format.`);
			}
        } else {
			throw new Error(`Could not load file${requestData.status ? '. Status ' + requestData.status : ''}.`);
        }

    } catch(error) {
		yield put(
			showContentError('Get P-Score Legend content failed. '+error.message)
		);
    }
    
}

export function* callGetDashboardTooltipsContent() {
	try {
		const requestData = yield getDashboardTooltipsDoc();
		if(requestData && requestData.status && requestData.status<300 && requestData.data){
			// make sure markdown is valid (error on CDN returns an HTML page)
			if( startsWith((requestData.data as string).trim(), '#') ){
				const convertedHtml = marked(requestData.data, {
					breaks: true
				});
				
				const div = document.createElement('div');
				div.innerHTML = convertedHtml;
				
				type SubSection = {
					title:string;
					titleBadgeColor?:StatusColor;
					body:HTMLDivElement;
				}
				type Tooltip = SubSection & {
					id:DashboardTooltip | string;
					accordion?:SubSection[];
				}
				const tooltips:Record<DashboardTooltip | string, DashboardTooltipContent | undefined> = {};
				const sections:Tooltip[] = [];
				let lastSection:Tooltip | undefined;
				let lastSubSection:Tooltip | undefined;
				Array.from(div.children).forEach(node => {
					switch( node.tagName ){

						case 'H1':
							const header:HTMLHeadingElement = node.cloneNode(true) as HTMLHeadingElement;
							const headerTitleBadgeNode = header.querySelector('i, em');
							if( headerTitleBadgeNode ){
								header.removeChild(headerTitleBadgeNode);
							}
							const headerTitleNode = header.querySelector('b, strong');
							if( headerTitleNode ){
								header.removeChild(headerTitleNode);
							}
							if( header.textContent ){
								lastSubSection = undefined;
								lastSection = {
									id: header.textContent.trim(),
									title: headerTitleNode && headerTitleNode.textContent ? headerTitleNode.textContent.trim() : header.textContent.trim(),
									titleBadgeColor: headerTitleBadgeNode && headerTitleBadgeNode.textContent ? headerTitleBadgeNode.textContent.trim() as StatusColor : undefined,
									body: document.createElement('div')
								}
								sections.push(lastSection);
							}
							break;

						case 'H2':
							const subHeader:HTMLHeadingElement = node.cloneNode(true) as HTMLHeadingElement;
							const subHeaderTitleBadgeNode = subHeader.querySelector('i, em');
							if( subHeaderTitleBadgeNode ){
								subHeader.removeChild(subHeaderTitleBadgeNode);
							}
							const subHeaderTitleNode = subHeader.querySelector('b, strong');
							if( subHeaderTitleNode ){
								subHeader.removeChild(subHeaderTitleNode);
							}
							if( lastSection && subHeader.textContent ){
								lastSection.accordion = lastSection.accordion || [];
								lastSubSection = {
									id: subHeader.textContent.trim(),
									title: subHeaderTitleNode && subHeaderTitleNode.textContent ? subHeaderTitleNode.textContent.trim() : subHeader.textContent.trim(),
									titleBadgeColor: subHeaderTitleBadgeNode && subHeaderTitleBadgeNode.textContent ? subHeaderTitleBadgeNode.textContent.trim() as StatusColor : undefined,
									body: document.createElement('div')
								}
								lastSection.accordion.push(lastSubSection);
							}
							break;

							
						default :
							const contentSection = lastSubSection ? lastSubSection : lastSection;
							if(contentSection){
								contentSection.body.append( node.cloneNode(true) );
							}
					}
				});
				sections.forEach(({id, title, titleBadgeColor, body, accordion}) => {
					tooltips[id] = {
						title,
						titleBadgeColor,
						body: accordion ? accordion.map(({title:subTitle, titleBadgeColor:subTitleBadgeColor, body:subBody}) => ({
							title: subTitle,
							titleBadgeColor: subTitleBadgeColor,
							body: subBody.innerHTML
						})) : body.innerHTML
					};
				});
				
				yield put(setDashboardTooltips(tooltips));
			} else {
				throw new Error(`Improper markdown format.`);
			}
        } else {
			throw new Error(`Could not load file${requestData.status ? '. Status ' + requestData.status : ''}.`);
        }

    } catch(error) {
		yield put(
			showContentError('Get Dashboard Tooltips content failed. '+error.message)
		);
    }
    
}


export function* onGetPscoreLegendContent() {
    yield takeLatest(GET_PSCORE_LEGEND_CONTENT, callGetPscoreLegendContent);
}

export function* onGetDashboardTooltipsContent() {
    yield takeLatest(GET_DASH_TOOLTIPS_CONTENT, callGetDashboardTooltipsContent);
}

export function* contentSagas() {
    yield all([
		call( onGetPscoreLegendContent ),
		call( onGetDashboardTooltipsContent )
    ]);
}