import { DOWNLOAD_REPORT, GET_REPORT_MEMBER, GET_REPORT_MEMBERS, IDownloadReport, IDownloadReportPayload, IGetReportMember, IGetReportMemberPayload, IGetReportMembers, IGetReportMembersPayload, IReportPlayer, ISelectReportOrgPeriod, ISetReportDaysCompletedFilter, ISetReportLoading, ISetReportMember, ISetReportMembers, ISetReportOrder, ISetReportOrderBy, ISetReportOrgs, ISetReportPositionFilter, ISetReportRowsPerPage, ISetReportSearchFilter, ISetReportSetPage, ISetReportSort, ISetReportSortPayload, ISetReportState, ISetReportStatePayload, ISetReportStatusFilter, ISetReportTableColumns, ISetReportTableColumnsPayload, ISetReportTableType, IShowReportError, ReportTableType, SELECT_REPORT_ORG_PERIOD, SET_REPORT_DAYS_COMPLETED_FILTER, SET_REPORT_LOADING, SET_REPORT_MEMBER, SET_REPORT_MEMBERS, SET_REPORT_ORDER, SET_REPORT_ORDER_BY, SET_REPORT_ORGS, SET_REPORT_PAGE, SET_REPORT_POSITION_FILTER, SET_REPORT_ROWS_PER_PAGE, SET_REPORT_SEARCH_FILTER, SET_REPORT_SORT, SET_REPORT_STATE, SET_REPORT_STATUS_FILTER, SET_REPORT_TABLE_COLUMNS, SET_REPORT_TABLE_TYPE, SHOW_REPORT_ERROR } from './report.types';
import { Order, OrderByKey, RangeType, TypeFilterSelection } from '../dashboard/dashboard.types';

import { IDType } from '../core.types';
import { OrgPeriod } from '../org/org.types';

export const getReportMembers = (payload:IGetReportMembersPayload):IGetReportMembers => ({
    type: GET_REPORT_MEMBERS,
    payload
});

export const setReportMembers = (members?:IReportPlayer[]):ISetReportMembers => ({
    type: SET_REPORT_MEMBERS,
    payload: members
});

export const showReportError = (error:string):IShowReportError => ({
    type: SHOW_REPORT_ERROR,
    payload: error
});

export const setReportState = (payload?:ISetReportStatePayload):ISetReportState => ({
    type: SET_REPORT_STATE,
    payload
});

export const setReportPage = (pageIndex:number):ISetReportSetPage => ({
    type: SET_REPORT_PAGE,
    payload: pageIndex
})

export const setReportRowsPerPage = (rows:number):ISetReportRowsPerPage => ({
    type: SET_REPORT_ROWS_PER_PAGE,
    payload: rows
})

export const setReportPositionFilter = (selection:TypeFilterSelection):ISetReportPositionFilter => ({
    type: SET_REPORT_POSITION_FILTER,
    payload: selection
})

export const setReportStatusFilter = (selection:TypeFilterSelection):ISetReportStatusFilter => ({
    type: SET_REPORT_STATUS_FILTER,
    payload: selection
})

export const setReportDaysCompletedFilter = (selection:RangeType):ISetReportDaysCompletedFilter => ({
    type: SET_REPORT_DAYS_COMPLETED_FILTER,
    payload: selection
})

export const setReportSearchFilter = (term?:string):ISetReportSearchFilter => ({
    type: SET_REPORT_SEARCH_FILTER,
    payload: term
})

export const setReportOrder = (order?:Order):ISetReportOrder => ({
    type: SET_REPORT_ORDER,
    payload: order
})

export const setReportOrderBy = (orderBy?:OrderByKey):ISetReportOrderBy => ({
    type: SET_REPORT_ORDER_BY,
    payload: orderBy
})

export const setReportSort = (payload:ISetReportSortPayload):ISetReportSort => ({
    type: SET_REPORT_SORT,
    payload
})

export const setReportTableType = (table:ReportTableType):ISetReportTableType => ({
    type: SET_REPORT_TABLE_TYPE,
    payload: table
})

export const setReportTableColumns = (payload:ISetReportTableColumnsPayload):ISetReportTableColumns => ({
    type: SET_REPORT_TABLE_COLUMNS,
    payload
})

export const setReportOrgSelections = (orgIds:IDType[]):ISetReportOrgs => ({
    type: SET_REPORT_ORGS,
    payload: orgIds
})

export const setReportLoading = (bool:boolean):ISetReportLoading => ({
    type: SET_REPORT_LOADING,
    payload: bool
})

export const downloadReport = (payload:IDownloadReportPayload):IDownloadReport => ({
    type: DOWNLOAD_REPORT,
    payload
})

export const getReportMember = (payload:IGetReportMemberPayload):IGetReportMember => ({
    type: GET_REPORT_MEMBER,
    payload
});

export const setReportMember = (member?:IReportPlayer):ISetReportMember => ({
    type: SET_REPORT_MEMBER,
    payload: member
});

export const selectOrgReportPeriod = (period:OrgPeriod):ISelectReportOrgPeriod => ({
    type: SELECT_REPORT_ORG_PERIOD,
    payload: period
});