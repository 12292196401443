import { CorrectiveExerciseStatus } from '../redux/correctives/correctives.types';
import { IDType } from '../redux/core.types';
import { MediaTypes } from '../utils/media-type';
import { Side } from '../redux/assessment/body-parts.types';

type DataLayer = object[];
declare global {
    interface Window { 
		dataLayer?:DataLayer;
	}
}

const getDataLayer = () => {
	return window.dataLayer = window.dataLayer || [];
}

export const trackGAEvent = (category: string, action: string, label: string, nonInteraction:boolean, value?:string|number) => {
	const dataLayer = getDataLayer();

	dataLayer.push({
		event: 'GAevent',
		eventCategory: category,
		eventAction: action,
		eventLabel: label,
		eventValue: value
	});
}

export const trackMediaEvent = (media:MediaTypes, action: string, mediaLabel: string, value?:number) => {
	const dataLayer = getDataLayer();

	dataLayer.push({
		"event": (media===MediaTypes.Audio) ? 'audio' : 'video',
		"eventCategory": (media===MediaTypes.Audio) ? 'Audio' : 'Video',
		"gtm.videoStatus": action,
		"gtm.videoTitle": mediaLabel,
		"gtm.videoPercent": value
	});
}

export const trackMediaStartEvent = (media:MediaTypes, mediaLabel: string) => {
	trackMediaEvent(media, 'start', mediaLabel);
}

export const trackMediaPauseEvent = (media:MediaTypes, mediaLabel: string, percent?:number) => {
	trackMediaEvent(media, 'pause', mediaLabel, percent);
}

export const trackMediaEndedEvent = (media:MediaTypes, mediaLabel: string) => {
	trackMediaEvent(media, 'end', mediaLabel);
}

export const trackExerciseEvent = (actionStatus:CorrectiveExerciseStatus, exerciseTitle: string, exerciseSide?:Side) => {
	const dataLayer = getDataLayer();
	
	dataLayer.push({
		"event": 'exercise',
		"eventCategory": 'Exercise',
		"exerciseAction": actionStatus.toLowerCase(),
		"exerciseTitle": exerciseTitle,
		"exerciseSide": exerciseSide
	});
}

export const trackProgramEvent = (action:string, playerId:IDType|string, assessmentId?:IDType | null) => {
	const dataLayer = getDataLayer();
	
	dataLayer.push({
		"event": 'program',
		"eventCategory": 'Program',
		"programAction": action.toLowerCase(),
		"memberId": playerId,
		"assessmentId": assessmentId!==null ? assessmentId : undefined
	});
}

export const trackOrgEvent = (action:string, orgKey:string, oldOrgKey?:string) => {
	const dataLayer = getDataLayer();
	
	dataLayer.push({
		"event": 'org',
		"eventCategory": 'Org',
		"orgAction": action.toLowerCase(),
		"orgKey": orgKey,
		"oldOrgKey": oldOrgKey
	});
}

export type FilterTrackFunction = (action:string, label:string, value?:string|number) => void;
export const trackDashboardEvent:FilterTrackFunction = (action:string, label:string, value?:string|number) => {
	const dataLayer = getDataLayer();
	
	dataLayer.push({
		"event": 'dashboard',
		"eventCategory": 'Dashboard',
		"dashboardAction": action.toLowerCase(),
		"dashboardLabel": label,
		"dashboardValue": value
	});
}

export const trackRiskOverviewEvent:FilterTrackFunction = (action:string, label:string, value?:string|number) => {
	const dataLayer = getDataLayer();
	
	dataLayer.push({
		"event": 'risk',
		"eventCategory": 'Risk Overview',
		"riskOverviewAction": action.toLowerCase(),
		"riskOverviewLabel": label,
		"riskOverviewValue": value
	});
}