import { IDType } from '../core.types';
import { IOrg } from './org.types';
import { IPlayer } from '../player/player.types';
import {IRootState} from '../root.types';
import {createSelector} from 'reselect';
import { memoize } from 'lodash';
import { stringDeburred } from '../../utils/display';

const selectOrg = (state:IRootState) => state.org;

export const selectOrgIsLoading = createSelector([selectOrg], state => state.isLoading);

export const selectOrgError = createSelector([selectOrg], state => state.error);

export const selectOrgInfo = createSelector([selectOrg], state => state.org);

export const selectTopOrgInfo = createSelector([selectOrg], state => {
	if( state.org && state.org.isTopLevel ){
		return state.org;

	} else if( state.org && state.allOrgs ){

		const findTopOrg = (orgId:IDType, subOrgs:IOrg[]):IOrg | undefined => {
			return subOrgs.find(({id, children}) => {
				return id===orgId || !!(children && findTopOrg(orgId, children));
			});
		}
		return findTopOrg(state.org.id, state.allOrgs);

	} else {
		return state.allOrgs && state.allOrgs.length ? state.allOrgs[0] : undefined
	}
});

export const selectAllOrgs = createSelector([selectOrg], state => state.allOrgs);

export const getFlattenedOrgs = (topOrg:IOrg, excludeSelf:boolean=true, excludeTopLevel:boolean=true):IOrg[] => {
	let flattened:IOrg[] = excludeSelf ? [] : [topOrg];
	if(topOrg.children){
		const flatten = (o:IOrg):IOrg[] => {
			let orgs:IOrg[] = [o];
			const {children} = o;
			if( children ){
				children.forEach(child => {
					orgs = [
						...orgs,
						...flatten(child)
					];
				});
			}
			return orgs
		}
		topOrg.children.forEach(org => {
			flattened = [
				...flattened,
				...flatten(org)
			]
		})
	}
	return excludeTopLevel ? flattened.filter(({isTopLevel}) => !isTopLevel) : flattened;
}

// all sub-orgs of the currently selected top-level Org
export const selectOrgsFlattened = createSelector([selectTopOrgInfo], topOrg => {
	return (topOrg && topOrg.children) ? getFlattenedOrgs(topOrg) : undefined;
});

// all sub-orgs of the currently selected top-level Org, including the top-level org
export const selectOrgsFlattenedWithTop = createSelector([selectTopOrgInfo], topOrg => {
	return topOrg ? getFlattenedOrgs(topOrg, false, false) : undefined;
});

// all orgs, including top-level Orgs
export const selectAllOrgsFlattened = createSelector([selectAllOrgs], allOrgs => {
	return allOrgs ? allOrgs.reduce((accu, org) => {
		accu = [
			...accu,
			...getFlattenedOrgs(org, false, false)
		];
		return accu;
	}, [] as IOrg[]) : undefined;
});

export const selectOrgById = (orgId:IDType) => createSelector([selectAllOrgsFlattened], orgs => orgs ? orgs.find(({id}) => id===orgId) : undefined);

export const selectOrgMembers = createSelector([selectOrgInfo], org => org ? org.members : undefined);

export const selectPendingMembers = createSelector([selectOrgInfo], org => org ? org.pendingMembers : undefined);

export const selectOrgCanShowCompliance = createSelector([selectOrgInfo], org => org ? !!org.showCompliance : false);

export const selectOrgCanAllowExitAssessment = createSelector([selectOrgInfo], org => org ? !!org.allowExitAssessment : false);

export const orgMemberNameDeburred = memoize(({firstName, lastName}:IPlayer) => stringDeburred(`${firstName} ${lastName}`));

export const selectSearchOrgMembers = (term:string) => createSelector([selectOrgMembers], members => {
	term = stringDeburred(term.trim());
	if( members ){
		if(term===''){
			return members;
		} else {
			return members.filter(member => orgMemberNameDeburred(member).includes(term));
		}
	}
	return undefined;
});

export const selectOrgMembersIsLoading = createSelector([selectOrg], state => state.membersIsLoading);

export const selectOrgMembersComplianceIsLoading = createSelector([selectOrg], state => state.membersComplianceIsLoading);

export const selectOrgIsDev = createSelector([selectOrg], state => state.usingDev);

export const selectOrgLastLoginUrl = createSelector([selectOrg], state => state.lastLoginUrl);

export const selectIsOrgRolesLoading = createSelector([selectOrg], state => state.isOrgRolesLoading);

export const selectOrgRoles = createSelector([selectOrg], state => state.orgRoles);

export const selectOrgRolesError = createSelector([selectOrg], state => state.orgRolesError);

export const selectIsAllOrgsLoading = createSelector([selectOrg], state => state.isAllOrgsLoading);

export const selectAllOrgsError = createSelector([selectOrg], state => state.allOrgsError);

export const selectSelectedOrg = createSelector([selectOrg], state => state.selectedOrg);

export const selectEditOrgSuccess = createSelector([selectOrg], state => state.editOrgSuccess);

export const selectEditOrgError = createSelector([selectOrg], state => state.editOrgError);

export const selectTimezones = createSelector([selectOrg], state => state.timezones);

export const selectOrgPeriods = createSelector([selectOrg], state => state.orgPeriods);

export const selectOrgPeriodsIsLoading = createSelector([selectOrg], state => state.orgPeriodsIsLoading);

export const selectOrgPeriodsError = createSelector([selectOrg], state => state.orgPeriodsError);