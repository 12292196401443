import {
    IAssessmentsMap,
    IAssessmentsState,
    LoadableProgramType,
    SET_ALL_PLAYER_ASSESSMENTS,
    SET_PLAYER_ASSESSMENTS_LOADING,
    SHOW_ALL_PLAYER_ASSESSMENTS_ERROR,
    SHOW_PLAYER_ASSESSMENT_ERROR,
    TypeAssessmentsAction,
    UPDATE_PLAYER_ASSESSMENT
} from './assessments.types';

import { GET_EXPANDED_MEASUREMENTS } from './assessments.types';
import { SET_PLAYER, GET_PLAYER } from '../player/player.types';
import { SIGN_OUT_SUCCESS } from '../user/user.types';
import { merge } from 'lodash';

export const INITIAL_STATE:IAssessmentsState = {
	isLoading: true,
	playerId: undefined,
	programOrder: undefined,
	programMap: {},
	error: null,
    assessmentsErrorsMap: {},
    isExpandedMeasurementsLoading: true
};

const assessmentsReducer = (state = INITIAL_STATE, action:TypeAssessmentsAction):IAssessmentsState => {
    switch( action.type ){
        
        case SIGN_OUT_SUCCESS :
            return {
                ...INITIAL_STATE
            };
        
        case SET_PLAYER :
            const newPId = action.payload ? action.payload.id : undefined;
            if( state.playerId!==newPId ){
                /* Reset for new player */    
                return {
                    ...INITIAL_STATE,
                    playerId: newPId
                };
            } else {
                return state;
            }

        case GET_PLAYER :
            return (action.payload.assessments) ? {
                ...state,
                playerId: action.payload.id,
                programOrder: undefined,
	            programMap: {}
            } : state;
        
        case SET_ALL_PLAYER_ASSESSMENTS :
            if( action.payload.playerId===state.playerId){
                return {
                    ...state,
                    programOrder: action.payload.programs.map((program:LoadableProgramType) => program.id),
                    programMap: action.payload.programs.reduce((acc:IAssessmentsMap, program:LoadableProgramType) => {
                        const prev = state.programMap[program.id];
                        acc[program.id] = merge(prev || {}, program)
                        return acc;
                    }, {})
                };
            } else {
                return state;
            }
            
        case SET_PLAYER_ASSESSMENTS_LOADING :
            return {
                ...state,
                isLoading: action.payload
			};
		
		case SHOW_ALL_PLAYER_ASSESSMENTS_ERROR :
			return {
                ...state,
                error: action.payload
            };

        case SHOW_PLAYER_ASSESSMENT_ERROR :
            return {
                ...state,
                assessmentsErrorsMap: {
                    ...state.assessmentsErrorsMap,
                    [action.payload.programId]: action.payload.error
                }
            };

        case GET_EXPANDED_MEASUREMENTS :
            return {
                ...state,
                isExpandedMeasurementsLoading: true
            }

        case UPDATE_PLAYER_ASSESSMENT :
            if( action.payload.playerId===state.playerId){
                const {programMap} = state;
                return {
                    ...state,
                    programMap: {
                        ...programMap,
                        [action.payload.program.id]: {
                            ...action.payload.program
                        }
                    },
                    isExpandedMeasurementsLoading: false,
                };
            } else {
                return state;
            }

        default :
            return state;
    }
};

export default assessmentsReducer;