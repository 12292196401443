import { DashboardTableType, ISetDashboardDaysCompletedFilter, ISetDashboardOrder, ISetDashboardOrderBy, ISetDashboardPositionFilter, ISetDashboardRowsPerPage, ISetDashboardSearchFilter, ISetDashboardSetPage, ISetDashboardSort, ISetDashboardSortPayload, ISetDashboardState, ISetDashboardStatePayload, ISetDashboardStatusFilter, ISetDashboardTableType, Order, OrderByKey, RangeType, SET_DASHBOARD_DAYS_COMPLETED_FILTER, SET_DASHBOARD_ORDER, SET_DASHBOARD_ORDER_BY, SET_DASHBOARD_PAGE, SET_DASHBOARD_POSITION_FILTER, SET_DASHBOARD_ROWS_PER_PAGE, SET_DASHBOARD_SEARCH_FILTER, SET_DASHBOARD_SORT, SET_DASHBOARD_STATE, SET_DASHBOARD_STATUS_FILTER, SET_DASHBOARD_TABLE_TYPE, TypeFilterSelection } from './dashboard.types';

export const setDashboardState = (payload?:ISetDashboardStatePayload):ISetDashboardState => ({
    type: SET_DASHBOARD_STATE,
    payload
});

export const setDashboardPage = (pageIndex:number):ISetDashboardSetPage => ({
    type: SET_DASHBOARD_PAGE,
    payload: pageIndex
})

export const setDashboardRowsPerPage = (rows:number):ISetDashboardRowsPerPage => ({
    type: SET_DASHBOARD_ROWS_PER_PAGE,
    payload: rows
})

export const setDashboardPositionFilter = (selection:TypeFilterSelection):ISetDashboardPositionFilter => ({
    type: SET_DASHBOARD_POSITION_FILTER,
    payload: selection
})

export const setDashboardStatusFilter = (selection:TypeFilterSelection):ISetDashboardStatusFilter => ({
    type: SET_DASHBOARD_STATUS_FILTER,
    payload: selection
})

export const setDashboardDaysCompletedFilter = (selection:RangeType):ISetDashboardDaysCompletedFilter => ({
    type: SET_DASHBOARD_DAYS_COMPLETED_FILTER,
    payload: selection
})

export const setDashboardSearchFilter = (term?:string):ISetDashboardSearchFilter => ({
    type: SET_DASHBOARD_SEARCH_FILTER,
    payload: term
})

export const setDashboardOrder = (order?:Order):ISetDashboardOrder => ({
    type: SET_DASHBOARD_ORDER,
    payload: order
})

export const setDashboardOrderBy = (orderBy?:OrderByKey):ISetDashboardOrderBy => ({
    type: SET_DASHBOARD_ORDER_BY,
    payload: orderBy
})

export const setDashboardSort = (payload:ISetDashboardSortPayload):ISetDashboardSort => ({
    type: SET_DASHBOARD_SORT,
    payload
})

export const setDashboardTableType = (table:DashboardTableType):ISetDashboardTableType => ({
    type: SET_DASHBOARD_TABLE_TYPE,
    payload: table
})