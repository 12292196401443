import { ComplianceLevel, CompliancePoint, IPlayer, Injury, RiskLevel } from '../player/player.types';
import { ISetOrg, OrgPeriod } from '../org/org.types';
import { ISignOutSuccess, ITokenPayload } from '../user/user.types';
import { Order, OrderByKey, RangeType, TypeFilterSelection } from '../dashboard/dashboard.types';

import { IDType } from '../core.types';

export const GET_REPORT_MEMBERS = 'GET_REPORT_MEMBERS';
export const SET_REPORT_MEMBERS = 'SET_REPORT_MEMBERS';
export const SHOW_REPORT_ERROR = 'SHOW_REPORT_ERROR';
export const SET_REPORT_STATE = 'SET_REPORT_STATE';
export const SET_REPORT_PAGE = 'SET_REPORT_PAGE';
export const SET_REPORT_ROWS_PER_PAGE = 'SET_REPORT_ROWS_PER_PAGE';
export const SET_REPORT_POSITION_FILTER = 'SET_REPORT_POSITION_FILTER';
export const SET_REPORT_STATUS_FILTER = 'SET_REPORT_STATUS_FILTER';
export const SET_REPORT_DAYS_COMPLETED_FILTER = 'SET_REPORT_DAYS_COMPLETED_FILTER';
export const SET_REPORT_SEARCH_FILTER = 'SET_REPORT_SEARCH_FILTER';
export const SET_REPORT_ORDER = 'SET_REPORT_ORDER';
export const SET_REPORT_ORDER_BY = 'SET_REPORT_ORDER_BY';
export const SET_REPORT_SORT = 'SET_REPORT_SORT';
export const SET_REPORT_TABLE_TYPE = 'SET_REPORT_TABLE_TYPE';
export const SET_REPORT_TABLE_COLUMNS = 'SET_REPORT_TABLE_COLUMNS';
export const SET_REPORT_ORGS = 'SET_REPORT_ORGS';
export const SET_REPORT_LOADING = 'SET_REPORT_LOADING';
export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const GET_REPORT_MEMBER = 'GET_REPORT_MEMBER';
export const SET_REPORT_MEMBER = 'SET_REPORT_MEMBER';
export const SELECT_REPORT_ORG_PERIOD = 'SELECT_REPORT_ORG_PERIOD';

// Data types
export enum ReportTableType {
    Compliance = 'compliance',
    PScore = 'pscore'
}

export const ReportTableTypeLabels = new Map<ReportTableType, string>([
	[ReportTableType.Compliance, 'Compliance'],
	[ReportTableType.PScore, 'PScore']
]);

export enum ReportFileType {
    Excel = 'excel',
    CSV = 'csv'
}

export type ReportPastAssessment = {
	assessmentRiskScore?:number | null;
    assessmentDate?:string | null;
	assessmentId?:IDType | null;
	riskLevel?:RiskLevel | null;
	injury?:Injury | null;
}

export interface ReportPlayerAddons {
	roleCode?:string | null;
	trend?:number | null;
	mostImpacted?:string | null;
	leastImpacted?:string | null;
	areaImpactedCount?:number | null;
	pscoreHistory?:ReportPastAssessment[] | null;
	groupPScoreHistory?:ReportPastAssessment[] | null;
	complianceHistory?:CompliancePoint[] | null;
	groupComplianceHistory?:CompliancePoint[] | null;
	groupName?:string | null;
	groupCompliance?:number | null;
	groupComplianceDiff?:number | null;
	groupPScore?:number | null;
	groupPScoreDiff?:number | null;
	complianceLevel?:ComplianceLevel | null;
	complianceGreenMin?:number | null;
	complianceYellowMin?:number | null;
	complianceDate?:string | null;
	recentDateRange?:number | null;
	recentlyCompletedWorkouts?:number | null;
}
export type IReportPlayer = IPlayer & ReportPlayerAddons;

export enum ReportTableKeys {
    id = 'id',
    name = 'name',
	height = 'height',
	weight = 'weight',
	throws = 'throws',
	bats = 'bats',
	position = 'position',
	pscore = 'pscore',
    compliance = 'compliance',
    trend = 'trend',
	change = 'change',
	mostImpacted = 'mostImpacted',
	leastImpacted = 'leastImpacted',
	impacted = 'impacted',
	risk = 'risk',
    latestPScores = 'latestPScores',
    latestCompliance = 'latestCompliance',
    side = 'side',
    groupProjection = 'groupProjection',
	groupProjection2 = 'groupProjection2',
    complianceLevel = 'complianceLevel',
    chart = 'chart'
}

export type ReportTableColumnsMap = Map<ReportTableType, ReportTableKeys[] | undefined>;


// State type
export interface IReportState {
	isLoading:boolean;
	members?:IReportPlayer[];
	error?:string;
	page:number;
	rowsPerPage:number;
	positionFilterSelections:TypeFilterSelection;
	statusFilterSelections:TypeFilterSelection;
	daysCompletedRangeFilterSelections:RangeType;
	searchFilter?:string;
	order?:Order;
	orderBy?:OrderByKey;
	tableType?:ReportTableType;
	columnsMap?:ReportTableColumnsMap;
	selectedOrgIds?:IDType[];
	member?:IReportPlayer;
	selectedOrgPeriod?:OrgPeriod;
}


// Action types
export interface IGetReportMembersPayload extends ITokenPayload {
	orgIds: IDType[];
	tableType:ReportTableType;
}
export interface IGetReportMembers {
	type: typeof GET_REPORT_MEMBERS;
	payload: IGetReportMembersPayload;
}
export interface ISetReportMembers {
	type: typeof SET_REPORT_MEMBERS;
	payload?: IReportPlayer[];
}

export interface IGetReportMemberPayload extends ITokenPayload {
	memberId: IDType;
	report?:ReportTableType;
	startDate?:Date;
	endDate?:Date;
}
export interface IGetReportMember {
	type: typeof GET_REPORT_MEMBER;
	payload: IGetReportMemberPayload;
}
export interface ISetReportMember {
	type: typeof SET_REPORT_MEMBER;
	payload?: IReportPlayer;
}

export interface IShowReportError {
	type: typeof SHOW_REPORT_ERROR;
	payload?: string;
}
export interface ISetReportStatePayload {
	page?:number;
	rowsPerPage?:number;
	positionFilterSelections?:TypeFilterSelection;
	statusFilterSelections?:TypeFilterSelection;
	daysCompletedRangeFilterSelections?:RangeType;
	searchFilter?:string;
	order?:Order;
	orderBy?:OrderByKey;
	tableType?:ReportTableType;
}
export interface ISetReportState {
	type: typeof SET_REPORT_STATE;
	payload?: ISetReportStatePayload;
};

export interface ISetReportSetPage {
	type: typeof SET_REPORT_PAGE;
	payload:number;
};

export interface ISetReportRowsPerPage {
	type: typeof SET_REPORT_ROWS_PER_PAGE;
	payload:number;
};

export interface ISetReportPositionFilter {
	type: typeof SET_REPORT_POSITION_FILTER;
	payload:TypeFilterSelection;
};

export interface ISetReportStatusFilter {
	type: typeof SET_REPORT_STATUS_FILTER;
	payload:TypeFilterSelection;
};

export interface ISetReportDaysCompletedFilter {
	type: typeof SET_REPORT_DAYS_COMPLETED_FILTER;
	payload:RangeType;
};

export interface ISetReportSearchFilter {
	type: typeof SET_REPORT_SEARCH_FILTER;
	payload?:string;
};

export interface ISetReportOrder {
	type: typeof SET_REPORT_ORDER;
	payload?:Order;
};

export interface ISetReportOrderBy {
	type: typeof SET_REPORT_ORDER_BY;
	payload?:OrderByKey;
};

export interface ISetReportSortPayload {
	order?:Order;
	orderBy?:OrderByKey;
};
export interface ISetReportSort {
	type: typeof SET_REPORT_SORT;
	payload:ISetReportSortPayload;
};

export interface ISetReportTableType {
	type: typeof SET_REPORT_TABLE_TYPE;
	payload:ReportTableType;
};

export interface ISetReportTableColumnsPayload {
	tableType:ReportTableType;
	hiddenColumnKeys?:ReportTableKeys[];
}
export interface ISetReportTableColumns {
	type: typeof SET_REPORT_TABLE_COLUMNS;
	payload:ISetReportTableColumnsPayload;
};

export interface ISetReportOrgs {
	type: typeof SET_REPORT_ORGS;
	payload:IDType[];
};

export interface ISetReportLoading {
	type: typeof SET_REPORT_LOADING;
	payload:boolean;
};

export interface IDownloadReportPayload extends ITokenPayload {
	orgIds:IDType[];
	reportType:ReportTableType;
	fileType:ReportFileType;
};
export interface IDownloadReport {
	type: typeof DOWNLOAD_REPORT;
	payload:IDownloadReportPayload;
};

export interface ISelectReportOrgPeriod {
	type: typeof SELECT_REPORT_ORG_PERIOD;
	payload:OrgPeriod;
};

export type TypeReportAction = ISetReportState | ISetReportSetPage | ISetReportRowsPerPage | ISetReportPositionFilter | ISetReportStatusFilter | 
ISetReportDaysCompletedFilter | ISetReportSearchFilter | ISetReportOrder | ISetReportOrderBy | ISetReportSort | ISignOutSuccess | ISetReportTableType | 
IGetReportMembers | ISetReportMembers | IShowReportError | ISetReportTableColumns | ISetReportOrgs | ISetOrg | ISetReportLoading | IDownloadReport | 
IGetReportMember | ISetReportMember | ISelectReportOrgPeriod;