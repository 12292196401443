import { COMPLETE_PLAYER_PROGRAM, GET_PLAYER, GET_PLAYER_COMPLIANCE_HISTORY, IPlayerState, MARK_PLAYER_INJURY_STATUS, PLAYER_PROGRAM_FAILURE, PLAYER_PROGRAM_SUCCESS, RESTART_PLAYER_PROGRAM, SET_PLAYER, SET_PLAYER_COMPLIANCE_HISTORY, SET_PLAYER_LOADING, SHOW_COMPLIANCE_HISTORY_ERROR, SHOW_PLAYER_ERROR, TypePlayerAction } from './player.types';

import { SIGN_OUT_SUCCESS } from '../user/user.types';

export const INITIAL_STATE:IPlayerState = {
	isLoading: true,
    player: undefined,
    error: null,
    complianceHistory: undefined,
	complianceHistoryIsLoading: false,
    complianceHistoryError: undefined,
    complianceGreenMin: undefined,
	complianceYellowMin: undefined,
    programLoading: false,
	programSuccess: undefined,
    programError: undefined
};

const playerReducer = (state = INITIAL_STATE, action:TypePlayerAction):IPlayerState => {
    switch( action.type ){
        
        case SIGN_OUT_SUCCESS :
            /* Reset */    
            return {
                ...INITIAL_STATE
            };

        case GET_PLAYER :
            return {
                ...state,
                isLoading: true,
                player: state.player && action.payload.id===state.player.id ? {
                    ...state.player
                } : undefined,
                error: null,
            };
        
        case SET_PLAYER_LOADING :
            return {
                ...state,
                isLoading: action.payload
            };
            
        case SET_PLAYER :
            return {
                ...state,
                player: action.payload,
                isLoading: !action.payload,
                programLoading: false,
                programSuccess: undefined,
                programError: undefined
			};
		
		case SHOW_PLAYER_ERROR :
			return {
                ...state,
                error: action.payload
            };

        case RESTART_PLAYER_PROGRAM :
        case COMPLETE_PLAYER_PROGRAM :
        case MARK_PLAYER_INJURY_STATUS :
            return {
                ...state,
                programLoading: true,
                programSuccess: undefined,
                programError: undefined
            };

        case PLAYER_PROGRAM_SUCCESS :
            return {
                ...state,
                programLoading: false,
                programSuccess: true,
                programError: undefined
            };

        case PLAYER_PROGRAM_FAILURE :
            return {
                ...state,
                programLoading: false,
                programSuccess: false,
                programError: action.payload
            };

        case GET_PLAYER_COMPLIANCE_HISTORY :
            return {
                ...state,
                complianceHistory: undefined,
                complianceGreenMin: undefined,
	            complianceYellowMin: undefined,
	            complianceHistoryIsLoading: true,
                complianceHistoryError: undefined
            };

        case SET_PLAYER_COMPLIANCE_HISTORY :
            return {
                ...state,
                complianceHistory: action.payload.history,
                complianceGreenMin: action.payload.complianceGreenMin,
	            complianceYellowMin: action.payload.complianceYellowMin,
                complianceHistoryIsLoading: false,
                complianceHistoryError: undefined
            };

        case SHOW_COMPLIANCE_HISTORY_ERROR :
            return {
                ...state,
                complianceHistoryIsLoading: false,
                complianceHistoryError: action.payload
            };
        
        default :
            return state;
    }
};

export default playerReducer;