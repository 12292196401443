import { GET_ALL_MESSAGE_THREADS, GET_MESSAGE_THREAD, GET_MESSAGE_THREAD_BY_MEMBERS, GET_NEW_MESSAGE_THREAD_COUNT, IGetAllMessageThreads, IGetMessageThread, IGetMessageThreadByMembers, IGetNewMessageThreadCount, IMarkMessageStatus, IMessage, IMessageThread, ISendMessage, MARK_MESSAGE_STATUS, MessageStatus, SEND_MESSAGE } from './message.types';
import {all, call, put, takeEvery, takeLatest} from 'redux-saga/effects';
import { getAllMessageThreads, getMessageThread, getNewMessageThreadCount, markMessageStatusSuccess, setAllMessageThreads, setMessageThread, setNewMessageThreadCount, showAllMessageThreadsError, showMessageThreadError } from './message.actions';
import { getAllMessageThreads as getAllMessageThreadsAPI, getMessageThread as getMessageThreadAPI, getMessageThreadByMembers as getMessageThreadByMembersAPI, getNewMessageThreadCount as getNewMessageThreadCountAPI, markMessageAsRead, sendMessage } from '../../services/PMotionApi';

import { IDType } from '../core.types';
import { IPlayer } from '../player/player.types';
import { isNumber } from '../../utils/validator';
import { isUserTokenValid } from '../user/user.sagas';

interface IRemoteMessage extends IMessage {
	messageThreadId:IDType;
	userId:IDType;
	createdDate?:string | null;
}
interface IRemoteMessagePlayer extends Omit<IPlayer, 'userId'> {
	userId?:IDType | null;
	iconUrl?:string | null;
}

interface IRemoteMessageThread extends Partial<Omit<IMessageThread, 'messageCount' | 'messages' | 'users' | 'lastMessage'>> {
	messageCount?:number | null;
	messages?:IRemoteMessage[] | null;
	users:IRemoteMessagePlayer[];
	lastMessage?:IRemoteMessage | null;
}

export function* getAllThreads({payload: {userId, token}}:IGetAllMessageThreads) {
	
	// get info
	try {
		const threadsInfo = yield getAllMessageThreadsAPI(userId, token);
		const valid = yield isUserTokenValid(threadsInfo.data, token);
		if( valid ){
			if( threadsInfo.data && Array.isArray(threadsInfo.data) ){
				const threads = (threadsInfo.data as IRemoteMessageThread[]).map(thread => convertRemoteMessageThread(thread));
	
				yield put(
					setAllMessageThreads( threads.filter(({messageCount}) =>  messageCount > 0) ) // filter out threads with no messages
				);
			} else {
				throw new Error('Get All Message Threads API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showAllMessageThreadsError('Get All Message Threads data failed. '+er.message)
		);
	}
    
}

export function* getSingleThread({payload: {threadId, authorId, token}}:IGetMessageThread) {
	
	// get info
	try {
		const threadInfo = yield getMessageThreadAPI(threadId, authorId, token);
		const valid = yield isUserTokenValid(threadInfo.data, token);
		if( valid ){
			if( threadInfo.data ){
				const thread = convertRemoteMessageThread(threadInfo.data as IRemoteMessageThread);
				
				yield put(
					setMessageThread({
						...thread,
						id: threadId
					})
				);
			} else {
				throw new Error('Get Single Message Thread API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showMessageThreadError('Get Single Message Thread data failed. '+er.message)
		);
	}
    
}

const convertRemoteMessageUser = ({iconUrl, userId, ...player}:IRemoteMessagePlayer):IPlayer => {
	return {
		...player,
		userId: userId ? userId : player.id,
		image: iconUrl ? iconUrl : player.image
	}
}

const convertRemoteMessage = ({userId, createdDate, messageThreadId, ...message}:IRemoteMessage):IMessage => {
	return {
		...message,
		threadId: messageThreadId,
		authorId: userId ? userId : message.authorId,
		sentDate: createdDate ? createdDate : message.sentDate
	}
}

const convertRemoteLastMessage = ({content, ...message}:IRemoteMessage):IMessage => {
	return convertRemoteMessage({
		...message,
		excerpt: content,
		content
	})
}

// Since thread object will come in different forms from different APIs, let's merge the info we get and strip out the empty props, so we don't override them
const stripEmptyProps = (obj:any):any => {
	for (const prop in obj) {
		const val = obj[prop];
		if(val===null || val===undefined){
			delete obj[prop]
		}
	}
	return obj;
}

const convertRemoteMessageThread = ({lastMessage, messageCount, messages, users, ...thread}:IRemoteMessageThread):IMessageThread => {
	const convertedMessages = messages ? messages.map(message => convertRemoteMessage(message)) : undefined;
	return stripEmptyProps({
		...thread,
		lastMessage: lastMessage ? convertRemoteLastMessage(lastMessage) : undefined,
		messageCount: isNumber(messageCount) ? (messageCount as number) : convertedMessages ? convertedMessages.length : 0,
		messages: convertedMessages,
		users: users.map(user => convertRemoteMessageUser(user))
	})
}

export function* getSingleThreadByMembers({payload: {userId, orgKey, memberIds, token}}:IGetMessageThreadByMembers) {
	
	// get info
	try {
		const threadInfo = yield getMessageThreadByMembersAPI(userId, orgKey, memberIds.sort((a, b) => a - b), token); // sort ids asc
		const valid = yield isUserTokenValid(threadInfo.data, token);
		if( valid ){
			if( threadInfo.data ){
				const thread = convertRemoteMessageThread(threadInfo.data as IRemoteMessageThread);
	
				if( thread.messageCount > 0 ){
					const threadMessageInfo = yield getMessageThreadAPI(thread.id, userId, (typeof valid === 'string') ? valid : token);
					if( threadMessageInfo.data ){
						const messageThread = convertRemoteMessageThread(threadMessageInfo.data as IRemoteMessageThread);
						
						yield put(
							setMessageThread({
								...thread,
								...messageThread,
								id: thread.id
							})
						);
					}
				} else {
					yield put(
						setMessageThread(thread)
					);
				}
			} else {
				//throw new Error('Get Single Message Thread API returned, but cannot read data.')
				yield put(
					setMessageThread(undefined) // clear and stop loading
				);
			}
		}
	} catch(er){
		yield put(
			showMessageThreadError('Get Single Message Thread by Members data failed. '+er.message)
		);
	}
    
}

export function* sendAMessage({payload: {authorId, content, threadId, token}}:ISendMessage) {
	
	// get info
	try {
		const responseInfo = yield sendMessage(threadId, authorId, content, token);
		const valid = yield isUserTokenValid(responseInfo.data, token);
		if( valid ){
			if( responseInfo.data && responseInfo.data===true ){

				yield put( // since we're forwarding the user to the thread view screen, this may not be needed
					getMessageThread({
						threadId,
						authorId,
						token: (typeof valid === 'string') ? valid : token
					})
				);
	
				yield put(
					getAllMessageThreads({
						userId: authorId,
						token: (typeof valid === 'string') ? valid : token
					})
				);
			} else {
				throw new Error('API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showMessageThreadError('Sending Message failed. '+er.message)
		);
	}
    
}

export function* newMessageThreadCountGetter({payload: {userId, token, failSilently}}:IGetNewMessageThreadCount) {
	
	// get info
	try {
		const threadsInfo = yield getNewMessageThreadCountAPI(userId, token);
		const valid = yield isUserTokenValid(threadsInfo.data, token, failSilently);
		if( valid ){
			if( threadsInfo.data!==undefined && isNumber(threadsInfo.data) ){
				yield put(
					setNewMessageThreadCount(threadsInfo.data as number)
				);
			} else {
				yield put(
					setNewMessageThreadCount(0)
				);
			}
		}
	} catch(er){
		// fail silently
		console.log('API ERROR messaging/getunreadcount failed.', er.message);
		yield put(
			setNewMessageThreadCount(0)
		);
	}
    
}

export function* markMmsgStatus({payload: {threadId, messageIds, status, userId, token}}:IMarkMessageStatus) {
	
	// get info
	try {
		if( status===MessageStatus.Read ){
			const threadsInfo = yield markMessageAsRead(messageIds, userId, token);
			const valid = yield isUserTokenValid(threadsInfo.data, token);
			if( valid ){
				if( threadsInfo.data && threadsInfo.data===true ){
					yield put(
						markMessageStatusSuccess({threadId, messageIds, status, userId})
					);
	
					yield put(
						getNewMessageThreadCount({
							userId, 
							token: (typeof valid === 'string') ? valid : token
						})
					);
		
				} else {
					console.log('Mark Message As Read API returned, but cannot read data.', threadsInfo.data);
				}
			}
		}
	} catch(er){
		// fail silently
	}
    
}

export function* onGetAllThreads() {
    yield takeLatest(GET_ALL_MESSAGE_THREADS, getAllThreads);
}

export function* onGetSingleThread() {
    yield takeLatest(GET_MESSAGE_THREAD, getSingleThread);
}

export function* onGetSingleThreadByMembers() {
    yield takeLatest(GET_MESSAGE_THREAD_BY_MEMBERS, getSingleThreadByMembers);
}

export function* onSendMessage() {
    yield takeLatest(SEND_MESSAGE, sendAMessage);
}

export function* onGetNewMessageThreadCount() {
    yield takeLatest(GET_NEW_MESSAGE_THREAD_COUNT, newMessageThreadCountGetter);
}

export function* onMarkMessageStatus() {
    yield takeEvery(MARK_MESSAGE_STATUS, markMmsgStatus);
}

export function* messageSagas() {
    yield all([
		call( onGetAllThreads ),
		call( onGetSingleThread ),
		call( onGetSingleThreadByMembers ),
		call( onSendMessage ),
		call( onGetNewMessageThreadCount ),
		call( onMarkMessageStatus )
    ]);
}