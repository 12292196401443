import React, {ComponentPropsWithRef, useRef} from 'react';
import {
	Theme,
	makeStyles
} from "@material-ui/core/styles";

import { ObservableRefAPI } from '../../hooks/useObservableRef.hook';
import {SrcMapType} from '../../utils/src-map';
import clsx from "clsx";
import { getAssetPath } from '../../utils/routes';
import { useForwardedRef } from '../../hooks/useForwardedRef.hook';
import useImageLoader from '../../hooks/useImageLoader.hook';

const useStyles = makeStyles((theme: Theme) => ({	
	responsive: {
		width: '100%',
        height: 'auto',
        verticalAlign: 'middle',
        border: 0
	},
	responsiveHeight: {
		objectFit: 'contain',
		width: 'auto',
		height: '100%',
		border: 0
	}
}));

export interface ResponsiveImageProps extends ComponentPropsWithRef<'img'> {
	sizeToHeight?:boolean;
	srcMapping?:SrcMapType;
	lazyLoad?:boolean;
	placeholderSrc?:string;
	scaleObserver?:ObservableRefAPI<number>;
}

const ResponsiveImage:React.FC<ResponsiveImageProps> = React.forwardRef<HTMLImageElement, ResponsiveImageProps>(({
	sizeToHeight,
	srcMapping,
	lazyLoad,
	placeholderSrc,
	className,
	alt,
	src : originalSrc,
	scaleObserver,
	...props
}, ref) => {
	const classes = useStyles();
	
	const imageRef = ref ? useForwardedRef<HTMLImageElement>(ref) : useRef<HTMLImageElement | null>(null);

	srcMapping = srcMapping || new Map<string | number, string>();
	if(originalSrc){
		srcMapping?.set('src', originalSrc);
	}

	placeholderSrc = placeholderSrc ? getAssetPath(placeholderSrc) : placeholderSrc;

	const {src, srcset, sizes} = useImageLoader(imageRef, srcMapping, !lazyLoad, placeholderSrc, scaleObserver);
	
	return (
		<img ref={imageRef} src={src} sizes={sizes} srcSet={srcset} alt={alt} className={clsx(
			!sizeToHeight && classes.responsive,
			sizeToHeight && classes.responsiveHeight,
			className && className
		)} {...props}  />
	);
});

export default ResponsiveImage;