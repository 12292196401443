import { Colors, Sizes } from './utils/style';
import { ConnectedProps, connect } from "react-redux";
import { CssBaseline, Typography } from '@material-ui/core';
import { Environment, IUser, isUserReady } from './redux/user/user.types';
import { IOrg, OrgAccessLevel } from './redux/org/org.types';
import React, { Suspense, lazy, useCallback, useMemo, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Routes, {PrivateRoute} from './utils/routes';
import {
	Theme,
	createStyles,
	makeStyles,
	withStyles
} from "@material-ui/core/styles";
import { VERSION, mainRef } from './utils/PMotionGlobals';
import { getOrgLoginPath, getPlayerPath } from './utils/routes';
import { selectCurrentUser, selectEnvironment, selectUserCanAccess, selectCurrentUserHasStaffAccess } from './redux/user/user.selectors';

import ErrorBoundary from "./components/error-boundary/error-boundary.component";
import Footer from './components/footer/footer.component';
import Header from "./components/header/header.component";
import { IRootState } from "./redux/root.types";
import Snackbar from './components/snackbar/snackbar.component';
import SpinnerBackdrop from './components/spinner-backdrop/spinner-backdrop.component';
import { createStructuredSelector } from "reselect";
import { globalStyles } from './themes/App.theme';
import orgs from './orgs.json';
import { selectOrgInfo } from './redux/org/org.selectors';

const HomePage = lazy(() => import("./pages/home/home.page"));
const HomeManagementPage = lazy(() => import("./pages/home-management/home-management.page"));
const LoginPage = lazy(() => import("./pages/login/login.page"));
const ResetPasswordPage = lazy(() => import("./pages/reset-password/reset-password.page"));
const JoinPage = lazy(() => import("./pages/join/join.page"));
const ChangePasswordPage = lazy(() => import("./pages/change-password/change-password.page"));
const AssessmentPage = lazy(() => import("./pages/assessment/assessment.page"));
const PlayerPage = lazy(() => import("./pages/player/player.page"));
const PlayerManagementPage = lazy(() => import("./pages/player-management/player-management.page"));
const ExercisePage = lazy(() => import("./pages/exercise/exercise.page"));
const AccountPage = lazy(() => import("./pages/account/account.page"));
const MessagesPage = lazy(() => import("./pages/messages/messages.page"));
const TermsPage = lazy(() => import("./pages/terms/terms.page"));
const AdminPage = lazy(() => import("./pages/admin/admin.page"));
const ReportingPage = lazy(() => import("./pages/reporting/reporting.page"));
const SubscriptionPage = lazy(() => import("./pages/subscription/subscription.page"));

// org pages
const PerfectGameWwbaPage = lazy(() => import("./orgs/perfectgame/pages/wwba.page"));
const PerfectGameSignupConfirmationPage = lazy(() => import("./orgs/perfectgame/pages/signup-confirmation.page"));
const BaseballFactorySignupPage = lazy(() => import("./orgs/baseballfactory/pages/baseball_factory_signup.page"));
const GenericBaseballSignupPage = lazy(() => import("./signup/pages/generic-signup.page"));
const GenericSignupFlowPage = lazy(() => import("./signup/pages/generic-signup-flow.page"));


/*if (process.env.NODE_ENV !== 'production') {
	const whyDidYouRender = require('@welldone-software/why-did-you-render');
	whyDidYouRender(React, { include: [/^ConnectFunction$/] });
}*/

const footerHeight = 84;
const desktopDiff = Sizes.HeaderHeight + footerHeight + 2;
const footerMobileHeight = 76;
const mobileDiff = Sizes.HeaderHeight + footerMobileHeight + 2;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			maxWidth: Sizes.MaxWidth,
			marginLeft: "auto",
			marginRight: "auto",

			'& .positionRelative': {
				position: 'relative',
			},

			[theme.breakpoints.down("lg")]: {
				width: `calc(100vw - ${Sizes.MobileGutter * 2}px)`,
			},

			[theme.breakpoints.down("sm")]: {
				//width: `calc(100vw - ${Sizes.MobileGutter * 2}px)`,

				'& .fullWidthMobile': {
					width: '100vw',
					maxWidth: '100vw',
					marginLeft: Sizes.MobileGutter * -1,
					marginRight: Sizes.MobileGutter * -1,

					'& figcaption': {
						paddingLeft: Sizes.MobileGutter,
						paddingRight: Sizes.MobileGutter
					}
				}
			},
		},
		main: {
			minHeight: theme.viewport.percentOfHeight(100, desktopDiff),

			[theme.breakpoints.down("sm")]: {
				minHeight: theme.viewport.percentOfHeight(100, mobileDiff),
			},
		},
		environment: {
			position: 'fixed',
			top: 0,
			left: 0,
			zIndex: theme.zIndex.modal + 1,
			border: '5px solid #c70000',
			width: '100%',
			height: theme.viewport.percentOfHeight(100),
			pointerEvents: 'none'
		},
		environmentLabel: {
			position: 'absolute',
			bottom: 0,
			right: 0,
			display: 'block',
			fontSize: theme.typography.pxToRem(12),
			lineHeight: theme.typography.pxToRem(17),
			padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(2)} ${theme.typography.pxToRem(10)}`,
			backgroundColor: '#c70000',
			color: Colors.White,
			textTransform: 'uppercase'
		}
	})
);

interface mapStateToPropsInterface {
	currentUser?: IUser;
	org?:IOrg;
	hasLevel2Access:boolean;
	hasLevel3Access:boolean;
	hasStaffAccess:boolean;
	environment?:Environment | string;
}
const mapStateToProps = createStructuredSelector<
	IRootState,
	mapStateToPropsInterface
>({
	currentUser: selectCurrentUser,
	org: selectOrgInfo,
	hasLevel2Access: selectUserCanAccess(OrgAccessLevel.Level2),
	hasLevel3Access: selectUserCanAccess(OrgAccessLevel.Level3), // admin
	hasStaffAccess: selectCurrentUserHasStaffAccess,
	environment: selectEnvironment
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type RootContainerProps = PropsFromRedux;

export const RootContainer: React.FC<RootContainerProps> = ({ currentUser, org, hasLevel2Access, hasLevel3Access, hasStaffAccess, environment }) => {
	const classes = useStyles();

	const [hasErrored, setHasErrored] = useState<boolean>(false);

	const onError = useCallback(() => {
		setHasErrored(true);
	}, [setHasErrored]);

	/*const location = useLocation();
	type hasFrom = {
		from?:string;
	}
	let originalFrom = (location.state && (location.state as hasFrom).from) ? (location.state as hasFrom).from : undefined;
	const from = originalFrom ? originalFrom : Routes.HOME;*/
	const from = (org && org.memberId && !hasLevel2Access) ? getPlayerPath(org.memberId) : Routes.HOME;

	const isReadyUser:boolean = useMemo(() => isUserReady(currentUser), [currentUser]);

	const privateRedirect:string | undefined = useMemo(() => {
		if(currentUser && currentUser.needsNewPassword && currentUser.tempPassword){
			return Routes.CHANGE_PASSWORD;
		} else if(currentUser && !isReadyUser){
			return Routes.TERMS;
		} else {
			return undefined;
		}
	}, [currentUser, isReadyUser]);
	
	return (
		<div className={classes.root} id="rootContainer">
			<CssBaseline />
			{isReadyUser && !hasErrored && (
				<Header />
			)}
			<div className={classes.main} ref={mainRef}>
				<Switch>
					<ErrorBoundary onError={onError}>
						<Suspense fallback={<SpinnerBackdrop isLoading={true} />}>
							<PrivateRoute
								exact
								path={Routes.HOME}
								//component={HomePage}
								render={() =>
									hasLevel2Access ? (
										<HomePage />
									) : org && org.memberId ? (
										<Redirect to={getPlayerPath(org.memberId)} />
									) : null
								}
								isAuthenticated={isReadyUser}
								redirectPath={privateRedirect}
							/>
							<PrivateRoute
								exact
								path={Routes.HOME_MANAGMENT}
								component={HomeManagementPage}
								isAuthenticated={isReadyUser && hasLevel2Access}
								redirectPath={privateRedirect}
							/>
							<Route
								exact
								path={Routes.RESET_PASSWORD}
								component={ResetPasswordPage}
							/>
							<Route
								exact
								path={Routes.JOIN}
								component={JoinPage}
							/>
							<PrivateRoute
								exact
								path={Routes.CHANGE_PASSWORD}
								component={ChangePasswordPage}
								isAuthenticated={!!currentUser && !!currentUser.needsNewPassword && !!currentUser.tempPassword}
							/>
							<Route
								exact
								path={Routes.LOGIN}
								render={(routeProps) =>
									currentUser ? (
										<Redirect to={from} />
									) : (
										<LoginPage {...routeProps} />
									)
								}
							/>
							{Object.keys(orgs).filter(key => key!=='ROOT').map(key => (
								<Route
									key={`login${key}`}	
									exact
									path={getOrgLoginPath(key)}
									render={(routeProps) =>
										currentUser ? (
											<Redirect to={from} />
										) : (
											<LoginPage orgName={key} {...routeProps} />
										)
									}
								/>
							))}
							<PrivateRoute
								exact
								path={Routes.ASSESSMENT}
								component={AssessmentPage}
								isAuthenticated={isReadyUser && hasLevel2Access}
								redirectPath={privateRedirect}
							/>
							<PrivateRoute
								path={Routes.PLAYER}
								component={PlayerPage}
								isAuthenticated={isReadyUser}
								redirectPath={privateRedirect}
							/>
							<PrivateRoute
								path={Routes.PLAYER_MANAGEMENT}
								component={PlayerManagementPage}
								isAuthenticated={isReadyUser && hasLevel2Access}
								redirectPath={privateRedirect}
							/>
							<PrivateRoute
								path={Routes.EXERCISE}
								component={ExercisePage}
								isAuthenticated={isReadyUser}
								redirectPath={privateRedirect}
							/>
							<PrivateRoute
								path={Routes.ACCOUNT}
								component={AccountPage}
								isAuthenticated={isReadyUser}
								redirectPath={privateRedirect}
							/>
							<PrivateRoute
								path={Routes.MESSAGES}
								component={MessagesPage}
								isAuthenticated={isReadyUser}
								redirectPath={privateRedirect}
							/>
							<PrivateRoute
								exact
								path={Routes.TERMS}
								render={(routeProps) =>
									currentUser && !currentUser.termsAccepted ? (
										<TermsPage {...routeProps} />
									) : hasLevel2Access ? (
										<Redirect to={Routes.HOME} />
									) : org && org.memberId ? (
										<Redirect to={getPlayerPath(org.memberId)} />
									) : null
								}
								isAuthenticated={!!currentUser}
							/>
							<PrivateRoute
								path={Routes.ADMIN}
								component={AdminPage}
								isAuthenticated={isReadyUser && hasLevel3Access}
								redirectPath={privateRedirect}
							/>
							<PrivateRoute
								path={Routes.REPORTS}
								component={ReportingPage}
								isAuthenticated={isReadyUser && hasLevel3Access && hasStaffAccess}
								redirectPath={privateRedirect}
							/>
							<PrivateRoute
								path={Routes.SUBSCRIPTION}
								component={SubscriptionPage}
								isAuthenticated={isReadyUser}
								redirectPath={privateRedirect}
							/>
							{/* Perfect Game routes */}
							<Route
								exact
								path="/perfectgame/wwba"
								component={PerfectGameWwbaPage}
							/>
							<Route
								exact
								path="/perfectgame/signup-confirmation"
								component={PerfectGameSignupConfirmationPage}
							/>
							{/* Baseball Factory routes */}
							<Route
								exact
								path="/baseball-factory/signup"
								component={BaseballFactorySignupPage}
							/>

							{/* Generic Baseball Signup Routes */}
							<Route
								exact
								path="/signup/code"
								component={GenericBaseballSignupPage}
							/>

							<Route
								exact
								path="/signup/flow"
								component={GenericSignupFlowPage}
							/>


						</Suspense>
					</ErrorBoundary>
				</Switch>
			</div>	
			{isReadyUser && (
				<Footer />
			)}
			{environment && environment.toLowerCase()!==Environment.Prod.toLowerCase() && (
				<div className={classes.environment}>
					<Typography variant="h4" component="span" className={classes.environmentLabel}>{environment} {VERSION}</Typography>
				</div>
			)}
			<Snackbar />
		</div>
	);
};

export default connector(withStyles(globalStyles)(RootContainer));