import {
    AUTO_SAVE_ASSESSMENT_FAILURE,
    DELETE_ASSESSMENT,
    GET_ASSESSMENT,
    GET_CACHED_ASSESSMENT,
    IAssessmentState,
    RESTORE_CACHED_ASSESSMENT,
    SAVE_ASSESSMENT,
    SAVE_ASSESSMENT_FAILURE,
    SAVE_ASSESSMENT_SUCCESS,
    SET_ASSESSMENT,
    SET_ASSESSMENT_ORG_MEMBERS,
    SET_CACHED_ASSESSMENT,
    SET_CURRENT_ORG_MEMBER,
    SHOW_ASSESSMENT_ERROR,
    TypeAssessmentAction,
    UPDATE_ASSESSMENT
} from './assessment.types';
import { GET_MEASUREMENTS, RESTART_ASSESSMENT, SAVE_PARTIAL_ASSESSMENT, SET_MEASUREMENTS } from './assessment.types';

import { SIGN_OUT_SUCCESS } from '../user/user.types';

export const INITIAL_STATE:IAssessmentState = {
	isLoading: true,
    assessments: undefined,
    orgMembers: undefined,
	currentOrgMember: undefined,
    cachedChecked: false,
    error: undefined,
    saveAssessmentSuccess: false,
    saveAssessmentError: undefined,
    autoSaveAssessmentError: undefined,
    measurements: undefined
};

const assessmentReducer = (state = INITIAL_STATE, action:TypeAssessmentAction):IAssessmentState => {
    switch( action.type ){
        
        case SIGN_OUT_SUCCESS :
            return {
                ...INITIAL_STATE
            };
        
        case GET_ASSESSMENT :
            return {
                ...state,
                autoSaveAssessmentError: undefined,
                isLoading: true
            };

        case SET_ASSESSMENT :
            return {
                ...state,
                assessments: action.payload,
                isLoading: false
            };
        
        case SET_CACHED_ASSESSMENT :
            if( !action.payload ){
                // reset
                return {
                    ...INITIAL_STATE
                };
            } else {
                const {assessments, changingPlayer, orgMembers, currentOrgMember, measurements} = action.payload;
                return changingPlayer ? {
                    ...state,
                    assessments,
                    orgMembers,
                    currentOrgMember,
                    measurements
                } : state;
            }
            
        case SET_ASSESSMENT_ORG_MEMBERS :
            const st = action.payload.reset ? INITIAL_STATE : state;
            return {
                ...st,
                orgMembers: action.payload.members,
                currentOrgMember: action.payload.members.length ? action.payload.members[0] : undefined
            };

        case SET_CURRENT_ORG_MEMBER :
            return {
                ...state,
                currentOrgMember: action.payload
            };
		
		case SHOW_ASSESSMENT_ERROR :
			return {
                ...state,
                error: action.payload,
                isLoading: false
            };
            
        case RESTORE_CACHED_ASSESSMENT :
            return {
                ...state,
                assessments: action.payload.assessments,
                measurements: action.payload.measurements,
                currentOrgMember: action.payload.currentOrgMember,
                orgMembers: action.payload.orgMembers,
                isLoading: false
            }

        case GET_CACHED_ASSESSMENT :
            return {
                ...state,
                cachedChecked: true
            }

        case SAVE_ASSESSMENT :
            const {currentOrgMember, assessments} = state;
            if(assessments && currentOrgMember){
                assessments[currentOrgMember.id] = action.payload.assessment;
            }
            return {
                ...state,
                assessments,
                isLoading: true,
                saveAssessmentSuccess: false,
                saveAssessmentError: undefined,
                autoSaveAssessmentError: undefined
            };

        case DELETE_ASSESSMENT :
        case RESTART_ASSESSMENT :
        case UPDATE_ASSESSMENT :
        case SAVE_PARTIAL_ASSESSMENT :
            return {
                ...state,
                isLoading: true,
                saveAssessmentSuccess: false,
                saveAssessmentError: undefined,
                autoSaveAssessmentError: undefined
            };

        case SAVE_ASSESSMENT_SUCCESS :
            return {
                ...state,
                isLoading: false,
                saveAssessmentSuccess: true,
                saveAssessmentError: undefined,
                autoSaveAssessmentError: undefined
            };

        case SAVE_ASSESSMENT_FAILURE :
            return {
                ...state,
                isLoading: false,
                saveAssessmentSuccess: false,
                saveAssessmentError: action.payload
            };

        case AUTO_SAVE_ASSESSMENT_FAILURE :
            return {
                ...state,
                autoSaveAssessmentError: action.payload
            };

        case GET_MEASUREMENTS :
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };

        case SET_MEASUREMENTS :
            return {
                ...state,
                measurements: action.payload,
                isLoading: false
            };


        default :
            return state;
    }
};

export default assessmentReducer;