import { ComplianceLevel, CompliancePoint, IPlayer, RiskLevel } from '../player/player.types';
import { DOWNLOAD_REPORT, GET_REPORT_MEMBER, GET_REPORT_MEMBERS, IDownloadReport, IGetReportMember, IGetReportMembers, IReportPlayer, ReportFileType, ReportPastAssessment, ReportPlayerAddons } from './report.types';
import { IPlayerRemote, convertPlayerRemoteData, convertRemoteComplianceColor } from '../player/player.sagas';
import { PrivateRemoteData, isUserTokenValid } from '../user/user.sagas';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { downloadReportFile, getMemberReportAPI, getReportAPI } from '../../services/PMotionApi';
import { setReportLoading, setReportMember, setReportMembers, showReportError } from './report.actions';

import { isNumber } from '../../utils/validator';
import moment from 'moment';

type RemoteCompliancePoint = Pick<CompliancePoint, 'programId' | 'programStatus' | 'complianceLevel' | 'injury'> & {
	completedDate?:string | null;
	nextAssessmentDate?:string | null;
	compliancePercent?:number | null;
	complianceColor?:string | null;
	recentDateRange?:number | null;
	recentlyCompletedWorkouts?:number | null;
}
type RemotePastPScore = Pick<ReportPastAssessment, 'assessmentId' | 'assessmentDate' | 'injury'> & {
	pscore?:number | null;
	riskLevel?:ComplianceLevel | RiskLevel | null;
}
type RemoteGroupPoint = {
	index:number;
	average:number;
	count:number;
	max:number;
	min:number;
}

const convertRiskLevelFromComplianceLevel = (complianceLevel?:ComplianceLevel | RiskLevel | null):RiskLevel | null | undefined => {
	switch( complianceLevel ){
		case ComplianceLevel.High :
			return RiskLevel.High;
		case ComplianceLevel.Medium :
			return RiskLevel.Medium;
		case ComplianceLevel.Low :
			return RiskLevel.Low;
		case ComplianceLevel.Pending :
			return null;
		default :
			return complianceLevel;
	}
}

export type RemoteReportUser = Omit<IPlayerRemote, 'assessmentRiskLevel'> & Omit<ReportPlayerAddons, 'complianceHistory' | 'complianceDate' | 'pscoreHistory' | 'groupPScoreHistory' | 'groupComplianceHistory'> & {
	assessmentRiskLevel?:RiskLevel | ComplianceLevel | null;
	complianceHistory?:RemoteCompliancePoint[] | null;
	groupComplianceHistory?:RemoteGroupPoint[] | null;
	pscoreHistory?:RemotePastPScore[] | null;
	groupPScoreHistory?:RemoteGroupPoint[] | null;
}
/*{
    mostImpacted?:string | null;
	leastImpacted?:string | null;
	areaImpactedCount?:number | null;
	pscoreHistory?:ReportPastAssessment[] | null;
	complianceHistory?:RemoteCompliancePoint[] | null;
	trend?:number | null;
	roleCode?:string | null;
}*/
enum Order {
	Asc = "asc",
	Desc = "desc"
}
const getDateSorting = (order:Order, aString?:string | null, bString?:string | null):number => {
	const maxValue = Number.MAX_VALUE;
	const lastNumericValue:number = order===Order.Desc ? maxValue : -maxValue;

	const getTime = (date?:Date) => (date!==undefined && date instanceof Date) ? date.getTime() : lastNumericValue;
	
	const aDate = aString ? moment(aString).toDate() : undefined;
	const bDate = bString ? moment(bString).toDate() : undefined;

	if( order === Order.Desc ){
		return getTime(aDate) - getTime(bDate);
	} else {
		return getTime(bDate) - getTime(aDate);
	}
}
const getComplianceDateSorting = (order:Order) => ({date:aString}:CompliancePoint, {date:bString}:CompliancePoint):number => {
	return getDateSorting(order, aString, bString);
}
const getPScoreDateSorting = (order:Order) => ({assessmentDate:aString}:ReportPastAssessment, {assessmentDate:bString}:ReportPastAssessment):number => {
	return getDateSorting(order, aString, bString);
}

const convertRemoteReportMember = ({
	mostImpacted, 
	leastImpacted, 
	areaImpactedCount, 
	pscoreHistory, 
	groupPScoreHistory,
	complianceHistory, 
	groupComplianceHistory,
	trend, 
	roleCode, 
	groupName,
	groupCompliance,
	groupComplianceDiff,
	groupPScore,
	groupPScoreDiff,
	complianceLevel,
	complianceGreenMin,
	complianceYellowMin, 
	assessmentRiskLevel,
	recentDateRange,
	recentlyCompletedWorkouts,
	...player
}:RemoteReportUser):IReportPlayer => {
	const realAssessmentRiskLevel = convertRiskLevelFromComplianceLevel(assessmentRiskLevel);
	const realComplianceHistory = complianceHistory ? [...complianceHistory].map(({nextAssessmentDate, compliancePercent, complianceColor, recentDateRange, recentlyCompletedWorkouts, ...data}) => ({
		...data,
		date: nextAssessmentDate,
		compliance: compliancePercent,
		color: complianceColor ? (convertRemoteComplianceColor(complianceColor) || complianceColor) : complianceColor,
		colorOriginal: complianceColor
	})).sort(getComplianceDateSorting(Order.Desc)) : complianceHistory;

	const orderedComplianceGroupHistory = realComplianceHistory && groupComplianceHistory ? groupComplianceHistory.sort(({index:a}, {index:b}) => (a - b)) : undefined; // sort index ascending
	
	const realComplianceGroupHistory:CompliancePoint[] | undefined = (realComplianceHistory && orderedComplianceGroupHistory) ? orderedComplianceGroupHistory.map(({average}, idx) => {
		// find match by index
		const compliance = realComplianceHistory && idx < realComplianceHistory.length ? realComplianceHistory[idx] : undefined;
		return compliance ? {
			date: compliance.date,
			compliance: average,
		} : undefined;
	}).filter(group => group!==undefined) as CompliancePoint[] : undefined; 

	const latest = realComplianceHistory ? realComplianceHistory.pop() : undefined;
	
	const realPScoreHistory:ReportPastAssessment[] | null | undefined = pscoreHistory ? [...pscoreHistory].map(({pscore, riskLevel, ...data}) => ({
		...data,
		assessmentRiskScore: pscore,
		riskLevel: convertRiskLevelFromComplianceLevel(riskLevel)
	})).sort(getPScoreDateSorting(Order.Desc)) : pscoreHistory;

	const orderedPScoreGroupHistory = realPScoreHistory && groupPScoreHistory ? groupPScoreHistory.sort(({index:a}, {index:b}) => (a - b)) : undefined; // sort index ascending
	const realPScoreGroupHistory:ReportPastAssessment[] | undefined = orderedPScoreGroupHistory ? orderedPScoreGroupHistory.map(({average}, idx) => {
		const pscore = realPScoreHistory && idx < realPScoreHistory.length ? realPScoreHistory[idx] : undefined;
		return pscore ? {
			assessmentDate: pscore.assessmentDate,
			assessmentRiskScore: average,
		} : undefined;
	}).filter(group => group!==undefined) as ReportPastAssessment[] : undefined;
	
	const latestPScore = realPScoreHistory ? realPScoreHistory.pop() : undefined;

	type PartialPScore = Pick<IPlayer, 'lastAssessment' | 'assessmentRiskScore' | 'assessmentId' | 'assessmentRiskLevel'>;
	const latestPScoreInfo:PartialPScore = latestPScore ? {
		lastAssessment: player.lastAssessment || latestPScore.assessmentDate,
		assessmentRiskScore: isNumber(player.assessmentRiskScore) ? player.assessmentRiskScore : latestPScore.assessmentRiskScore,
		assessmentId: isNumber(player.assessmentId) ? player.assessmentId : latestPScore.assessmentId,
		assessmentRiskLevel: realAssessmentRiskLevel || latestPScore.riskLevel
	} : {};
	
	return {
		...convertPlayerRemoteData({
			...player,
			...latestPScoreInfo
		}),
		compliancePercent: latest ? latest.compliance : undefined,
		complianceColor: latest ? latest.colorOriginal : undefined,
		complianceDate: latest ? latest.date : undefined,
		complianceLevel: complianceLevel ? complianceLevel : latest ? latest.complianceLevel : undefined,
		mostImpacted, 
		leastImpacted, 
		areaImpactedCount,
		pscoreHistory: realPScoreHistory,
		groupPScoreHistory: realPScoreGroupHistory,
		complianceHistory: realComplianceHistory,
		groupComplianceHistory: realComplianceGroupHistory,
		trend,
		roleCode,
		groupName,
		groupCompliance,
		groupComplianceDiff,
		groupPScore,
		groupPScoreDiff,
		complianceGreenMin,
		complianceYellowMin,
		recentDateRange,
		recentlyCompletedWorkouts
	}
};

export function* getReportOfMembers({payload}:IGetReportMembers) {
	// get info
	try {
		const {orgIds, tableType, token} = payload;
		type RemoteData = {
			members:RemoteReportUser[];
		}
		type RemoteResponse = PrivateRemoteData & {
			data?:RemoteData;
			errorCode?:string;
			message?:string;
		}
		const membersInfoData:RemoteResponse = yield getReportAPI(orgIds, tableType, token);
		const membersInfo = membersInfoData.data;
		
		const valid:boolean | string = membersInfo ? yield isUserTokenValid(membersInfo, token) : false;
		if( valid ){
			if( membersInfo && membersInfo.members && Array.isArray(membersInfo.members) ){
			
				const reportMembers:IReportPlayer[] = membersInfo.members.map((player) => convertRemoteReportMember(player));
				
				yield put(
					setReportMembers(reportMembers)
				);
				
			} else {
				throw new Error('Report API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showReportError('Getting report data failed. '+er.message)
		);
	}
    
}

export function* getReportDownload({payload}:IDownloadReport) {
	// get info
	try {
		const {orgIds, reportType, fileType, token} = payload;
		type ResponseHeaders = Record<string, string>;
		type RemoteResponse = PrivateRemoteData & {
			data?:any;
			errorCode?:string;
			message?:string;
			headers:ResponseHeaders;
		}
		const response:RemoteResponse = yield downloadReportFile(orgIds, reportType, fileType, token);
		const fileInfo = response.data;
		
		const valid:boolean | string = fileInfo ? yield isUserTokenValid(fileInfo, token) : false;
		if( valid ){
			if( fileInfo ){
				// get filename from response headers
				let filename:string | undefined;
				const headerLine = response.headers['content-disposition'] || response.headers['Content-Disposition'];
				const fileKey = 'filename=';
				const fileSegment = headerLine.split(' ').find(item => item.toLowerCase().indexOf(fileKey)===0);
				if(fileSegment){
					const parts = fileSegment.split('=');
					if(parts.length > 1){
						filename = parts[1];
					}
				}
				if(filename===undefined){
					filename = `${reportType}_report_${moment().format('YYYYMMDD')}.${fileType===ReportFileType.CSV ? 'csv' : 'xlsx'}`;
				}
				
				const url = window.URL.createObjectURL(new Blob([fileInfo]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', filename);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				
				yield put(
					setReportLoading(false)
				);
				
			} else {
				throw new Error('Report download failed.')
			}
		}
	} catch(er){
		yield put(
			showReportError(er.message)
		);
	}
    
}

export function* getSingleReportOfMember({payload}:IGetReportMember) {
	// get info
	try {
		const {memberId, token, report, startDate, endDate} = payload;
		type RemoteResponse = PrivateRemoteData & {
			data?:RemoteReportUser;
			errorCode?:string;
			message?:string;
		}
		const memberInfoData:RemoteResponse = yield getMemberReportAPI(memberId, token, report, startDate, endDate);
		const memberInfo = memberInfoData.data;
		
		const valid:boolean | string = memberInfo ? yield isUserTokenValid(memberInfo, token) : false;
		if( valid ){
			if( memberInfo ){
			
				const reportMember:IReportPlayer = convertRemoteReportMember( memberInfo );
				
				yield put(
					setReportMember(reportMember)
				);
				
			} else {
				throw new Error('Member Report API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showReportError(er.message)
		);
	}
    
}


export function* onGetReport() {
    yield takeLatest(GET_REPORT_MEMBERS, getReportOfMembers);
}

export function* onDownloadReport() {
    yield takeLatest(DOWNLOAD_REPORT, getReportDownload);
}

export function* onGetReportMember() {
    yield takeLatest(GET_REPORT_MEMBER, getSingleReportOfMember);
}

export function* reportSagas() {
    yield all([
		call( onGetReport ),
		call( onDownloadReport ),
		call( onGetReportMember )
    ]);
}