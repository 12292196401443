import { AUTO_SAVE_ASSESSMENT_FAILURE, DELETE_ASSESSMENT, GET_MEASUREMENTS, IAssessmentPayload, IAutoSaveAssessmentFailure, ICachableAssessmentPayload, IDeleteAssessment, IGetAssessmentPayload, IGetMeasurements, IGetMeasurementsPayload, IMeasurement, IRestartAssessment, IRestartAssessmentPayload, ISavePartialAssessment, ISavePartialAssessmentPayload, ISetAssessmentOrgMembersPayload, ISetMeasurements, IUpdateAssessment, RESTART_ASSESSMENT, SAVE_PARTIAL_ASSESSMENT, SET_MEASUREMENTS, UPDATE_ASSESSMENT, ISubmitAssessmentPayload } from './assessment.types';
import {
    AssessmentMapInterface,
    GET_ASSESSMENT,
    GET_CACHED_ASSESSMENT,
    ICachedAssessmentPayload,
    IGetAssessment,
    IGetCachedAssessment,
    IRestoreCachedAssessment,
    ISaveAssessment,
    ISetAssessment,
    ISetAssessmentOrgMembers,
    ISetCachedAssessment,
    ISetCurrentOrgMember,
    IShowAssessmentError,
    RESTORE_CACHED_ASSESSMENT,
    SAVE_ASSESSMENT,
    SAVE_ASSESSMENT_FAILURE,
    SAVE_ASSESSMENT_SUCCESS,
    SET_ASSESSMENT,
    SET_ASSESSMENT_ORG_MEMBERS,
    SET_CACHED_ASSESSMENT,
    SET_CURRENT_ORG_MEMBER,
    SHOW_ASSESSMENT_ERROR
} from './assessment.types';

import {IPlayer} from '../player/player.types';

export const showError = (error:string):IShowAssessmentError => ({
    type: SHOW_ASSESSMENT_ERROR,
    payload: error
});

export const setAssessment = (assessmentMap:AssessmentMapInterface):ISetAssessment => ({
    type: SET_ASSESSMENT,
    payload: assessmentMap
});

export const getAssessment = (payload:IGetAssessmentPayload):IGetAssessment => ({
    type: GET_ASSESSMENT,
    payload
});

export const setAssessmentOrgMembers = (payload:ISetAssessmentOrgMembersPayload):ISetAssessmentOrgMembers => ({
    type: SET_ASSESSMENT_ORG_MEMBERS,
    payload
});

export const setCurrentOrgMember = (member:IPlayer):ISetCurrentOrgMember => ({
    type: SET_CURRENT_ORG_MEMBER,
    payload: member
});

export const cacheAssessment = (assessmentInfo:ICachedAssessmentPayload | null):ISetCachedAssessment => ({
    type: SET_CACHED_ASSESSMENT,
    payload: assessmentInfo
});

export const getCachedAssessment = ():IGetCachedAssessment => ({
    type: GET_CACHED_ASSESSMENT
});

export const restoreAssessment = (assessmentInfo:ICachableAssessmentPayload):IRestoreCachedAssessment => ({
    type: RESTORE_CACHED_ASSESSMENT,
    payload: assessmentInfo
});

export const saveAssessment = (payload:ISubmitAssessmentPayload):ISaveAssessment => ({
    type: SAVE_ASSESSMENT,
    payload
});

export const savePartialAssessment = (payload:ISavePartialAssessmentPayload):ISavePartialAssessment => ({
    type: SAVE_PARTIAL_ASSESSMENT,
    payload
});

export const updateAssessment = (payload:IAssessmentPayload):IUpdateAssessment => ({
    type: UPDATE_ASSESSMENT,
    payload
});

export const deleteAssessment = (payload:IAssessmentPayload):IDeleteAssessment => ({
    type: DELETE_ASSESSMENT,
    payload
});

export const restartAssessment = (payload:IRestartAssessmentPayload):IRestartAssessment => ({
    type: RESTART_ASSESSMENT,
    payload
});

export const saveAssessmentSuccess = () => ({
    type: SAVE_ASSESSMENT_SUCCESS
});

export const saveAssessmentFailure = (error:string) => ({
    type: SAVE_ASSESSMENT_FAILURE,
    payload: error
});

export const autoSaveAssessmentFailure = (error:string):IAutoSaveAssessmentFailure => ({
    type: AUTO_SAVE_ASSESSMENT_FAILURE,
    payload: error
});

export const getMeasurements = (payload:IGetMeasurementsPayload):IGetMeasurements => ({
    type: GET_MEASUREMENTS,
    payload
});

export const setMeasurements = (measurements:IMeasurement[]):ISetMeasurements => ({
    type: SET_MEASUREMENTS,
    payload: measurements
});