import { DependencyList, useEffect, useRef } from "react";

type EffectConditionCallback = () => boolean | void;
const useOnce = (callback:EffectConditionCallback, deps:DependencyList=[]) => {
	const hasCalled = useRef<boolean>(false);

	const realDeps = (!deps.includes(callback)) ? [
		...deps,
		callback
	] : deps;

	useEffect(() => {
		if( !hasCalled.current ){ // once the callback returns true, it stops
			hasCalled.current = !!callback();
		}
	}, [ //eslint-disable-line
		...realDeps, //eslint-disable-line
		hasCalled
	]);

}

export default useOnce;