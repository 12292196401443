import {all, call} from 'redux-saga/effects';

import {assessmentSagas} from './assessment/assessment.sagas';
import {assessmentsSagas} from './assessments/assessments.sagas';
import { contentSagas } from './content/content.sagas';
import {correctivesSagas} from './correctives/correctives.sagas';
import {exerciseSagas} from './exercise/exercise.sagas';
import {messageSagas} from './message/message.sagas';
import {orgSagas} from './org/org.sagas';
import {playerSagas} from './player/player.sagas';
import {reportSagas} from './report/report.sagas';
import {userSagas} from './user/user.sagas';

export default function* rootSaga() {
    yield all([
        call(userSagas),
        call(orgSagas),
        call(playerSagas),
        call(assessmentSagas),
        call(correctivesSagas),
        call(assessmentsSagas),
        call(exerciseSagas),
        call(messageSagas),
        call(contentSagas),
        call(reportSagas)
    ])
};