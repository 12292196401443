import { MutableRefObject, useCallback, useEffect, useRef } from "react";

type useTimerReturn = [
	MutableRefObject<NodeJS.Timeout | null>,
	() => void,
	MutableRefObject<boolean>
];

const useTimer = ():useTimerReturn => {

	const onStage = useRef<boolean>(false);
	useEffect(() => {
		if( !onStage.current ){
			onStage.current = true;
		}
		return () => {
			onStage.current = false;
		}
	}, [onStage]);
	
	const timer = useRef<NodeJS.Timeout | null>(null);

	const clearTimer = useCallback(() => {
		if( timer.current!==null ){
			// stop popover from closing and let parent screen transition out
			clearTimeout( timer.current );
			timer.current = null;
		}
	}, [timer]);

	useEffect(() => {
		return () => {
			// clear timer on unmount
			clearTimer();
		}
	}, [clearTimer]);

	return [timer, clearTimer, onStage];
}

export default useTimer;