import { IDType } from '../core.types';
import { ISignOutSuccess } from '../user/user.types';

export const SET_DASHBOARD_STATE = 'SET_DASHBOARD_STATE';
export const SET_DASHBOARD_PAGE = 'SET_DASHBOARD_PAGE';
export const SET_DASHBOARD_ROWS_PER_PAGE = 'SET_DASHBOARD_ROWS_PER_PAGE';
export const SET_DASHBOARD_POSITION_FILTER = 'SET_DASHBOARD_POSITION_FILTER';
export const SET_DASHBOARD_STATUS_FILTER = 'SET_DASHBOARD_STATUS_FILTER';
export const SET_DASHBOARD_DAYS_COMPLETED_FILTER = 'SET_DASHBOARD_DAYS_COMPLETED_FILTER';
export const SET_DASHBOARD_SEARCH_FILTER = 'SET_DASHBOARD_SEARCH_FILTER';
export const SET_DASHBOARD_ORDER = 'SET_DASHBOARD_ORDER';
export const SET_DASHBOARD_ORDER_BY = 'SET_DASHBOARD_ORDER_BY';
export const SET_DASHBOARD_SORT = 'SET_DASHBOARD_SORT';
export const SET_DASHBOARD_TABLE_TYPE = 'SET_DASHBOARD_TABLE_TYPE';

// Data types
export type TypeFilterSelection = string | string[];
export type RangeType = [number, number];
export type Order = "asc" | "desc";
export type OrderByKey = IDType | string;

export const FILTER_DEFAULT_VALUE = '*';
export const RANGE_DEFAULT_VALUE:RangeType = [-1, -1];

export enum DashboardTableType {
    Overview = 'Overview',
    Compliance = 'Compliance'
}


// State type
export interface IDashboardState {
	page:number;
	rowsPerPage:number;
	positionFilterSelections:TypeFilterSelection;
	statusFilterSelections:TypeFilterSelection;
	daysCompletedRangeFilterSelections:RangeType;
	searchFilter?:string;
	order?:Order;
	orderBy?:OrderByKey;
	complianceOrder?:Order;
	complianceOrderBy?:OrderByKey;
	tableType?:DashboardTableType;
}


// Action types
export interface ISetDashboardStatePayload {
	page?:number;
	rowsPerPage?:number;
	positionFilterSelections?:TypeFilterSelection;
	statusFilterSelections?:TypeFilterSelection;
	daysCompletedRangeFilterSelections?:RangeType;
	searchFilter?:string;
	order?:Order;
	orderBy?:OrderByKey;
	tableType?:DashboardTableType;
}
export interface ISetDashboardState {
	type: typeof SET_DASHBOARD_STATE;
	payload?: ISetDashboardStatePayload;
};

export interface ISetDashboardSetPage {
	type: typeof SET_DASHBOARD_PAGE;
	payload:number;
};

export interface ISetDashboardRowsPerPage {
	type: typeof SET_DASHBOARD_ROWS_PER_PAGE;
	payload:number;
};

export interface ISetDashboardPositionFilter {
	type: typeof SET_DASHBOARD_POSITION_FILTER;
	payload:TypeFilterSelection;
};

export interface ISetDashboardStatusFilter {
	type: typeof SET_DASHBOARD_STATUS_FILTER;
	payload:TypeFilterSelection;
};

export interface ISetDashboardDaysCompletedFilter {
	type: typeof SET_DASHBOARD_DAYS_COMPLETED_FILTER;
	payload:RangeType;
};

export interface ISetDashboardSearchFilter {
	type: typeof SET_DASHBOARD_SEARCH_FILTER;
	payload?:string;
};

export interface ISetDashboardOrder {
	type: typeof SET_DASHBOARD_ORDER;
	payload?:Order;
};

export interface ISetDashboardOrderBy {
	type: typeof SET_DASHBOARD_ORDER_BY;
	payload?:OrderByKey;
};

export interface ISetDashboardSortPayload {
	order?:Order;
	orderBy?:OrderByKey;
};
export interface ISetDashboardSort {
	type: typeof SET_DASHBOARD_SORT;
	payload:ISetDashboardSortPayload;
};

export interface ISetDashboardTableType {
	type: typeof SET_DASHBOARD_TABLE_TYPE;
	payload:DashboardTableType;
};

export type TypeDashboardAction = ISetDashboardState | ISetDashboardSetPage | ISetDashboardRowsPerPage | ISetDashboardPositionFilter | ISetDashboardStatusFilter | ISetDashboardDaysCompletedFilter | ISetDashboardSearchFilter | ISetDashboardOrder | ISetDashboardOrderBy | ISetDashboardSort | ISignOutSuccess | ISetDashboardTableType;