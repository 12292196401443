import { EDIT_ORG, EDIT_ORG_ERROR, EDIT_ORG_SUCCESS, GET_ALL_ORGS, GET_ORG_MEMBERS_COMPLIANCE, GET_ORG_PERIODS, GET_ORG_ROLES, GET_ORG_UI, GET_PENDING_ORG_MEMBERS, GET_TIMEZONES, IEditOrg, IEditOrgError, IEditOrgPayload, IEditOrgSuccess, IGetAllOrgs, IGetOrgMembersCompliance, IGetOrgMembersCompliancePayload, IGetOrgMembersPayload, IGetOrgPayload, IGetOrgPeriods, IGetOrgPeriodsPayload, IGetOrgRoles, IGetOrgUI, IGetPendingOrgMembers, IGetTimezones, ISelectOrg, ISelectOrgPayload, ISetOrgMembersCompliance, ISetOrgPeriods, ISetOrgRoles, ISetPendingOrgMembers, ISetTimezones, IShowGetAllOrgsError, IShowOrgPeriodsError, IShowOrgRolesError, OrgPeriod, OrgRoleData, SELECT_ORG, SET_ORG_MEMBERS_COMPLIANCE, SET_ORG_PERIODS, SET_ORG_ROLES, SET_PENDING_ORG_MEMBERS, SET_TIMEZONES, SHOW_GET_ALL_ORGS_ERROR, SHOW_ORG_PERIODS_ERROR, SHOW_ORG_ROLES_ERROR } from './org.types';
import {
    GET_ORG,
    GET_ORG_MEMBERS,
    GET_ORG_MEMBERS_MANAGEMENT,
    IGetOrg,
    IGetOrgMembers,
    IGetOrgMembersManagement,
    IOrg,
    ISetAllOrgs,
    ISetAllOrgsPayload,
    ISetLastLoginUrl,
    ISetOrg,
    ISetOrgMembers,
    IShowOrgError,
    IShowOrgMembersError,
    SET_ALL_ORGS,
    SET_ORG,
    SET_ORG_LAST_LOGIN_URL,
    SET_ORG_MEMBERS,
    SHOW_ORG_ERROR,
    SHOW_ORG_MEMBERS_ERROR
} from './org.types';

import { IPlayer } from '../player/player.types';
import { ITokenPayload } from '../user/user.types';

export const getAllOrgs = (payload:ITokenPayload):IGetAllOrgs => ({
    type: GET_ALL_ORGS,
    payload
});

export const setAllOrgs = (payload:ISetAllOrgsPayload):ISetAllOrgs => ({
    type: SET_ALL_ORGS,
    payload
});

export const showAllOrgsError = (error:string):IShowGetAllOrgsError => ({
    type: SHOW_GET_ALL_ORGS_ERROR,
    payload: error
});

export const getOrg = (payload:IGetOrgPayload):IGetOrg => ({
    type: GET_ORG,
    payload
});

export const getOrgUI = (orgJSONKey:string):IGetOrgUI => ({
    type: GET_ORG_UI,
    payload: orgJSONKey
});

export const setOrg = (org?:IOrg):ISetOrg => ({
    type: SET_ORG,
    payload: org
});

export const showOrgError = (error:string):IShowOrgError => ({
    type: SHOW_ORG_ERROR,
    payload: error
});

export const setOrgMembers = (members?:IPlayer[]):ISetOrgMembers => ({
    type: SET_ORG_MEMBERS,
    payload: members
});

export const setPendingOrgMembers = (members?:IPlayer[]):ISetPendingOrgMembers => ({
    type: SET_PENDING_ORG_MEMBERS,
    payload: members
});

export const getOrgMembers = (payload:IGetOrgMembersPayload):IGetOrgMembers => ({
    type: GET_ORG_MEMBERS,
    payload
});

export const getPendingOrgMembers = (payload:IGetOrgMembersPayload):IGetPendingOrgMembers => ({
    type: GET_PENDING_ORG_MEMBERS,
    payload
});

export const getOrgMembersManagement = (payload:IGetOrgMembersPayload):IGetOrgMembersManagement => ({
    type: GET_ORG_MEMBERS_MANAGEMENT,
    payload
});

export const getOrgMembersCompliance = (payload:IGetOrgMembersCompliancePayload):IGetOrgMembersCompliance => ({
    type: GET_ORG_MEMBERS_COMPLIANCE,
    payload
});

export const setOrgMembersCompliance = (members:IPlayer[]):ISetOrgMembersCompliance => ({
    type: SET_ORG_MEMBERS_COMPLIANCE,
    payload: members
});

export const showOrgMembersError = (error:string):IShowOrgMembersError => ({
    type: SHOW_ORG_MEMBERS_ERROR,
    payload: error
});

export const setOrgLastLoginUrl = (path?:string):ISetLastLoginUrl => ({
    type: SET_ORG_LAST_LOGIN_URL,
    payload: path
});

export const getOrgRoles = (payload:ITokenPayload):IGetOrgRoles => ({
    type: GET_ORG_ROLES,
    payload
});

export const setOrgRoles = (roles:OrgRoleData[]):ISetOrgRoles => ({
    type: SET_ORG_ROLES,
    payload: roles
});

export const showOrgRolesError = (error:string):IShowOrgRolesError => ({
    type: SHOW_ORG_ROLES_ERROR,
    payload: error
});

export const selectOrg = (payload:ISelectOrgPayload):ISelectOrg => ({
    type: SELECT_ORG,
    payload
});

export const editOrg = (payload: IEditOrgPayload):IEditOrg =>  ({
    type: EDIT_ORG,
    payload
});

export const editOrgSuccess = ():IEditOrgSuccess =>  ({
    type: EDIT_ORG_SUCCESS
});

export const editOrgError = (error:string):IEditOrgError =>  ({
    type: EDIT_ORG_ERROR,
    payload: error
});

export const getTimezones = (payload:ITokenPayload):IGetTimezones =>  ({
    type: GET_TIMEZONES,
    payload
});

export const setTimezones = (timezones:Record<string, string>):ISetTimezones =>  ({
    type: SET_TIMEZONES,
    payload: timezones
});

export const getOrgPeriods = (payload:IGetOrgPeriodsPayload):IGetOrgPeriods =>  ({
    type: GET_ORG_PERIODS,
    payload
});

export const setOrgPeriods = (periods:OrgPeriod[] | false):ISetOrgPeriods =>  ({
    type: SET_ORG_PERIODS,
    payload: periods
});

export const showOrgPeriodsError = (error:string):IShowOrgPeriodsError =>  ({
    type: SHOW_ORG_PERIODS_ERROR,
    payload: error
});