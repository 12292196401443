import {IRootState} from '../root.types';
import {createSelector} from 'reselect';

const selectState = (state:IRootState) => state.message;

export const selectIsAllThreadsLoading = createSelector([selectState], state => state.isAllThreadsLoading);

export const selectIsSingleThreadLoading = createSelector([selectState], state => state.isSingleThreadLoading);

export const selectThreads = createSelector([selectState], state => state.threads);

export const selectCurrentThread = createSelector([selectState], state => state.singleThread);

export const selectThreadsError = createSelector([selectState], state => state.allThreadsError);

export const selectCurrentThreadError = createSelector([selectState], state => state.singleThreadError);

export const selectNewMessageThreadCount = createSelector([selectState], state => state.newMessageThreadCount);