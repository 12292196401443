import {
	GET_EXERCISE,
	IExerciseComplete,
	IGetExercise,
} from './exercise.types';
import { ISetExerciseCorrectiveAndUpdateStatus, SET_EXERCISE_CORRECTIVE_AND_UPDATE } from './exercise.types';
import { RemoteMediaType, convertRemoteMedia } from '../../utils/media-type';
import {all, call, put, takeLatest} from 'redux-saga/effects';
import {setExercise, showError} from './exercise.actions';

import MediaType from '../../utils/media-type';
import { UPDATE_CORRECTIVE_EXERCISE_STATUS } from '../correctives/correctives.types';
import { callUpdateCorrectiveExerciseStatus } from '../correctives/correctives.sagas';
import {getExercise as getExerciseAPI} from '../../services/PMotionApi';
import { isUserTokenValid } from '../user/user.sagas';

interface IExerciseRemoteComplete extends Omit<IExerciseComplete, 'media'> {
	media:RemoteMediaType[];
}

export function* getExercise({payload: {id, token}}:IGetExercise) {
	
	// get info
	try {
		const exerciseInfoData = yield getExerciseAPI(id, token);
		
		const valid = yield isUserTokenValid(exerciseInfoData.data, token);
		if( valid ){
			const exerciseRemote:IExerciseRemoteComplete = exerciseInfoData?.data as IExerciseRemoteComplete;
			if( exerciseRemote ){
			
				const exercise:IExerciseComplete = parseRemoteData( exerciseRemote );
				
				yield put(
					setExercise(exercise)
				);
			} else {
				throw new Error('Exercise API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showError('Get exercise data failed. '+er.message)
		);
	}
    
}

const parseRemoteData = (remoteExercise:IExerciseRemoteComplete):IExerciseComplete => {
	const newMedia:MediaType[] = remoteExercise.media.map(media => convertRemoteMedia(media));
	/* TESTING */ 
	/*newMedia.push( convertRemoteVideo({
		"caption" : null,
		"type" : MediaTypes.Audio,
		"poster" : "https://qa.media.pmotiontech.com/media/exercises/video/1-C0-poster.png",
		"order" : 2,
		"sources" : [ 
			{
				"key" : "1",
				"path" : "https://www.nexad.kudirka.com/mock/videos/electronic-music-education.mp3",
				"type" : RemoteVideoSourceType.MP3
			}
		]
	  }) );*/
	return {
		...remoteExercise,
		media: newMedia
	}
}

export function* callSelectAndUpdateCorrectiveExerciseStatus({payload}:ISetExerciseCorrectiveAndUpdateStatus) {
	const {exercise, status, token, withFeedbackResponse, refreshPlayerId, isProgramDayNew} = payload;
	if( exercise && status){
		yield callUpdateCorrectiveExerciseStatus({
			type: UPDATE_CORRECTIVE_EXERCISE_STATUS,
			payload: {
				exerciseId: exercise.id,
				exerciseKey: exercise.key || exercise.description || exercise.name,
				exerciseSide: exercise.side ? exercise.side : undefined,
				status,
				withFeedbackResponse,
				token,
				refreshPlayerId,
				isProgramDayNew
			}
		});
	}
}

export function* onGetExercise() {
    yield takeLatest(GET_EXERCISE, getExercise);
}

export function* onSelectAndUpdateCorrectiveExerciseStatus() {
    yield takeLatest(SET_EXERCISE_CORRECTIVE_AND_UPDATE, callSelectAndUpdateCorrectiveExerciseStatus);
}

export function* exerciseSagas() {
    yield all([
		call( onGetExercise ),
		call( onSelectAndUpdateCorrectiveExerciseStatus )
    ]);
}