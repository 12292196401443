import { AssessmentStatus, AssessmentType, MeasurementStatus } from '../assessment/assessment.types';
import { IDType, IData } from '../core.types';
import { ISignOutSuccess, ITokenPayload } from '../user/user.types';

import { Order } from '../dashboard/dashboard.types';
import { Side } from '../assessment/body-parts.types';
import { SportType } from '../org/org.types';

export const SET_PLAYER_LOADING = 'SET_PLAYER_LOADING';
export const GET_PLAYER = 'GET_PLAYER';
export const SET_PLAYER = 'SET_PLAYER';
export const SHOW_PLAYER_ERROR = 'SHOW_PLAYER_ERROR';
export const RESTART_PLAYER_PROGRAM = 'RESTART_PLAYER_PROGRAM';
export const COMPLETE_PLAYER_PROGRAM = 'COMPLETE_PLAYER_PROGRAM';
export const PLAYER_PROGRAM_SUCCESS = 'PLAYER_PROGRAM_SUCCESS';
export const PLAYER_PROGRAM_FAILURE = 'PLAYER_PROGRAM_FAILURE';
export const MARK_PLAYER_INJURY_STATUS = 'MARK_PLAYER_INJURY_STATUS';
export const GET_PLAYER_COMPLIANCE_HISTORY = 'GET_PLAYER_COMPLIANCE_HISTORY';
export const SET_PLAYER_COMPLIANCE_HISTORY = 'SET_PLAYER_COMPLIANCE_HISTORY';
export const SHOW_COMPLIANCE_HISTORY_ERROR = 'SHOW_COMPLIANCE_HISTORY_ERROR';

// Data types
export enum Status {
	MissedSevere = 'MISSED_SEVERE',
	Missed = 'MISSED',
	Skipped = 'SKIPPED',
	ReadyForAssessment = 'READY_FOR_ASSESSMENT', // has done an assessment, but ready for a new one
	NeedAssessment = 'N/A', // has never done an assessment
	AssessmentSubmitted = 'ASSESSMENT_SUBMITTED',
	OnTrack = 'ON_TRACK',
	Paused = 'PAUSED',
	Complete = 'COMPLETE',
	New = 'NEW',
	Idle = 'IDLE',
	FirstExerciseIncomplete = 'FIRST_EXERCISE_INCOMPLETE',
	Injured = 'COMPLETE_INJURED',
	AssessmentLate = 'ASSESSMENT_LATE',
	CompletedSnapshot = 'COMPLETE_SNAPSHOT'
}

export const STATUS_LABELS = new Map<Status, string>([
    [Status.OnTrack, 'On Track'],
    [Status.Skipped, 'Skipped'],
	[Status.NeedAssessment, 'Ready for Assessment'],
	[Status.ReadyForAssessment, 'Ready for Assessment'],
    [Status.AssessmentSubmitted, 'Assessment Submitted'],
    [Status.Missed, 'Missed'],
	[Status.MissedSevere, 'Missed'],
	[Status.Paused, 'Paused'],
	[Status.Complete, 'Complete'],
	[Status.New, 'Needs to Start Program'],
	[Status.Idle, 'Idle'],
	[Status.FirstExerciseIncomplete, "Hasn't Started"],
	[Status.Injured, "Completed - Injury"],
	[Status.AssessmentLate, "Assessment Late"],
	[Status.CompletedSnapshot, "Completed - Snapshot"]
]);

// rank status from lowest to highest in severity
const statusRank:Status[] = [
	//Status.NeedAssessment, // ensure it doesn't get sorted with the rest
	Status.OnTrack,
	Status.Paused,
	//Status.New,
	Status.AssessmentSubmitted,
	//Status.Complete,
	//Status.ReadyForAssessment,
	Status.Skipped,
	Status.Missed,
	Status.MissedSevere
];
const statusWeight = new Map<Status, number>([...statusRank].map((status, idx) => [status, idx]));

// status that will always come first, ranked from lowest to highest in severity
const prefixStatusRank:Status[] = [
	Status.Idle,
	Status.AssessmentLate
];
const prefixStatusWeight = new Map<Status, number>([...prefixStatusRank].map((status, idx) => [status, idx]));

const secondaryStatusRank:Status[] = [
	Status.ReadyForAssessment,
	Status.Complete,
	Status.FirstExerciseIncomplete,
	Status.New,
	Status.NeedAssessment, // start and continue assessment, based on assessmentStatus
	Status.CompletedSnapshot,
	Status.Injured
];
const secondaryStatusWeight = new Map<Status, number>([...secondaryStatusRank].map((status, idx) => [status, idx]));

/* Returns the status weight based on the ranking we have set. */
export const getStatusWeight = (status?:Status | null, order?:Order):number => {
	let weight = status ? prefixStatusWeight.get(status) : undefined;
	if(weight!==undefined){
		// for prefix status keep order ascending order at top
		return order==="desc" ? (statusRank.length + weight) : (-1 - weight);
	} else {
		weight = status ? statusWeight.get(status) : undefined;
		if(weight===undefined){
			const secondaryWeight = status && secondaryStatusWeight.has(status) ? secondaryStatusWeight.get(status) : secondaryStatusRank.length;
			if(secondaryWeight!==undefined){
				return order==="desc" ? (-1 - secondaryWeight) : (statusRank.length + secondaryWeight)
			}
		}
		return (weight!==undefined) ? weight : order==="desc" ? -(Number.MAX_VALUE-1) : (Number.MAX_VALUE-1);
	}
}

export enum StatusColor {
	Red = 'red',
	Green = 'green',
	Yellow = 'yellow',
	Gray = 'gray',
	White = 'white'
}

export enum ProgramStatus {
	New = 'NEW',
	InProgress = 'IN_PROGRESS', 
	Complete = 'COMPLETE'
}

export enum ProgramDayStatus {
	New = 'NEW',
	Assigned = 'ASSIGNED', 
	InProgress = 'IN_PROGRESS', 
	Skipped = 'SKIPPED', 
	Complete = 'COMPLETE'
}

export enum ProfileItemType {
	BattingSide = 'BATTING_SIDE',
	ThrowingSide = 'THROW_SIDE', 
	LegSide = 'KICK_SIDE',
	Height = 'HEIGHT', 
	Weight = 'WEIGHT',
	ShootingSide = 'SHOOTING_SIDE', 
	SwingSide = 'SWING_SIDE',
	GeneralStrongSide = 'GENERAL_STRONG_SIDE',
	BirthYear = 'BIRTH_YEAR'
}

export enum ProfileSideType {
	Left = 'LEFT',
	Right = 'RIGHT',
	Center = 'CENTER',
	Both = 'BOTH',
	Switch = 'SWITCH',
};

export type ProfileItem = {
	key: ProfileItemType;
	value?:string | number | ProfileSideType | null
};

export enum RiskLevel {
	High = 'LEVEL_1', // is high risk
	Medium = 'LEVEL_2', // is medium risk
	Low = 'LEVEL_3' // is low risk
}

export enum ComplianceLevel {
	Low = 'Low',
	Medium = 'Medium',
	High = 'High',
	Pending = 'Pending'
}

export enum PScoreLevel {
	low = 'low',
	medium = 'medium',
	high = 'high',
	pending = 'pending'
}

export enum SportRoleType {
	ANY_MEMBER = 'ANY_MEMBER',
	BASEBALL_CATCHER = 'BASEBALL_CATCHER',
	BASEBALL_DH = 'BASEBALL_DH',
	BASEBALL_INFIELDER = 'BASEBALL_INFIELDER',
	BASEBALL_OUTFIELDER = 'BASEBALL_OUTFIELDER',
	BASEBALL_PITCHER = 'BASEBALL_PITCHER',
	BASEBALL_UTILITY = 'BASEBALL_UTILITY',
	BASKETBALL_CENTER = 'BASKETBALL_CENTER',
	BASKETBALL_GUARD = 'BASKETBALL_GUARD',
	BASKETBALL_POWER_FORWARD = 'BASKETBALL_POWER_FORWARD',
	BASKETBALL_SMALL_FORWARD = 'BASKETBALL_SMALL_FORWARD',
	CROSS_TRAINING_MEMBER = 'CROSS_TRAINING_MEMBER',
	FOOTBALL_C = 'FOOTBALL_C',
	FOOTBALL_DB = 'FOOTBALL_DB',
	FOOTBALL_DE = 'FOOTBALL_DE',
	FOOTBALL_DL = 'FOOTBALL_DL',
	FOOTBALL_K = 'FOOTBALL_K',
	FOOTBALL_KR = 'FOOTBALL_KR',
	FOOTBALL_LB = 'FOOTBALL_LB',
	FOOTBALL_LS = 'FOOTBALL_LS',
	FOOTBALL_NG = 'FOOTBALL_NG',
	FOOTBALL_NT = 'FOOTBALL_NT',
	FOOTBALL_OL = 'FOOTBALL_OL',
	FOOTBALL_P = 'FOOTBALL_P',
	FOOTBALL_PR = 'FOOTBALL_PR',
	FOOTBALL_QB = 'FOOTBALL_QB',
	FOOTBALL_RB = 'FOOTBALL_RB',
	FOOTBALL_ST = 'FOOTBALL_ST',
	FOOTBALL_TE = 'FOOTBALL_TE',
	FOOTBALL_WR = 'FOOTBALL_WR',
	GOLF_GOLFER = 'GOLF_GOLFER',
	HOCKEY_DEFENSE = 'HOCKEY_DEFENSE',
	HOCKEY_FORWARD = 'HOCKEY_FORWARD',
	HOCKEY_GOALTENDER = 'HOCKEY_GOALTENDER',
	NONE_MEMBER = 'NONE_MEMBER',
	SOCCER_DEFENDER = 'SOCCER_DEFENDER',
	SOCCER_FORWARD = 'SOCCER_FORWARD',
	SOCCER_GOALKEEPER = 'SOCCER_GOALKEEPER',
	SOCCER_MIDFIELDER = 'SOCCER_MIDFIELDER',
	TENNIS_PLAYER = 'TENNIS_PLAYER'
}

export enum InjuryType {
	NonTrauma = 'NON_TRAUMA',
	Trauma = 'TRAUMA',
}

export enum SubscriptionType {
	FREE_TRIAL = 'FREE_TRIAL',
	PAID_BLOCK = 'PAID_BLOCK',
	PAID_RECURRING = 'PAID_RECURRING'
}

export const InjuryTypeLabels = new Map<InjuryType, string>([
    [InjuryType.NonTrauma, 'Non-Trauma'],
    [InjuryType.Trauma, 'Trauma']
]);

export type Injury = IData & {
	type:InjuryType;
	days:number;
	details:string;
	date:string;
}

export const SportRoleMap = new Map<string, SportRoleType>([
	['Starting Pitcher', SportRoleType.BASEBALL_PITCHER],
	['Relief Pitcher', SportRoleType.BASEBALL_PITCHER],
	['Catcher', SportRoleType.BASEBALL_CATCHER],
	['First Baseman', SportRoleType.BASEBALL_INFIELDER],
	['Second Baseman', SportRoleType.BASEBALL_INFIELDER],
	['Third Baseman', SportRoleType.BASEBALL_INFIELDER],
	['Shortstop', SportRoleType.BASEBALL_INFIELDER],
	['Left Fielder', SportRoleType.BASEBALL_OUTFIELDER],
	['Center Fielder', SportRoleType.BASEBALL_OUTFIELDER],
	['Right Fielder', SportRoleType.BASEBALL_OUTFIELDER],
	['Designated Hitter', SportRoleType.BASEBALL_DH],
	['Forward', SportRoleType.SOCCER_FORWARD],
	['Striker', SportRoleType.SOCCER_FORWARD],
	['Midfielder', SportRoleType.SOCCER_MIDFIELDER],
	['Defender', SportRoleType.SOCCER_DEFENDER],
	['Sweeper', SportRoleType.SOCCER_DEFENDER],
	['Goalkeeper', SportRoleType.SOCCER_GOALKEEPER],
	['Center', SportRoleType.BASKETBALL_CENTER],
	['Golfer', SportRoleType.GOLF_GOLFER],
	['Left Wing', SportRoleType.HOCKEY_FORWARD],
	['Right Wing', SportRoleType.HOCKEY_FORWARD],
	['Centre', SportRoleType.HOCKEY_FORWARD],
	['Defense', SportRoleType.HOCKEY_DEFENSE],
	['Goaltender', SportRoleType.HOCKEY_GOALTENDER],
	['Guard', SportRoleType.BASKETBALL_GUARD],
	['Point Guard', SportRoleType.BASKETBALL_GUARD],
	['Small Forward', SportRoleType.BASKETBALL_SMALL_FORWARD],
	['Power Forward', SportRoleType.BASKETBALL_POWER_FORWARD],
	['Member', SportRoleType.CROSS_TRAINING_MEMBER],
	['Quarterback', SportRoleType.FOOTBALL_QB],
	['Center', SportRoleType.FOOTBALL_C],
	['Offensive Guard', SportRoleType.FOOTBALL_OL],
	['Offensive Tackle', SportRoleType.FOOTBALL_OL],
	['Wide Receiver', SportRoleType.FOOTBALL_WR],
	['Halfback', SportRoleType.FOOTBALL_RB],
	['Fullback', SportRoleType.FOOTBALL_RB],
	['Tight End', SportRoleType.FOOTBALL_TE],
	['Nose Guard', SportRoleType.FOOTBALL_NG],
	['Nose Tackle', SportRoleType.FOOTBALL_NT],
	['Defensive Tackle', SportRoleType.FOOTBALL_DL],
	['Defensive End', SportRoleType.FOOTBALL_DE],
	['Middle Linebacker', SportRoleType.FOOTBALL_LB],
	['Outside Linebacker', SportRoleType.FOOTBALL_LB],
	['Cornerback', SportRoleType.FOOTBALL_DB],
	['Free Safety', SportRoleType.FOOTBALL_DB],
	['Strong Safety', SportRoleType.FOOTBALL_DB],
	['Nickelback', SportRoleType.FOOTBALL_DB],
	['Kicker', SportRoleType.FOOTBALL_K],
	['Punter', SportRoleType.FOOTBALL_P],
	['Long Snapper', SportRoleType.FOOTBALL_LS],
	['Kick Returner', SportRoleType.FOOTBALL_KR],
	['Punt Returner', SportRoleType.FOOTBALL_PR],
	['Special Teams', SportRoleType.FOOTBALL_ST]
]);

export const SportRoleLabelMap = new Map<SportRoleType, string>(
	Array.from(SportRoleMap).map(([label, key]) => [key, label])
);

export type CompliancePoint = {
	date?:string | null;
	compliance?:number | null;
	color?:string | null;
	colorOriginal?:string | null;
	completedWorkouts?:number | null;
	programId?:IDType;
	programStatus?:ProgramStatus;
	complianceLevel?:ComplianceLevel | null;
	injury?:Injury | null;
}

export type Subscription = {
	type?:string | null;
	expirationDate?:string | null;
	expired?:boolean | null;
	daysLeft?:number | null;
	daysLeftDisplay?:number | null;
	numTrialSubs?:number | null;
}

export interface IPlayer extends IData {
	userId:IDType;
	firstName: string;
	lastName: string;
	role?:string | null;
	lastLogin?:string | null;
	lastExercise?:string | null;
	lastAssessment?:string | null;
	startDate?:string | null;
	completeDate?:string | null;
	expirationDate?:string | null;
	allowPartialAssessment?:boolean | null;
	exercisesCount?:number | null;
	status?:Status | null;
	statusColor?:StatusColor | string | null;
	overallStatus?:Status | null;
	programStatus?:ProgramStatus | null;
	programDayStatus?:ProgramDayStatus | null;
	assessmentRiskLevel?:RiskLevel | null;
	assessmentRiskScore?:number | null;
	completedDays?:number | null;
	totalDays?:number | null;
	skippedDays?:number | null;
	missedDays?:number | null;
	daysSinceStart?:number | null;
	daysSinceAssessment?:number | null;
	daysSinceCompletion?:number | null;
	image?:string | null;
	orgId:IDType;
	profileItems?:ProfileItem[] | null;
	assessmentId?:IDType | null;
	assessmentStatus?: AssessmentStatus | null;
	assessmentType?: AssessmentType | null;
	maxExerciseTimeDay?:number | null;
	canStartNextDay?:boolean | null;
	programDay?:number | null;
	timeZone?:string | null;
	compliancePercent?:number | null;
    complianceColor?:StatusColor | string | null;
	injury?:Injury | null;
	subscription?:Subscription | null;
	email?:string | null;

	leftNeckStandard?:MeasurementStatus | null;
	rightNeckStandard?:MeasurementStatus | null;
	leftTorsoStandard?:MeasurementStatus | null;
	rightTorsoStandard?:MeasurementStatus | null;
	leftShoulderStandard?:MeasurementStatus | null;
	rightShoulderStandard?:MeasurementStatus | null;
	leftArmStandard?:MeasurementStatus | null;
	rightArmStandard?:MeasurementStatus | null;
	leftHipStandard?:MeasurementStatus | null;
	rightHipStandard?:MeasurementStatus | null;
	leftLegStandard?:MeasurementStatus | null;
	rightLegStandard?:MeasurementStatus | null;
	leftFootStandard?:MeasurementStatus | null;
	rightFootStandard?:MeasurementStatus | null;
}


// State type
export interface IPlayerState {
	isLoading:boolean;
	player?:IPlayer;
	error?:(string | null);
	complianceHistory?:CompliancePoint[];
	complianceHistoryIsLoading:boolean;
	complianceHistoryError?:string;
	complianceGreenMin?:number;
	complianceYellowMin?:number;
	programLoading:boolean;
	programSuccess?:boolean;
    programError?:string;
}


// Action types
export interface IGetPlayerPayload extends ITokenPayload {
	id:IDType;

	/**
	 * Whether to include shallow correctives info.
	 * Possibly first item is [[ICorrective]] and the rest are [[ICorrectiveLimited]] to be loaded later.
	 */
	correctives:boolean; 

	/**
	 * Whether to include shallow assessments info.
	 * Possibly first item is [[IAssessmentsProgram]] and the rest are [[IAssessmentsProgramLimited]] to be loaded later.
	 */
	assessments:boolean;

	isProgramDayNew?:boolean;
}
export interface IGetPlayer {
	type: typeof GET_PLAYER;
	payload: IGetPlayerPayload;
};

export interface ISetPlayerLoading {
    type: typeof SET_PLAYER_LOADING;
    payload: boolean;
};

export interface ISetPlayer {
    type: typeof SET_PLAYER;
    payload?:IPlayer;
}

export interface IShowPlayerError {
    type: typeof SHOW_PLAYER_ERROR;
    payload:string;
}

export interface IRestartPlayerProgramPayload extends ITokenPayload {
	player:IPlayer;
}
export interface IRestartPlayerProgram {
    type: typeof RESTART_PLAYER_PROGRAM;
    payload:IRestartPlayerProgramPayload;
}

export interface ICompletePlayerProgram {
    type: typeof COMPLETE_PLAYER_PROGRAM;
    payload:IRestartPlayerProgramPayload;
}

export interface IPlayerProgramSuccess {
    type: typeof PLAYER_PROGRAM_SUCCESS;
};

export interface IPlayerProgramFailure {
    type: typeof PLAYER_PROGRAM_FAILURE;
    payload: string;
};

type DateRange = {
	start:Date;
	end:Date;
}
export interface IPlayerMarkInjuryStatusPayload extends ITokenPayload {
	player:IPlayer;
	type:InjuryType;
	daysLength:number;
	info:string;
	date?:string;
	injuryId?:IDType;
}
export interface IPlayerMarkInjuryStatus {
	type: typeof MARK_PLAYER_INJURY_STATUS;
	payload: IPlayerMarkInjuryStatusPayload;

}

export interface IPlayerGetComplianceHistoryPayload extends ITokenPayload {
	player:IPlayer; 
	maxDays:number;
}
export interface IPlayerGetComplianceHistory {
	type: typeof GET_PLAYER_COMPLIANCE_HISTORY;
	payload: IPlayerGetComplianceHistoryPayload;
}

export interface IPlayerSetComplianceHistoryPayload {
	history: CompliancePoint[];
	complianceGreenMin?:number;
	complianceYellowMin?:number;
}
export interface IPlayerSetComplianceHistory {
	type: typeof SET_PLAYER_COMPLIANCE_HISTORY;
	payload: IPlayerSetComplianceHistoryPayload;
}

export interface IPlayerShowComplianceHistoryError {
	type: typeof SHOW_COMPLIANCE_HISTORY_ERROR;
	payload: string;
}


// Classes
export class Player implements IPlayer {
	
	constructor(
		public readonly id:IDType,
		public readonly userId:IDType,
		public readonly firstName: string,
		public readonly lastName: string,
		public readonly orgId: IDType,
		public readonly status?:Status | null,
		public readonly statusColor?:StatusColor | string | null,
		public readonly overallStatus?:Status | null,
		public readonly programStatus?:ProgramStatus | null,
		public readonly programDayStatus?:ProgramDayStatus | null,
		public readonly assessmentRiskLevel?:RiskLevel | null,
		public readonly assessmentRiskScore?:number | null,
		public readonly completedDays?:number | null,
		public readonly totalDays?:number | null,
		public readonly skippedDays?:number | null,
		public readonly missedDays?:number | null,
		public readonly daysSinceStart?:number | null,
		public readonly daysSinceAssessment?:number | null,
		public readonly daysSinceCompletion?:number | null,
		public readonly profileItems?:ProfileItem[] | null,
		public readonly role?:string | null,
		public readonly image?:string | null,
		public readonly exercisesCount?:number | null,
		public readonly lastLogin?:string | null,
		public readonly lastExercise?:string | null,
		public readonly lastAssessment?:string | null,
		public startDate?:string | null,
		public completeDate?:string | null,
		public readonly expirationDate?:string | null,
		public readonly allowPartialAssessment?:boolean | null,
		public readonly assessmentId?:IDType | null,
		public assessmentStatus?:AssessmentStatus | null,
		public readonly assessmentType?: AssessmentType | null,
		public maxExerciseTimeDay?:number | null,
		public readonly canStartNextDay?:boolean | null,
		public readonly programDay?:number | null,
		public readonly timeZone?:string | null,
		public compliancePercent?:number | null,
    	public complianceColor?:StatusColor | string | null,
		public injury?:Injury | null,
		public subscription?:Subscription | null,
		public email?:string | null,

		public readonly leftNeckStandard?:MeasurementStatus | null,
		public readonly rightNeckStandard?:MeasurementStatus | null,
		public readonly leftTorsoStandard?:MeasurementStatus | null,
		public readonly rightTorsoStandard?:MeasurementStatus | null,
		public readonly leftShoulderStandard?:MeasurementStatus | null,
		public readonly rightShoulderStandard?:MeasurementStatus | null,
		public readonly leftArmStandard?:MeasurementStatus | null,
		public readonly rightArmStandard?:MeasurementStatus | null,
		public readonly leftHipStandard?:MeasurementStatus | null,
		public readonly rightHipStandard?:MeasurementStatus | null,
		public readonly leftLegStandard?:MeasurementStatus | null,
		public readonly rightLegStandard?:MeasurementStatus | null,
		public readonly leftFootStandard?:MeasurementStatus | null,
		public readonly rightFootStandard?:MeasurementStatus | null
	){}

	
	static create({
		id,
		userId, 
		firstName, 
		lastName, 
		orgId, 
		status, 
		statusColor,
		overallStatus, 
		programStatus,
		programDayStatus,
		assessmentRiskLevel,
		assessmentRiskScore,
		completedDays, 
		totalDays, 
		skippedDays, 
		missedDays,
		daysSinceStart,
		daysSinceAssessment,
		daysSinceCompletion,
		profileItems, 
		role, 
		image, 
		exercisesCount, 
		lastLogin, 
		lastExercise, 
		lastAssessment, 
		startDate,
		completeDate,
		expirationDate,
		allowPartialAssessment,
		assessmentId,
		assessmentStatus,
		assessmentType,
		maxExerciseTimeDay,
		canStartNextDay,
		programDay,
		timeZone,
		compliancePercent,
		complianceColor,
		injury,
		subscription,
		email,
		leftNeckStandard,
		rightNeckStandard,
		leftTorsoStandard,
		rightTorsoStandard,
		leftShoulderStandard,
		rightShoulderStandard,
		leftArmStandard,
		rightArmStandard,
		leftHipStandard,
		rightHipStandard,
		leftLegStandard,
		rightLegStandard,
		leftFootStandard,
		rightFootStandard
	}:IPlayer):Player {
		return new Player(
			id, 
			userId,
			firstName, 
			lastName, 
			orgId, 
			status, 
			statusColor,
			overallStatus, 
			programStatus,
			programDayStatus,
			assessmentRiskLevel,
			assessmentRiskScore,
			completedDays, 
			totalDays, 
			skippedDays, 
			missedDays, 
			daysSinceStart,
			daysSinceAssessment,
			daysSinceCompletion,
			profileItems, 
			role, 
			image, 
			exercisesCount, 
			lastLogin, 
			lastExercise, 
			lastAssessment,
			startDate,
			completeDate,
			expirationDate,
			allowPartialAssessment, 
			assessmentId,
			assessmentStatus,
			assessmentType,
			maxExerciseTimeDay,
			canStartNextDay,
			programDay,
			timeZone,
			compliancePercent,
			complianceColor,
			injury,
			subscription,
			email,
			leftNeckStandard,
			rightNeckStandard,
			leftTorsoStandard,
			rightTorsoStandard,
			leftShoulderStandard,
			rightShoulderStandard,
			leftArmStandard,
			rightArmStandard,
			leftHipStandard,
			rightHipStandard,
			leftLegStandard,
			rightLegStandard,
			leftFootStandard,
			rightFootStandard
		);
	}

	/*static clone({correctives, updateCorrective, ...otherProps}:IPlayer):Player {
		const clone = Player.create(otherProps);
		if( correctives ){
			clone.correctives = [
				...correctives
			];
		}
		return clone;
	}*/

	static getHandednessType({role, profileItems}:IPlayer, sport?:SportType | string):Side | undefined {
		const profileMap = profileItems ? new Map<ProfileItemType, string | number | ProfileSideType | null | undefined>(profileItems.map(({key, value}) => [key, value])) : undefined;
		const sportRole = role ? SportRoleMap.get(role) : undefined;
		let profileData:string | number | ProfileSideType | null | undefined;

		if( profileMap ){

			switch( sport ){

				case SportType.Baseball :
					if(sportRole===SportRoleType.BASEBALL_PITCHER){
						profileData = profileMap.get(ProfileItemType.ThrowingSide);
					} else {
						profileData = profileMap.get(ProfileItemType.BattingSide);
					}
					break;

				case SportType.Soccer :
					if(sportRole===SportRoleType.SOCCER_GOALKEEPER){
						profileData = profileMap.get(ProfileItemType.ThrowingSide);
					} else {
						profileData = profileMap.get(ProfileItemType.LegSide);
					}
					break;

				case SportType.Basketball :
					profileData = profileMap.get(ProfileItemType.ShootingSide);
					break;

				case SportType.Football :
					if(sportRole===SportRoleType.FOOTBALL_QB){
						profileData = profileMap.get(ProfileItemType.ThrowingSide);
					} else {
						profileData = profileMap.get(ProfileItemType.GeneralStrongSide);
					}
					break;

				case SportType.Hockey :
					if(sportRole===SportRoleType.HOCKEY_GOALTENDER){
						profileData = profileMap.get(ProfileItemType.GeneralStrongSide);
						if(!profileData){
							profileData = profileMap.get(ProfileItemType.ShootingSide);
						}
					} else {
						profileData = profileMap.get(ProfileItemType.ShootingSide);
					}
					break;

				case SportType.Golf :
				case SportType.Tennis :
					profileData = profileMap.get(ProfileItemType.SwingSide);
					break;

				default: 
					profileData = profileMap.get(ProfileItemType.GeneralStrongSide);
			}

		}

		if( profileData ){
			
			switch( profileData ){
				case ProfileSideType.Left :
					return Side.left;
				case ProfileSideType.Right :
					return Side.right;
				case ProfileSideType.Center :
					return Side.center;
				case ProfileSideType.Both :
				case ProfileSideType.Switch :
					return Side.bilateral;
			}
		}
		return undefined;
	}
}

export type TypePlayerAction = IGetPlayer | ISetPlayerLoading | ISetPlayer | IShowPlayerError | ISignOutSuccess | IRestartPlayerProgram | ICompletePlayerProgram | 
IPlayerProgramSuccess | IPlayerProgramFailure | IPlayerMarkInjuryStatus | IPlayerGetComplianceHistory | IPlayerSetComplianceHistory | IPlayerShowComplianceHistoryError;