import React, {useCallback} from 'react';
import { Paper, PaperProps, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { IOrg } from '../../redux/org/org.types';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles, Theme, createStyles, darken } from '@material-ui/core/styles';
import { Colors, FontWeight } from '../../utils/style';
import { createStructuredSelector } from 'reselect';
import { IRootState } from '../../redux/root.types';
import { selectOrgInfo } from '../../redux/org/org.selectors';
import { connect, ConnectedProps } from 'react-redux';
import clsx from 'clsx';

export const PanelWidth = 253;
export const PanelSpace = 81;
export const PanelSpaceMobile = 35;
export const PanelTransition = 'all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000) 20ms'; /* easeOutQuint */
export const PanelDialogPadding =  32 * 2;


interface mapStateToPropsInterface  {
	currentOrg?:IOrg;
}
const mapStateToProps = createStructuredSelector<IRootState, mapStateToPropsInterface>({
	currentOrg: selectOrgInfo,
});

const connector = connect(
    mapStateToProps
);
type PropsFromRedux = ConnectedProps<typeof connector>;

type OrgPanelProps = PropsFromRedux & Omit<PaperProps, 'children'> & {
	org: IOrg;
	stackIndex:number;
	totalStacks:number;
	onOrgExpand:(org:IOrg) => void;
	onOrgClick:(org:IOrg) => void;
}
const OrgPanel: React.FC<OrgPanelProps> = ({ org, currentOrg, stackIndex, totalStacks, onOrgClick, onOrgExpand }) => {
	
	const useStyles = makeStyles<Theme, {
		stackIndex:number;
		totalStacks:number;
		isTopPanel:boolean;
	
	}>((theme: Theme) => {
		
		return createStyles({
			root: props => ({
				backgroundColor: props.isTopPanel ? undefined : darken(Colors.White, (props.totalStacks - props.stackIndex) * .05),
				width: PanelWidth,
				height: 483,
				maxHeight: `calc(100vh - ${PanelDialogPadding}px)`,
				position: 'absolute',
				left: props.stackIndex * PanelSpace,
				transition: PanelTransition,
				top: 54,
				zIndex: props.stackIndex + 1,
				overflowY: 'auto',
				pointerEvents: 'auto',
	
				'&.panel-enter': {
					opacity: 0,
					left: 0,
				},
				'&.panel-enter-active': {
					opacity: 1,
					left: props.stackIndex * PanelSpace,
				},
				'&.panel-exit': {
					opacity: 1,
					left: props.stackIndex * PanelSpace,
				},
				'&.panel-exit-active': {
					opacity: 0,
					left: 0,
				},

				[theme.breakpoints.down("sm")]: {
		
					'&, &.panel-enter-active, &.panel-exit': {
						left: props.stackIndex * PanelSpaceMobile,
					},
				},
			}),
			listItem: {
				padding: '0 8px 0 28px',

				'& .MuiListItemText-root': {
					marginTop: 8,
					marginBottom: 8,
					maxWidth: 'calc(100% - 20px)'
				}
			},
			label: {
				'& .MuiTypography-body1': {
					fontSize: 14,
					lineHeight: '19px',
				},
			},
			labelActive: {
				'& .MuiTypography-body1': {
					fontWeight: FontWeight.Bold
				},
			},
			labelLink: props => ({
				'&:hover': {
					textDecoration: (props.isTopPanel && org!==currentOrg) ? 'underline' : undefined,
					cursor: (props.isTopPanel && org!==currentOrg) ? 'pointer' : undefined,
				}
			}),
			expandButton: {
				borderRadius: 0,
				color: Colors.BrandSkyBlue,
				padding: 7,

				'&:hover': {
					backgroundColor: Colors.BrandSkyBlue,
					color: Colors.White
				}
			}
		});
	});
	
	const { children } = org;

	const isTopPanel = stackIndex === (totalStacks-1);

	const classes = useStyles({
		stackIndex,
		totalStacks,
		isTopPanel
	});

	const onExpand = useCallback((setOrg:IOrg) => {
		onOrgExpand(setOrg);
	}, [onOrgExpand]);

	const onClick = useCallback((setOrg:IOrg) => {
		onOrgClick(setOrg);
	}, [onOrgClick]);

	const isActive = useCallback((setOrg:IOrg) => {
		return currentOrg && setOrg.id===currentOrg.id;
	}, [currentOrg]);

	return children && children.length > 0 ? (
		<Paper className={classes.root} elevation={3} onClick={isTopPanel ? undefined : () => onExpand(org)}>
			<List>
				{children.map((childOrg) => (
					<ListItem key={childOrg.id} className={classes.listItem}>
						<ListItemText
							primary={childOrg.displayName}
							className={clsx(classes.label, isActive(childOrg) && classes.labelActive, !isActive(childOrg) && isTopPanel && classes.labelLink)}
							onClick={isTopPanel && !isActive(childOrg) ? () => onClick(childOrg) : undefined}
						/>
						{childOrg.children && childOrg.children.length > 0 && (
							<ListItemSecondaryAction>
								<IconButton className={classes.expandButton} edge="end" aria-label="open org" onClick={() => onExpand(childOrg)}>
									<ChevronRightIcon />
								</IconButton>
							</ListItemSecondaryAction>
						)}
					</ListItem>
				))}
			</List>
		</Paper>
	) : null;
}

export default connector(OrgPanel);