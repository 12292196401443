import { difference } from 'lodash';

export const isNumber = (input?:number | null):boolean => {
	return (input!==undefined && input!==null && !isNaN(input));
}

export const isPositiveNumber = (input?:number | null):boolean => {
	return isNumber(input) && (input as number) > 0;
}

export const isElementInLayout = (element:HTMLElement):boolean => {
	// first checks if visible (not display none). Does not work with fixed elements - https://stackoverflow.com/a/21696585/3229669
	// then checks body for element
	return element.offsetParent!==null && document.body.contains(element);
}

/* Using regex to check if input is a valid email address.
- The email couldn't start or finish with a dot
- The email shouldn't contain spaces into the string
- The email shouldn't contain special chars (<:, *,ecc)
- The email could contain dots in the middle of mail address before the @
- The email could contain a double doman ( '.de.org' or similar rarity)
- Allow + for Gmail aliases like 'user+variation@gmail.com'
Source: https://regex101.com/r/JEdtWy/3
*/
export const isEmailAddress = (input:string) => /^((?!\.)[\w-_.+]*[^.])(@[\w-]+)(\.\w+(\.\w+)?[^.\W])$/.test(input);

export const isDefined = (input?:any | null):boolean => {
	return (input!==undefined && input!==null);
}

/* Checks if the config or override config differs from the default settings. */
export type BasicObject = Record<string, any | any[]>;
export const compareConfigs = (config:BasicObject, configDefault?:BasicObject) => {
	const keys = Object.keys(config);
	if( !configDefault ){
		return keys.length < 1;
	} else if(keys.length < 1){
		return true;
	} else {
		// compare override to default
		let diffs = 0;
		for (const key in config) {
			const overrideVal = config.hasOwnProperty(key) ? config[key] : undefined;
			const defaultVal = configDefault.hasOwnProperty(key) ? configDefault[key] : undefined;
			if( Array.isArray(overrideVal) && Array.isArray(defaultVal) ){
				if(difference(overrideVal, defaultVal).length > 0){
					diffs++;
				}
			} else if(typeof overrideVal==='object' && typeof defaultVal==='object' && !Array.isArray(overrideVal) && !Array.isArray(defaultVal) && overrideVal!==null && defaultVal!==null ){
				if( !compareConfigs(overrideVal, defaultVal) ){
					diffs++;
				}
			} else if(overrideVal!==defaultVal){
				diffs++;
			}
		}
		return diffs > 0;
	}
}