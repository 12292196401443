import { AssessmentStatus, IAssessment } from '../assessment/assessment.types';
import { IBodyPartGroup, Side } from '../assessment/body-parts.types';
import { IDType, IData } from '../core.types';
import { IGetPlayer, ISetPlayer } from '../player/player.types';
import { ISignOutSuccess, ITokenPayload } from '../user/user.types';

export const SET_PLAYER_ASSESSMENTS_LOADING = 'SET_PLAYER_ASSESSMENTS_LOADING';
export const GET_ALL_PLAYER_ASSESSMENTS = 'GET_ALL_PLAYER_ASSESSMENTS';
export const GET_ALL_PLAYER_ASSESSMENTS_MANAGEMENT = 'GET_ALL_PLAYER_ASSESSMENTS_MANAGEMENT';
export const SHOW_ALL_PLAYER_ASSESSMENTS_ERROR = 'SHOW_ALL_PLAYER_ASSESSMENTS_ERROR';
export const GET_PLAYER_ASSESSMENT = 'GET_PLAYER_ASSESSMENT';
export const SHOW_PLAYER_ASSESSMENT_ERROR = 'SHOW_PLAYER_ASSESSMENT_ERROR';
export const UPDATE_PLAYER_ASSESSMENT = 'UPDATE_PLAYER_ASSESSMENT';
export const SET_ALL_PLAYER_ASSESSMENTS = 'SET_ALL_PLAYER_ASSESSMENTS';
export const GET_EXPANDED_MEASUREMENTS = 'GET_EXPANDED_MEASUREMENTS';

// Data types
export interface IAssessmentCompleted extends IAssessment {
	date:string;
	status?:AssessmentStatus | null;
	bodyPartGroups?:IBodyPartGroup[];
	snapshots?:IAssessmentCompleted[] | null;
	assessmentRiskScore?:number | null;
}

export interface IAssessmentsProgram extends IData {
	name?:string | null;
	days:IAssessmentCompleted[];
	error?:string;
	forceUpdate?:string;
}

export interface IAssessmentsProgramLimited extends IData {
	name:string;
	error?:string;
}
export type LoadableProgramType = IAssessmentsProgramLimited | IAssessmentsProgram | IAssessmentCompleted;

export interface IAssessmentsMap {
	[programId:number]:LoadableProgramType;
}
export interface IAssessmentsErrorsMap {
	[programId:number]:string;
}



// State type
export interface IAssessmentsState {
	isLoading:boolean;
	playerId?:IDType;
	programOrder?:IDType[],
	programMap:IAssessmentsMap;
	error?:(string | null);
	assessmentsErrorsMap:IAssessmentsErrorsMap;
	isExpandedMeasurementsLoading:boolean;
}


// Action types
export interface IGetAllPlayerAssessmentsPayload extends ITokenPayload {
	playerId:IDType;
}
export interface IGetAllPlayerAssessments {
	type: typeof GET_ALL_PLAYER_ASSESSMENTS;
	payload: IGetAllPlayerAssessmentsPayload;
};

export interface IGetAllPlayerAssessmentsManagement {
	type: typeof GET_ALL_PLAYER_ASSESSMENTS_MANAGEMENT;
	payload: IGetAllPlayerAssessmentsPayload;
};
export interface ISetPlayerAssessmentsLoading {
	type: typeof SET_PLAYER_ASSESSMENTS_LOADING;
	payload: boolean;
};
export interface IShowAllPlayerAssessmentsError {
    type: typeof SHOW_ALL_PLAYER_ASSESSMENTS_ERROR;
    payload: string;
}

export interface IGetPlayerAssessmentPayload {
	id:IDType;
	playerId:IDType;
}
export interface IGetPlayerAssessment {
	type: typeof GET_PLAYER_ASSESSMENT;
	payload: IGetPlayerAssessmentPayload;
};

export interface IShowGetPlayerAssessmentErrorPayload {
	programId:IDType;
	error:string;
}
export interface IShowGetPlayerAssessmentError {
    type: typeof SHOW_PLAYER_ASSESSMENT_ERROR;
    payload:IShowGetPlayerAssessmentErrorPayload;
}

export interface ISetAllPlayerAssessmentsPayload {
	programs:LoadableProgramType[];
	playerId:IDType;
}
export interface ISetAllPlayerAssessments {
	type: typeof SET_ALL_PLAYER_ASSESSMENTS;
	payload:ISetAllPlayerAssessmentsPayload;
};

export interface IUpdatePlayerAssessmentPayload {
	program:IAssessmentsProgram;
	playerId:IDType;
}
export interface IUpdatePlayerAssessment {
	type: typeof UPDATE_PLAYER_ASSESSMENT;
	payload: IUpdatePlayerAssessmentPayload;
};

export type IGetExpandedMeasurementPayload = IUpdatePlayerAssessmentPayload & ITokenPayload & {
	measurementIds:IDType[]; // measurement IDs
	side:Side;
}
export interface IGetExpandedMeasurement {
	type: typeof GET_EXPANDED_MEASUREMENTS;
	payload: IGetExpandedMeasurementPayload;
};

export type TypeAssessmentsAction = IGetAllPlayerAssessments | ISetPlayerAssessmentsLoading | IShowAllPlayerAssessmentsError | IGetPlayerAssessment |
IShowGetPlayerAssessmentError | ISetAllPlayerAssessments | IUpdatePlayerAssessment | ISetPlayer | IGetExpandedMeasurement | ISignOutSuccess | IGetPlayer;