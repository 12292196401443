import { BodyParts, convertRemoteBodyPart } from './body-parts.types';
import { Fallback, IDType, IData } from '../core.types';
import { ISignOutSuccess, ITokenPayload } from '../user/user.types';
import { RemoteMediaType, convertRemoteMedia } from '../../utils/media-type';

import { IMeasurementInfo } from './measurement-expanded';
import {IPlayer} from '../player/player.types';
import MediaType from '../../utils/media-type';
import { ROMAxis } from '../ROM/ROM.types';

export const SET_ASSESSMENT_LOADING = 'SET_ASSESSMENT_LOADING';
export const GET_ASSESSMENT = 'GET_ASSESSMENT';
export const SET_ASSESSMENT = 'SET_ASSESSMENT';
export const GET_CACHED_ASSESSMENT = 'GET_CACHED_ASSESSMENT';
export const SET_CACHED_ASSESSMENT = 'SET_CACHED_ASSESSMENT';
export const RESTORE_CACHED_ASSESSMENT = 'RESTORE_CACHED_ASSESSMENT';
export const SHOW_ASSESSMENT_ERROR = 'SHOW_ASSESSMENT_ERROR';
export const SET_ASSESSMENT_ORG_MEMBERS = 'SET_ASSESSMENT_ORG_MEMBERS';
export const SET_CURRENT_ORG_MEMBER = 'SET_CURRENT_ORG_MEMBER';
export const SAVE_ASSESSMENT = 'SAVE_ASSESSMENT';
export const SAVE_PARTIAL_ASSESSMENT = 'SAVE_PARTIAL_ASSESSMENT';
export const SAVE_ASSESSMENT_SUCCESS = 'SAVE_ASSESSMENT_SUCCESS';
export const SAVE_ASSESSMENT_FAILURE = 'SAVE_ASSESSMENT_FAILURE';
export const AUTO_SAVE_ASSESSMENT_FAILURE = 'AUTO_SAVE_ASSESSMENT_FAILURE';
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT';
export const DELETE_ASSESSMENT = 'DELETE_ASSESSMENT';
export const RESTART_ASSESSMENT = 'RESTART_ASSESSMENT';
export const GET_MEASUREMENTS = 'GET_MEASUREMENTS';
export const SET_MEASUREMENTS = 'SET_MEASUREMENTS';

// Data types
export enum AssessmentType {
	Full = 'FULL',
	Partial = 'PARTIAL',
	Manual = 'MANUAL',
	Snapshot = 'SNAPSHOT'
}

export enum AssessmentStatus {
	New = 'NEW',
	InProgress = 'IN_PROGRESS',
	Submitted = 'SUBMITTED',
	Processed = 'PROCESSED',
	Completed = 'COMPLETED',
	Deleted = 'DELETED'
}

export enum MeasurementStatus {
	LEVEL_1 = 'LEVEL_1',
	LEVEL_2 = 'LEVEL_2',
	LEVEL_3 = 'LEVEL_3',
	LEVEL_4= 'LEVEL_4'
}

export enum AssessmentFlowType {
	OPTIMIZED = 'OPTIMIZED'
}

export const getStatusClass = (standard?: MeasurementStatus | null): string => {
	switch (standard) {
		case MeasurementStatus.LEVEL_1:
			return 'low';
		case MeasurementStatus.LEVEL_2:
			return 'medium';
		case MeasurementStatus.LEVEL_3:
			return 'high';
		default:
			return 'best';
	}
};

export enum MeasurementKey {
	FemoralExternalRotation = 'femoral-xtr',
	GreatToeExtension = 'big-toe',
	AnkleDorsiflexion = 'ankle',
	WristExtension = 'wrist-extension',
	WristSupination = 'wrist-supination',
	WristPronation = 'wrist-pronation',
	Piriformis = 'piriformis',
	QuadratusFemoris = 'quadratus-femoris',
	ObturatorInternus = 'obturator-internus',
	TLJunction = 'tl-junction',
	ShoulderFlexion = 'shoulder-flex',
	ShoulderXtr90Abduction = 'shoulder-abduction-xtr',
	ShoulderXtr180Flexion = 'shoulder-flex-xtr',
	NeckRotation = 'neck-rotation',
	TibialInternal = 'tibial-itr',
	TibialExternal = 'tibial-xtr',
	HipExtension = 'hip-ext',
	HipFlexion = 'hip-flex',
}

export interface IMeasurement extends IData {
	measurementId:IDType;
	name:string;
	key:MeasurementKey;
	bilateral:boolean; // if it applies to left & right, or just center
	left?:number | null;
	right?:number | null;
	center?:number | null;
	leftTrending?:number | null;
	rightTrending?:number | null;
	centerTrending?:number | null;
	leftStandard?:MeasurementStatus | null,
	rightStandard?:MeasurementStatus | null,
	centerStandard?:MeasurementStatus | null,
	leftPercent?:number | null;
	rightPercent?:number | null;
	centerPercent?:number | null;
	bodyPart?:BodyParts | null,
	expandedInfoLeft?:IMeasurementInfo | Fallback;
	expandedInfoRight?:IMeasurementInfo | Fallback;
	expandedInfoCenter?:IMeasurementInfo | Fallback;
	media?:MediaType[] | null;
	visible?:boolean | null;
	imported?:boolean; // internal
}

export enum MeasurementPart {
    Left,
    Right,
    Center
}

export interface IMeasurementFlow extends Pick<IMeasurement, 'measurementId' | 'key'> {
	order?:number | null;
	side:MeasurementPart;
	romAxis:ROMAxis;
}

export interface IAssessment extends IData {
	name?:string;
	measurements:IMeasurement[];
	memberId:IDType;
	type?:AssessmentType | null;
	flow?:IMeasurementFlow[] | null;
}

export interface IMeasurementSave extends Pick<IMeasurement, "id" | "bilateral"> {
	left:number | null;
	right:number | null;
	center:number | null;
	visible:boolean | null;
}

export interface IAssessmentSave extends Pick<IAssessment, "id" | "memberId" | "type"> {
	measurements:IMeasurementSave[];
}

export interface IAssessmentSubmit extends IAssessmentSave {
	completeAfterSubmit?:boolean;
}

//export type AssessmentMapType = Map<string, IAssessment>;

export interface AssessmentMapInterface {
	[key:number]: IAssessment;
}

/* Track a org member's results and progress */
/*export interface IAssessmentProgress extends IAssessment {
	memberId:string;
}*/


// State type
export interface IAssessmentState {
	isLoading:boolean;
	assessments?:AssessmentMapInterface;
	orgMembers?:IPlayer[];
	currentOrgMember?:IPlayer;
	error?:string;
	cachedChecked:boolean;
	saveAssessmentSuccess:boolean;
	saveAssessmentError?:string;
	autoSaveAssessmentError?:string;
	measurements?:IMeasurement[];
}


// Action types
export interface IGetAssessmentPayload extends ITokenPayload {
	players:IPlayer[];
	type?:AssessmentType;
	measurementIds?:IDType[];
	measurements?:IMeasurement[];
	flowType?:AssessmentFlowType;
}
export interface IGetAssessment {
	type: typeof GET_ASSESSMENT;
	payload: IGetAssessmentPayload;
};

export interface ISetAssessmentLoading {
    type: typeof SET_ASSESSMENT_LOADING;
    payload: boolean;
};

export interface ISetAssessment {
    type: typeof SET_ASSESSMENT;
    payload:AssessmentMapInterface;
}

export interface IGetCachedAssessment {
	type: typeof GET_CACHED_ASSESSMENT;
};

export interface ICachableAssessmentPayload {
	assessments?:AssessmentMapInterface;
	measurements?:IMeasurement[];
	orgMembers:IPlayer[];
	currentOrgMember:IPlayer;
}

export type ICachedAssessmentPayload = ICachableAssessmentPayload & ITokenPayload & {
	autoSave:boolean;
	changingPlayer?:boolean; // let's reducer know it's ok to overwrite the existing assessments map
}
export interface ISetCachedAssessment {
	type: typeof SET_CACHED_ASSESSMENT;
	payload:ICachedAssessmentPayload | null;
};

export interface IRestoreCachedAssessment {
	type: typeof RESTORE_CACHED_ASSESSMENT;
	payload:ICachableAssessmentPayload;
};

export interface IShowAssessmentError {
    type: typeof SHOW_ASSESSMENT_ERROR;
    payload:string;
}

export interface ISetAssessmentOrgMembersPayload {
	members:IPlayer[];
	reset?:boolean;
}
export interface ISetAssessmentOrgMembers {
    type: typeof SET_ASSESSMENT_ORG_MEMBERS;
    payload:ISetAssessmentOrgMembersPayload;
}

export interface ISetCurrentOrgMember {
    type: typeof SET_CURRENT_ORG_MEMBER;
    payload:IPlayer;
}

export interface IAssessmentPayload extends ITokenPayload {
	assessment:IAssessment;
}

export interface ISubmitAssessmentPayload extends IAssessmentPayload {
	completeAfterSubmit?:boolean;
}
export interface ISaveAssessment {
    type: typeof SAVE_ASSESSMENT;
    payload:ISubmitAssessmentPayload;
}

export interface ISavePartialAssessmentPayload extends Omit<IGetAssessmentPayload, 'type' | 'measurementIds' | 'players' | 'measurements'> {
	type:AssessmentType.Partial | AssessmentType.Snapshot;
	measurements:IMeasurement[];
	player:IPlayer;
}
export interface ISavePartialAssessment {
    type: typeof SAVE_PARTIAL_ASSESSMENT;
    payload:ISavePartialAssessmentPayload;
}

export interface IUpdateAssessment {
    type: typeof UPDATE_ASSESSMENT;
    payload:IAssessmentPayload;
}

export interface IDeleteAssessment {
    type: typeof DELETE_ASSESSMENT;
    payload:IAssessmentPayload;
}

export interface ISaveAssessmentSuccess {
    type: typeof SAVE_ASSESSMENT_SUCCESS;
};

export interface ISaveAssessmentFailure {
    type: typeof SAVE_ASSESSMENT_FAILURE;
    payload: string;
};

export interface IAutoSaveAssessmentFailure {
    type: typeof AUTO_SAVE_ASSESSMENT_FAILURE;
    payload: string;
};

export interface IRestartAssessmentPayload extends IAssessmentPayload {
	members:IPlayer[];
}

export interface IRestartAssessment {
    type: typeof RESTART_ASSESSMENT;
    payload:IRestartAssessmentPayload;
}

export interface IGetMeasurementsPayload extends ITokenPayload {
	player:IPlayer;
}
export interface IGetMeasurements {
	type: typeof GET_MEASUREMENTS;
	payload: IGetMeasurementsPayload;
};

export interface ISetMeasurements {
    type: typeof SET_MEASUREMENTS;
    payload:IMeasurement[];
}



// Classes
export class Measurement implements IMeasurement {
	constructor(
		public readonly id:IDType,
		public readonly measurementId:IDType,
		public readonly name: string,
		public readonly key: MeasurementKey,
		public readonly bilateral:boolean=true,
		public left?:number | null,
		public right?:number | null,
		public center?:number | null,
		public readonly leftTrending?: number | null,
		public readonly rightTrending?: number | null,
		public readonly centerTrending?: number | null,
		public readonly leftStandard?:MeasurementStatus | null,
		public readonly rightStandard?:MeasurementStatus | null,
		public readonly centerStandard?:MeasurementStatus | null,
		public readonly leftPercent?:number | null,
		public readonly rightPercent?:number | null,
		public readonly centerPercent?:number | null,
		public readonly bodyPart?:BodyParts | null,
		public readonly media?:MediaType[] | null,
		public readonly visible?:boolean | null
	){}

	static create({id, measurementId, name, key, bilateral=true, left, right, center, leftTrending, rightTrending, centerTrending, leftStandard, rightStandard, centerStandard, leftPercent, rightPercent, centerPercent, bodyPart, media, visible}:IMeasurement):Measurement {
		return new Measurement(id, measurementId, name, key, bilateral, left, right, center, leftTrending, rightTrending, centerTrending, leftStandard, rightStandard, centerStandard, leftPercent, rightPercent, centerPercent, convertRemoteBodyPart(bodyPart), media ? media.map(singleMedia => convertRemoteMedia(singleMedia as RemoteMediaType)) : undefined, visible);
	}
}

export class Assessment implements IAssessment {
	constructor(
		public readonly id:IDType,
		public readonly memberId:IDType,
		public measurements:IMeasurement[],
		public readonly name?:string,
		public readonly type?:AssessmentType | null,
		public readonly flow?:IMeasurementFlow[] | null
	){}

	static create({id, memberId, measurements, name, type, flow}:IAssessment):Assessment {
		return new Assessment(id, memberId, measurements, name, type, flow);
	}
}

export type TypeAssessmentAction = IGetAssessment | ISetAssessmentLoading | ISetAssessment | IShowAssessmentError | ISetAssessmentOrgMembers | ISetCurrentOrgMember | 
IGetCachedAssessment | ISetCachedAssessment | IRestoreCachedAssessment | ISaveAssessment | ISavePartialAssessment | ISaveAssessmentSuccess | ISaveAssessmentFailure | IAutoSaveAssessmentFailure | 
ISignOutSuccess | IUpdateAssessment | IDeleteAssessment | IGetMeasurements | ISetMeasurements | IRestartAssessment;