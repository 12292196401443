import {
    GET_ALL_CORRECTIVES,
    GET_CORRECTIVE_PROGRAM,
    GET_CORRECTIVE_SET,
    ICorrectivesMap,
    ICorrectivesState,
    LoadableCorrectiveType,
    SET_ALL_CORRECTIVES,
    SET_CORRECTIVES_DAY_INDEX,
    SET_CORRECTIVES_LOADING,
    SET_CORRECTIVE_PROGRAM,
    SET_CORRECTIVE_SET,
    SHOW_ALL_CORRECTIVES_ERROR,
    SHOW_CORRECTIVE_ERROR,
    TypeCorrectivesAction,
    UPDATE_CORRECTIVE
} from './correctives.types';
import { GET_PLAYER, SET_PLAYER } from '../player/player.types';
import { SET_CORRECTIVES_PLAN_EXERCISE_ORDER, SET_CORRECTIVES_PLAN_ORDER, SET_SELECTED_CORRECTIVES_PLAN, START_CORRECTIVES, UPDATE_CORRECTIVE_EXERCISE_FEEDBACK, UPDATE_CORRECTIVE_EXERCISE_FEEDBACK_SUCCESS, UPDATE_CORRECTIVE_EXERCISE_STATUS, UPDATE_CORRECTIVE_EXERCISE_STATUS_SUCCESS } from './correctives.types';

import { SET_EXERCISE_CORRECTIVE_AND_UPDATE } from '../exercise/exercise.types';
import { SIGN_OUT_SUCCESS } from '../user/user.types';
import { UpdateStatus } from '../core.types';

export const INITIAL_STATE:ICorrectivesState = {
	isLoading: true,
	playerId: undefined,
	correctivesOrder: undefined,
	correctivesMap: {},
	error: undefined,
    correctiveErrorsMap: {},
    selectedDayIndex: undefined,
    correctivesSet:undefined,
    correctivesProgram:undefined,
    selectedPlan:undefined,
    correctivesPlanOrder:undefined,
    correctivesPlanExerciseOrder:undefined,
    correctivesPlanScrollPosition:undefined,
    correctivesPlanExerciseScrollPosition:undefined,
    feedbackResponse:undefined,
};

const correctivesReducer = (state = INITIAL_STATE, action:TypeCorrectivesAction):ICorrectivesState => {
    switch( action.type ){
        
        case SIGN_OUT_SUCCESS :
            return {
                ...INITIAL_STATE
            };
        
        case SET_PLAYER :
            const newPId = action.payload ? action.payload.id : undefined;
            if( state.playerId!==newPId ){
                /* Reset for new player */    
                return {
                    ...INITIAL_STATE,
                    playerId: newPId
                };
            } else {
                return state;
            }

        case GET_PLAYER :
            return (action.payload.correctives) ? {
                ...state,
                isLoading: true,
                playerId: action.payload.id,
                correctivesOrder: undefined,
                correctivesMap: {},
                error: undefined
            } : state;
        
        case SET_ALL_CORRECTIVES :
            if( action.payload.playerId===state.playerId){
                return {
                    ...state,
                    isLoading: false,
                    correctivesOrder: action.payload.correctives.map((corrective:LoadableCorrectiveType) => corrective.id),
                    correctivesMap: action.payload.correctives.reduce((acc:ICorrectivesMap, corrective:LoadableCorrectiveType) => {
                        acc[corrective.id] = corrective;
                        return acc;
                    }, {}),
                    error: undefined,
                };
            } else {
                return state;
            }
            
        case SET_CORRECTIVES_LOADING :
            return {
                ...state,
                isLoading: action.payload
			};
		
		case SHOW_ALL_CORRECTIVES_ERROR :
			return {
                ...state,
                isLoading: false,
                error: action.payload
            };

        case SHOW_CORRECTIVE_ERROR :
            return {
                ...state,
                correctiveErrorsMap: {
                    ...state.correctiveErrorsMap,
                    [action.payload.correctiveId]: action.payload.error
                }
            };

        case UPDATE_CORRECTIVE :
            if( action.payload.playerId===state.playerId){
                const {correctivesMap} = state;
                return {
                    ...state,
                    correctivesMap: {
                        ...correctivesMap,
                        [action.payload.corrective.id]: action.payload.corrective
                    }
                };
            } else {
                return state;
            }

        case SET_CORRECTIVES_DAY_INDEX :
            return {
                ...state,
                selectedDayIndex: action.payload,
                feedbackResponse: undefined
            };

        case GET_ALL_CORRECTIVES :
        case GET_CORRECTIVE_PROGRAM :
            return {
                ...state,
                feedbackResponse: undefined,
                isLoading: true,
                error: undefined
            };

        case START_CORRECTIVES :
                return {
                    ...state,
                    selectedDayIndex: undefined,
                    feedbackResponse: undefined,
                    isLoading: true,
                    error: undefined
                };

        case GET_CORRECTIVE_SET :
            const getDayIdx = (action.payload.dateIndex!==undefined) ? action.payload.dateIndex : state.selectedDayIndex;
            return {
                ...state,
                selectedDayIndex: getDayIdx,
                isLoading: true,
                error: undefined
            };
            
        case SET_CORRECTIVE_SET :
            const setDayIdx = (action.payload.dateIndex!==undefined) ? action.payload.dateIndex : state.selectedDayIndex;
            if(setDayIdx===state.selectedDayIndex && action.payload.playerId){
                return {
                    ...state,
                    selectedDayIndex: setDayIdx,
                    isLoading: false,
                    playerId: action.payload.playerId,
                    correctivesSet: action.payload.correctivesSet
                };
            } else {
                return state;
            }

        case SET_CORRECTIVE_PROGRAM :
            if( action.payload.playerId===state.playerId){
                return {
                    ...state,
                    isLoading: false,
                    correctivesProgram: action.payload.correctivesProgram
                };
            } else {
                return state;
            }

        case UPDATE_CORRECTIVE_EXERCISE_STATUS :
            if( state.correctivesSet || state.correctivesProgram ){
                return {
                    ...state,
                    correctivesSet: state.correctivesSet ? {
                        ...state.correctivesSet,
                        plans: state.correctivesSet.plans.map(plan => ({
                            ...plan,
                            exercises: plan.exercises.map(({id, updateStatus, ...exercise}) => ({
                                id,
                                ...exercise,
                                updateStatus: (action.payload.exerciseId===id) ? UpdateStatus.Updating : updateStatus
                            }))
                        }))
                    } : undefined,
                    correctivesProgram: state.correctivesProgram ? {
                        ...state.correctivesProgram,
                        days: state.correctivesProgram.days.map(correctivesSet => ({
                            ...correctivesSet,
                            plans: correctivesSet.plans.map(plan => ({
                                ...plan,
                                exercises: plan.exercises.map(({id, updateStatus, ...exercise}) => ({
                                    id,
                                    ...exercise,
                                    updateStatus: (action.payload.exerciseId===id) ? UpdateStatus.Updating : updateStatus
                                }))
                            }))
                        }))
                    } : undefined,
                    feedbackResponse: undefined,
                    error: undefined,
                    isLoading: true
                };
            } else {
                return state;
            }

        case SET_EXERCISE_CORRECTIVE_AND_UPDATE :
            if( state.correctivesSet || state.correctivesProgram ){
                return {
                    ...state,
                    correctivesSet: state.correctivesSet ? {
                        ...state.correctivesSet,
                        plans: state.correctivesSet.plans.map(plan => ({
                            ...plan,
                            exercises: plan.exercises.map(({id, updateStatus, ...exercise}) => ({
                                id,
                                ...exercise,
                                updateStatus: (action.payload.exercise && action.payload.exercise.id===id) ? UpdateStatus.Updating : updateStatus
                            }))
                        }))
                    } : undefined,
                    correctivesProgram: state.correctivesProgram ? {
                        ...state.correctivesProgram,
                        days: state.correctivesProgram.days.map(correctivesSet => ({
                            ...correctivesSet,
                            plans: correctivesSet.plans.map(plan => ({
                                ...plan,
                                exercises: plan.exercises.map(({id, updateStatus, ...exercise}) => ({
                                    id,
                                    ...exercise,
                                    updateStatus: (action.payload.exercise && action.payload.exercise.id===id) ? UpdateStatus.Updating : updateStatus
                                }))
                            }))
                        }))
                    } : undefined,
                    feedbackResponse: undefined,
                    error: undefined,
                    isLoading: true
                };
            } else {
                return state;
            }

        case UPDATE_CORRECTIVE_EXERCISE_STATUS_SUCCESS :
            if( state.correctivesSet || state.correctivesProgram ){
                return {
                    ...state,
                    correctivesSet: state.correctivesSet ? {
                        ...state.correctivesSet,
                        plans: state.correctivesSet.plans.map(plan => ({
                            ...plan,
                            exercises: plan.exercises.map(({id, status, updateStatus, ...exercise}) => ({
                                id,
                                ...exercise,
                                status: (action.payload.exerciseId===id) ? action.payload.status : status,
                                updateStatus: (action.payload.exerciseId===id) ? UpdateStatus.Updated : updateStatus
                            }))
                        }))
                    } : undefined,
                    correctivesProgram: state.correctivesProgram ? {
                        ...state.correctivesProgram,
                        days: state.correctivesProgram.days.map(correctivesSet => ({
                            ...correctivesSet,
                            plans: correctivesSet.plans.map(plan => ({
                                ...plan,
                                exercises: plan.exercises.map(({id, status, updateStatus, ...exercise}) => ({
                                    id,
                                    ...exercise,
                                    status: (action.payload.exerciseId===id) ? action.payload.status : status,
                                    updateStatus: (action.payload.exerciseId===id) ? UpdateStatus.Updated : updateStatus
                                }))
                            }))
                        }))
                    } : undefined,
                    feedbackResponse: action.payload.feedbackResponse,
                    error: undefined,
                    isLoading: false
                };
            } else {
                return state;
            }

        case SET_CORRECTIVES_PLAN_ORDER :
            return {
                ...state,
                correctivesPlanOrder: [
                    ...action.payload.order
                ],
                correctivesPlanScrollPosition: action.payload.scrollPosition
            }

        case SET_CORRECTIVES_PLAN_EXERCISE_ORDER :
            return {
                ...state,
                correctivesPlanExerciseOrder: action.payload ? [
                    ...action.payload.order
                ] : undefined,
                correctivesPlanExerciseScrollPosition: action.payload ? action.payload.scrollPosition : undefined
            }

        case SET_SELECTED_CORRECTIVES_PLAN :
            return {
                ...state,
                selectedPlan: action.payload,
                correctivesPlanExerciseOrder: undefined,
                correctivesPlanExerciseScrollPosition: undefined
            }

        case UPDATE_CORRECTIVE_EXERCISE_FEEDBACK :
            return {
                ...state,
                error: undefined,
                isLoading: true
            }

        case UPDATE_CORRECTIVE_EXERCISE_FEEDBACK_SUCCESS :
            return {
                ...state,
                feedbackResponse: undefined,
                error: undefined,
                isLoading: false
            }

        default :
            return state;
    }
};

export default correctivesReducer;