import { ACCEPT_TERMS, ACCEPT_TERMS_FAILURE, ACCEPT_TERMS_SUCCESS, ADD_USER, ADD_USER_ERROR, ADD_USER_SUCCESS, CHANGE_USER_STATUS, CHECK_LAST_LOGIN_SUCCESS, EDIT_USER, EDIT_USER_ERROR, EDIT_USER_SUCCESS, EXTEND_USER_SESSION_SUCCESS, GET_ADD_USER_CONFIG, GET_ADD_USER_CONFIG_ERROR, GET_USER, GET_USERS, GET_USERS_ERROR, GET_USER_ERROR, SELECT_USER, SET_ADD_USER_CONFIG, SET_SHOW_PLAYER_EXERCISE_FINISHED_TIME, SET_USERS, SET_USE_PLAYER_TIMEZONE, RESET_USER_PASSWORD, RESET_USER_PASSWORD_SUCCESS, RESET_USER_PASSWORD_FAILURE, UPDATE_TEMP_PASSWORD } from './user.types';
import {
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_RESET,
    CHANGE_PASSWORD_SUCCESS,
    CHECK_USER_SESSION,
    EMAIL_SIGN_IN_START,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_SUCCESS,
    GET_TERMS,
    GET_TERMS_FAILURE,
    GET_TERMS_SUCCESS,
    IUserState,
    RESET_PASSWORD,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_SUCCESS,
    SIGN_IN_FAILURE,
    SIGN_IN_SUCCESS,
    SIGN_OUT_FAILURE,
    SIGN_OUT_SUCCESS,
    TEMP_PASSWORD,
    TEMP_PASSWORD_FAILURE,
    TEMP_PASSWORD_SUCCESS,
    TypeUserAction
} from './user.types';

export const INITIAL_STATE:IUserState = {
    isLoading: false,
    currentUser: undefined,
    error: null,
    changePasswordLoading: false,
    changePasswordSuccess: false,
    changePasswordError: undefined,
    forgotPasswordLoading: false,
    forgotPasswordSuccess: false,
    forgotPasswordError: undefined,
    resetPasswordLoading: false,
    resetPasswordSuccess: false,
    resetPasswordError: undefined,
    tempPasswordLoading: false,
    tempPasswordSuccess: false,
    tempPasswordError: undefined,
    tempUser: undefined,
    termsIsLoading: true,
    termsContent: undefined,
    termsError: undefined,
    lastLoginInfo: undefined,
    isUsersLoading: true,
    users: undefined,
    usersError: undefined,
    selectedUser: undefined,
    isGetUserLoading: true,
    getUserError: undefined,
    editUserSuccess: undefined,
    editUserError: undefined,
    isAddUserLoading: false,
    addUserSuccess: undefined,
    addUserError: undefined,
    addUserConfig: undefined,
    isAddUserConfigLoading: false,
    addUserConfigError: undefined,
    usePlayerTimezone: undefined,
    showExerciseFinishedTime: undefined,
    resetUserPasswordLoading: false,
    resetUserPasswordSuccess: false,
    resetUserPasswordError: undefined,
};

const userReducer = (state = INITIAL_STATE, action:TypeUserAction):IUserState => {
    switch( action.type ){
        
        case CHECK_USER_SESSION :
        case EMAIL_SIGN_IN_START :
            return {
                ...state,
                isLoading: true,
                error: null
            };
        
        case SIGN_IN_SUCCESS :
            return {
                ...state,
                currentUser: action.payload,
                isLoading: false,
                error: null,
                lastLoginInfo: {
                    username: action.payload.emailAddress
                }
            };

        case SIGN_OUT_SUCCESS :
            return {
                ...INITIAL_STATE,
                lastLoginInfo: state.lastLoginInfo,
                usePlayerTimezone: state.usePlayerTimezone,
                showExerciseFinishedTime: state.showExerciseFinishedTime
            };

        case SIGN_IN_FAILURE :
        case SIGN_OUT_FAILURE :
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };

        case CHANGE_PASSWORD :
            return {
                ...state,
                changePasswordLoading: true,
                changePasswordSuccess: false,
                changePasswordError: undefined,
                forgotPasswordLoading: false,
                forgotPasswordSuccess: false,
                forgotPasswordError: undefined
            };

        case CHANGE_PASSWORD_SUCCESS :
            return {
                ...state,
                changePasswordLoading: false,
                changePasswordSuccess: true,
                changePasswordError: undefined
            };

        case CHANGE_PASSWORD_FAILURE :
            return {
                ...state,
                changePasswordLoading: false,
                changePasswordSuccess: false,
                changePasswordError: action.payload
            };

        case CHANGE_PASSWORD_RESET :
            return {
                ...state,
                changePasswordLoading: false,
                changePasswordSuccess: false,
                changePasswordError: undefined,
            };

        case FORGOT_PASSWORD :
            const spreadState = action.payload.keepUserState ? state : INITIAL_STATE;
            return {
                ...spreadState,
                forgotPasswordLoading: true,
                forgotPasswordSuccess: false,
                forgotPasswordError: undefined
            };

        case FORGOT_PASSWORD_SUCCESS :
            return {
                ...state,
                forgotPasswordLoading: false,
                forgotPasswordSuccess: true,
                forgotPasswordError: undefined
            };
    
        case FORGOT_PASSWORD_FAILURE :
            return {
                ...state,
                forgotPasswordLoading: false,
                forgotPasswordSuccess: false,
                forgotPasswordError: action.payload
            };

        case RESET_PASSWORD :
            return {
                ...INITIAL_STATE,
                resetPasswordLoading: true,
                resetPasswordSuccess: false,
                resetPasswordError: undefined
            };

        case RESET_PASSWORD_SUCCESS :
            return {
                ...state,
                resetPasswordLoading: false,
                resetPasswordSuccess: true,
                resetPasswordError: undefined,
                tempUser: action.payload
            };
    
        case RESET_PASSWORD_FAILURE :
            return {
                ...state,
                resetPasswordLoading: false,
                resetPasswordSuccess: false,
                resetPasswordError: action.payload
            };


        case TEMP_PASSWORD :
            return {
                ...(action.payload.isTokenFormat ? INITIAL_STATE : state),
                tempPasswordLoading: true,
                tempPasswordSuccess: false,
                tempPasswordError: undefined,
                tempUser: undefined
            };

        case UPDATE_TEMP_PASSWORD :
            return {
                ...state,
                tempPasswordLoading: true,
                tempPasswordSuccess: false,
                tempPasswordError: undefined,
                tempUser: undefined
            };

        case TEMP_PASSWORD_SUCCESS :
            return {
                ...state,
                tempPasswordLoading: false,
                tempPasswordSuccess: true,
                tempPasswordError: undefined,
                tempUser: action.payload
            };
    
        case TEMP_PASSWORD_FAILURE :
            return {
                ...state,
                tempPasswordLoading: false,
                tempPasswordSuccess: false,
                tempPasswordError: action.payload,
                tempUser: undefined
            };

        case GET_TERMS :
            return {
                ...state,
                termsIsLoading: true,
                termsContent: undefined,
                termsError: undefined
            };

        case GET_TERMS_SUCCESS :
            return {
                ...state,
                termsIsLoading: false,
                termsContent: action.payload,
                termsError: undefined
            };

        case GET_TERMS_FAILURE :
            return {
                ...state,
                termsIsLoading: false,
                termsContent: undefined,
                termsError: action.payload
            };

        case ACCEPT_TERMS :
            return {
                ...state,
                termsIsLoading: true,
                termsError: undefined
            };

        case ACCEPT_TERMS_SUCCESS :
            return {
                ...state,
                termsIsLoading: false,
                termsError: undefined,
                currentUser: state.currentUser ? {
                    ...state.currentUser,
                    termsAccepted: true
                } : undefined
            };

        case ACCEPT_TERMS_FAILURE :
            return {
                ...state,
                termsIsLoading: false,
                termsError: action.payload
            };

        case EXTEND_USER_SESSION_SUCCESS :
            const user = state.currentUser;
            return user ? {
                ...state,
                currentUser: {
                    ...user,
                    token: action.payload.token,
                    tokenTTL: action.payload.tokenTTL,
                    lastLogin: action.payload.lastLogin,
                    idToken: action.payload.idToken
                }
            } : state;

        case CHECK_LAST_LOGIN_SUCCESS :
            return {
                ...state,
                lastLoginInfo: {
                    username: action.payload.username
                }
            }

        case GET_USERS :
            return {
                ...state,
                isUsersLoading: true,
                usersError: undefined
            }
        
        case SET_USERS :
            return {
                ...state,
                users: action.payload,
                isUsersLoading: !action.payload
            }

        case GET_USERS_ERROR :
            return {
                ...state,
                usersError: action.payload,
                isUsersLoading: false
            }

        case GET_USER :
        case EDIT_USER :
            return {
                ...state,
                isGetUserLoading: true,
                getUserError: undefined,
                editUserSuccess: undefined
            }

        case EDIT_USER_SUCCESS :
            let loggedInOveride:Pick<IUserState, 'currentUser' | 'lastLoginInfo'> | undefined;
            // if update is to logged in user, update the currentUser info
            if(action.payload && state.currentUser && action.payload.id===state.currentUser.id){
                loggedInOveride = {
                    currentUser: {
                        ...state.currentUser,
                        emailAddress: action.payload.email ? action.payload.email : state.currentUser.emailAddress,
                        firstName: action.payload.firstName,
                        lastName: action.payload.lastName,
                        displayName: action.payload.displayName
                    }
                }
            }
            return {
                ...state,
                isGetUserLoading: false,
                getUserError: undefined,
                editUserSuccess: true,
                editUserError: undefined,
                ...loggedInOveride
            }

        case EDIT_USER_ERROR :
            return {
                ...state,
                isGetUserLoading: false,
                isUsersLoading: false,
                editUserSuccess: false,
                editUserError: action.payload,
            }

        case ADD_USER :
            return {
                ...state,
                isAddUserLoading: true,
                addUserSuccess: undefined,
                addUserError: undefined
            }

        case ADD_USER_SUCCESS :
            return {
                ...state,
                isAddUserLoading: false,
                addUserSuccess: true,
                addUserError: undefined
            }

        case ADD_USER_ERROR :
            return {
                ...state,
                isAddUserLoading: false,
                addUserSuccess: false,
                addUserError: action.payload
            }

        case GET_ADD_USER_CONFIG :
            return {
                ...state,
                addUserConfig: undefined,
                isAddUserConfigLoading: true,
                addUserConfigError: undefined
            }

        case SET_ADD_USER_CONFIG :
            return {
                ...state,
                addUserConfig: action.payload,
                isAddUserConfigLoading: false,
                addUserConfigError: undefined
            }

        case GET_ADD_USER_CONFIG_ERROR :
            return {
                ...state,
                addUserConfig: undefined,
                isAddUserConfigLoading: false,
                addUserConfigError: action.payload
            }

        case CHANGE_USER_STATUS :
            return {
                ...state,
                isUsersLoading: action.payload.refreshUsers ? true : state.isUsersLoading,
                usersError: action.payload.refreshUsers ? undefined : state.usersError,
                editUserSuccess: undefined,
                editUserError: undefined,
            }

        case SELECT_USER :
            const existingUser = state.selectedUser && action.payload.user && state.selectedUser.id===action.payload.user.id ? state.selectedUser : undefined;
            return {
                ...state,
                selectedUser: action.payload.user ? {
                    ...existingUser,
                    ...action.payload.user
                } : undefined,
                isGetUserLoading: action.payload.isLoading!==undefined ? action.payload.isLoading : !action.payload.user
            }

        case GET_USER_ERROR :
            return {
                ...state,
                getUserError: action.payload,
                isGetUserLoading: false
            }

        case SET_USE_PLAYER_TIMEZONE :
            return {
                ...state,
                usePlayerTimezone: action.payload
            }
            
        case SET_SHOW_PLAYER_EXERCISE_FINISHED_TIME :
            return {
                ...state,
                showExerciseFinishedTime: action.payload
            }

        case RESET_USER_PASSWORD :
            return {
                ...state,
                resetUserPasswordLoading: true,
                resetUserPasswordSuccess: false,
                resetUserPasswordError: undefined
            };

        case RESET_USER_PASSWORD_SUCCESS :
            return {
                ...state,
                resetUserPasswordLoading: false,
                resetUserPasswordSuccess: true,
                resetUserPasswordError: undefined
            };
    
        case RESET_USER_PASSWORD_FAILURE :
            return {
                ...state,
                resetUserPasswordLoading: false,
                resetUserPasswordSuccess: false,
                resetUserPasswordError: action.payload
            };

        default :
            return state;
    }
};

export default userReducer;