import {
    GET_ALL_PLAYER_ASSESSMENTS,
    GET_PLAYER_ASSESSMENT,
    IGetAllPlayerAssessments,
    IGetPlayerAssessment,
    IGetPlayerAssessmentPayload,
    ISetAllPlayerAssessments,
    ISetAllPlayerAssessmentsPayload,
    ISetPlayerAssessmentsLoading,
    IShowAllPlayerAssessmentsError,
    IShowGetPlayerAssessmentError,
    IShowGetPlayerAssessmentErrorPayload,
    IUpdatePlayerAssessment,
    IUpdatePlayerAssessmentPayload,
    SET_ALL_PLAYER_ASSESSMENTS,
    SET_PLAYER_ASSESSMENTS_LOADING,
    SHOW_ALL_PLAYER_ASSESSMENTS_ERROR,
    SHOW_PLAYER_ASSESSMENT_ERROR,
    UPDATE_PLAYER_ASSESSMENT
} from './assessments.types';
import { GET_ALL_PLAYER_ASSESSMENTS_MANAGEMENT, GET_EXPANDED_MEASUREMENTS, IGetAllPlayerAssessmentsManagement, IGetAllPlayerAssessmentsPayload, IGetExpandedMeasurement, IGetExpandedMeasurementPayload } from './assessments.types';

export const setLoading = (val:boolean):ISetPlayerAssessmentsLoading => ({
    type: SET_PLAYER_ASSESSMENTS_LOADING,
    payload: val
});

export const getAllAssessmentPrograms = (payload:IGetAllPlayerAssessmentsPayload):IGetAllPlayerAssessments => ({
    type: GET_ALL_PLAYER_ASSESSMENTS,
    payload
});

export const getAllAssessmentProgramsManagement = (payload:IGetAllPlayerAssessmentsPayload):IGetAllPlayerAssessmentsManagement => ({
    type: GET_ALL_PLAYER_ASSESSMENTS_MANAGEMENT,
    payload
});

export const showAllAssessmentsError = (error:string):IShowAllPlayerAssessmentsError => ({
    type: SHOW_ALL_PLAYER_ASSESSMENTS_ERROR,
    payload: error
});

export const getAssessmentProgram = (config:IGetPlayerAssessmentPayload):IGetPlayerAssessment => ({
    type: GET_PLAYER_ASSESSMENT,
    payload: config
});

export const showGetAssessmentProgramError = (config:IShowGetPlayerAssessmentErrorPayload):IShowGetPlayerAssessmentError => ({
    type: SHOW_PLAYER_ASSESSMENT_ERROR,
    payload: config
});

export const updateAssessmentProgram = (config:IUpdatePlayerAssessmentPayload):IUpdatePlayerAssessment => ({
    type: UPDATE_PLAYER_ASSESSMENT,
    payload: config
});

export const setAllAssessmentPrograms = (config:ISetAllPlayerAssessmentsPayload):ISetAllPlayerAssessments => ({
    type: SET_ALL_PLAYER_ASSESSMENTS,
    payload: config
});

export const getExpandedMeasurements = (config:IGetExpandedMeasurementPayload):IGetExpandedMeasurement => ({
    type: GET_EXPANDED_MEASUREMENTS,
    payload: config
});