import {
    GET_EXERCISE,
    IExerciseComplete,
    IGetExercise,
    ISetExercise,
    ISetExerciseLoading,
    IShowExerciseError,
    SET_EXERCISE,
    SET_EXERCISE_LOADING,
    SHOW_EXERCISE_ERROR,
} from './exercise.types';
import { IGetExercisePayload, ISetExerciseCorrective, ISetExerciseCorrectiveAndUpdateStatus, ISetExerciseCorrectiveAndUpdateStatusPayload, SET_EXERCISE_CORRECTIVE, SET_EXERCISE_CORRECTIVE_AND_UPDATE } from './exercise.types';

import { ICorrectiveExercise } from '../correctives/correctives.types';

export const showError = (error?:string):IShowExerciseError => ({
    type: SHOW_EXERCISE_ERROR,
    payload: error
});

export const setLoading = (val:boolean):ISetExerciseLoading => ({
    type: SET_EXERCISE_LOADING,
    payload: val
});

export const setExercise = (exercise?:IExerciseComplete):ISetExercise => ({
    type: SET_EXERCISE,
    payload: exercise
});

export const getExercise = (payload:IGetExercisePayload):IGetExercise => ({
    type: GET_EXERCISE,
    payload
});

export const setCorrectiveExercise = (correctiveExercise?:ICorrectiveExercise):ISetExerciseCorrective => ({
    type: SET_EXERCISE_CORRECTIVE,
    payload: correctiveExercise
});

// to both update the status and select it in the Exercise reducer
export const setCorrectiveExerciseAndUpdate = (payload:ISetExerciseCorrectiveAndUpdateStatusPayload):ISetExerciseCorrectiveAndUpdateStatus => ({
    type: SET_EXERCISE_CORRECTIVE_AND_UPDATE,
    payload
});