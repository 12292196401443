import { GET_ALL_MESSAGE_THREADS, GET_MESSAGE_THREAD, GET_MESSAGE_THREAD_BY_MEMBERS, IMessageState, MARK_MESSAGE_STATUS_SUCCESS, MessageStatus, SEARCH_THREADS, SEND_MESSAGE, SET_ALL_MESSAGE_THREADS, SET_MESSAGE_THREAD, SET_NEW_MESSAGE_THREAD_COUNT, SHOW_ALL_MESSAGE_THREADS_ERROR, SHOW_MESSAGE_THREAD_ERROR, TypeMessageAction } from './message.types';

import { IDType } from '../core.types';
import { SIGN_OUT_SUCCESS } from '../user/user.types';

export const INITIAL_STATE:IMessageState = {
	isAllThreadsLoading: true,
	isSingleThreadLoading: false,
	threads: undefined,
	singleThread: undefined,
	allThreadsError: undefined,
    singleThreadError: undefined,
    searchThreadsTerm: undefined,
    newMessageThreadCount: undefined
};

const messageReducer = (state = INITIAL_STATE, action:TypeMessageAction):IMessageState => {
    switch( action.type ){
        
        case SIGN_OUT_SUCCESS :
            return {
                ...INITIAL_STATE
            };
        
        case GET_ALL_MESSAGE_THREADS :
            return {
                ...state,
                isAllThreadsLoading: true,
                allThreadsError: undefined,
            };
        
        case SET_ALL_MESSAGE_THREADS :
            const existingThread = (threadId:IDType) => state.threads ? state.threads.find(({id}) => id===threadId) : undefined;
            const singleThread = state.singleThread;
            return {
                ...state,
                threads: action.payload.map(thread => (singleThread && thread.id===singleThread.id) ? {
                    ...existingThread(thread.id),
                    ...singleThread,
                    ...thread
                } : {
                    ...existingThread(thread.id),
                    ...thread
                }),
                isAllThreadsLoading: false,
                allThreadsError: undefined,
            };
            
        case SHOW_ALL_MESSAGE_THREADS_ERROR :
            return {
                ...state,
                isAllThreadsLoading: false,
                allThreadsError: action.payload,
			};
		
        case GET_MESSAGE_THREAD :
        case SEND_MESSAGE :
			return {
                ...state,
                isSingleThreadLoading: true,
                singleThreadError: undefined
            };

        case GET_MESSAGE_THREAD_BY_MEMBERS :
			return {
                ...state,
                singleThread: undefined, // clear out
                isSingleThreadLoading: true,
                singleThreadError: undefined
            };

        case SET_MESSAGE_THREAD :
            const threadId =  action.payload ? action.payload.id : undefined;
            const current = (threadId && state.threads) ? state.threads.find(({id}) => id===threadId) : undefined;
            const updatedThread = action.payload ? {
                // update data already gotten from previous calls
                ...current,
                ...state.singleThread,
                ...action.payload
            } : undefined;
            return {
                ...state,
                singleThread: updatedThread,
                threads: (updatedThread && state.threads) ? state.threads.map(thread => (thread.id===updatedThread.id) ? ({
                    ...thread,
                    ...updatedThread
                }) : thread) : state.threads,
                isSingleThreadLoading: false,
                singleThreadError: undefined
            }

        case SHOW_MESSAGE_THREAD_ERROR :
            return {
                ...state,
                isSingleThreadLoading: false,
                singleThreadError: action.payload
            };

        case SEARCH_THREADS :
            return {
                ...state,
                isAllThreadsLoading: true,
                allThreadsError: undefined,
                searchThreadsTerm: action.payload
            };

        case SET_NEW_MESSAGE_THREAD_COUNT :
            return {
                ...state,
                newMessageThreadCount: action.payload
            }

        case MARK_MESSAGE_STATUS_SUCCESS :
            const resetUnreadCount = (status:MessageStatus, prev:number=0) => {
                return (status===MessageStatus.Read) ? Math.max(0, prev-1) : (prev + 1);
            }
            return {
                ...state,
                threads: state.threads ? state.threads.map(({id:threadId, messages, unreadCount, ...thread}) => ({
                    ...thread,
                    id: threadId,
                    messages: messages ? messages.map(({id, status, ...message}) => ({
                        ...message,
                        id,
                        status: (threadId===action.payload.threadId && action.payload.messageIds.includes(id)) ? action.payload.status : status
                    })) : undefined,
                    unreadCount: (threadId===action.payload.threadId) ? resetUnreadCount(action.payload.status, unreadCount) : unreadCount
                })) : undefined,
                singleThread: (state.singleThread && state.singleThread.id===action.payload.threadId) ? {
                    ...state.singleThread,
                    messages: state.singleThread.messages ? state.singleThread.messages.map(({id, status, ...message}) => ({
                        ...message,
                        id,
                        status: (action.payload.messageIds.includes(id)) ? action.payload.status : status
                    })) : undefined,
                    unreadCount: resetUnreadCount(action.payload.status, state.singleThread.unreadCount)
                } : undefined
            }
        
        default :
            return state;
    }
};

export default messageReducer;