import { EDIT_ORG, GET_ALL_ORGS, GET_ORG, GET_ORG_MEMBERS, GET_ORG_MEMBERS_COMPLIANCE, GET_ORG_MEMBERS_MANAGEMENT, GET_ORG_PERIODS, GET_ORG_ROLES, GET_ORG_UI, GET_PENDING_ORG_MEMBERS, GET_TIMEZONES, IEditOrg, IGetAllOrgs, IGetOrg, IGetOrgMembers, IGetOrgMembersCompliance, IGetOrgMembersManagement, IGetOrgPeriods, IGetOrgRoles, IGetOrgUI, IGetTimezones, IOrg, IOrgView, OrgPeriod, OrgRoleData, ProgramConfig, ProgramConfigType, ProgramRoleComplianceConfig } from './org.types';
import { GetActiveInclude, editOrg as editOrgAPI, getAllOrgs, getOrg as getOrgAPI, getOrgMembers as getOrgMembersAPI, getPendingOrgMembers as getPendingOrgMembersAPI, getOrgMembersCompliance as getOrgMembersComplianceAPI, getOrgMembersManagement as getOrgMembersManagementAPI, getOrgPeriodsAPI, getOrgRoles, getOrgUI as getOrgUIAPI, getTimezoneList } from '../../services/PMotionApi';
import { IPlayer, Player } from '../player/player.types';
import {IPlayerRemote, convertPlayerRemoteData} from '../player/player.sagas';
import { PrivateRemoteData, isUserTokenValid } from '../user/user.sagas';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { editOrgError, editOrgSuccess, getOrgMembersCompliance, selectOrg, setAllOrgs, setOrg, setOrgMembers, setOrgMembersCompliance, setOrgPeriods, setOrgRoles, setPendingOrgMembers, setTimezones, showAllOrgsError, showOrgError, showOrgMembersError, showOrgPeriodsError, showOrgRolesError } from './org.actions';
import { selectAllOrgs, selectAllOrgsFlattened, selectOrgInfo, selectOrgMembers } from './org.selectors';

import { IDType } from '../core.types';
import SrcMap from '../../utils/src-map';
import { SrcMapEntryType } from '../../utils/src-map';

// traverse Org tree and find ID match
export const findOrgById = (orgId:IDType, searchOrgs:IOrg[]):IOrg | undefined => {
    for(let i=0, len=searchOrgs.length; i<len; i++){
        const testOrg = searchOrgs[i];
        const {id, children} = testOrg;
        if(id===orgId){
            return testOrg;
        } else if(children){
            const foundChild = findOrgById(orgId, children);
            if( foundChild ){
                return foundChild;
            }
        }
    }
    return undefined;
}

const isConfigEmpty = (config:object) => Object.keys(config).length < 1;

export type GetOrgRemote = Pick<IOrgView, 'id' | 'config' | 'configDefault' | 'expirationDate'> & {
    name:string;
    childOrgs?:Record<string, string> | null;
	positionConfig?:ProgramRoleComplianceConfig | null;
    positionConfigDefault?:ProgramRoleComplianceConfig | null;
}

const convertRemoteGetOrg = ({id:topId, name, childOrgs, config, configDefault, positionConfig, positionConfigDefault, ...org}:GetOrgRemote, allOrgsFlattened:IOrg[]):IOrgView => {
	const foundTopOrg = allOrgsFlattened.find(({id}) => id===topId);
	const realConfig = config ? config : ({} as ProgramConfig);
	const realConfigDefault = configDefault ? configDefault : ({} as ProgramConfig);
	return {
		...org,
		id: topId,
		displayName: name,
		children: childOrgs ? Object.keys(childOrgs).map(orgId => {
			const foundOrg = allOrgsFlattened.find(({id}) => `${id}`===orgId);
			return foundOrg ? foundOrg : {
				id: parseInt(orgId),
				displayName: childOrgs[orgId]
			}
		}) : undefined,
		isTopLevel: foundTopOrg ? foundTopOrg.isTopLevel: undefined,
		config: positionConfig && !isConfigEmpty(positionConfig) ? {
			...realConfig,
			[ProgramConfigType.ROLE_COMPLIANCE]: positionConfig
		} : config,
		configDefault: positionConfigDefault ? {
			...realConfigDefault,
			[ProgramConfigType.ROLE_COMPLIANCE]: positionConfigDefault
		} : configDefault
	}
};

const convertOrgViewToRemoteGetOrg = ({displayName, children, id, config, configDefault, expirationDate}:IOrgView):GetOrgRemote => {
	let realConfig = config;
	let positionConfig:ProgramRoleComplianceConfig | undefined = undefined;
	if( config ){
		const {[ProgramConfigType.ROLE_COMPLIANCE]:positionConfigVal, ...restConfig} = config;
		realConfig = restConfig as ProgramConfig;
		positionConfig = typeof positionConfigVal==='object' ? positionConfigVal : undefined;
	}
	let realConfigDefault = configDefault;
	let positionConfigDefault:ProgramRoleComplianceConfig | undefined = undefined;
	if( configDefault ){
		const {[ProgramConfigType.ROLE_COMPLIANCE]:positionConfigDefaultVal, ...restConfigDefault} = configDefault;
		realConfigDefault = restConfigDefault as ProgramConfig;
		positionConfigDefault = typeof positionConfigDefaultVal==='object' ? positionConfigDefaultVal : undefined;
	}
	const expirationDateObj = expirationDate!==undefined ? {
		expirationDate
	} : undefined;
	return {
		id,
		name: displayName,
		childOrgs: children ? children.reduce((obj, {id, displayName}) => {
			obj[`${id}`] = displayName;
			return obj;
		}, {} as Record<string, string>) : undefined,
		config: realConfig, 
		configDefault: realConfigDefault,
		positionConfig,
		positionConfigDefault,
		...expirationDateObj
	}
};

export function* callGetOrg({payload}:IGetOrg) {
	const {orgId, token} = payload;
	// get info
	try {
		const orgInfoData = yield getOrgAPI(orgId, token);
		const valid = yield isUserTokenValid(orgInfoData.data, token);
		if( valid ){
			const orgInfo:GetOrgRemote = orgInfoData?.data as GetOrgRemote;
			const allOrgsFlattened:IOrg[] | undefined = yield select(selectAllOrgsFlattened);
			if( orgInfoData.data && orgInfoData.data.id!==undefined && allOrgsFlattened ){

				const org = convertRemoteGetOrg(orgInfo, allOrgsFlattened);
				if( org ){
					yield put(
						selectOrg({
							org,
							isLoading: false
						})
					);
				}
			} else {
				throw new Error('Get Org API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showOrgError(`Get Org data failed. ${er.message}`)
		);
	}
    
}

export function* callEditOrg({payload}:IEditOrg) {
	const {token, org} = payload;
	try {
		const remoteOrg = convertOrgViewToRemoteGetOrg(org);
		const {configDefault, positionConfigDefault, ...limitedRemoteOrgData} = remoteOrg; // deconstruct off unneeded data for API
        const orgInfoData = yield editOrgAPI(limitedRemoteOrgData, token);
		const valid = yield isUserTokenValid(orgInfoData.data, token);
		if(valid){
			if( orgInfoData && orgInfoData.data && orgInfoData.data===true ){

				yield put(
					selectOrg({
						org,
						isLoading: false
					})
				);
				yield put(
                    editOrgSuccess()
                );
			} else {
				throw new Error('EditOrg API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			editOrgError(er.message)
		);
	}
}

export interface IOrgRemote extends Omit<IOrg, 'members' | 'image' | 'loginImage'> {
	image:SrcMapEntryType[];
	loginImage:SrcMapEntryType[];
	members?:IPlayerRemote[];
}

export type IOrgMembersRemote = IPlayerRemote[];

export function* callGetOrgUI({payload}:IGetOrgUI) {
	
	// get info
	try {
		const orgInfoData = yield getOrgUIAPI(payload);
		const orgInfo:IOrgRemote = orgInfoData?.data as IOrgRemote;
		if( orgInfo ){

			const org = convertOrgRemoteData(orgInfo);
			if( org ){
				yield put(
					setOrg(org)
				);
			}
		} else {
			throw new Error('Get Org UI API returned, but cannot read data.')
		}
	} catch(er){
		yield put(
			showOrgError(`Get Org data failed. ${er.message}`)
		);
	}
    
}

export const convertOrgRemoteData = ({members, image, loginImage, ...orgdata}:IOrgRemote):IOrg => {
	return {
		...orgdata,
		image: SrcMap.createSrcMap(image),
		loginImage: SrcMap.createSrcMap(loginImage),
		members: (members && Array.isArray(members)) ? members.map(pdata => convertPlayerRemoteData(pdata)) : undefined
	}
}

export function* getOrgMembers({payload}:IGetOrgMembers) {
	// get info
	try {
		const includes:GetActiveInclude[] = [];
		if( payload.includeRisk ){
			includes.push(GetActiveInclude.Risk);
		}
		if( payload.includePScore ){
			includes.push(GetActiveInclude.PScore);
		}
		const orgInfoData = yield getOrgMembersAPI(payload.orgId, payload.token, includes);
		const orgInfo:IOrgMembersRemote = orgInfoData?.data as IOrgMembersRemote;
		const valid = yield isUserTokenValid(orgInfo, payload.token);
		if( valid ){
			if( orgInfo && Array.isArray(orgInfo) ){

				let members:Player[] = orgInfo.map(pdata => convertPlayerRemoteData(pdata));
				
				if( members ){
					yield put(
						setOrgMembers(members)
					);

					if(payload.includeCompliance){
						yield put(
							getOrgMembersCompliance({
								orgId: payload.orgId,
								token: typeof valid==='string' ? valid : payload.token,
								members
							})
						);
					}
				}
			} else {
				throw new Error('Org Members API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showOrgMembersError(`Get Org Members data failed. ${er.message}`)
		);
	}
    
}

export function* getPendingOrgMembers({payload}:IGetOrgMembers) {
	// get info
	try {
		const includes:GetActiveInclude[] = [];
		if( payload.includeRisk ){
			includes.push(GetActiveInclude.Risk);
		}
		if( payload.includePScore ){
			includes.push(GetActiveInclude.PScore);
		}
		const orgInfoData = yield getPendingOrgMembersAPI(payload.orgId, payload.token, includes);
		const orgInfo:IOrgMembersRemote = orgInfoData?.data as IOrgMembersRemote;
		const valid = yield isUserTokenValid(orgInfo, payload.token);
		if( valid ){
			if( orgInfo && Array.isArray(orgInfo) ){

				let members:Player[] = orgInfo.map(pdata => convertPlayerRemoteData(pdata));
				
				if( members ){
					yield put(
						setPendingOrgMembers(members)
					);

					// if(payload.includeCompliance){
					// 	yield put(
					// 		getOrgMembersCompliance({
					// 			orgId: payload.orgId,
					// 			token: typeof valid==='string' ? valid : payload.token,
					// 			members
					// 		})
					// 	);
					// }
				}
			} else {
				throw new Error('Org Members API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showOrgMembersError(`Get Org Members data failed. ${er.message}`)
		);
	}
    
}

export function* getOrgMembersManagement({payload: {orgId, token, includeRisk, includePScore}}:IGetOrgMembersManagement) {
	// get info
	try {
		const includes:GetActiveInclude[] = [];
		if( includeRisk ){
			includes.push(GetActiveInclude.Risk);
		}
		if( includePScore ){
			includes.push(GetActiveInclude.PScore);
		}
		const orgInfoData = yield getOrgMembersManagementAPI(orgId, token, includes);
		const orgInfo:IOrgMembersRemote = orgInfoData?.data as IOrgMembersRemote;
		const valid = yield isUserTokenValid(orgInfo, token);
		if( valid ){
			if( orgInfo && Array.isArray(orgInfo) ){

				let members:Player[] = orgInfo.map(pdata => convertPlayerRemoteData(pdata));
				
				if( members ){
					yield put(
						setOrgMembers(members)
					);
				}
			} else {
				throw new Error('Org Members API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showOrgMembersError(`Get Org Members data failed. ${er.message}`)
		);
	}
    
}

export function* callGetOrgMembersCompliance({payload}:IGetOrgMembersCompliance) {
	// get info
	try {
		const orgInfoData = yield getOrgMembersComplianceAPI(payload.orgId, payload.token);
		const orgInfo:IOrgMembersRemote = orgInfoData?.data as IOrgMembersRemote;
		const valid = yield isUserTokenValid(orgInfo, payload.token);
		if( valid ){
			if( orgInfo && Array.isArray(orgInfo) ){

				const currentMembers:IPlayer[] | undefined = payload.members ? payload.members : yield select(selectOrgMembers);
				let members:Player[] | undefined = currentMembers ? currentMembers.map((oldPlayer) => {
					const newPlayer = orgInfo.find(({id}) => id===oldPlayer.id);
					if( newPlayer ){
						const {lastAssessment, startDate, completeDate, lastExercise, programDay, completedDays, skippedDays, missedDays, compliancePercent, complianceColor, daysSinceStart, daysSinceAssessment} = newPlayer;
						return Player.create({
							...oldPlayer,
							lastAssessment, 
							startDate, 
							completeDate,
							lastExercise, 
							programDay, 
							completedDays, 
							skippedDays, 
							missedDays, 
							compliancePercent, 
							complianceColor,
							daysSinceStart,
							daysSinceAssessment
						});
					} else {
						return oldPlayer
					}
				}) : undefined;
				
				if( members ){
					yield put(
						setOrgMembersCompliance(members)
					);
				}
			} else {
				throw new Error('Org Members Compliance API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showOrgMembersError(`Get Org Members Compliance data failed. ${er.message}`)
		);
	}
    
}

export function* callGetOrgRoles({payload}:IGetOrgRoles) {
	
	// get info
	try {
		const orgInfoData = yield getOrgRoles(payload.token);
		if( orgInfoData && orgInfoData.data && Array.isArray(orgInfoData.data) ){

			yield put(
				setOrgRoles(orgInfoData.data as OrgRoleData[])
			);
		} else {
			throw new Error('Get Org Roles API returned, but cannot read data.')
		}
	} catch(er){
		yield put(
			showOrgRolesError(`Get Org Roles failed. ${er.message}`)
		);
	}
}

type RemoteAllOrg = Pick<IOrg, 'id' | 'userCount'> & {
	name:string;
	childOrgs?:RemoteAllOrg[] | null;
};

export function* callGetAllOrgs({payload}:IGetAllOrgs) {
	// traverse Org tree and find ID match
	const findRemoteOrgById = (orgId:IDType, searchOrgs:RemoteAllOrg[]):RemoteAllOrg | undefined => {
		for(let i=0, len=searchOrgs.length; i<len; i++){
			const testOrg = searchOrgs[i];
			const {id, childOrgs} = testOrg;
			if(id===orgId){
				return testOrg;
			} else if(childOrgs){
				const foundChild = findRemoteOrgById(orgId, childOrgs);
				if( foundChild ){
					return foundChild;
				}
			}
		}
		return undefined;
	}

	// get info
	try {
		const currentAllOrgs:IOrg[] | undefined = yield select(selectAllOrgs);
		const currentDefaultOrg:IOrg | undefined = yield select(selectOrgInfo);
		
		const orgInfoData = yield getAllOrgs(payload.token);
		if( currentAllOrgs && currentDefaultOrg && orgInfoData && orgInfoData.data && Array.isArray(orgInfoData.data) ){

			const remoteOrgs = orgInfoData.data as RemoteAllOrg[];

			const updateOrg = ({id, children, ...orgProps}:IOrg):IOrg => {
				const remote = findRemoteOrgById(id, remoteOrgs);
				let extraProps:{} | Partial<IOrg> = {};
				if(remote){
					const {name, childOrgs, ...extraRemoteProps} = remote;
					extraProps = extraRemoteProps;
				}

				return {
					id,
					...orgProps,
					...extraProps,
					children: children ? children.map(subOrg => updateOrg(subOrg)) : undefined
				}
			}

			const newAllOrgs = currentAllOrgs.map(org => updateOrg(org));

			yield put(
				setAllOrgs({
					allOrgs: newAllOrgs,
					org: updateOrg(currentDefaultOrg)
				})
			);
		} else {
			throw new Error('API returned, but cannot read data.')
		}
	} catch(er){
		yield put(
			showAllOrgsError(`Get All Orgs failed. ${er.message}`)
		);
	}
    
}

export function* callGetTimezones({payload}:IGetTimezones) {
	const {token} = payload;
	try {
        const timezoneList = yield getTimezoneList(token);
		const valid = yield isUserTokenValid(timezoneList.data, token);
		if(valid){
			if( timezoneList.data && typeof timezoneList.data==='object' ){
				yield put(
                    setTimezones(timezoneList.data)
                );
			} else {
				throw new Error('Timezone API returned, but cannot read data.')
			}
		}
	} catch(er){
		console.log(er.message);
	}
}

type PeriodsResponse = PrivateRemoteData & {
	data?:OrgPeriod[];
	errorCode?:string;
	message?:string;
}
export function* callGetPeriods({payload}:IGetOrgPeriods) {
	const {orgId, token} = payload;
	try {
        const periodsResponse:PeriodsResponse = yield getOrgPeriodsAPI(orgId, token);
		const valid:boolean | string = yield isUserTokenValid(periodsResponse, token);
		if(valid){
			yield put(
				setOrgPeriods( periodsResponse.data && Array.isArray(periodsResponse.data) && periodsResponse.data.length>0 ? periodsResponse.data : false )
			);
		}
	} catch(er){
		if(er && er.message && typeof er.message==='string'){
			yield put(
				showOrgPeriodsError(er.message)
			);
		}
	}
}

export function* onGetOrg() {
    yield takeLatest(GET_ORG, callGetOrg);
}

export function* onEditOrg() {
    yield takeLatest(EDIT_ORG, callEditOrg);
}


export function* onGetOrgUI() {
    yield takeLatest(GET_ORG_UI, callGetOrgUI);
}


export function* onGetOrgMembers() {
    yield takeLatest(GET_ORG_MEMBERS, getOrgMembers);
}

export function* onGetOrgMembersManagement() {
    yield takeLatest(GET_ORG_MEMBERS_MANAGEMENT, getOrgMembersManagement);
}

export function* onGetOrgMembersCompliance() {
    yield takeLatest(GET_ORG_MEMBERS_COMPLIANCE, callGetOrgMembersCompliance);
}

export function* onGetPendingOrgMembers() {
    yield takeLatest(GET_PENDING_ORG_MEMBERS, getPendingOrgMembers);
}

export function* onGetOrgRoles() {
    yield takeLatest(GET_ORG_ROLES, callGetOrgRoles);
}

export function* onGetAllOrgs() {
    yield takeLatest(GET_ALL_ORGS, callGetAllOrgs);
}

export function* onGetTimezones() {
    yield takeLatest(GET_TIMEZONES, callGetTimezones);
}

export function* onGetPeriods() {
    yield takeLatest(GET_ORG_PERIODS, callGetPeriods);
}

export function* orgSagas() {
    yield all([
		call( onGetOrg ),
		call( onEditOrg ),
		call( onGetOrgUI ),
		call( onGetOrgMembers ),
		call( onGetOrgMembersManagement ),
		call( onGetOrgMembersCompliance ),
		call( onGetOrgRoles ),
		call( onGetAllOrgs ),
		call( onGetTimezones ),
		call( onGetPeriods )
    ]);
}