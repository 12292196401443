import React, { ElementType, SVGProps, memo } from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import {ReactComponent as PMotionLogo} from '../assets/pmotion-logo-horizontal.svg';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		responsive: {
			height: `${31/219}em`
		},
		responsiveHeight: {
			width: `${219/31}em`
		}
	})
);

interface PMotionLogoLongProps extends SvgIconProps {
	sizeToHeight?:boolean;
}
const PMotionLogoLong:React.FC<PMotionLogoLongProps> = ({className, sizeToHeight, ...otherProps}) => {
	const classes = useStyles();
	return (
		<SvgIcon className={clsx(sizeToHeight ? classes.responsiveHeight : classes.responsive, className)} component={PMotionLogo as ElementType<SVGProps<SVGSVGElement>>} viewBox="0 0 219 31" {...otherProps} />
	)
}

export default memo(PMotionLogoLong);