import {
	GET_EXERCISE,
	IExerciseState,
	SET_EXERCISE,
	SET_EXERCISE_LOADING,
	SHOW_EXERCISE_ERROR,
	TypeExerciseAction
} from './exercise.types';
import { SET_EXERCISE_CORRECTIVE, SET_EXERCISE_CORRECTIVE_AND_UPDATE } from './exercise.types';
import { UPDATE_CORRECTIVE_EXERCISE_STATUS, UPDATE_CORRECTIVE_EXERCISE_STATUS_SUCCESS } from '../correctives/correctives.types';

import { SIGN_OUT_SUCCESS } from '../user/user.types';
import { UpdateStatus } from '../core.types';

export const INITIAL_STATE:IExerciseState = {
	isLoading: true,
    exercise: undefined,
    correctiveReference: undefined,
	error: undefined
};

const exerciseReducer = (state = INITIAL_STATE, action:TypeExerciseAction):IExerciseState => {
    switch( action.type ){
        
        case SIGN_OUT_SUCCESS :
            /* Reset */    
            return {
                ...INITIAL_STATE
            };

        case GET_EXERCISE :
            return {
                ...state,
                isLoading: true,
                exercise: undefined,
                error: undefined
            };
        
        case SET_EXERCISE_LOADING :
            return {
                ...state,
                isLoading: action.payload
            };
            
        case SET_EXERCISE :
            return {
                ...state,
                exercise: action.payload,
                isLoading: !action.payload,
                correctiveReference: action.payload ? state.correctiveReference : undefined,
			};
		
		case SHOW_EXERCISE_ERROR :
			return {
                ...state,
				error: action.payload,
				isLoading: false
            };

        case SET_EXERCISE_CORRECTIVE :
            /* Reset everything else */    
            return {
                ...INITIAL_STATE,
				correctiveReference: action.payload
            }

        case UPDATE_CORRECTIVE_EXERCISE_STATUS :
            return (state.correctiveReference && state.correctiveReference.id===action.payload.exerciseId) ? {
                ...state,
                correctiveReference: {
                    ...state.correctiveReference,
                    updateStatus: UpdateStatus.Updating
                }
            } : state;

        case UPDATE_CORRECTIVE_EXERCISE_STATUS_SUCCESS :
            return (state.correctiveReference && state.correctiveReference.id===action.payload.exerciseId) ? {
                ...state,
                correctiveReference: {
                    ...state.correctiveReference,
                    status: action.payload.status,
                    updateStatus: UpdateStatus.Updated
                }
            } : state;

        case SET_EXERCISE_CORRECTIVE_AND_UPDATE :
            /* Reset everything else */    
            return {
                ...INITIAL_STATE,
				correctiveReference: action.payload.exercise ? {
                    ...action.payload.exercise,
                    updateStatus: UpdateStatus.Updating
                } : undefined
            }
        
        default :
            return state;
    }
};

export default exerciseReducer;