import { BodyParts, Side } from './body-parts.types';
import { MeasurementKey, MeasurementStatus } from './assessment.types';

type InfoSection = {
	title?:string | null;
	description?:string | null;
}

export type PartLink = {
	name:string;
	standard?:MeasurementStatus | null;
	key:BodyParts;
	partSide?:Side;
	measurementKey?:MeasurementKey | null;
	measurementSide?:Side | null;
	order?:number | null;
}

export enum NoteTypes {
	InjuryRisk = 'INJURY_RISK',
	PerformanceImpactLevel1 = 'PERFORMANCE_IMPACT_LEVEL_1',
	PerformanceImpactLevel2 = 'PERFORMANCE_IMPACT_LEVEL_2',
	PerformanceImpactLevel3 = 'PERFORMANCE_IMPACT_LEVEL_3',
	PerformanceImpactLevel4 = 'PERFORMANCE_IMPACT_LEVEL_4'
}
export type Note = {
	type:NoteTypes;
	title?:string | null;
	content?:string | null;
}

export type PartsSection = InfoSection & {
	parts?: PartLink[] | null;
	notes?:Note[] | null;
}

export interface IMeasurementInfo {
	name?:string | null;
	description?:string | null;
	content?:string | null;
	recommendations?:InfoSection[] | null;
	kineticPartsConcentric?:PartsSection | null;
	kineticPartsEccentric?:PartsSection | null;
	riskParts?:PartsSection | null;
	relatedParts?:PartsSection | null;
}