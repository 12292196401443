import { useEffect, useRef } from "react";

import {debounce} from 'lodash';

type WindowChange = (width:number, height:number) => void;

const useWindowSize = (onChangeCallback?:WindowChange, debounceTime:number=0) => {

	const vh = useRef<number>(window.innerHeight);
	const vw = useRef<number>(window.innerWidth);

	useEffect(() => {
		
		const checkSize = () => {
			const newVh = window.innerHeight;
			const newVw = window.innerWidth;
			// only update if the dimension changes
			if( vw.current !== newVw || vh.current !== newVh ){
				vh.current = newVh;
				vw.current = newVw;
				if(onChangeCallback){
					onChangeCallback(newVw, newVh);
				}
			}
		}

        const handleResize = debounce(() => {
            checkSize();
		}, debounceTime);
		window.addEventListener('resize', handleResize);
        checkSize();

        return () => {
			// unmounting
			window.removeEventListener('resize', handleResize);

        }
    }, [vh, vw, debounceTime, onChangeCallback]);
	
	
	return [vw, vh];
}

export default useWindowSize;