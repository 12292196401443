import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Colors, FontSize } from '../../utils/style';
import { ConnectedProps, connect } from 'react-redux';
import { Dialog, DialogContent, IconButton, useMediaQuery } from '@material-ui/core';
import { PanelDialogPadding, PanelSpace, PanelSpaceMobile, PanelTransition, PanelWidth } from './org-panel.component';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { selectAllOrgs, selectOrgInfo } from '../../redux/org/org.selectors';
import { useHistory, useRouteMatch } from 'react-router-dom';

import CloseIcon from '@material-ui/icons/Close';
import { IDType } from '../../redux/core.types';
import { IOrg } from '../../redux/org/org.types';
import { IRootState } from '../../redux/root.types';
import OrgPanel from './org-panel.component';
import Routes from '../../utils/routes';
import { createStructuredSelector } from 'reselect';
import { setDashboardState } from '../../redux/dashboard/dashboard.actions';
import { setOrg } from '../../redux/org/org.actions';
import { trackOrgEvent } from '../../services/GoogleTagManager';

const useStyles = makeStyles<Theme, {
	totalStacks:number;
	panelSpace:number;
}>((theme: Theme) => {
	return createStyles({
		root: {
			fontSize: FontSize.Desktop,

			[theme.breakpoints.down("sm")]: {
				fontSize: FontSize.Mobile
			},
		},
		closeButton: props => ({
			position: "absolute",
			right: 0,
			top: 0,
			color: Colors.White,
			pointerEvents: 'auto',
			transform: 'translateX(33%)',
		}),
		closeButtonIcon: {
			fontSize: 40
		},
		content: {
			overflowX: 'hidden',
			flex: 'none',
			padding: 0
		},
		paper: {
			minHeight: `calc(100vh - ${PanelDialogPadding}px)`,
			background: 'none',
			boxShadow: 'none',
			overflow: 'hidden',
			pointerEvents: 'none',
			marginLeft: 0,
			marginRight: 0,
			width: '100%'
		},
		panelContainer: props => ({
			position: 'absolute',
			left: '50%',
			transition: PanelTransition,
			top: 0,
			transform: `translateX(-50%)`,
			//top: '50%',
			//transform: `translate(-50%, -50%)`,
			width: PanelWidth + ((props.totalStacks-1) * props.panelSpace),
		}),
	});
});

interface mapStateToPropsInterface  {
	org?:IOrg;
	allOrgs?:IOrg[];
}
const mapStateToProps = createStructuredSelector<IRootState, mapStateToPropsInterface>({
	org: selectOrgInfo,
	allOrgs: selectAllOrgs,
});

const mapDispatchToProps = {
	setOrg,
	setDashboardState
};

const connector = connect(
    mapStateToProps,
    mapDispatchToProps
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const MAX_STACKS = 4;

type OrgPanelType = React.ComponentProps<typeof OrgPanel>;
type OrgPickerProps = PropsFromRedux & {
	open:boolean;
	onClose?: () => void;
}
const OrgPicker:React.FC<OrgPickerProps> = ({
	org,
	allOrgs,
	setOrg,
	setDashboardState,
	open,
	onClose
}) => {

	const history = useHistory();
	const homeMatch = useRouteMatch(Routes.HOME);
	const homeManagementMatch = useRouteMatch(Routes.HOME_MANAGMENT);
	const playerManagementMatch = useRouteMatch(Routes.PLAYER_MANAGEMENT);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	
	const findOrg = useCallback((orgId:IDType) => {
		const seekOrg = (seekId:IDType, stack:IOrg[]=[], orgs?:IOrg[]) => {
			orgs = orgs || allOrgs;
			if( orgs ){
				let found = false;
				let i = 0;
				while( i < orgs.length && !found){
					const org = orgs[i];
					const {id, children} = org;
					if( id===seekId || (children && children.filter(childOrg => childOrg.id===seekId).length)){
						found = true;
						stack.push(org);
					} else if(children){
						const grandChildStack = seekOrg(seekId, stack, children);
						if(grandChildStack.length){
							found = true;
							stack = [
								org,
								...grandChildStack
							];
						}
					}
					i++;
				}
			};
			return stack;
		}
		return seekOrg(orgId);
	}, [allOrgs]);
	
	const [orgStack, setOrgStack] = useState<IOrg[]>([]);

	useEffect(() => {
		if(org && allOrgs){
			let stack = findOrg(org.id);
			// max stack to 4 panels
			if(stack.length > MAX_STACKS){
				stack = stack.slice(0, 4);
			}
			setOrgStack( stack );
		}
	}, [org, allOrgs, setOrgStack, findOrg]);

	const goToStack = useCallback((targetOrg:IOrg) => {
		const idx = orgStack.findIndex(stackOrg => stackOrg.id===targetOrg.id);
		let newStack;
		if(idx!==-1){
			newStack = orgStack.slice(0, idx+1);
		} else {
			newStack = [
				...orgStack,
				targetOrg
			];
		}
		if(newStack.length <= MAX_STACKS){
			setOrgStack(newStack);
		}
	}, [orgStack, setOrgStack]);

	const selectOrg = useCallback((targetOrg:IOrg) => {
		if(!org || org.id!==targetOrg.id){
			trackOrgEvent('Change Sub Org', targetOrg.key, org?.key);
			
			setOrg( targetOrg );
			if(onClose){
				onClose();
			}
			setDashboardState({
				page: 0,
				positionFilterSelections: undefined,
				statusFilterSelections: undefined,
				daysCompletedRangeFilterSelections: undefined,
				searchFilter: undefined,
			});
			
			if( (homeManagementMatch && homeManagementMatch.isExact) || (playerManagementMatch && playerManagementMatch.isExact) ){
				if( !homeManagementMatch || !homeManagementMatch.isExact){
					history.push( Routes.HOME_MANAGMENT );
				}
			} else if( !homeMatch || !homeMatch.isExact){
				history.push( Routes.HOME );
			}
		}
	}, [org, setOrg, onClose, history, homeMatch, setDashboardState, homeManagementMatch, playerManagementMatch]);

	const onCloseButtonClick = useCallback((e) => {
		if(onClose){
			onClose();
		}
	}, [onClose]);

	const onDialogClose = useCallback((event, reason) => {
		if(onClose){
			onClose();
		}
	}, [onClose]);

	const stackLength = useMemo(() => {
		return orgStack.reduce((accu, {children}) => {
			if(children && children.length){
				accu++;
			}
			return accu;
		}, 0)
	}, [orgStack]);

	const classes = useStyles({
		totalStacks: stackLength,
		panelSpace: isMobile ? PanelSpaceMobile : PanelSpace
	});
	
	return (
		<Dialog
			className={classes.root}
			fullWidth={true}
			maxWidth="lg"
			PaperProps={{
				className: classes.paper
			}}
			//fullScreen	
			open={open}
			onClose={onDialogClose}
		>
			<DialogContent className={classes.content}>
				<TransitionGroup className={classes.panelContainer}>
					<CSSTransition
						key="close-button"
						classNames="close-button"
						timeout={500}
					>
						<IconButton className={classes.closeButton} onClick={onCloseButtonClick} aria-label="close" component="span">
							<CloseIcon className={classes.closeButtonIcon} />
						</IconButton>
					</CSSTransition>
					{orgStack.map((stackOrg, idx) => {
						const { id, children } = stackOrg;
						return (children && children.length) ? (
							<CSSTransition
								key={id}
								timeout={500}
								classNames="panel"
							>
								<OrgPanel org={stackOrg} stackIndex={idx} totalStacks={stackLength} onOrgClick={selectOrg} onOrgExpand={goToStack} />
							</CSSTransition>
						) : null
					})}
				</TransitionGroup>
			</DialogContent>
		</Dialog>
	);
}

export default connector(OrgPicker);