import { Backdrop, CircularProgress } from '@material-ui/core';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import ResponsiveImage from '../responsive-image/responsonsive-image.component';
import clsx from 'clsx';
import sizer16x9 from '../../assets/sizer16x9.png';
import useTimer from '../../hooks/useTimer.hook';

const FADE_OUT_TIME = 300;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff',

			'&.stopAnimation .MuiCircularProgress-indeterminate': {
				animation: 'none'
			}
		},
		absolute: {
			position: 'absolute',
		}
	}),
);

enum Sizer {
	s16x9 = '16x9'
}

export interface SpinnerBackdropProps {
	isLoading?: boolean;
	sizer?: Sizer | string;
	backDropClassName?: string;
	responsiveSizerClassName?: string;
	absolute?:boolean;
}
const SpinnerBackdrop: React.FC<SpinnerBackdropProps> = ({ isLoading, sizer, backDropClassName, responsiveSizerClassName, absolute }) => {
	const classes = useStyles();
	const [isAnimating, setIsAnimating] = useState<boolean>(!!isLoading);
	const [isOpen, setIsOpen] = useState<boolean>(!!isLoading);

	const [disableAnimationTimer, clearDisableAnimationTimer, onStage] = useTimer();

	const settingAnimation = useRef<boolean>(true);
	
	useEffect(() => {
		if( onStage.current && isLoading !== settingAnimation.current ){
			clearDisableAnimationTimer();
			if (isLoading) {
				settingAnimation.current = true;
				setIsAnimating(true);
				disableAnimationTimer.current = setTimeout(() => {
					// allow time for CSS to be set
					setIsOpen(true);
				}, 20);
			} else {
				settingAnimation.current = false;
				setIsAnimating(true);
				setIsOpen(false);
				disableAnimationTimer.current = setTimeout(() => {
					// allow time for the fade out effect, then shut off animation
					setIsAnimating(false);
				}, FADE_OUT_TIME);
			}
		}
	}, [isLoading, onStage, isAnimating, setIsAnimating, setIsOpen, clearDisableAnimationTimer, settingAnimation, disableAnimationTimer]);

	const transitionDuration = useMemo(() => ({ 
		appear: 0, 
		enter: 0, 
		exit: FADE_OUT_TIME
	}), []);

	return (
		<Fragment>
			<Backdrop
				className={clsx(classes.backdrop, absolute && classes.absolute, backDropClassName, !isAnimating && 'stopAnimation')}
				open={isOpen}
				transitionDuration={transitionDuration}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			{isLoading && sizer && sizer === Sizer.s16x9 && (
				<ResponsiveImage src={sizer16x9} className={responsiveSizerClassName} alt="Loading..." />
			)}
		</Fragment>
	)
};

export default SpinnerBackdrop;