import { FormControl, InputLabel, Select } from '@material-ui/core';
import React, {useMemo} from 'react';
import {
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core/styles";

import Assign from '../../utils/assign';
import { FontWeight } from '../../utils/style';
import { SelectProps } from '@material-ui/core/Select';
import clsx from 'clsx';
import {uniqueId} from 'lodash';

interface SelectNativeStyle {
	variant?: "filled" | "standard" | "outlined";
}

const useStyles = makeStyles<Theme, SelectNativeStyle>((theme: Theme) => {
	const topPad = 13.79;
	
	return createStyles({
		selectControl: props => ({
			width: '100%',

			'& .MuiFilledInput-root': {
				backgroundColor: theme.palette.info.main,
			},

			'& .MuiSelect-select': {
				fontSize: 16,
				lineHeight: '18px',
				height: 18,
				fontWeight: FontWeight.Bold,
				borderRadius: '4px',
				padding: `${topPad}px 40px ${topPad}px 16px`,

				[theme.breakpoints.down('sm')]: {
					fontSize: 14,
					lineHeight: '16px',
					height: 16,
				},
			},
			'& .MuiFilledInput-root, & .MuiSelect-select, & .MuiSelect-icon': {
				color: (props.variant==='filled') ? theme.palette.info.contrastText : theme.palette.text.primary,
			},

			'& .MuiSelect-icon': {
				fontSize: '30px',
				top: 'calc(50% - 15px)',
			}
		}),
		label: {
			position: 'relative',
    		transform: 'none'
		}
	});
});

export type SelectNativeProps = Assign<React.ComponentPropsWithRef<'select'>, SelectProps> & SelectNativeStyle & {
	selectClassName?:string;
	label?:string;
	labelClassName?:string;
}

const SelectNative = React.forwardRef<HTMLSelectElement, SelectNativeProps>(({
	native=true,
	disableUnderline=true,
	children,
	className,
	selectClassName,
	inputProps={},
	label,
	labelId,
	labelClassName,
	variant="filled",
	...otherProps
}, ref) => {
	const classes = useStyles({
		variant
	});

	const realLabelId = useMemo(() => labelId ? labelId : label ? uniqueId('select_native_component_label_') : undefined, [label, labelId]);
        
	return (
		<FormControl variant={variant} className={clsx(classes.selectControl, className)}>
			{label && (
				<InputLabel id={realLabelId} disableAnimation variant="standard" shrink={false} className={clsx(classes.label, labelClassName)}>{label}</InputLabel>
			)}
			<Select
				ref={ref}
				labelId={realLabelId}
				className={selectClassName}
				native={native}
				disableUnderline
				inputProps={inputProps}
				{...otherProps}
			>
				{children}
			</Select>
		</FormControl>
	)
});

export default SelectNative;