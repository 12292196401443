import { ConnectedProps, connect } from 'react-redux';
import { selectStatusSnackbarAutoHideDuration, selectStatusSnackbarMessage, selectStatusSnackbarOpen, selectStatusSnackbarSeverity } from '../../redux/status/status.selectors';

import { Color } from '@material-ui/lab/Alert';
import { IRootState } from '../../redux/root.types';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { Snackbar as SnackbarOriginal } from '@material-ui/core';
import { closeSnackbar } from '../../redux/status/status.actions';
import { createStructuredSelector } from 'reselect';

interface mapStateToPropsInterface {
	isOpen:boolean;
	autoHideDuration?:number | null;
	severity:Color;
	message:string;
}
const mapStateToProps = createStructuredSelector<
	IRootState,
	mapStateToPropsInterface
>({
	severity: selectStatusSnackbarSeverity,
	message: selectStatusSnackbarMessage,
	isOpen: selectStatusSnackbarOpen,
	autoHideDuration: selectStatusSnackbarAutoHideDuration
});

const mapDispatchToProps = {
	closeSnackbar
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type SnackbarProps = PropsFromRedux & {};

const Snackbar:React.FC<SnackbarProps> = ({
	isOpen,
	autoHideDuration,
	severity,
	message,
	closeSnackbar
}) => {

	const onSnackbarClose = (event: React.SyntheticEvent<any, Event>, reason: string) => {
		closeSnackbar();
	};

	return (
		<SnackbarOriginal open={isOpen} autoHideDuration={autoHideDuration} onClose={onSnackbarClose}>
			<MuiAlert elevation={6} variant="filled" severity={severity}>
				{message}
			</MuiAlert>
		</SnackbarOriginal>
	)
};

export default connector(Snackbar);