import 'intersection-observer';

export type ObservableElementType = {
	unobserve: () => void;
	observing:boolean;
	element:Element;
	callback: () => void;
}

export type ObservableListType = {
	add: (element:Element, callback:()=>void) => ObservableElementType;
	find: (element:Element) => ObservableElementType | undefined;
	remove: (observable:ObservableElementType) => void;
	destroy: () => void;
}

// Use this wrapper class so we can add a callback.
class ObservableElement implements ObservableElementType {
	private _observing:boolean = true;
	
	constructor(
		private readonly ioObserver:IntersectionObserver,
		public readonly element:Element,
		public readonly callback:()=>void
	){
		this.ioObserver.observe( element );
	}

	unobserve = () => {
		if( this._observing ){
			this._observing = false;
			this.ioObserver.unobserve( this.element );
		}
	}

	get observing(){
		return this._observing;
	}
}

export type IntersectionObserverProps = {
	root?:Element | null;
	rootMargin?:string;
	threshold?:number | number[];
}
// Master list class of observables
class GroupIntersectionObserver implements ObservableListType {
	private list:ObservableElementType[] = [];
	private ioObserver:IntersectionObserver;
	
	constructor({root, rootMargin, threshold}:IntersectionObserverProps){
		this.ioObserver = new window.IntersectionObserver((entries, itemObserver) => {
			entries.forEach(entry => {
				if( entry.isIntersecting ){
					const observable = this.find( entry.target );
					if( observable ){
						observable.callback();
					}
				}
			})
		}, {
			root,
			rootMargin,
			threshold
		});
		//ioObserver.POLL_INTERVAL = 100; // Time in milliseconds.
	}

	add = (element:Element, callback:()=>void) => {
		const obj = new ObservableElement(this.ioObserver, element, callback);
		this.list.push( obj );
		return obj;
	};

	find = (element:Element) => {
		return this.list.find(observable => observable.element===element);
	}

	remove = (observable:ObservableElementType) => {
		const idx = this.list.indexOf(observable);
		if( idx !== -1 ){
			observable.unobserve();
			this.list.splice(idx, 1);
		}
	}

	destroy = () => {
		[ // copy original list
			...this.list
		].forEach(observable => this.remove(observable));
	}
}

export default GroupIntersectionObserver;