import { Color } from "@material-ui/lab/Alert";
import { ISignOutSuccess } from '../user/user.types';

export const SNACKBAR_OPEN = 'SNACKBAR_OPEN';
export const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE';

export interface ISnackBarProps {
	snackbarSeverity:Color;
	snackbarMessage:string;
	snackbarAutoHideDuration?:number | null;
}

// State type
export interface IStatusState extends ISnackBarProps {
	snackbarOpen:boolean;
}


// Action types
export interface ISnackbarOpen {
	type: typeof SNACKBAR_OPEN;
	payload: ISnackBarProps;
};

export interface ISnackbarClose {
    type: typeof SNACKBAR_CLOSE;
}

export type TypeStatusAction = ISnackbarOpen | ISnackbarClose | ISignOutSuccess;