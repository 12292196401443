import {
	Assessment,
	AssessmentMapInterface,
	GET_ASSESSMENT,
	GET_CACHED_ASSESSMENT,
	IAssessment,
	IGetAssessment,
	IGetCachedAssessment,
	IMeasurement,
	ISetCachedAssessment,
	Measurement,
	SET_CACHED_ASSESSMENT
} from './assessment.types';
import { AssessmentFlowType, AssessmentStatus, DELETE_ASSESSMENT, GET_MEASUREMENTS, IAssessmentSave, ICachableAssessmentPayload, IDeleteAssessment, IGetMeasurements, IMeasurementFlow, IMeasurementSave, IRestartAssessment, ISaveAssessment, ISavePartialAssessment, ISetAssessmentOrgMembers, IUpdateAssessment, MeasurementPart, RESTART_ASSESSMENT, SAVE_ASSESSMENT, SAVE_PARTIAL_ASSESSMENT, SET_ASSESSMENT_ORG_MEMBERS, UPDATE_ASSESSMENT, IAssessmentSubmit } from './assessment.types';
import {StorageKey, deleteSession, getSession, setSession} from '../../utils/storage';
import {actionChannel, all, call, put, select, take, takeLatest} from 'redux-saga/effects';
import { autoSaveAssessment, deleteAssessment as deleteAssessmentAPI, getAssessmentMeasurements, getNewAssessment, submitAssessment } from '../../services/PMotionApi';
import { autoSaveAssessmentFailure, getAssessment as getAssessmentAction, restoreAssessment, saveAssessment, saveAssessmentFailure, saveAssessmentSuccess, setAssessment, setAssessmentOrgMembers, setMeasurements, showError } from './assessment.actions';

import { IDType } from '../core.types';
import {IPlayer} from '../player/player.types';
import { isNumber } from '../../utils/validator';
import { isUserTokenValid } from '../user/user.sagas';
import { selectCurrentAssessmentByMember } from './assessment.selectors';
import { selectCurrentUserToken } from '../user/user.selectors';

interface ISavedAssessment {
	assessments?:AssessmentMapInterface;
	orgMembers:IPlayer[];
	currentOrgMemberID:IDType;
	measurements?:IMeasurement[];
}

export function* cacheAssessment({payload}:ISetCachedAssessment) {
	if( payload ){
		// locally store data
		const {assessments, measurements, orgMembers, currentOrgMember, autoSave, token} = payload;
		const currentAssessment = assessments ? assessments[currentOrgMember.id] : undefined;
		
		if(currentAssessment && assessments){
			assessments[currentOrgMember.id] = currentAssessment;
		}
		const storedObj:ISavedAssessment = {
			assessments: assessments ? {
				...assessments
			} : undefined,
			orgMembers,
			currentOrgMemberID: currentOrgMember.id,
			measurements
		};
		
		yield setSession(StorageKey.Assessment, storedObj);

		if( autoSave && currentAssessment ){
			// save on server
			const savable = getSaveableData(currentAssessment);
			//console.log('SAVING', savable)
			try{
				const result = yield autoSaveAssessment( savable, token );
				yield isUserTokenValid(result.data, token);
			} catch(er){
				yield put(
					autoSaveAssessmentFailure('Auto-Save Assessment failed. '+er.message)
				);
			}
		}
		
	} else {
		// delete data
		yield deleteSession(StorageKey.Assessment);
	}
	
}

export function* getCachedAssessment(action:IGetCachedAssessment) {
	const storedAssessments:ISavedAssessment | null = yield getSession(StorageKey.Assessment) as ISavedAssessment | null;
	if( storedAssessments && storedAssessments.orgMembers && storedAssessments.orgMembers.length ){
		const {assessments, orgMembers, currentOrgMemberID, measurements} = storedAssessments;
		const currentOrgMember = orgMembers.find(member => member.id===currentOrgMemberID);
		
		if(currentOrgMember){
			const realAssessment:ICachableAssessmentPayload = {
				assessments,
				measurements,
				orgMembers,
				currentOrgMember
			}
			yield put(
				restoreAssessment(realAssessment)
			);
		}
	}
}

const overrideMeasurementValues = (baseMeasurements:IMeasurement[], overrideMeasurements:IMeasurement[]) => {
	baseMeasurements.forEach((measurement) => {
		const {measurementId, bilateral, left, right, center} = measurement;
		const matchMeasure = overrideMeasurements.find(({measurementId:idCheck}) => idCheck===measurementId);
		if( matchMeasure ){
			measurement.left = bilateral ? matchMeasure.left : left;
			measurement.right = bilateral ? matchMeasure.right : right;
			measurement.center = !bilateral ? matchMeasure.center : center;
		}
	});
}

enum RemoteSide {
	LEFT = 'LEFT',
	RIGHT = 'RIGHT',
	CENTER = 'CENTER',
}
type MeasurementFlowStepRemote = Omit<IMeasurementFlow, 'side'> & {
	side: RemoteSide;
}
type MeasurementFlowRemote = IDType & {
	key: AssessmentFlowType;
	steps: MeasurementFlowStepRemote[];
}
type SingleAsessmentRemote = Omit<IAssessment, 'flow'> & {
	flow?:MeasurementFlowRemote | null;
}
type AssessmentsRemote = SingleAsessmentRemote[];

export function* getAssessment({payload:{players:members, type, measurementIds, measurements:overrideMeasurements, token, flowType}}:IGetAssessment) {
	// get info
	try {
		const ids = members.map(({id}) => id);
		const assessmentInfoData = yield getNewAssessment(ids, token, type, measurementIds, flowType);
		const remoteAssessmentsInfo:AssessmentsRemote = assessmentInfoData.data && Array.isArray(assessmentInfoData.data) ? (assessmentInfoData.data as AssessmentsRemote) : [assessmentInfoData?.data as SingleAsessmentRemote];
		// filter out any invalid data within the array
		const assessmentsInfo:AssessmentsRemote = remoteAssessmentsInfo.filter(assessment => !!assessment && isNumber(assessment.memberId));
		const valid = yield isUserTokenValid(assessmentsInfo, token);
		if( valid ){
			if( assessmentsInfo && Array.isArray(assessmentsInfo) ){

				if(assessmentsInfo.length === 0){
					throw new Error('Empty array returned.');
				}
				
				const assessmentMemberMap:AssessmentMapInterface = {};
	
				// get stored assesssments
				const cachedAssessmentMemberMap:AssessmentMapInterface | null | undefined = getSession(StorageKey.Assessment) as AssessmentMapInterface | null;
	
				members.forEach(member => {
					const memberId = member.id;
					const storedAssessment = cachedAssessmentMemberMap ? cachedAssessmentMemberMap[memberId] : null;
					if( storedAssessment ){
						// used stored
						if(overrideMeasurements && storedAssessment.measurements){
							overrideMeasurementValues(storedAssessment.measurements, overrideMeasurements);
						}
						assessmentMemberMap[memberId] = storedAssessment;
					} else {
						// create new instance
						const singleAssessmentInfo:SingleAsessmentRemote | undefined = assessmentsInfo.find(assessment => memberId===assessment.memberId);
						if(singleAssessmentInfo){
							const measurements:Measurement[] = singleAssessmentInfo?.measurements?.map((measurement:IMeasurement) => Measurement.create(measurement));
							if(overrideMeasurements){
								overrideMeasurementValues(measurements, overrideMeasurements);
							}
	
							const assessment:Assessment = Assessment.create({
								...singleAssessmentInfo,
								memberId,
								measurements,
								flow: singleAssessmentInfo.flow && singleAssessmentInfo.flow.key===flowType ? singleAssessmentInfo.flow.steps.map(({side, ...flowItem}) => ({
									...flowItem,
									side: side===RemoteSide.CENTER ? MeasurementPart.Center : side===RemoteSide.RIGHT ? MeasurementPart.Right : MeasurementPart.Left
								})) : undefined
							});
							assessmentMemberMap[memberId] = assessment;
						} else {
							throw new Error('No assessment data found.');
						}
					}
				});
				
				yield put(
					setAssessment(assessmentMemberMap)
				);
			} else {
				throw new Error('Assessment API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showError('Get Assessment data failed. '+er.message)
		);
	}
    
}

export function* onGetAssessmentMeasurements({payload:{player, token}}:IGetMeasurements) {
	// get info
	try {
		const measurementsInfoData = yield getAssessmentMeasurements(player.id, token);
		const measurementsInfo = measurementsInfoData?.data;
		const valid = yield isUserTokenValid(measurementsInfo, token);
		if( valid ){
			if( measurementsInfo && Array.isArray(measurementsInfo) ){

				const measurements:IMeasurement[] = measurementsInfo.map(({id, ...measure}) => Measurement.create({
					...measure,
					measurementId: id // api ID is actually referring to measurementId
				}));
				
				yield put(
					setMeasurements(measurements)
				);
			} else {
				throw new Error('Measurements API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showError('Get Measurements data failed. '+er.message)
		);
	}
}

export function* submitAssessmentData({ payload: {assessment, completeAfterSubmit, token} }:ISaveAssessment) {
	const savable:IAssessmentSubmit = {
		...getSaveableData(assessment),
		completeAfterSubmit
	};
	try {
		const saveData = yield submitAssessment( savable, token );
		const valid = yield isUserTokenValid(saveData.data, token);
		if(valid){
			if(saveData && saveData.data && saveData.data===true){
				yield put(
					saveAssessmentSuccess()
				);
			} else {
				throw new Error('Error in request.');
			}
		}
    } catch(error) {
		yield put(saveAssessmentFailure(error.message));
    }
}

export function* submitPartialAssessmentData({ payload }:ISavePartialAssessment) {
	const {measurements, player, ...otherPayloadProps} = payload;
	const measurementIds = measurements.map(({measurementId}) => measurementId);
	yield put( getAssessmentAction({
		...otherPayloadProps,
		measurementIds,
		measurements,
		players: [player]
	}) );
	yield take('*'); // wait for store to be updated by reducer
	const assessment:IAssessment | undefined = yield select( selectCurrentAssessmentByMember );
	const token:string | undefined = yield select( selectCurrentUserToken );
	if( assessment && token ){
		overrideMeasurementValues(assessment.measurements, measurements);
		yield put( saveAssessment({
			assessment,
			token
		}) );
	}
}

export function* updateAssessmentData({ payload: {assessment, token} }:IUpdateAssessment) {
	const savable = getSaveableData(assessment);
	try {
		const saveData = yield autoSaveAssessment( savable, token );
		const valid = yield isUserTokenValid(saveData.data, token);
		if(valid){
			if(saveData && saveData.data && saveData.data===true){
				yield put(
					saveAssessmentSuccess()
				);
			} else {
				throw new Error('Error in Update Assessment request.');
			}
		}

    } catch(error) {
		yield put(saveAssessmentFailure(error.message));
    }
}

export function* deleteAssessmentData({ payload: {assessment, token} }:IDeleteAssessment) {
	let success:boolean = false;
	try {
		const result = yield deleteAssessmentAPI( assessment.id, token );
		const valid = yield isUserTokenValid(result.data, token);
		if(valid){
			if(result && result.data && result.data.status===AssessmentStatus.Deleted){
				yield put(
					saveAssessmentSuccess()
				);
				success = true;
			} else {
				throw new Error('Error in Delete Assessment request.');
			}
		}
    } catch(error) {
		yield put(saveAssessmentFailure(error.message));
	}
	return success;
}

export function* restartAssessmentCall({ payload: {assessment, members, token} }:IRestartAssessment) {
	try {
		const success = yield deleteAssessmentData({
			type: DELETE_ASSESSMENT,
			payload: {
				assessment,
				token
			}
		});
		if(success){
			yield put(
				setAssessmentOrgMembers({
					members,
					reset: true // clears cache
				})
			);
		}
    } catch(error) {
		yield put(saveAssessmentFailure('Error in Restart Assessment request.'));
    }
}

const numberOrNull = (val?:number | null) => (val===undefined || val===null || isNaN(val)) ? null : val;

const getSaveableData = ({id, memberId, measurements, type}:IAssessment):IAssessmentSave => {
	return {
		id,
		memberId,
		measurements: measurements.map(({id:testId, bilateral, left, right, center, visible}) => {
			if( bilateral ){
				return {
					id: testId,
					bilateral,
					left: numberOrNull(left),
					right: numberOrNull(right),
					visible: (visible!==undefined) ? visible : null
				} as IMeasurementSave;
			}
			return {
				id: testId,
				bilateral,
				center: numberOrNull(center),
				visible: (visible!==undefined) ? visible : null
			} as IMeasurementSave
		}),
		type
	}
}

export function* onSetOrgMembers(action:ISetAssessmentOrgMembers) {
	if(action.payload.reset){
		// if reset, clear cache
		yield cacheAssessment({
			type: SET_CACHED_ASSESSMENT,
			payload: null
		})
	}
}

export function* onGetAssessment() {
    yield takeLatest(GET_ASSESSMENT, getAssessment);
}

export function* onGetMeasurements() {
    yield takeLatest(GET_MEASUREMENTS, onGetAssessmentMeasurements);
}

export function* onCacheAssessment() {
	// queueing requests per https://redux-saga.js.org/docs/advanced/Channels.html
	// 1- Create a channel for request actions
	const requestChan = yield actionChannel(SET_CACHED_ASSESSMENT);
	while (true) {
		// 2- take from the channel
		const action:ISetCachedAssessment = yield take(requestChan);
		// 3- Note that we're using a blocking call
		yield call(cacheAssessment, action);
	}
}

export function* onGetCacheAssessment() {
    yield takeLatest(GET_CACHED_ASSESSMENT, getCachedAssessment);
}

export function* onSaveAssessment() {
    yield takeLatest(SAVE_ASSESSMENT, submitAssessmentData);
}

export function* onSavePartialAssessment() {
    yield takeLatest(SAVE_PARTIAL_ASSESSMENT, submitPartialAssessmentData);
}

export function* onUpdateAssessment() {
    yield takeLatest(UPDATE_ASSESSMENT, updateAssessmentData);
}

export function* onDeleteAssessment() {
    yield takeLatest(DELETE_ASSESSMENT, deleteAssessmentData);
}

export function* onRestartAssessment() {
    yield takeLatest(RESTART_ASSESSMENT, restartAssessmentCall);
}

export function* onSetAssessmentOrgMembers() {
    yield takeLatest(SET_ASSESSMENT_ORG_MEMBERS, onSetOrgMembers);
}

export function* assessmentSagas() {
    yield all([
		call( onGetAssessment ),
		call( onGetMeasurements ),
		call( onCacheAssessment ),
		call( onGetCacheAssessment ),
		call( onSaveAssessment ),
		call( onSavePartialAssessment ),
		call( onUpdateAssessment ),
		call( onDeleteAssessment ),
		call( onRestartAssessment ),
		call( onSetAssessmentOrgMembers )
    ]);
}