import React, {useCallback, useEffect, useRef, useState} from 'react';
import SrcMap, {SrcMapType} from '../utils/src-map';

import GroupIntersectionObserver from '../utils/group-intersection-observer';
import { ObservableRefAPI } from './useObservableRef.hook';
import {debounce} from 'lodash';
import placeholder from '../assets/placeholder-image.png';

const lazyLoadedRefs = new Map<string, boolean>();

// Master list of Observables
const observables = new GroupIntersectionObserver({
    rootMargin: '33.33%'
});

const hasAlreadyLazyLoaded = (src:string) => lazyLoadedRefs.has(src);

type NullableFunction = null | Function;
//type RefType = React.Ref<HTMLImageElement | null> | React.MutableRefObject<HTMLImageElement | undefined | null> React.RefObject;
type RefType = React.MutableRefObject<HTMLImageElement | undefined> | React.RefObject<HTMLImageElement | undefined | null>;

const useImageLoader = (
    reference:RefType, 
    srcMapping:SrcMapType, 
    skipLazyLoad:boolean, 
    placeholderSrc:string=placeholder,
    scaleObserver?:ObservableRefAPI<number>
) => {

    const srcMap = new SrcMap(srcMapping);

    const hasLoaded = skipLazyLoad || hasAlreadyLazyLoaded(srcMap.src);

    interface ILoaderState {
        src: string;
        srcset?: string;
        sizes?: string;
        loaded: boolean;
    }
    const initialState:ILoaderState = {
        src: hasLoaded ? srcMap.src : placeholderSrc,
        srcset: hasLoaded ? srcMap.srcSet : undefined,
        sizes: '1px',
        loaded: hasLoaded
    }

    const needsObserving = useRef<boolean>(!hasLoaded);
    const stopObserving = useRef<NullableFunction>(null);
    const [originalSrcPath, setOriginalSrcPath] = useState<string>(srcMap.src);
    const [srcPath, setSrcPath] = useState<string | undefined>(initialState.src);
    const [srcset, setSrcset] = useState<string | undefined>(initialState.srcset);

    const [loaded, setLoaded] = useState<boolean>(initialState.loaded);
    const [sizes, setSizes] = useState<string | undefined>(initialState.sizes);

    const checkSize = useCallback((force?:boolean) => {
        if( loaded || force ){
            if(reference && reference.current && reference.current instanceof HTMLImageElement){
                const getCurrentScale = scaleObserver ? scaleObserver[0] : (() => 1);
                const scale = getCurrentScale();
                const newSize = Math.max(reference.current.clientWidth * scale, 1);
                const prevSize = sizes ? parseFloat(sizes) : 0;
                const diff = Math.abs(newSize - prevSize);
                if(diff > 1){
                    setSizes( newSize + 'px' );
                }
            }
        }
    }, [reference, loaded, sizes, scaleObserver]);

    useEffect(() => {
        if(originalSrcPath!==srcMap.src){
            // a src reset
            needsObserving.current = !loaded;
            setOriginalSrcPath(srcMap.src);
            // maintain this order of setting sizes, srcset, then src
            checkSize(true);
            setSrcset(loaded ? srcMap.srcSet : undefined);
            setSrcPath(loaded ? srcMap.src : placeholderSrc);
            
        }
    }, [needsObserving, loaded, setSrcPath, originalSrcPath, setOriginalSrcPath, srcMap, placeholderSrc, checkSize]);

    const stopObserver = useCallback(() => {
        if(stopObserving.current){
            stopObserving.current();
            stopObserving.current = null
        }
    }, [stopObserving]);

    const startObserving = useCallback(() => {
        let isObserving = false;
        if( needsObserving.current && reference.current ){
            needsObserving.current = false;
            stopObserver();
            const observable = observables.add( reference.current, () => {
                stopObserver();
                // lazy-load
                lazyLoadedRefs.set(srcMap.src, true); // unique to this project, only care if this particular image has already loaded. Other project you may just stick to reference object.
                setLoaded(true);
                // maintain this order of setting sizes, srcset, then src
                checkSize(true);
                setSrcset( srcMap.srcSet );
                setSrcPath( srcMap.src );
            });
            isObserving = true;

            stopObserving.current = () => {
                if(isObserving){
                    isObserving = false;
                    observables.remove( observable );
                }
            };
        }

        return stopObserving.current ? stopObserving.current : null;
    }, [reference, srcMap, checkSize, needsObserving, stopObserving, stopObserver]);

    useEffect(() => {
        // mounting
        startObserving();
        let loadListening:HTMLImageElement | undefined;

        const handleResize = debounce(() => {
            checkSize();
        }, 100);
        window.addEventListener('resize', handleResize);

        if(reference && reference.current && reference.current instanceof HTMLImageElement){
            loadListening = reference.current;
            loadListening.addEventListener('load', handleResize);
        }
        checkSize();

        return () => {
            // unmounting
            window.removeEventListener('resize', handleResize);
            if( loadListening ){
                loadListening.removeEventListener('load', handleResize);
                loadListening = undefined;
            }

            stopObserver();
        }
    }, [startObserving, stopObserver, checkSize, reference]);

    if( scaleObserver ){
        const scaleSubscriber = scaleObserver[1];
        scaleSubscriber(() => {
            checkSize();
        })
    }

    return {
        src: srcPath,
        srcset,
        sizes,
        loaded
    };
}

export default useImageLoader;