import { OrgAccessLevel, OrgRoleAccessLevels } from '../org/org.types';

import {IRootState} from '../root.types';
import { canAccess } from '../../utils/routes';
import {createSelector} from 'reselect';
import { selectOrgInfo } from '../org/org.selectors';

const selectUser = (state:IRootState) => state.user;

export const selectCurrentUser = createSelector([selectUser], user => user.currentUser);

export const selectSelectedUser = createSelector([selectUser], user => user.selectedUser);

export const selectCurrentUserToken = createSelector([selectCurrentUser], currentUser => currentUser ? currentUser.token : undefined);

export const selectCurrentUserTokenTTL = createSelector([selectCurrentUser], currentUser => currentUser ? currentUser.tokenTTL : undefined);

export const selectCurrentUserLastLogin = createSelector([selectCurrentUser], currentUser => currentUser ? currentUser.lastLogin : undefined);

export const selectCurrentUserTermsAccepted = createSelector([selectCurrentUser], currentUser => currentUser ? currentUser.termsAccepted : undefined);

export const selectCurrentUserHasStaffAccess = createSelector([selectCurrentUser], currentUser => currentUser ? currentUser.hasStaffAccess : false);

export const selectCurrentUserCanAccessPScore = createSelector([selectCurrentUserHasStaffAccess], access => access);

export const selectCurrentUserCanShowCompliance = createSelector([selectCurrentUser], currentUser => currentUser ? currentUser.showCompliance : undefined);

export const selectEnvironment = createSelector([selectCurrentUser], currentUser => currentUser ? currentUser.env : undefined);

export const selectUserError = createSelector([selectUser], user => user.error);

export const selectChangePasswordLoading = createSelector([selectUser], user => user.changePasswordLoading);

export const selectChangePasswordSuccess = createSelector([selectUser], user => user.changePasswordSuccess);

export const selectChangePasswordError = createSelector([selectUser], user => user.changePasswordError);

export const selectForgotPasswordLoading = createSelector([selectUser], user => user.forgotPasswordLoading);

export const selectForgotPasswordSuccess = createSelector([selectUser], user => user.forgotPasswordSuccess);

export const selectForgotPasswordError = createSelector([selectUser], user => user.forgotPasswordError);

export const selectResetPasswordLoading = createSelector([selectUser], user => user.resetPasswordLoading);

export const selectResetPasswordSuccess = createSelector([selectUser], user => user.resetPasswordSuccess);

export const selectResetPasswordError = createSelector([selectUser], user => user.resetPasswordError);

export const selectTempPasswordLoading = createSelector([selectUser], user => user.tempPasswordLoading);

export const selectTempPasswordSuccess = createSelector([selectUser], user => user.tempPasswordSuccess);

export const selectTempPasswordError = createSelector([selectUser], user => user.tempPasswordError);

export const selectTempUser = createSelector([selectUser], user => user.tempUser);

export const selectUserCanAccess = (level:OrgAccessLevel) => createSelector([selectCurrentUser, selectOrgInfo], (user, org) => {
	const allowedRoles = OrgRoleAccessLevels.get(level);
	return !!allowedRoles && !!user && !!org && canAccess(user, org, allowedRoles);
});

export const selectIsTermsLoading = createSelector([selectUser], user => user.termsIsLoading);

export const selectTermsContent = createSelector([selectUser], user => user.termsContent);

export const selectTermsError = createSelector([selectUser], user => user.termsError);

export const selectLastLoginInfo = createSelector([selectUser], user => user.lastLoginInfo);

export const selectUsers = createSelector([selectUser], user => user.users);

export const selectUsersIsLoading = createSelector([selectUser], user => user.isUsersLoading);

export const selectUsersError = createSelector([selectUser], user => user.usersError);

export const selectGetUserIsLoading = createSelector([selectUser], user => user.isGetUserLoading);

export const selectGetUserError = createSelector([selectUser], user => user.getUserError);

export const selectEditUserSuccess = createSelector([selectUser], user => user.editUserSuccess);

export const selectEditUserError = createSelector([selectUser], user => user.editUserError);

export const selectAddUserIsLoading = createSelector([selectUser], user => user.isAddUserLoading);

export const selectAddUserSuccess = createSelector([selectUser], user => user.addUserSuccess);

export const selectAddUserError = createSelector([selectUser], user => user.addUserError);

export const selectAddUserConfig = createSelector([selectUser], user => user.addUserConfig);

export const selectAddUserConfigIsLoading = createSelector([selectUser], user => user.isAddUserConfigLoading);

export const selectAddUserConfigError = createSelector([selectUser], user => user.addUserConfigError);

export const selectUsePlayerTimezone = createSelector([selectUser], user => user.usePlayerTimezone);

export const selectShowPlayerFinishedTime = createSelector([selectUser], user => user.showExerciseFinishedTime);

export const selectResetUserPasswordLoading = createSelector([selectUser], user => user.resetUserPasswordLoading);

export const selectResetUserPasswordSuccess = createSelector([selectUser], user => user.resetUserPasswordSuccess);

export const selectResetUserPasswordError = createSelector([selectUser], user => user.resetUserPasswordError);