import { BodyParts, IBodyPartGroup, Side, convertRemoteBodyPart, getWorstBodyPartStandard } from '../assessment/body-parts.types';
import { COMPLETE_PLAYER_PROGRAM, CompliancePoint, GET_PLAYER, GET_PLAYER_COMPLIANCE_HISTORY, ICompletePlayerProgram, IGetPlayer, IPlayer, IPlayerGetComplianceHistory, IPlayerMarkInjuryStatus, IRestartPlayerProgram, Injury, MARK_PLAYER_INJURY_STATUS, Player, RESTART_PLAYER_PROGRAM, Status, StatusColor } from './player.types';
import { CorrectiveSetProgramRemote, convertAllCorrectivesRemoteData, getCorrectivesProgram } from '../correctives/correctives.sagas';
import { GET_CORRECTIVE_PROGRAM, LoadableCorrectiveType } from '../correctives/correctives.types';
import { IAllAssessmentProgramsRemote, IAssessmentProgramRemote, ISingleAssessmentProgramRemote, LoadableAssessmentProgramRemoteType, convertAllAssessmentProgramsRemoteData, convertSingleAssessmentProgramsRemoteData } from '../assessments/assessments.sagas';
import { PrivateRemoteData, isUserTokenValid } from '../user/user.sagas';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { completePlayerProgram, createInjury, getMembersComplianceHistory, getPlayer as getPlayerAPI, restartPlayerProgram, updateInjury } from '../../services/PMotionApi';
import {setAllAssessmentPrograms, setLoading as setAssessmentsLoading} from '../assessments/assessments.actions';
import { setLoading, setPlayer, setPlayerComplianceHistory, setProgramFailure, setProgramSuccess, showError, showPlayerComplianceHistoryError } from './player.actions';

import { Colors } from '../../utils/style';
import { IDType } from '../core.types';
import { LoadableProgramType } from '../assessments/assessments.types';
import { selectOrgMembers } from '../org/org.selectors';
import {setCorrectives} from '../correctives/correctives.actions';
import { setOrgMembersCompliance } from '../org/org.actions';
import { isNumber } from '../../utils/validator';

export interface IPlayerRemote extends IPlayer {
	programs?:CorrectiveSetProgramRemote[];
	assessments?:LoadableAssessmentProgramRemoteType[];
	assessmentGroupStandards?:RemoteBodyGroup[];
}

export interface RemoteBodyGroup extends Omit<IBodyPartGroup, 'innerBodyParts'> {
}

export function* getPlayer({payload}:IGetPlayer) {
	yield put(
		setLoading(true)
	);
	if(payload.assessments){
		yield put(
			setAssessmentsLoading(true)
		);
	}

	if(payload.correctives){
		yield getCorrectivesProgram({
			type: GET_CORRECTIVE_PROGRAM,
			payload: {
				playerId: payload.id,
				isProgramDayNew: payload.isProgramDayNew,
				token: payload.token
			}
		});
	} else {
		// get info
		try {
			const playerInfoData = yield getPlayerAPI(payload);
			const playerInfo:IPlayerRemote = playerInfoData?.data as IPlayerRemote;
			const valid = yield isUserTokenValid(playerInfo, payload.token);
			if( valid ){
				if( playerInfo ){
				
					const {programs:correctivesInfo, assessments:assessmentsInfo, ...otherPlayerInfo} = playerInfo;

					const currentMembers:IPlayer[] | undefined = yield select(selectOrgMembers);
					const oldPlayerData = currentMembers ? currentMembers.find(({id}) => id===payload.id) : undefined;
					
					const player:IPlayer = convertPlayerRemoteData({
						...otherPlayerInfo,
						id: payload.id /* Test for now, set ID for testing */
					}, oldPlayerData);
					
					const correctives:LoadableCorrectiveType[] | undefined = (payload.correctives && correctivesInfo) ? convertAllCorrectivesRemoteData(correctivesInfo) : undefined;
	
					let assessments:LoadableProgramType[] | undefined;
					if( payload.assessments && assessmentsInfo ){
						const assessmentProgramsInfo:IAllAssessmentProgramsRemote = playerInfo as IAllAssessmentProgramsRemote;
						if( assessmentProgramsInfo && assessmentProgramsInfo.assessments && Array.isArray(assessmentProgramsInfo.assessments)){
							const firstPrg = assessmentProgramsInfo.assessments.length ? assessmentProgramsInfo.assessments[0] : undefined;
							const hasDays = firstPrg && firstPrg.hasOwnProperty('days') && Array.isArray((firstPrg as IAssessmentProgramRemote).days);
							assessments = hasDays ? convertAllAssessmentProgramsRemoteData(assessmentProgramsInfo.assessments as LoadableAssessmentProgramRemoteType[]) : convertSingleAssessmentProgramsRemoteData(assessmentProgramsInfo as ISingleAssessmentProgramRemote);
						}
					}
	
					yield put(
						setPlayer(player)
					);

					if(currentMembers && oldPlayerData){
						yield put(
							setOrgMembersCompliance(
								currentMembers.map((oldPlayer) => {
									return oldPlayer.id===player.id ? player : oldPlayer
								})
							)
						);
					}
	
					if( correctives ){
						
						yield put(
							setCorrectives({
								correctives,
								playerId: player.id
							})
						);
					}
	
					if( assessments ){
						
						yield put(
							setAllAssessmentPrograms({
								programs: assessments,
								playerId: player.id
							})
						);
					}
				} else {
					throw new Error('Player API returned, but cannot read data.')
				}
			}
		} catch(er){
			yield put(
				showError('Get player data failed. '+er.message)
			);
		}
	}

	if(payload.assessments){
		yield put(
			setAssessmentsLoading(false)
		);
	}

	yield put(
		setLoading(false)
	);
    
}

const parseRemoteStandards = ({
	assessmentGroupStandards, 
	leftNeckStandard,
	rightNeckStandard,
	leftTorsoStandard,
	rightTorsoStandard,
	leftShoulderStandard,
	rightShoulderStandard,
	leftArmStandard,
	rightArmStandard,
	leftHipStandard,
	rightHipStandard,
	leftLegStandard,
	rightLegStandard,
	leftFootStandard,
	rightFootStandard,
	...memberdata
}:IPlayerRemote):IPlayerRemote => {

	if(assessmentGroupStandards){
		const standard = (part:BodyParts, side:Side) => {
			const group = assessmentGroupStandards.find(({key}) => convertRemoteBodyPart(key)===part);
			if( group ){
				switch( side ){
					case Side.left :
						return group.leftStandard;
					case Side.right :
						return group.rightStandard;
					case Side.center :
						return group.centerStandard;
					case Side.bilateral :
						return getWorstBodyPartStandard(group, true);
					case Side.all :
						return getWorstBodyPartStandard(group);
				}
			}
			return undefined;
		}

		leftNeckStandard = standard(BodyParts.Neck, Side.left);
		rightNeckStandard = standard(BodyParts.Neck, Side.right);
		leftTorsoStandard = standard(BodyParts.Spine, Side.left);
		rightTorsoStandard = standard(BodyParts.Spine, Side.right);
		leftShoulderStandard = standard(BodyParts.Shoulder, Side.left);
		rightShoulderStandard = standard(BodyParts.Shoulder, Side.right);
		leftArmStandard = standard(BodyParts.Arm, Side.left);
		rightArmStandard = standard(BodyParts.Arm, Side.right);
		leftHipStandard = standard(BodyParts.Hip, Side.left);
		rightHipStandard = standard(BodyParts.Hip, Side.right);
		leftLegStandard = standard(BodyParts.Leg, Side.left);
		rightLegStandard = standard(BodyParts.Leg, Side.right);
		leftFootStandard = standard(BodyParts.Foot, Side.left);
		rightFootStandard = standard(BodyParts.Foot, Side.right);
	}
	return {
		leftNeckStandard,
		rightNeckStandard,
		leftTorsoStandard,
		rightTorsoStandard,
		leftShoulderStandard,
		rightShoulderStandard,
		leftArmStandard,
		rightArmStandard,
		leftHipStandard,
		rightHipStandard,
		leftLegStandard,
		rightLegStandard,
		leftFootStandard,
		rightFootStandard,
		...memberdata
	}
}

export const convertRemoteComplianceColor = (complianceColor:StatusColor | string):string | undefined => {
	let realComplianceColor:string | undefined;
	switch(complianceColor){
		case StatusColor.Red :
			realComplianceColor = Colors.RedDark;
			break;
		case StatusColor.Yellow :
			realComplianceColor = Colors.Gold;
			break;
		case StatusColor.Green :
			realComplianceColor = Colors.Green;
			break;
		case StatusColor.White :
			realComplianceColor = Colors.White;
			break;
		case StatusColor.Gray :
			realComplianceColor = Colors.GrayDark;
			break;
	}
	return realComplianceColor;
}

export const convertPlayerRemoteData = ({programs, status, ...memberdata}:IPlayerRemote, currentPlayerData?:IPlayer):Player => {
	const currentData = currentPlayerData ? currentPlayerData : {};
	memberdata = parseRemoteStandards({
		...currentData,
		...memberdata
	});
	status = status ? status : Status.NeedAssessment;
	const player = Player.create({
		...currentData,
		...memberdata,
		status
	});
	return player;
}

export function* restartProgramForPlayer({ payload:{player, token} }:IRestartPlayerProgram) {
	try {
		const saveData = yield restartPlayerProgram( player.id, token );
		const valid = yield isUserTokenValid(saveData.data, token);
		if( valid ){
			if(saveData && saveData.data && saveData.data===true){
				yield put(
					setProgramSuccess()
				);
			} else {
				throw new Error('Error in request to Restart Program.');
			}
		}
    } catch(error) {
		yield put(setProgramFailure(error.message));
    }
}

export function* completeProgramForPlayer({ payload:{player, token} }:ICompletePlayerProgram) {
	try {
		const saveData = yield completePlayerProgram( player.id, token );
		const valid = yield isUserTokenValid(saveData.data, token);
		if( valid ){
			if(saveData && saveData.data && saveData.data===true){
				yield put(
					setProgramSuccess()
				);
			} else {
				throw new Error('Error in request to Complete Program.');
			}
		}

    } catch(error) {
		yield put(setProgramFailure(error.message));
    }
}

type InjuryResponse = object & {
	data?:Injury | boolean;
}
export function* markInjuryForPlayer({ payload:{player, type, daysLength, info, date, injuryId, token} }:IPlayerMarkInjuryStatus) {
	try {
		const saveData:InjuryResponse = (injuryId!==undefined) ? yield updateInjury(injuryId, type, daysLength, token, info, date) : yield createInjury(player.id, type, daysLength, token, info, date);
		
		const valid:boolean | string = saveData.data ? yield isUserTokenValid(saveData.data, token) : false;
		if(valid){
			yield put(
				setProgramSuccess()
			);
		} else {
			throw new Error(`Error in request to ${(injuryId!==undefined) ? 'updateInjury' : 'createInjury'} API.`);
		}

    } catch(error) {
		yield put(setProgramFailure(error.message));
    }
}

type RemoteComplianceHistory = {
	memberId:IDType;
    memberProgramId:IDType;
    firstExerciseDate?:string | null;
    details:CompliancePoint[];
	complianceGreenMin?:number | null;
	complianceYellowMin?:number | null;
}
export function* getPlayerComplianceDetails({payload}:IPlayerGetComplianceHistory) {
	// get info
	try {
		const {player, maxDays, token} = payload;
		type RemoteResponse = PrivateRemoteData & {
			data?:RemoteComplianceHistory;
			errorCode?:string;
			message?:string;
		}
		const complianceInfoData:RemoteResponse = yield getMembersComplianceHistory(player.id, maxDays, token);
		const complianceInfo = complianceInfoData.data;
		
		const valid:boolean | string = complianceInfo ? yield isUserTokenValid(complianceInfo, payload.token) : false;
		if( valid ){
			if( complianceInfo && complianceInfo.details && Array.isArray(complianceInfo.details) ){
			
				const chartData:CompliancePoint[] = complianceInfo.details.map(({color, ...data}) => ({
					...data,
					color: color ? (convertRemoteComplianceColor(color) || color) : color,
					colorOriginal: color
				}));
				
				yield put(
					setPlayerComplianceHistory({
						history: chartData,
						complianceGreenMin: isNumber(complianceInfo.complianceGreenMin) ? complianceInfo.complianceGreenMin as number : undefined,
						complianceYellowMin: isNumber(complianceInfo.complianceYellowMin) ? complianceInfo.complianceYellowMin as number : undefined
					})
				);
				
			} else {
				throw new Error('Compliance History API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showPlayerComplianceHistoryError('Getting compliance data failed. '+er.message)
		);
	}
    
}

export function* onGetPlayer() {
    yield takeLatest(GET_PLAYER, getPlayer);
}

export function* onRestartProgram() {
    yield takeLatest(RESTART_PLAYER_PROGRAM, restartProgramForPlayer);
}

export function* onCompleteProgram() {
    yield takeLatest(COMPLETE_PLAYER_PROGRAM, completeProgramForPlayer);
}

export function* onMarkInjury() {
    yield takeLatest(MARK_PLAYER_INJURY_STATUS, markInjuryForPlayer);
}

export function* onGetPlayerComplianceHistory() {
    yield takeLatest(GET_PLAYER_COMPLIANCE_HISTORY, getPlayerComplianceDetails);
}

export function* playerSagas() {
    yield all([
		call( onGetPlayer ),
		call( onRestartProgram ),
		call( onCompleteProgram ),
		call( onMarkInjury ),
		call( onGetPlayerComplianceHistory )
    ]);
}