import { AssessmentFlowType, AssessmentType, IAssessmentSave, IAssessmentSubmit } from '../redux/assessment/assessment.types';
import { BASE_PATH, ORGANIZATION, USE_DEV, VERSION } from '../utils/PMotionGlobals';
import { CorrectiveExerciseStatus, IGetCorrectivePayload } from '../redux/correctives/correctives.types';
import { IChangePasswordPayload, IEmailSignInStartPayload, IResetPasswordPayload, ITempPasswordPayload } from '../redux/user/user.types';
import { IGetPlayerPayload, InjuryType } from '../redux/player/player.types';
import { RemoteAddUser, RemoteUser } from '../redux/user/user.sagas';
import { ReportFileType, ReportTableType } from '../redux/report/report.types';

import { GetOrgRemote } from '../redux/org/org.sagas';
import { IDType } from '../redux/core.types';
import { IGetPlayerAssessmentPayload } from '../redux/assessments/assessments.types';
import { LimitedSide } from '../redux/assessment/body-parts.types';
// a library to wrap and simplify api calls
import apisauce from 'apisauce';
import moment from 'moment';

const isDev = USE_DEV;
export const DEV_API_BASE = (process.env.NODE_ENV === 'development') ? `/${ORGANIZATION}` : `${BASE_PATH}/${ORGANIZATION}`;
const contentVersion = 'V2';

// ------
// STEP 1
// ------
//
// Create and configure an apisauce-based api object.
//
// Static json data
const api = apisauce.create({
	// base URL is read from the "constructor"
	baseURL: `${DEV_API_BASE}/data/`,
	// here are some default headers
	headers: {
		'Cache-Control': 'no-cache'
	},
	// 10 second timeout...
	timeout: 10000
});
// Live API data
const api2 = apisauce.create({
	// base URL is read from the "constructor"
	baseURL: `/api/`,
	// here are some default headers
	headers: {
		'Cache-Control': 'no-cache',
		'Accept': 'application/json'
	},
	// 60 second timeout...
	timeout: 60000
});
// Markdown content
const apiMarkdown = apisauce.create({
	// base URL is read from the "constructor"
	baseURL: isDev ? `/` : ``,
	// here are some default headers
	headers: {
		'Cache-Control': 'no-cache',
		'Accept': 'text/html'
	},
	// 10 second timeout...
	timeout: 10000
});

type AxiosGet = Parameters<typeof api2.get>;
type AxiosRequestConfig = AxiosGet[2];

// ------
// STEP 2
// ------
//
// Define some functions that call the api.  The goal is to provide
// a thin wrapper of the api layer providing nicer feeling functions
// rather than "get", "post" and friends.
//
// I generally don't like wrapping the output at this level because
// sometimes specific actions need to be take on `403` or `401`, etc.
//
// Since we can't hide from that, we embrace it by getting out of the
// way at this level.
//
const devJSONReturnTrue = 'submit-assessments.json';
const EXCERPT_LENGTH = 110;

const tokenConfig = (token:string, otherHeaders?:object) => ({ 
	headers: {
		...otherHeaders,
		'Authorization': 'Bearer ' + token
	}
});

export const signInUserWithEmail = isDev ? (payload:IEmailSignInStartPayload) => api.get('user.json', payload) : 
		({email, password}:IEmailSignInStartPayload) => {
			const formData = new FormData();
			formData.append('login', email);
			formData.append('password', password);
			formData.append('version', VERSION);
			return api2.post('login', formData);
		};

export const signOutUser = (token:string) => isDev ? api.get(devJSONReturnTrue) : api2.post('logout', undefined, tokenConfig(token));

export const acceptUserTerms = isDev ? (token:string) => api.get(devJSONReturnTrue) : (token:string) => {
	const formData = new FormData();
	formData.append('agree', 'true');
	return api2.post('user/termsofuse', formData, tokenConfig(token));
};

export const extendUserSession = (token:string) => api2.post('extendsession', undefined, tokenConfig(token));

export const changeUserPassword =  isDev ? ({userId, oldPassword, newPassword, token}:IChangePasswordPayload) => api.get('user-change-password.json', {userId, oldPassword, newPassword}, tokenConfig(token)) : 
	({userId, oldPassword, newPassword, token}:IChangePasswordPayload) => {
		const formData = new FormData();
		formData.append('login', userId);
		formData.append('password', oldPassword);
		formData.append('newPassword', newPassword);
		return api2.post('changePassword', formData, tokenConfig(token));
	};

export const forgotUserPassword =  isDev ? (emailAddress:string) => api.get(devJSONReturnTrue, {emailAddress}) : 
									(emailAddress:string) => {
										const formData = new FormData();
										formData.append('login', emailAddress);
										return api2.post('forgotpassword', formData);
									};

export const resetUserPassword =  isDev ? ({userId, resetCode, newPassword}:IResetPasswordPayload) => api.get('reset-password.json', {userId, resetCode, newPassword}) : 
									({userId, resetCode, newPassword}:IResetPasswordPayload) => {
										const formData = new FormData();
										formData.append('login', userId);
										formData.append('resetCode', resetCode);
										formData.append('newPassword', newPassword);
										return api2.post('resetpassword', formData);
									};

type TempPasswordPayload = Omit<ITempPasswordPayload, 'isTokenFormat'>;
export const changeTempUserToken =  isDev ? ({userId, tempPassword, newPassword}:TempPasswordPayload) => api.get('reset-password.json', {userId, tempPassword, newPassword}) : 
									({userId, tempPassword, newPassword}:TempPasswordPayload) => {
										const formData = new FormData();
										formData.append('login', userId);
										formData.append('password', tempPassword);
										formData.append('newPassword', newPassword);
										return api2.post('changetemporarypassword', formData);
									};

export const changeTempUserPassword =  isDev ? ({userId, tempPassword, newPassword}:TempPasswordPayload) => api.get('change-temp-password.json', {userId, tempPassword, newPassword}) : 
									({userId, tempPassword, newPassword}:TempPasswordPayload) => {
										const formData = new FormData();
										formData.append('login', userId);
										formData.append('password', tempPassword);
										formData.append('newPassword', newPassword);
										return api2.post('password/changetemp', formData);
									};

export const updateTempUserPassword =  isDev ? (userId:IDType, newPassword:string, token:string, sendEmail?:boolean) => api.get(devJSONReturnTrue, {userId, newPassword, token, sendEmail}) : 
									(userId:IDType, newPassword:string, token:string, sendEmail?:boolean) => {
										const formData = new URLSearchParams();
										formData.append('password', newPassword);
										if(sendEmail){
											formData.append('sendEmail', 'true');
										}
										return api2.post(isDev ? devJSONReturnTrue : `user/${userId}/updatetemppassword`, formData, tokenConfig(token, {
											'Content-Type': 'application/x-www-form-urlencoded' // https://gist.github.com/akexorcist/ea93ee47d39cf94e77802bc39c46589b
										}));
									};

export const getOrgUI = (orgJSONKey:string) => api.get('team.json', {orgJSONKey}, {
	baseURL: `${BASE_PATH}/${orgJSONKey}/data/`
});

export const getOrg = isDev ? (orgId:IDType, token:string) => api.get('org-get.json', {orgId}, tokenConfig(token)) 
							: (orgId:IDType, token:string) => api2.get(`org/${orgId}`, undefined, tokenConfig(token));

export enum GetActiveInclude {
	Risk = 'risk',
	PScore = 'pscore'
}
export const getOrgMembers = isDev ? (orgId:IDType, token:string, includes?:GetActiveInclude[]) => api.get('team-members.json', {orgId, include: (includes && includes.length) ? includes.join(',') : undefined}, tokenConfig(token)) 
									: (orgId:IDType, token:string, includes?:GetActiveInclude[]) => {
										const config:AxiosRequestConfig = tokenConfig(token);
										if(includes && includes.includes(GetActiveInclude.Risk) && includes.includes(GetActiveInclude.PScore)){
											// extend timeout when including risk and pscore
											config.timeout = 15000;
										}
										return api2.get('member/getactive', {orgId, include: (includes && includes.length) ? includes.join(',') : undefined}, config);
									}

export const getPendingOrgMembers = isDev ? (orgId:IDType, token:string, includes?:GetActiveInclude[]) => api.get('team-members.json', {orgId, include: (includes && includes.length) ? includes.join(',') : undefined}, tokenConfig(token)) 
									: (orgId:IDType, token:string, includes?:GetActiveInclude[]) => {
										const config:AxiosRequestConfig = tokenConfig(token);
										if(includes && includes.includes(GetActiveInclude.Risk) && includes.includes(GetActiveInclude.PScore)){
											// extend timeout when including risk and pscore
											config.timeout = 15000;
										}
										return api2.get('member/getpending', {orgId, include: (includes && includes.length) ? includes.join(',') : undefined}, config);
									}

export const getOrgMembersCompliance = isDev ? (orgId:IDType, token:string) => api.get('team-members-compliance.json', {orgId}, tokenConfig(token)) 
									: (orgId:IDType, token:string) => api2.get('member/compliance', {orgId}, tokenConfig(token));
									
export const getOrgPeriodsAPI = isDev ? (orgId:IDType, token:string) => api.get('org-periods.json', {orgId}, tokenConfig(token)) 
									: (orgId:IDType, token:string) => api2.get(`org/${orgId}/periods`, undefined, tokenConfig(token));

export const getMembersComplianceHistory = isDev ? (memberId:IDType, maxDays:number, token:string) => api.get('user-compliance-history.json', {memberId, maxDays}, tokenConfig(token)) 
									: (memberId:IDType, maxDays:number, token:string) => api2.get('member/compliance/history', {memberId, maxDays}, tokenConfig(token));

export const getAllPlayerCorrectives = isDev ? (memberId:IDType, token:string, isProgramDayNew?:boolean) => api.get('player-correctives.json', {memberId, isProgramDayNew, contentVersion}, tokenConfig(token)) 
									: (memberId:IDType, token:string, isProgramDayNew?:boolean) => api2.get('member/getactiveprogram', isProgramDayNew ? {memberId, isProgramDayNew, contentVersion} : {memberId, contentVersion}, tokenConfig(token));

export const getPlayerCorrectivesSet = isDev ? (memberId:IDType, token:string, isProgramDayNew?:boolean) => api.get('playerAndCorrectivesApp.json', {memberId, isProgramDayNew, contentVersion}, tokenConfig(token))
									: (memberId:IDType, token:string, isProgramDayNew?:boolean) => api2.get('member/getactiveprogramgroups', isProgramDayNew ? {memberId, isProgramDayNew, contentVersion} : {memberId, contentVersion}, tokenConfig(token));

export const getPlayerCorrectivesProgram = isDev ? (memberId:IDType, token:string, isProgramDayNew?:boolean) => api.get('playerAndCorrectivesApp.json', {memberId, isProgramDayNew, contentVersion}, tokenConfig(token))
									: (memberId:IDType, token:string, isProgramDayNew?:boolean) => api2.get('member/getactiveprogramgroups', isProgramDayNew ? {memberId, isProgramDayNew, returnAllDays: true, contentVersion} : {memberId, returnAllDays: true, contentVersion}, tokenConfig(token));

export const startPlayerCorrectives = isDev ? (memberId:IDType, token:string) => api.get(devJSONReturnTrue, {memberId}, tokenConfig(token))
									: (memberId:IDType, token:string) => api2.get('member/program/start', {memberId}, tokenConfig(token));
									
export const updateCorrectiveExerciseStatus = isDev ? (programexerciseId:IDType, status:CorrectiveExerciseStatus, token:string) => api.get(devJSONReturnTrue, {programexerciseId, status}, tokenConfig(token))
									: (programexerciseId:IDType, status:CorrectiveExerciseStatus, token:string) => api2.get('member/program/updateexercisestatus', {programexerciseId, status}, tokenConfig(token));

export const updateCorrectiveExerciseStatusWithResponse = isDev ? (programexerciseId:IDType, status:CorrectiveExerciseStatus, token:string) => api.get('updateExerciseStatusWithResponse.json', {programexerciseId, status}, tokenConfig(token)) :
									(programexerciseId:IDType, status:CorrectiveExerciseStatus, token:string) => api2.get('member/program/updateexercisestatuswithresponse', {programexerciseId, status}, tokenConfig(token));

export const updateCorrectiveExerciseResponse = isDev ? (programExerciseId:IDType, responseId:IDType, token:string, message?:string) => api.get('updateExerciseStatusWithResponse.json', {programExerciseId, responseId}, tokenConfig(token)) :
									(programExerciseId:IDType, responseId:IDType, token:string, message?:string) => {
										return api2.post('member/program/updatememberresponse', {
											programExerciseId: programExerciseId,
											configDetailId: responseId,
											customMessage: message
										}, tokenConfig(token));
									};
									
export const getAllPlayerAssessments = isDev ? (memberId:IDType, token:string) => api.get('player-assessments.json', {memberId}, tokenConfig(token)) 
									: (memberId:IDType, token:string) => api2.get('assessment/getprocessed', {memberId}, tokenConfig(token));

export const getPlayer = (payload:IGetPlayerPayload) => {
	if( !isDev && payload.correctives ){
		return getAllPlayerCorrectives(payload.id, payload.token, payload.isProgramDayNew);
	} else if( !isDev && payload.assessments ){
		return getAllPlayerAssessments(payload.id, payload.token);
	} else {
		const endpoint:string = (payload.correctives) ? 'playerAndCorrectives.json' : (payload.assessments) ? 'playerAndAssessments.json' : 'player.json';
		return api.get(endpoint, payload);
	}
}

export const restartPlayerProgram = isDev ? (memberId:IDType, token:string) => api.get(devJSONReturnTrue, {memberId}, tokenConfig(token)) 
									: (memberId:IDType, token:string) => api2.get('member/program/restart', {memberId}, tokenConfig(token));

export const completePlayerProgram = isDev ? (memberId:IDType, token:string) => api.get(devJSONReturnTrue, {memberId}, tokenConfig(token)) 
									: (memberId:IDType, token:string) => api2.get('member/program/complete', {memberId}, tokenConfig(token));

export const getExercise = isDev ? (exerciseId:IDType, token:string) => api.get(`exercise_${exerciseId}.json`, {exerciseId, contentVersion}, tokenConfig(token)) 
								: (exerciseId:IDType, token:string) => api2.get('member/getexercisedetails', {exerciseId, contentVersion}, tokenConfig(token));

export const getPlayerCorrective = (payload:IGetCorrectivePayload) => api.get(`corrective${payload.id}.json`, payload);

export const getPlayerAssessment = (payload:IGetPlayerAssessmentPayload) => api.get('assessment-program1.json', payload);

export const getAssessmentMeasurements = isDev ? (playerId:IDType, token:string) => api.get('measurements.json', {memberId: playerId}, tokenConfig(token)) 
											: (playerId:IDType, token:string) => api2.get('assessment/measurements', {memberId: playerId}, tokenConfig(token));

export const getNewAssessment = isDev ? (playerIds:IDType[], token:string, type?:AssessmentType, measurementIds?:IDType[], flowType?:AssessmentFlowType) => api.get('assessment.json', {memberIds: playerIds.join(',')}, tokenConfig(token)) 
									: (playerIds:IDType[], token:string, type?:AssessmentType, measurementIds?:IDType[], flowType?:AssessmentFlowType) => api2.get('assessment/start', {memberIds: playerIds.join(','), type: type, measurementIds: measurementIds ? measurementIds.join(',') : undefined, flow: flowType}, tokenConfig(token));

export const autoSaveAssessment = isDev ? (assessment:IAssessmentSave, token:string) => api.get(devJSONReturnTrue, {payload: JSON.stringify(assessment)}, tokenConfig(token))
										: (assessment:IAssessmentSave, token:string) => api2.post('assessment/updatevalues', assessment, tokenConfig(token));


export const submitAssessment = isDev ? (assessment:IAssessmentSubmit, token:string) => api.get(devJSONReturnTrue, {payload: JSON.stringify(assessment)}, tokenConfig(token))
										: (assessment:IAssessmentSubmit, token:string) => api2.post('assessment/submit', assessment, tokenConfig(token));

export const deleteAssessment = isDev ? (assessmentId:IDType, token:string) => api.get('assessment-deleted.json', {assessmentId}, tokenConfig(token))
										: (assessmentId:IDType, token:string) => api2.get('assessment/delete', {assessmentId}, tokenConfig(token));

export const getOrgRoles =  isDev ? (token:string) => api.get('org-roles.json', undefined, tokenConfig(token))
									: (token:string) => api2.get('role', undefined, tokenConfig(token));

export const getAllOrgs =  isDev ? (token:string) => api.get('orgs-all.json', undefined, tokenConfig(token))
									: (token:string) => api2.get('org', undefined, tokenConfig(token));

export const getTimezoneList =  isDev ? (token:string) => api.get('timezones.json', undefined, tokenConfig(token))
									: (token:string) => api2.get('timezone', undefined, tokenConfig(token));





export const getAllMessageThreads = isDev ? (userId:IDType, token:string) => api.get(`message-threads-all.json`, {userId}, tokenConfig(token))
										: (userId:IDType, token:string) => api2.get('messaging/getthreads', {userId, minMessageCount: 1, excerptLength: EXCERPT_LENGTH}, tokenConfig(token));

export const getNewMessageThreadCount = isDev ? (userId:IDType, token:string) => api.get(`message-new-threads-count.json`, {userId}, tokenConfig(token))
											: (userId:IDType, token:string) => api2.get('messaging/getunreadcount', {userId}, tokenConfig(token));

export const getMessageThread = isDev ? (threadId:IDType, userId:IDType, token:string) => api.get(`message-thread-${threadId}.json`, {threadId, userId}, tokenConfig(token))
								: (threadId:IDType, userId:IDType, token:string) => api2.get('messaging/getmessages', {threadId, userId}, tokenConfig(token));

export const getMessageThreadByMembers = isDev ? (requesterUserId:IDType, orgKey:string, userIds:IDType[], token:string) => api.get(`message-thread-54001.json`)
										: (requesterUserId:IDType, orgKey:string, userIds:IDType[], token:string) => api2.get('messaging/getthread', {userId: requesterUserId, orgKey, userIdCSV: userIds.join(','), excerptLength: EXCERPT_LENGTH}, tokenConfig(token));

export const sendMessage = isDev ? (threadId:IDType, authorId:IDType, content:string, token:string) => api.get(devJSONReturnTrue) : 
							(threadId:IDType, authorId:IDType, content:string, token:string) => {
								const formData = new FormData();
								formData.append('threadId', `${threadId}`);
								formData.append('userId', `${authorId}`);
								formData.append('content ', content);
								return api2.post('messaging/addmessage', formData, tokenConfig(token));
							};

export const markMessageAsRead = isDev ? (messageIds:IDType[], userId:IDType, token:string) => api.get(devJSONReturnTrue) : 
								(messageIds:IDType[], userId:IDType, token:string) => {
									const formData = new FormData();
									formData.append('messageId', `${messageIds.join(',')}`);
									formData.append('userId', `${userId}`);
									return api2.post('messaging/markasread', formData, tokenConfig(token));
								};






// Management APIs
export const getOrgMembersManagement = isDev ? (orgId:IDType, token:string, includes?:GetActiveInclude[]) => api.get('team-members-management.json', {orgId, include: (includes && includes.length) ? includes.join(',') : undefined}, tokenConfig(token)) 
										: (orgId:IDType, token:string, includes?:GetActiveInclude[]) => {
											const config:AxiosRequestConfig = tokenConfig(token);
											if(includes && includes.includes(GetActiveInclude.Risk) && includes.includes(GetActiveInclude.PScore)){
												// extend timeout when including risk and pscore
												config.timeout = 15000;
											}
											return api2.get('member/getactive', {orgId, include: (includes && includes.length) ? includes.join(',') : undefined}, config);
										}

export const getOrgUsers = isDev ? (orgId:IDType, token:string) => api.get('users.json', {orgId}, tokenConfig(token)) 
								: (orgId:IDType, token:string) => api2.get(`org/${orgId}/users`, undefined, tokenConfig(token));

export const getOrgUser = isDev ? (userId:IDType, token:string) => api.get('user-get.json', {userId}, tokenConfig(token)) 
								: (userId:IDType, token:string) => api2.get(`user/${userId}`, undefined, tokenConfig(token));

export const editOrgUser = isDev ? (remoteUserData:RemoteUser, token:string) => api.get(devJSONReturnTrue, remoteUserData)
								: (remoteUserData:RemoteUser, token:string) => api2.put('user', remoteUserData, tokenConfig(token));

export const addOrgUser = isDev ? (remoteUserData:RemoteAddUser, token:string) => api.get('user-get.json', remoteUserData)
								: (remoteUserData:RemoteAddUser, token:string) => api2.post('user', remoteUserData, tokenConfig(token));

export const getAddOrgUserConfig = isDev ? (orgId:IDType, token:string) => api.get('add-user-config.json', {orgId}, tokenConfig(token))
								: (orgId:IDType, token:string) => api2.get('user/new', {orgId}, tokenConfig(token));

export const activateOrgUser = isDev ? (userId:IDType, token:string) => api.get(devJSONReturnTrue, {userId})
								: (userId:IDType, token:string) => api2.post(`user/${userId}/activate`, undefined, tokenConfig(token));

export const deactivateOrgUser = isDev ? (userId:IDType, token:string) => api.get(devJSONReturnTrue, {userId})
								: (userId:IDType, token:string) => api2.post(`user/${userId}/deactivate`, undefined, tokenConfig(token));

export const editOrg = isDev ? (remoteOrgData:GetOrgRemote, token:string) => api.get(devJSONReturnTrue, remoteOrgData)
						: (remoteOrgData:GetOrgRemote, token:string) => api2.put('org', remoteOrgData, tokenConfig(token));

export const getAllPlayerAssessmentsManagement = isDev ? (memberId:IDType, token:string) => api.get('player-assessments-management.json', {memberId}, tokenConfig(token)) 
												: (memberId:IDType, token:string) => api2.get('assessment/getprocessedgroups', {memberId}, tokenConfig(token));

export const getMeasurementInfo = isDev ? (memberId:IDType, measurementIds:IDType[], side:LimitedSide, token:string) => api.get('measurement-expanded-management.json', {memberId, measurementIds: measurementIds.join(','), side: side.toUpperCase()}, tokenConfig(token)) 
								: (memberId:IDType, measurementIds:IDType[], side:LimitedSide, token:string) => api2.get('measurement/getdetail', {memberId, measurementIds: measurementIds.join(','), side: side.toUpperCase()}, tokenConfig(token));

export const createInjury = isDev ? (memberId:IDType, type:InjuryType, days:number, token:string, details:string, date?:string) => api.get(devJSONReturnTrue) : 
								(memberId:IDType, type:InjuryType, days:number, token:string, details:string, date?:string) => {
									const formData = new FormData();
									formData.append('memberId', `${memberId}`);
									formData.append('type', type);
									formData.append('days', `${days}`);
									formData.append('details', details);
									if(date!==undefined){
										formData.append('date', date);
									}
									return api2.post('member/injury', formData, tokenConfig(token));
								};

export const updateInjury = isDev ? (injuryId:IDType, type:InjuryType, days:number, token:string, details:string, date?:string) => api.get(devJSONReturnTrue) : 
								(injuryId:IDType, type:InjuryType, days:number, token:string, details:string, date?:string) => {
									const formData = {
										id: `${injuryId}`,
										type,
										days,
										details,
										date
									};
									return api2.put('member/injury', formData, tokenConfig(token));
								};

// Reports
export const getReportAPI = isDev ? (orgIds:IDType[], reportType:ReportTableType, token:string) => {
		const reportPath = reportType ? reportType : 'overview';
		return api.get(`team-members-report-${reportPath}.json`, {orgIds: orgIds.join(','), token});
	} : (orgIds:IDType[], reportType:ReportTableType, token:string) => {
		return api2.get(`report/${reportType}/json`, {orgIds: orgIds.join(',')}, tokenConfig(token));
	}

export const getMemberReportAPI = isDev ? (memberId:IDType, token:string, report?:ReportTableType, start?:Date, end?:Date) => {
		const reportPath = report ? report : 'overview';
		const dateFormat = 'YYYY-MM-DD';
		const startDate = start ? moment(start).format(dateFormat) : undefined;
		const endDate = end ? moment(end).format(dateFormat) : undefined;
		return api.get(`team-member-report-${reportPath}.json`, {memberId, token, startDate, endDate});
	} : (memberId:IDType, token:string, report?:ReportTableType, start?:Date, end?:Date) => {
		const reportPath = report ? report : 'overview';
		const dateFormat = 'YYYY-MM-DD';
		const startDate = start ? moment(start).format(dateFormat) : undefined;
		const endDate = end ? moment(end).format(dateFormat) : undefined;
		return api2.get(`report/${reportPath}/member/json`, {memberId, startDate, endDate}, tokenConfig(token));
	}

export const downloadReportFile = isDev ? (orgIds:IDType[], reportType:ReportTableType, fileType:ReportFileType, token:string) => api.get(devJSONReturnTrue) : 
	(orgIds:IDType[], reportType:ReportTableType, fileType:ReportFileType, token:string) => {
		return api2.get(`report/${reportType}/${fileType}`, {orgIds: orgIds.join(',')}, {
			...tokenConfig(token),
			responseType: 'blob'
		});
	};

// Markdown Assets
export const getTermsDoc = (url:string) => apiMarkdown.get(url);

const pscoreLegendPath = isDev ? 'p-score-legend.md' : 'https://media.pmotiontech.com/p-score-legend.md';
export const getPScoreLegendDoc = () => apiMarkdown.get(`${pscoreLegendPath}?t=${Math.round(new Date().getTime() / 1000)}`);

const dashboardTooltipsPath = isDev ? 'dashboard-tooltips.md' : 'https://media.pmotiontech.com/dashboard-tooltips.md';
export const getDashboardTooltipsDoc = () => apiMarkdown.get(`${dashboardTooltipsPath}?t=${Math.round(new Date().getTime() / 1000)}`);