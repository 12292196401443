import {
    GET_ALL_CORRECTIVES,
    GET_CORRECTIVE,
    GET_CORRECTIVE_PROGRAM,
    GET_CORRECTIVE_SET,
    IGetAllCorrectives,
    IGetAllCorrectivesPayload,
    IGetCorrective,
    IGetCorrectivePayload,
    IGetCorrectiveProgram,
    IGetCorrectiveSet,
    IGetCorrectiveSetPayload,
    ISetAllCorrectives,
    ISetAllCorrectivesPayload,
    ISetCorrectivesDayIndex,
    ISetCorrectivesLoading,
    ISetCorrectivesProgram,
    ISetCorrectivesProgramPayload,
    ISetCorrectivesSet,
    ISetCorrectivesSetPayload,
    IShowAllCorrectivesError,
    IShowGetCorrectiveError,
    IShowGetCorrectiveErrorPayload,
    IUpdateCorrective,
    IUpdateCorrectivePayload,
    SET_ALL_CORRECTIVES,
    SET_CORRECTIVES_DAY_INDEX,
    SET_CORRECTIVES_LOADING,
    SET_CORRECTIVE_PROGRAM,
    SET_CORRECTIVE_SET,
    SHOW_ALL_CORRECTIVES_ERROR,
    SHOW_CORRECTIVE_ERROR,
    UPDATE_CORRECTIVE
} from './correctives.types';
import { ICorrectiveExercisePlan, ISetCorrectivesOrderPayload, ISetCorrectivesPlanExerciseOrder, ISetCorrectivesPlanOrder, ISetSelectedCorrectivesPlan, IStartCorrectives, IUpdateCorrectiveExerciseFeedback, IUpdateCorrectiveExerciseFeedbackPayload, IUpdateCorrectiveExerciseFeedbackSuccess, IUpdateCorrectiveExerciseStatus, IUpdateCorrectiveExerciseStatusPayload, IUpdateCorrectiveExerciseStatusSuccess, IUpdateCorrectiveExerciseStatusSuccessPayload, SET_CORRECTIVES_PLAN_EXERCISE_ORDER, SET_CORRECTIVES_PLAN_ORDER, SET_SELECTED_CORRECTIVES_PLAN, START_CORRECTIVES, UPDATE_CORRECTIVE_EXERCISE_FEEDBACK, UPDATE_CORRECTIVE_EXERCISE_FEEDBACK_SUCCESS, UPDATE_CORRECTIVE_EXERCISE_STATUS, UPDATE_CORRECTIVE_EXERCISE_STATUS_SUCCESS } from './correctives.types';

export const setLoading = (val:boolean):ISetCorrectivesLoading => ({
    type: SET_CORRECTIVES_LOADING,
    payload: val
});

export const getAllCorrectives = (payload:IGetAllCorrectivesPayload):IGetAllCorrectives => ({
    type: GET_ALL_CORRECTIVES,
    payload
});

export const showAllCorrectivesError = (error:string):IShowAllCorrectivesError => ({
    type: SHOW_ALL_CORRECTIVES_ERROR,
    payload: error
});

export const getCorrective = (config:IGetCorrectivePayload):IGetCorrective => ({
    type: GET_CORRECTIVE,
    payload: config
});

export const showGetCorrectiveError = (config:IShowGetCorrectiveErrorPayload):IShowGetCorrectiveError => ({
    type: SHOW_CORRECTIVE_ERROR,
    payload: config
});

export const updateCorrective = (config:IUpdateCorrectivePayload):IUpdateCorrective => ({
    type: UPDATE_CORRECTIVE,
    payload: config
});

export const setCorrectives = (config:ISetAllCorrectivesPayload):ISetAllCorrectives => ({
    type: SET_ALL_CORRECTIVES,
    payload: config
});

export const setCorrectivesDayIndex = (index:number):ISetCorrectivesDayIndex => ({
    type: SET_CORRECTIVES_DAY_INDEX,
    payload: index
});

export const getCorrectivesSet = (payload:IGetCorrectiveSetPayload):IGetCorrectiveSet => ({
    type: GET_CORRECTIVE_SET,
    payload
});

export const setCorrectivesSet = (config:ISetCorrectivesSetPayload):ISetCorrectivesSet => ({
    type: SET_CORRECTIVE_SET,
    payload: config
});

export const getCorrectivesProgram = (payload:IGetCorrectiveSetPayload):IGetCorrectiveProgram => ({
    type: GET_CORRECTIVE_PROGRAM,
    payload
});

export const setCorrectivesProgram = (payload:ISetCorrectivesProgramPayload):ISetCorrectivesProgram => ({
    type: SET_CORRECTIVE_PROGRAM,
    payload
});

export const startCorrectives = (payload:IGetCorrectiveSetPayload):IStartCorrectives => ({
    type: START_CORRECTIVES,
    payload
});
									
export const updateCorrectiveExerciseStatus = (config:IUpdateCorrectiveExerciseStatusPayload):IUpdateCorrectiveExerciseStatus => ({
    type: UPDATE_CORRECTIVE_EXERCISE_STATUS,
    payload: config
});

export const updateCorrectiveExerciseStatusSuccess = (config:IUpdateCorrectiveExerciseStatusSuccessPayload):IUpdateCorrectiveExerciseStatusSuccess => ({
    type: UPDATE_CORRECTIVE_EXERCISE_STATUS_SUCCESS,
    payload: config
});

export const updateCorrectiveExerciseFeedback = (payload?:IUpdateCorrectiveExerciseFeedbackPayload):IUpdateCorrectiveExerciseFeedback => ({
    type: UPDATE_CORRECTIVE_EXERCISE_FEEDBACK,
    payload
});

export const updateCorrectiveExerciseFeedbackSuccess = ():IUpdateCorrectiveExerciseFeedbackSuccess => ({
    type: UPDATE_CORRECTIVE_EXERCISE_FEEDBACK_SUCCESS
});

export const setCorrectivesPlanOrder = (payload:ISetCorrectivesOrderPayload):ISetCorrectivesPlanOrder => ({
    type: SET_CORRECTIVES_PLAN_ORDER,
    payload
});

export const setCorrectivesPlanExerciseOrder = (payload?:ISetCorrectivesOrderPayload):ISetCorrectivesPlanExerciseOrder => ({
    type: SET_CORRECTIVES_PLAN_EXERCISE_ORDER,
    payload
});

export const setSelectedCorrectivesPlan = (plan?:ICorrectiveExercisePlan):ISetSelectedCorrectivesPlan => ({
    type: SET_SELECTED_CORRECTIVES_PLAN,
    payload: plan
});