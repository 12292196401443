import { ISignOutSuccess } from '../user/user.types';
import { StatusColor } from '../player/player.types';

export const GET_PSCORE_LEGEND_CONTENT = 'GET_PSCORE_LEGEND_CONTENT';
export const SET_PSCORE_LEGEND_CONTENT = 'SET_PSCORE_LEGEND_CONTENT';

export const GET_DASH_TOOLTIPS_CONTENT = 'GET_DASH_TOOLTIPS_CONTENT';
export const SET_DASH_TOOLTIPS_CONTENT = 'SET_DASH_TOOLTIPS_CONTENT';

export const SHOW_CONTENT_ERROR = 'SHOW_CONTENT_ERROR';

// Data types
export enum DashboardTooltip {
	Started = 'tooltip-started',
	Compliance = 'tooltip-compliance',
	Progress = 'tooltip-progress',
	Status = 'tooltip-status'
}
export type DashboardTooltipSimpleContent = {
	title:string;
	titleBadgeColor?:StatusColor;
	body:string;
}
export type DashboardTooltipContent = {
	title:string;
	titleBadgeColor?:StatusColor;
	body:string | DashboardTooltipSimpleContent[];
}


// State type
export interface IContentState {
	isLoading:boolean;
	pscoreLegend?:string;
	dashboardTooltips?:Record<DashboardTooltip | string, DashboardTooltipContent | undefined>;
	error?:string;
}


// Action types
export interface IGetPscoreLegendContent {
	type: typeof GET_PSCORE_LEGEND_CONTENT;
};

export interface ISetPscoreLegendContent {
	type: typeof SET_PSCORE_LEGEND_CONTENT;
	payload: string;
};

export interface IGetDashboardTooltipsContent {
	type: typeof GET_DASH_TOOLTIPS_CONTENT;
};

export interface ISetDashboardTooltipsContent {
	type: typeof SET_DASH_TOOLTIPS_CONTENT;
	payload: Record<DashboardTooltip | string, DashboardTooltipContent | undefined> | undefined;
};

export interface IShowContentError {
	type: typeof SHOW_CONTENT_ERROR;
	payload: string;
};

export type TypeContentAction = ISignOutSuccess | IGetPscoreLegendContent | ISetPscoreLegendContent | IShowContentError | IGetDashboardTooltipsContent | ISetDashboardTooltipsContent;