import { IDType } from '../redux/core.types';
import Package from '../../package.json';
import { createRef } from 'react';
interface PMotion {
	organization?:string;
	organizationId?:IDType;
	rootPath?:string;
	basePath?:string;
	siteName?:string;
	isRoot?:boolean;
	useDev?:boolean;
}
declare global {
    interface Window { 
		PMotion:PMotion;
	}
}

const PMotion = window.PMotion;

export const ORGANIZATION = (PMotion.organization!==undefined) ? PMotion.organization : `${process.env.REACT_APP_ORG}`;
export const ORGANIZATION_ID = (PMotion.organizationId!==undefined) ? PMotion.organizationId : parseInt(`${process.env.REACT_APP_ORG_ID}`);
export const ROOT_PATH = (PMotion.rootPath!==undefined) ? PMotion.rootPath : `${process.env.PUBLIC_URL}`;
export const BASE_PATH = (PMotion.basePath!==undefined) ? PMotion.basePath : `${process.env.REACT_APP_BASE_PATH}`;
export const SITE_NAME = (PMotion.siteName!==undefined) ? PMotion.siteName : `${process.env.REACT_APP_SITE_NAME}`;
export const IS_ROOT = !!(PMotion.isRoot);
export const USE_DEV = (process.env.NODE_ENV === 'development') || (PMotion.useDev);
export const VERSION = Package.version;
export const TOKEN_EXTEND_TIME = 30 * 60; // 30 minutes in seconds - time before token expires to extend session

export const APP_DOWNLOAD_URL = 'https://apps.apple.com/us/app/pmotion/id1526724625';

export const isTouch = !!('ontouchstart' in window);
export const supportsCSSVars = !!('CSS' in window) && CSS.supports('color', 'var(--fake-var)'); // https://stackoverflow.com/questions/26633258/how-can-i-detect-css-variable-support-with-javascript
export const supportsSticky = !!('CSS' in window) && CSS.supports('position', 'sticky');

export const headerRef = createRef<HTMLDivElement>();
export const mainRef = createRef<HTMLDivElement>();

export default PMotion;