import { COMPLETE_PLAYER_PROGRAM, GET_PLAYER, GET_PLAYER_COMPLIANCE_HISTORY, ICompletePlayerProgram, IGetPlayer, IGetPlayerPayload, IPlayer, IPlayerGetComplianceHistory, IPlayerGetComplianceHistoryPayload, IPlayerMarkInjuryStatus, IPlayerMarkInjuryStatusPayload, IPlayerProgramFailure, IPlayerProgramSuccess, IRestartPlayerProgram, IRestartPlayerProgramPayload, ISetPlayer, ISetPlayerLoading, IShowPlayerError, MARK_PLAYER_INJURY_STATUS, PLAYER_PROGRAM_FAILURE, PLAYER_PROGRAM_SUCCESS, RESTART_PLAYER_PROGRAM, SET_PLAYER, SET_PLAYER_LOADING, SHOW_PLAYER_ERROR, SET_PLAYER_COMPLIANCE_HISTORY, IPlayerSetComplianceHistory, IPlayerShowComplianceHistoryError, SHOW_COMPLIANCE_HISTORY_ERROR, IPlayerSetComplianceHistoryPayload } from './player.types';

export const showError = (error:string):IShowPlayerError => ({
    type: SHOW_PLAYER_ERROR,
    payload: error
});

export const setLoading = (val:boolean):ISetPlayerLoading => ({
    type: SET_PLAYER_LOADING,
    payload: val
});

export const setPlayer = (member?:IPlayer):ISetPlayer => ({
    type: SET_PLAYER,
    payload: member
});

export const getPlayer = (config:IGetPlayerPayload):IGetPlayer => ({
    type: GET_PLAYER,
    payload: config
});

export const restartProgram = (payload:IRestartPlayerProgramPayload):IRestartPlayerProgram => ({
    type: RESTART_PLAYER_PROGRAM,
    payload
});

export const completeProgram = (payload:IRestartPlayerProgramPayload):ICompletePlayerProgram => ({
    type: COMPLETE_PLAYER_PROGRAM,
    payload
});

export const setProgramSuccess = ():IPlayerProgramSuccess => ({
    type: PLAYER_PROGRAM_SUCCESS
});

export const setProgramFailure = (error:string):IPlayerProgramFailure => ({
    type: PLAYER_PROGRAM_FAILURE,
    payload: error
});

export const markInjuryStatus = (payload:IPlayerMarkInjuryStatusPayload):IPlayerMarkInjuryStatus => ({
    type: MARK_PLAYER_INJURY_STATUS,
    payload
});

export const getPlayerComplianceHistory = (payload:IPlayerGetComplianceHistoryPayload):IPlayerGetComplianceHistory => ({
    type: GET_PLAYER_COMPLIANCE_HISTORY,
    payload
});

export const setPlayerComplianceHistory = (payload:IPlayerSetComplianceHistoryPayload):IPlayerSetComplianceHistory => ({
    type: SET_PLAYER_COMPLIANCE_HISTORY,
    payload
});

export const showPlayerComplianceHistoryError = (error:string):IPlayerShowComplianceHistoryError => ({
    type: SHOW_COMPLIANCE_HISTORY_ERROR,
    payload: error
});