import SrcMap from './src-map';
import logoBlackImage1 from '../assets/logo_bitmap_black.png';
import logoBlackImage2 from '../assets/logo_bitmap_black@2x.png';
import logoBlackImage3 from '../assets/logo_bitmap_black@3x.png';
import logoImage1 from '../assets/logo_bitmap.png';
import logoImage2 from '../assets/logo_bitmap@2x.png';
import logoImage3 from '../assets/logo_bitmap@3x.png';

// font weights based on what's provided by Google font
export enum FontWeight {
	Light = 300,
	Regular = 400,
	Medium = 600,
	Bold = 700
}

export const Colors = {
	White: '#fff',
	OffWhite: '#f6f6f6',
	Black: '#000',
	BlackLight1: '#2c2b29',
	BlackLight2: '#292929',
	BlackLight3: '#1a1a1a',
	BlackLight4: '#131313',
	CharcoalGray: '#364043',
	Gray: '#ededed',
	Gray2: '#d0d0d0',
	GrayDark: '#878787',
	GrayDarker: '#3c3c3c',
	GrayDarkest: '#2f2f2f',
	GrayLight: '#f1f1f1',
	GrayLighter: '#f5f5f5',
	RedDark: '#b80000',
	Green: '#2c9700',
	Gold: '#e7b900',
	BrandBrightGreen: '#acde21',
	BrandSkyBlue: '#21acde'
}

export const ComponentColors = {
	Header: Colors.GrayDarker,
	BackgroundGray: Colors.Gray,
	TableHeaderGray: Colors.GrayLight,
	TableHeaderGrayContrastText: Colors.GrayDark,
	BorderGray: Colors.Gray2
}

// 16px is the default font-size used by browsers.
export enum FontSize {
	Desktop = 16,
	Mobile = 13
}

export enum Sizes {
	MaxWidth = 1280, // max layout width
	HeaderHeight = 60,
	BodyHeightPercentDesktop = 93,
	BodyHeightPercentDesktopZoomed = 100,
	BodyHeightPercentMobile = 94,
	BodyHeightPercentMobileZoomed = 100,
	MobileGutter = 16
}

export const toRems = (pixels:number):string => (pixels/FontSize.Desktop)+'rem';

export const toRemsMobile = (pixels:number):string => (pixels/FontSize.Mobile)+'rem';

export const toEms = (pixels:number, contextfontSizePixels:number=FontSize.Desktop) => {
	return `${pixels/contextfontSizePixels}em`;
}

export const LogoSrcMap = SrcMap.createSrcMap([
	[318, logoImage3],
	[212, logoImage2],
	[106, logoImage1],
	['src', logoImage1]
]);

export const LogoBlackSrcMap = SrcMap.createSrcMap([
	[318, logoBlackImage3],
	[212, logoBlackImage2],
	[106, logoBlackImage1],
	['src', logoBlackImage1]
]);