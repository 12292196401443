import { AngleSuffix, BodyParts, IBodyPartGroup, LimitedSide, Side, SidePrefix, convertRemoteBodyPart, doesPartShowBothSides, getBodyPartFromMeasurementKey, getBodyPartGroup, getWorstBodyPartStandard, isZoomedIn } from '../assessment/body-parts.types';
import { AssessmentStatus, IMeasurement, Measurement, MeasurementKey, MeasurementStatus } from '../assessment/assessment.types';
import { Fallback, IDType, IData } from '../core.types';
import {
	GET_ALL_PLAYER_ASSESSMENTS,
	GET_PLAYER_ASSESSMENT,
	IAssessmentCompleted,
	IAssessmentsProgram,
	IAssessmentsProgramLimited,
	IGetAllPlayerAssessments,
	IGetPlayerAssessment,
	LoadableProgramType,
} from './assessments.types';
import { GET_ALL_PLAYER_ASSESSMENTS_MANAGEMENT, GET_EXPANDED_MEASUREMENTS, IGetAllPlayerAssessmentsManagement, IGetExpandedMeasurement } from './assessments.types';
import { IMeasurementInfo, Note, NoteTypes, PartLink, PartsSection } from '../assessment/measurement-expanded';
import { IPlayerRemote, convertPlayerRemoteData } from '../player/player.sagas';
import { RemoteMediaType, convertRemoteMedia } from '../../utils/media-type';
import {all, call, put, takeEvery, takeLatest} from 'redux-saga/effects';
import { getAllPlayerAssessments, getAllPlayerAssessmentsManagement, getMeasurementInfo, getPlayerAssessment } from '../../services/PMotionApi';
import { kebabCase, startsWith } from 'lodash';
import {setAllAssessmentPrograms, setLoading, showAllAssessmentsError, showGetAssessmentProgramError, updateAssessmentProgram} from './assessments.actions';

import { IPlayer } from '../player/player.types';
import { USE_DEV } from '../../utils/PMotionGlobals';
import { isNumber } from '../../utils/validator';
import { isUserTokenValid } from '../user/user.sagas';
import { setPlayer } from '../player/player.actions';

interface IAssessmentCompletedRemote extends Omit<IAssessmentCompleted, 'status'> {
	status?:AssessmentStatus | null;
}

interface RemoteMeasurement extends Omit<IMeasurement, 'media'> {
	media?:RemoteMediaType[] | null;
}

type RemoteBodyGroup =  Omit<IBodyPartGroup, 'innerBodyParts'> & {
	name:string;
	partNames?:BodyParts[] | null;
	parts?:(RemoteBodyGroup | BodyParts)[] | null;
	measurements?:RemoteMeasurement[] | null;
}

interface IAssessmentCompletedProgramManagementRemote extends Omit<IAssessmentCompletedRemote, 'measurements' | 'bodyPartGroups'> {
	partGroups:RemoteBodyGroup[];
}

export interface IAssessmentProgramDayRemote extends IAssessmentCompleted {}
export interface IAssessmentProgramRemote extends IAssessmentsProgram {}

interface IAssessmentProgramManagementRemote extends Omit<IAssessmentProgramRemote, 'days'> {
	days:IAssessmentCompletedProgramManagementRemote[]; 
}

export type LoadableAssessmentProgramRemoteType = IAssessmentsProgramLimited | IAssessmentProgramRemote;


interface IFullAssessmentProgramsRemote extends IPlayerRemote {
	assessmentGroups?:IAssessmentCompletedProgramManagementRemote[] | null;
}
export interface ISingleAssessmentProgramRemote extends IData {
	assessments:IAssessmentCompletedRemote[];
	assessmentGroups?:IAssessmentCompletedProgramManagementRemote[] | null;
}
export type IAllAssessmentProgramsRemote = IFullAssessmentProgramsRemote | ISingleAssessmentProgramRemote;

interface IFullAssessmentManagementProgramsRemote extends IData {
	assessments:IAssessmentProgramManagementRemote[]
}

export function* getAssessmentProgram({payload: {id, playerId} }:IGetPlayerAssessment) {
	// get info
	try {
		const correctiveInfoData = yield getPlayerAssessment({id, playerId});
		const assessmentProgramInfo:IAssessmentProgramRemote = correctiveInfoData?.data as IAssessmentProgramRemote;
		if( assessmentProgramInfo ){
			
			const program = convertAssessmentDaysProgramRemoteData(assessmentProgramInfo);
			
			yield put(
				updateAssessmentProgram({
					program,
					playerId
				})
			);
			
		} else {
			throw new Error('Assessment Program API returned, but cannot read data.')
		}
	} catch(er){
		yield put(
			showGetAssessmentProgramError({
				programId: id,
				error: 'Get assessments program data failed. '+er.message
			})
		);
	}
    
}

export function* getAllAssessmentPrograms({payload: {playerId, token}}:IGetAllPlayerAssessments) {
	yield put(
		setLoading(true)
	);
	
	// get info
	try {
		const correctivesInfoData = yield getAllPlayerAssessments(playerId, token);
		const assessmentProgramsInfo:IAllAssessmentProgramsRemote = correctivesInfoData?.data as IAllAssessmentProgramsRemote;
		const valid = yield isUserTokenValid(assessmentProgramsInfo, token);
		if(valid){
			if( assessmentProgramsInfo && assessmentProgramsInfo.assessments && Array.isArray(assessmentProgramsInfo.assessments)){
			
				const firstPrg = assessmentProgramsInfo.assessments.length ? assessmentProgramsInfo.assessments[0] : undefined;
				const hasDays = firstPrg && firstPrg.hasOwnProperty('days') && Array.isArray((firstPrg as IAssessmentProgramRemote).days);
				const programs = hasDays ? convertAllAssessmentProgramsRemoteData(assessmentProgramsInfo.assessments as LoadableAssessmentProgramRemoteType[]) : convertSingleAssessmentProgramsRemoteData(assessmentProgramsInfo as ISingleAssessmentProgramRemote);
				
				yield put(
					setAllAssessmentPrograms({
						programs,
						playerId
					})
				);
			} else {
				throw new Error('All assessment programs API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showAllAssessmentsError('Get all assessment programs data failed. '+er.message)
		);
	}

	yield put(
		setLoading(false)
	);
    
}

export function* getAllAssessmentProgramsManagement({payload: {playerId, token}}:IGetAllPlayerAssessmentsManagement) {
	yield put(
		setLoading(true)
	);
	
	// get info
	try {
		const correctivesInfoData = yield getAllPlayerAssessmentsManagement(playerId, token);
		const assessmentProgramsInfo:IAllAssessmentProgramsRemote = correctivesInfoData?.data as IAllAssessmentProgramsRemote;
		const valid = yield isUserTokenValid(assessmentProgramsInfo, token);
		if(valid){
			if(assessmentProgramsInfo && assessmentProgramsInfo.hasOwnProperty('firstName') && assessmentProgramsInfo.hasOwnProperty('lastName')){
				const player:IPlayer = convertPlayerRemoteData({
					...assessmentProgramsInfo as IFullAssessmentProgramsRemote,
					id: playerId
				});
				yield put(
					setPlayer(player)
				);
			}
			if( assessmentProgramsInfo && assessmentProgramsInfo.assessmentGroups && Array.isArray(assessmentProgramsInfo.assessmentGroups)){
				
				const programs = [
					convertAssessmentDaysProgramManagementRemoteData({
						id: playerId,
						days: assessmentProgramsInfo.assessmentGroups
					})
				];
				
				yield put(
					setAllAssessmentPrograms({
						programs,
						playerId
					})
				);
	
			} else {
				throw new Error('All assessment programs management API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showAllAssessmentsError('Get all assessment programs management data failed. '+er.message)
		);
	}

	yield put(
		setLoading(false)
	);
    
}

type RemotePartLink = Omit<PartLink, 'measurementSide' | 'measurementKey' | 'key'> & {
	left:boolean,
    right:boolean;
	center:boolean;
	key:MeasurementKey | string;
}
type RemotePartsSection = Omit<PartsSection, 'parts'> & {
	parts?: RemotePartLink[] | null;
}
type RemoteMeasurementInfo = Omit<IMeasurementInfo, 'kineticPartsConcentric' | 'kineticPartsEccentric' | 'riskParts' | 'relatedParts'> & Partial<IData> & {
	notes?:Note[] | null;
	kineticPartsConcentric?:RemotePartsSection | null;
	kineticPartsEccentric?:RemotePartsSection | null;
	riskParts?:RemotePartsSection | null;
	relatedParts?:RemotePartsSection | null;
}
type RemoteMeasurements = {
	measurements:RemoteMeasurementInfo[];
}
export function* singleCallToGetExpandedMeasurements(program:IAssessmentsProgram, playerId:IDType, measurementIds:IDType[], side:LimitedSide, token:string) {
	// get info
	const expandedMeasurementsData = yield getMeasurementInfo(playerId, measurementIds, side, token);
	const expandedMeasurementsInfo:RemoteMeasurements = expandedMeasurementsData?.data as RemoteMeasurements;

	const valid = yield isUserTokenValid(expandedMeasurementsInfo, token);
	if( valid ){
		if(expandedMeasurementsInfo && Array.isArray(expandedMeasurementsInfo)){
			const newMeasurements:RemoteMeasurementInfo[] = expandedMeasurementsInfo as RemoteMeasurementInfo[];
			program = {
				...program,
				days: program.days.map(day => {
					return {
						...day,
						measurements: day.measurements.map(measure => {
							if(measurementIds.includes(measure.measurementId)){
								let expanded = newMeasurements.find(({id}) => id===measure.measurementId);
								expanded = expanded ? expanded : (USE_DEV && newMeasurements.length) ? newMeasurements[0] : undefined;
								if( expanded ){
									return {
										...measure,
										expandedInfoLeft: side===Side.left ? convertRemoteExpandedMeasurement(expanded, program) : measure.expandedInfoLeft,
										expandedInfoRight: side===Side.right ? convertRemoteExpandedMeasurement(expanded, program) : measure.expandedInfoRight,
										expandedInfoCenter: side===Side.center ? convertRemoteExpandedMeasurement(expanded, program) : measure.expandedInfoCenter
									};
								} else {
									//throw new Error('Get expanded measurement API did not return the request measurements.')
									return {
										...measure,
										expandedInfoLeft: side===Side.left ? Fallback.NA : measure.expandedInfoLeft,
										expandedInfoRight: side===Side.right ? Fallback.NA : measure.expandedInfoRight,
										expandedInfoCenter: side===Side.center ? Fallback.NA : measure.expandedInfoCenter
									};
								}
							}
							return measure;
						})
					};
				})
			}
			
		} else {
			throw new Error('Get expanded measurement API returned, but cannot read data.')
		}
	}
    return program;
}
export function* callToGetExpandedMeasurements({payload: {measurementIds, program, playerId, side, token}}:IGetExpandedMeasurement) {
	// get info
	try {
		switch( side ){
			case Side.all :
				program = yield singleCallToGetExpandedMeasurements(program, playerId, measurementIds, Side.left, token);
				program = yield singleCallToGetExpandedMeasurements(program, playerId, measurementIds, Side.right, token);
				// not worrying about center right now
				break;
			case Side.left :
				program = yield singleCallToGetExpandedMeasurements(program, playerId, measurementIds, Side.left, token);
				break;
			case Side.right :
				program = yield singleCallToGetExpandedMeasurements(program, playerId, measurementIds, Side.right, token);
				break;
			case Side.center :
				program = yield singleCallToGetExpandedMeasurements(program, playerId, measurementIds, Side.center, token);
				break;
			default :
				// not supported
		}

		yield put(
			updateAssessmentProgram({
				program,
				playerId
			})
		);
		
	} catch(er){
		console.log('Get expanded measurement info call failed. '+er.message);
	}
    
}

const getMeasurementFromProgram = (program: IAssessmentsProgram, key:MeasurementKey):IMeasurement | undefined => {
	if(program.days){
		for (let {measurements} of program.days) {
			for (let measurement of measurements) {
				if(measurement.key===key){
					return measurement;
				}
			}
		}
	}
	return undefined;
}

const getMeasurementStatusFromProgram = (program: IAssessmentsProgram, key:MeasurementKey, side?:Side | null):MeasurementStatus | null | undefined => {
	const measurement = getMeasurementFromProgram(program, key);
	if( measurement ){
		const {id, name, bilateral, leftStandard, rightStandard, centerStandard, bodyPart} = measurement;
		return side===Side.left ? measurement.leftStandard : side===Side.right ? measurement.rightStandard : side===Side.center ? measurement.centerStandard : bodyPart ? getWorstBodyPartStandard({
			id,
			name,
			bilateral,
			leftStandard,
			rightStandard,
			centerStandard,
			key: bodyPart
		}, side===Side.bilateral) : undefined;
	}
	return undefined;
}

const getPartSide = (left:boolean, right:boolean, partSide?:Side | null):Side | undefined => {
	return partSide ? partSide : (left && right) ? Side.all : left ? Side.left : right ? Side.right : undefined;
}

const convertRemotePartsSection = ({parts, ...props}:RemotePartsSection, program:IAssessmentsProgram, notes?:Note[] | null):PartsSection => {
	return {
		...props,
		notes,
		parts: parts ? parts.map(({partSide, left, right, center, key, standard, ...partProps}) => {
			const measurementKey = (kebabCase(key) as MeasurementKey);
			partSide = getPartSide(left, right, partSide);
			const side:Side | undefined = center ? Side.center : (partSide as Side | undefined);
			const bodyPart = getBodyPartFromMeasurementKey(measurementKey);
			const showsBothSides = bodyPart ? doesPartShowBothSides(bodyPart) : false;
			return {
				...partProps,
				key: bodyPart,
				measurementKey,
				partSide: showsBothSides ? undefined : partSide,
				measurementSide: showsBothSides ? partSide : undefined,
				standard: standard ? standard : measurementKey ? getMeasurementStatusFromProgram(program, measurementKey, side) : undefined
			} as PartLink
		}).sort(({order:aOrder}, {order:bOrder}) => {
			aOrder = isNumber(aOrder) ? aOrder as number : 0;
			bOrder = isNumber(bOrder) ? bOrder as number : 0;
			return aOrder - bOrder;
		}) : undefined
	}
}

const convertRemoteExpandedMeasurement = ({kineticPartsConcentric, kineticPartsEccentric, riskParts, relatedParts, id, notes, ...props}:RemoteMeasurementInfo, program:IAssessmentsProgram):IMeasurementInfo => {
	const riskNotes = notes ? notes.filter(({type}) => {
		switch(type){
			case NoteTypes.InjuryRisk :
			case NoteTypes.PerformanceImpactLevel1 :
			case NoteTypes.PerformanceImpactLevel2 :
			case NoteTypes.PerformanceImpactLevel3 :
			case NoteTypes.PerformanceImpactLevel4 :
				return true;
		}
		return false;
	}) : undefined;
	return {
		...props,
		kineticPartsConcentric: kineticPartsConcentric ? convertRemotePartsSection(kineticPartsConcentric, program) : undefined,
		kineticPartsEccentric: kineticPartsEccentric ? convertRemotePartsSection(kineticPartsEccentric, program) : undefined,
		riskParts: riskParts ? convertRemotePartsSection(riskParts, program, riskNotes) : riskNotes ? {
			notes: riskNotes
		} : undefined,
		//relatedParts: relatedParts ? convertRemotePartsSection(relatedParts, program) : undefined,
		relatedParts: undefined // disabled for now
	};
}

export const convertSingleAssessmentProgramsRemoteData = ({id:memberId, assessments}:ISingleAssessmentProgramRemote):LoadableProgramType[] => {
	return [{
		id: memberId,
		days: assessments.map(({measurements, status, snapshots, ...otherAssessmentData}) => ({
			...otherAssessmentData,
			measurements: measurements.map((measurement:IMeasurement) => Measurement.create(measurement)),
			status: (status===null) ? AssessmentStatus.Submitted : status,
			snapshots: snapshots ? snapshots.map(({measurements:snapshotMeasurements, ...snapshot}) => ({
				...snapshot,
				measurements: snapshotMeasurements.map((measurement:IMeasurement) => Measurement.create(measurement))
			})) : undefined
		}))
	} as IAssessmentsProgram] as LoadableProgramType[];
}

export const convertAssessmentProgramRemoteData = ({measurements, id, ...correctivedata}:IAssessmentCompleted):IAssessmentsProgram => {
	return {
		id,
		days: [{
			...correctivedata,
			measurements: measurements.map((measurement:IMeasurement) => Measurement.create(measurement))
		}]
	} as IAssessmentsProgram;
}



export const convertAssessmentDaysProgramRemoteData = ({days, ...correctivedata}:IAssessmentProgramRemote):IAssessmentsProgram => {
	const convertedDays:IAssessmentCompleted[] = days.map(({measurements, bodyPartGroups, snapshots, ...otherAssessmentData}) => ({
		...otherAssessmentData,
		measurements: measurements.map((measurement:IMeasurement) => Measurement.create(measurement)),
		snapshots: snapshots ? snapshots.map(({measurements:snapshotMeasurements, ...snapshot}) => ({
			...snapshot,
			measurements: snapshotMeasurements.map((measurement:IMeasurement) => Measurement.create(measurement))
		})) : undefined
	}));
	return {
		...correctivedata,
		days: convertedDays
	} as IAssessmentsProgram;
}

export const convertAssessmentDaysProgramManagementRemoteData = ({days, ...correctivedata}:IAssessmentProgramManagementRemote):IAssessmentsProgram => {
	const convertedDays:IAssessmentCompleted[] = days.map(({partGroups, status, snapshots, ...otherAssessmentData}) => {
		const [bodyPartGroups, measurements] = createBodyPartGroups(partGroups);
		return {
			...otherAssessmentData,
			measurements: measurements.map((measurement:IMeasurement) => Measurement.create(measurement)),
			bodyPartGroups: convertFinalBodyPartGroups(bodyPartGroups),
			status: (status===null) ? AssessmentStatus.Submitted : status,
			snapshots: snapshots ? snapshots.map(({measurements:snapshotMeasurements, ...snapshot}) => ({
				...snapshot,
				measurements: snapshotMeasurements.map((measurement:IMeasurement) => Measurement.create(measurement))
			})) : undefined
		}
	});
	return {
		...correctivedata,
		days: convertedDays
	} as IAssessmentsProgram;
}


type ConversionReturn = [IBodyPartGroup[], IMeasurement[]];
const createBodyPartGroups = (remotePartGroups:RemoteBodyGroup[]):ConversionReturn => {
	let allMeasurements:IMeasurement[] = [];
	let groups:IBodyPartGroup[] = [];
	remotePartGroups.forEach(({key, partNames, parts, measurements, ...group}) => {
		const realKey = convertRemoteBodyPart(key);
		const realMeasurements = measurements ? measurements.map(({key:measurementKey, media, ...measure}) => ({
			...measure,
			key: (kebabCase(measurementKey) as MeasurementKey),
			bodyPart: realKey,
			media: media ? media.map(singleMedia => convertRemoteMedia(singleMedia)) : undefined
		})) : [];
		const subParts = parts ? parts.concat(partNames ? partNames : []) : [];
		const subPartIds:BodyParts[] = subParts.reduce((accu, partKey) => {
			if(typeof partKey==='string'){
				const converted = convertRemoteBodyPart(partKey);
				if( converted ){
					accu.push( converted );
				}
			}
			return accu;
		}, [] as BodyParts[]);
		
		const subPartObjs:RemoteBodyGroup[] | undefined = parts ? (parts.filter(part => part && typeof part!=='string') as RemoteBodyGroup[]) : undefined;
		const subInfo = subPartObjs ? createBodyPartGroups(subPartObjs) : undefined;
		const subGroups:IBodyPartGroup[] = subInfo ? subInfo[0] : [];
		const subMeasurements:IMeasurement[] = subInfo ? subInfo[1] : [];
		const parentGroup:IBodyPartGroup = {
			...group,
			bilateral: realKey && doesPartShowBothSides(realKey) ? true : group.bilateral, // had to override since API was returning false
			key: realKey as BodyParts,
			innerBodyParts: subGroups.map(subGroup => subGroup.key).concat( subPartIds ),
		};
		allMeasurements = [
			...allMeasurements,
			...realMeasurements,
			...subMeasurements
		];
		groups = [
			...groups,
			parentGroup,
			...subGroups
		];
	});
	return [groups, allMeasurements];
}

const establishGroup = (parentGroup:IBodyPartGroup, groups:IBodyPartGroup[], side?:SidePrefix):IBodyPartGroup[] => {
	const newGroups = [] as IBodyPartGroup[];
	const {id, innerBodyParts} = parentGroup;
	const partKey = parentGroup.key;
	if( isZoomedIn(partKey) ){
		const basePartKey = side ? (side + partKey as BodyParts) : partKey;
		const innerParts:BodyParts[] = [];
		const labelPrefix = (side===SidePrefix.left) ? 'Left ' : side ? 'Right ' : '';

		enum IdSuffix {
			left = .1,
			right = .2,
			back = .3,
			side = .4
		}

		// angles
		const backKey = basePartKey + AngleSuffix.back;
		let backGroup = getBodyPartGroup(backKey, groups);
		if(!backGroup){
			// create back group
			backGroup = {
				...parentGroup,
				id: id + IdSuffix.back,
				key: backKey as BodyParts,
				name: labelPrefix + parentGroup.name + ' Back',
				innerBodyParts: []
			};
			newGroups.push(backGroup);
			innerParts.push(backGroup.key);
		}

		const sideKey = basePartKey + AngleSuffix.side;
		let sideGroup = getBodyPartGroup(sideKey, groups);
		if(!sideGroup){
			// create side group
			sideGroup = {
				...parentGroup,
				id: id + ((side===SidePrefix.left) ? IdSuffix.left : IdSuffix.right),
				key: sideKey as BodyParts,
				name: labelPrefix + parentGroup.name + ' SidePrefix',
				innerBodyParts: []
			};
			newGroups.push(sideGroup);
			innerParts.push(sideGroup.key);
		}

		
		if(side && parentGroup.bilateral){
			let lateralGroup = !startsWith(partKey, side) ? getBodyPartGroup(basePartKey, groups) : undefined;
			if(!lateralGroup){
				// create left of right group
				lateralGroup = {
					...parentGroup,
					id: id + IdSuffix.back,
					key: basePartKey,
					name: labelPrefix + parentGroup.name,
					innerBodyParts: innerParts
				};
				newGroups.push(lateralGroup);
				innerBodyParts.push( basePartKey )
			}
		} else {
			const basePartGroup = getBodyPartGroup(basePartKey, groups);
			if( basePartGroup ){
				basePartGroup.innerBodyParts = Array.from(new Set([
					...basePartGroup.innerBodyParts,
					...innerParts
				]))
			}
		}
	}
	return newGroups;
}


export const convertFinalBodyPartGroups = (bodyPartGroups?:IBodyPartGroup[] | null):IBodyPartGroup[] | undefined => {
	if( bodyPartGroups ){
		let groups = bodyPartGroups.map(({key, innerBodyParts, ...group}:IBodyPartGroup) => ({
			...group,
			key: convertRemoteBodyPart(key) as BodyParts,
			innerBodyParts: innerBodyParts ? innerBodyParts.map(innerKey => convertRemoteBodyPart(innerKey) as BodyParts): innerBodyParts,
		} as IBodyPartGroup));
		
		// expand for left and right
		groups = groups.reduce((accu, group) => {
			const newGroups = group.bilateral ? establishGroup(group, groups, SidePrefix.left).concat(establishGroup(group, groups, SidePrefix.right)) : establishGroup(group, groups);
			// add the newly created child groups to the parent as inner body parts
			accu.push(group);
			accu = [
				...accu,
				...newGroups
			];
			return accu;
		}, [] as IBodyPartGroup[]);
		return groups;
	}
	return undefined;
}

export const convertAllAssessmentProgramsRemoteData = (correctives:LoadableAssessmentProgramRemoteType[]):LoadableProgramType[] => {
	const convertedAssessmentPrograms:LoadableProgramType[] = correctives.map(corrective => {
		return !!(corrective as IAssessmentProgramRemote).days ? convertAssessmentDaysProgramRemoteData((corrective as IAssessmentProgramRemote)) : corrective;
	});
	return convertedAssessmentPrograms;
}

export const convertAllAssessmentProgramsManagementRemoteData = (correctives:IAssessmentProgramManagementRemote[]):LoadableProgramType[] => {
	const convertedAssessmentPrograms:LoadableProgramType[] = correctives.map(corrective => {
		return convertAssessmentDaysProgramManagementRemoteData((corrective));
	});
	return convertedAssessmentPrograms;
}

export function* onGetAssessmentProgram() {
    yield takeEvery(GET_PLAYER_ASSESSMENT, getAssessmentProgram);
}

export function* onGetAllAssessmentPrograms() {
    yield takeLatest(GET_ALL_PLAYER_ASSESSMENTS, getAllAssessmentPrograms);
}

export function* onGetAllAssessmentProgramsManagement() {
    yield takeLatest(GET_ALL_PLAYER_ASSESSMENTS_MANAGEMENT, getAllAssessmentProgramsManagement);
}

export function* onGetExpandedMeasurements() {
    yield takeLatest(GET_EXPANDED_MEASUREMENTS, callToGetExpandedMeasurements);
}

export function* assessmentsSagas() {
    yield all([
		call( onGetAssessmentProgram ),
		call( onGetAllAssessmentPrograms ),
		call( onGetAllAssessmentProgramsManagement ),
		call( onGetExpandedMeasurements )
    ]);
}