import { CorrectiveExerciseStatus, ICorrectiveExercise, IUpdateCorrectiveExerciseStatus, IUpdateCorrectiveExerciseStatusSuccess } from '../correctives/correctives.types';
import { IDType, IData } from '../core.types';
import { ISignOutSuccess, ITokenPayload } from '../user/user.types';

import { MeasurementKey } from '../assessment/assessment.types';
import MediaType from '../../utils/media-type';
import { Side } from '../assessment/body-parts.types';

export const SET_EXERCISE_LOADING = 'SET_EXERCISE_LOADING';
export const GET_EXERCISE = 'GET_EXERCISE';
export const SET_EXERCISE = 'SET_EXERCISE';
export const SHOW_EXERCISE_ERROR = 'SHOW_EXERCISE_ERROR';
export const SET_EXERCISE_CORRECTIVE = 'SET_EXERCISE_CORRECTIVE';
export const SET_EXERCISE_CORRECTIVE_AND_UPDATE = 'SET_EXERCISE_CORRECTIVE_AND_UPDATE';

// Data types
export interface IExercise extends IData {
	name:string;
	key?:string | null;
	content:string;
	description?:string | null;
	sets?:number | null;
	reps?:number | null;
	rest?:number | null;
	tempo?:number | null;
	duration?:number | null;
	weight?:string | null;
	type:string;
	targetMeasurement?:MeasurementKey | string | null; //measurement key
	targetMeasurementName?:string | null;
	targetMeasurementSide?:Side | null;
}

enum MediaRenderType {
	Carousel = 'carousel',
	List = 'list'
}
export interface IExerciseComplete extends IExercise {
	media:MediaType[];
	mediaRenderType?:MediaRenderType; // defaults to carousel
}


// State type
export interface IExerciseState {
	isLoading:boolean;
	exercise?:IExerciseComplete;
	correctiveReference?:ICorrectiveExercise;
	error?:string;
}


// Action types
export interface IGetExercisePayload extends ITokenPayload {
	id:IDType;
}
export interface IGetExercise {
	type: typeof GET_EXERCISE;
	payload: IGetExercisePayload;
};
export interface ISetExercise {
	type: typeof SET_EXERCISE;
	payload: IExerciseComplete | undefined;
};
export interface ISetExerciseLoading {
	type: typeof SET_EXERCISE_LOADING;
	payload: boolean;
};
export interface IShowExerciseError {
    type: typeof SHOW_EXERCISE_ERROR;
    payload?:string;
}

export interface ISetExerciseCorrective {
	type: typeof SET_EXERCISE_CORRECTIVE;
    payload?:ICorrectiveExercise;
}

export interface ISetExerciseCorrectiveAndUpdateStatusPayload extends ITokenPayload {
	exercise?:ICorrectiveExercise;
	status?:CorrectiveExerciseStatus;
	withFeedbackResponse:boolean;
	refreshPlayerId?:IDType;
	isProgramDayNew?:boolean;
}
export interface ISetExerciseCorrectiveAndUpdateStatus {
	type: typeof SET_EXERCISE_CORRECTIVE_AND_UPDATE;
	payload:ISetExerciseCorrectiveAndUpdateStatusPayload;
};

export type TypeExerciseAction = IGetExercise | ISetExercise | ISetExerciseLoading | IShowExerciseError | ISetExerciseCorrective | IUpdateCorrectiveExerciseStatus | IUpdateCorrectiveExerciseStatusSuccess | ISetExerciseCorrectiveAndUpdateStatus | ISignOutSuccess;