import { IDType, IData } from '../core.types';
import { ISignOutSuccess, ITokenPayload } from '../user/user.types';

import { IPlayer } from '../player/player.types';

export const GET_ALL_MESSAGE_THREADS = 'GET_ALL_MESSAGE_THREADS';
export const SET_ALL_MESSAGE_THREADS = 'SET_ALL_MESSAGE_THREADS';
export const GET_NEW_MESSAGE_THREAD_COUNT = 'GET_NEW_MESSAGE_THREAD_COUNT';
export const SET_NEW_MESSAGE_THREAD_COUNT = 'SET_NEW_MESSAGE_THREAD_COUNT';
export const SHOW_ALL_MESSAGE_THREADS_ERROR = 'SHOW_ALL_MESSAGE_THREADS_ERROR';
export const GET_MESSAGE_THREAD = 'GET_MESSAGE_THREAD';
export const GET_MESSAGE_THREAD_BY_MEMBERS = 'GET_MESSAGE_THREAD_BY_MEMBERS';
export const SET_MESSAGE_THREAD = 'SET_MESSAGE_THREAD';
export const SHOW_MESSAGE_THREAD_ERROR = 'SHOW_MESSAGE_THREAD_ERROR';
export const SEARCH_THREADS = 'SEARCH_THREADS';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const MARK_MESSAGE_STATUS = 'MARK_MESSAGE_STATUS';
export const MARK_MESSAGE_STATUS_SUCCESS = 'MARK_MESSAGE_STATUS_SUCCESS';

// Data types
export enum MessageStatus {
	Unread = 'U',
	Read = 'R',
	Deleted = 'D'
}

export interface IMessage extends IData {
	threadId:IDType;
	authorId:IDType;
	content:string;
	excerpt?:string | null;
	sentDate:string;
	status?:MessageStatus | null;
}

export interface IMessageThread extends IData {
	orgId:IDType;
	users:IPlayer[];
	messages?:IMessage[] | null;
	lastMessage?:IMessage | null;
	messageCount:number;
	unreadCount:number;
}


// State type
export interface IMessageState {
	isAllThreadsLoading:boolean;
	isSingleThreadLoading:boolean;
	threads?:IMessageThread[];
	singleThread?:IMessageThread;
	allThreadsError?:string;
	singleThreadError?:string;
	searchThreadsTerm?:string;
	newMessageThreadCount?:number;
}


// Action types
export interface IGetAllMessageThreadsPayload extends ITokenPayload {
	userId: IDType;
	pageIndex?:number;
}
// Get All Message Threads action
export interface IGetAllMessageThreads {
	type: typeof GET_ALL_MESSAGE_THREADS;
	payload: IGetAllMessageThreadsPayload;
};

export interface ISetAllMessageThreads {
	type: typeof SET_ALL_MESSAGE_THREADS;
	payload: IMessageThread[];
};

export interface IShowAllMessageThreadsError {
    type: typeof SHOW_ALL_MESSAGE_THREADS_ERROR;
    payload?:string;
}

export interface IGetMessageThreadPayload extends ITokenPayload {
	threadId: IDType;
	authorId:IDType;
}
// Get Single Message Thread action
export interface IGetMessageThread {
	type: typeof GET_MESSAGE_THREAD;
	payload: IGetMessageThreadPayload;
};

export interface IGetMessageThreadByMembersPayload extends ITokenPayload {
	userId:IDType;
	orgKey:string;
	memberIds:IDType[];
}
// Get Single Message Thread by Members action
export interface IGetMessageThreadByMembers {
	type: typeof GET_MESSAGE_THREAD_BY_MEMBERS;
	payload:IGetMessageThreadByMembersPayload;
};

export interface ISetMessageThread {
	type: typeof SET_MESSAGE_THREAD;
	payload?: IMessageThread;
};

export interface IShowMessageThreadError {
    type: typeof SHOW_MESSAGE_THREAD_ERROR;
    payload?:string;
}

// Search Message Threads action
export interface ISearchThreads {
    type: typeof SEARCH_THREADS;
    payload?:string;
}

export interface ISendMessagePayload extends ITokenPayload {
	threadId:IDType;
	authorId:IDType;
	content:string; 
}
// Send Message action
export interface ISendMessage {
    type: typeof SEND_MESSAGE;
    payload:ISendMessagePayload;
}

export interface IGetNewMessageThreadCountPayload extends ITokenPayload {
	userId: IDType;
	failSilently?:boolean;
}
export interface IGetNewMessageThreadCount {
    type: typeof GET_NEW_MESSAGE_THREAD_COUNT;
    payload:IGetNewMessageThreadCountPayload
}

export interface ISetNewMessageThreadCount {
    type: typeof SET_NEW_MESSAGE_THREAD_COUNT;
    payload:number;
}

export interface IMarkMessageStatusPayload {
	threadId:IDType;
	messageIds:IDType[];
	status:MessageStatus;
	userId:IDType; // so new message count can be updated
}
export type IMarkMessageStatusTokenPayload = IMarkMessageStatusPayload & ITokenPayload;
export interface IMarkMessageStatus {
    type: typeof MARK_MESSAGE_STATUS;
    payload:IMarkMessageStatusTokenPayload;
}

export interface IMarkMessageStatusSuccess {
    type: typeof MARK_MESSAGE_STATUS_SUCCESS;
    payload:IMarkMessageStatusPayload;
}

export type TypeMessageAction = IGetAllMessageThreads | ISetAllMessageThreads | IShowAllMessageThreadsError | IGetMessageThread | IGetMessageThreadByMembers | ISetMessageThread | IShowMessageThreadError | ISearchThreads | ISendMessage | IGetNewMessageThreadCount | ISetNewMessageThreadCount | IMarkMessageStatus | IMarkMessageStatusSuccess | ISignOutSuccess;