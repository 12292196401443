import { BodyPartStandard, BodyParts, Side, convertRemoteBodyPart } from '../assessment/body-parts.types';
import {
	CorrectiveExerciseStatus,
	CorrectiveStatus,
	GET_ALL_CORRECTIVES,
	GET_CORRECTIVE,
	GET_CORRECTIVE_PROGRAM,
	GET_CORRECTIVE_SET,
	ICorrective,
	ICorrectiveDay,
	ICorrectiveExercise,
	ICorrectiveExercisePlan,
	ICorrectiveLimited,
	ICorrectiveProgram,
	ICorrectiveSet,
	IGetAllCorrectives,
	IGetCorrective,
	IGetCorrectiveProgram,
	IGetCorrectiveSet,
	LoadableCorrectiveType
} from './correctives.types';
import { IFeedbackResponse, IStartCorrectives, IUpdateCorrectiveExerciseFeedback, IUpdateCorrectiveExerciseStatus, START_CORRECTIVES, UPDATE_CORRECTIVE_EXERCISE_FEEDBACK, UPDATE_CORRECTIVE_EXERCISE_STATUS } from './correctives.types';
import { IPlayerRemote, RemoteBodyGroup, convertPlayerRemoteData } from '../player/player.sagas';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getAllPlayerCorrectives, getPlayerCorrective, getPlayerCorrectivesProgram, getPlayerCorrectivesSet, startPlayerCorrectives, updateCorrectiveExerciseResponse, updateCorrectiveExerciseStatus, updateCorrectiveExerciseStatusWithResponse } from '../../services/PMotionApi';
import { setCorrectives, setCorrectivesProgram, setCorrectivesSet, showAllCorrectivesError, showGetCorrectiveError, updateCorrective, updateCorrectiveExerciseFeedbackSuccess, updateCorrectiveExerciseStatusSuccess } from './correctives.actions';

import { IData } from '../core.types';
import { IExercise } from '../exercise/exercise.types';
import { IPlayer } from '../player/player.types';
import { isNumber } from '../../utils/validator';
import { isUserTokenValid } from '../user/user.sagas';
import { kebabCase } from 'lodash';
import { selectOrgMembers } from '../org/org.selectors';
import { setOrgMembersCompliance } from '../org/org.actions';
import { setPlayer } from '../player/player.actions';
import { trackExerciseEvent } from '../../services/GoogleTagManager';

export interface ICorrectiveDayRemote extends ICorrectiveDay {}
export interface ICorrectiveRemote extends ICorrective {}
export type LoadableCorrectiveRemoteType = ICorrectiveLimited | ICorrectiveRemote;
interface IAllCorrectivesRemote {
	programs:LoadableCorrectiveRemoteType[]
}

export function* getCorrective({payload: {id, playerId} }:IGetCorrective) {
	// get info
	try {
		const correctiveInfoData = yield getPlayerCorrective({id, playerId});
		const correctiveInfo:ICorrectiveRemote = correctiveInfoData?.data as ICorrectiveRemote;
		if( correctiveInfo ){
			
			const corrective = convertCorrectiveRemoteData(correctiveInfo);
			
			yield put(
				updateCorrective({
					corrective,
					playerId
				})
			);
			
		} else {
			throw new Error('Corrective API returned, but cannot read data.')
		}
	} catch(er){
		yield put(
			showGetCorrectiveError({
				correctiveId: id,
				error: 'Get corrective data failed. '+er.message
			})
		);
	}
    
}

export function* getAllCorrectives({payload}:IGetAllCorrectives) {
	const {playerId, isProgramDayNew, token} = payload;
	// get info
	try {
		const correctivesInfoData = yield getAllPlayerCorrectives(playerId, token, isProgramDayNew);
		const correctivesInfo:IAllCorrectivesRemote = correctivesInfoData?.data as IAllCorrectivesRemote;
		
		const valid = yield isUserTokenValid(correctivesInfo, token);
		if(valid){
			if( correctivesInfo && correctivesInfo.programs && Array.isArray(correctivesInfo.programs)){
				const correctives = convertAllCorrectivesRemoteData(correctivesInfo.programs);
				
				yield put(
					setCorrectives({
						correctives,
						playerId
					})
				);
			} else {
				throw new Error('All Correctives API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showAllCorrectivesError('Get all correctives data failed. '+er.message)
		);
	}
    
}

interface RemoteCorrectiveExercise extends IExercise {
	status:CorrectiveExerciseStatus;
	targetMeasurementKey?:string | null;
	exerciseSide?:string | null;
}
interface ICorrectivePlanRemote extends IData {
	status:CorrectiveStatus;
	measurementKey?:string | null;
	measurementName:string;
	exercisesDone?:number | null;
	exercisesSkipped?:number | null;
	totalExercises?:number | null;
	duration?:number | null;
	side?:string | null;
	assessmentGroupStandard?:RemoteBodyGroup | null;
	exercises:RemoteCorrectiveExercise[];
	order?:number | null;
}
interface ICorrectivePlanDayRemote extends ICorrectiveDay {
	exerciseMeasurements:ICorrectivePlanRemote[];
	day:number;
	startDate?:string | null;
	completeDate?:string | null;
}
export interface CorrectiveSetProgramRemote extends Omit<ICorrectiveProgram, 'days'> {
	days:ICorrectivePlanDayRemote[];
}
export interface ICorrectiveSetRemote {
	programs:CorrectiveSetProgramRemote[];
}
export function* getCorrectivesSet({payload}:IGetCorrectiveSet) {
	const {playerId, dateIndex, isProgramDayNew, token} = payload;
	
	// get info
	try {
		const playerInfoData = yield getPlayerCorrectivesSet(playerId, token, isProgramDayNew);
		const playerInfo:IPlayerRemote = playerInfoData?.data as IPlayerRemote;

		const valid = yield isUserTokenValid(playerInfo, token);
		if(valid){
			let correctivesSet:ICorrectiveSet | undefined;

			if( playerInfo ){
				const {programs:correctivesInfo, assessments, ...otherPlayerInfo} = playerInfo;

				const currentMembers:IPlayer[] | undefined = yield select(selectOrgMembers);
				const oldPlayerData = currentMembers ? currentMembers.find(({id}) => id===playerId) : undefined;
				
				const player:IPlayer = convertPlayerRemoteData({
					...otherPlayerInfo,
					id: playerId
				}, oldPlayerData);

				if( correctivesInfo && Array.isArray(correctivesInfo)){
					const firstProgram = correctivesInfo.length ? correctivesInfo[0] : undefined;
					const firstDay = (firstProgram && firstProgram.days.length) ? firstProgram.days[0] : undefined;
					
					correctivesSet = firstDay ? convertRemoteCorrectivesSet(firstDay) : undefined;

					if(firstProgram && !correctivesSet){
						correctivesSet = {
							id: firstProgram.id,
							plans: [],
							status: firstProgram.status,
							day: -1
						}
					}
				}

				yield put(
					setPlayer(player)
				);

				if(currentMembers && oldPlayerData){
					yield put(
						setOrgMembersCompliance(
							currentMembers.map((oldPlayer) => {
								return oldPlayer.id===player.id ? player : oldPlayer
							})
						)
					);
				}
			}
			
			if( correctivesSet ){
				
				yield put(
					setCorrectivesSet({
						correctivesSet,
						playerId,
						dateIndex
					})
				);
			} else {
				throw new Error('Get Correctives Set API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showAllCorrectivesError('Get Correctives Set API data failed. '+er.message)
		);
	}
    
}

export function* getCorrectivesProgram({payload}:IGetCorrectiveProgram) {
	const {playerId, isProgramDayNew, token} = payload;
	
	// get info
	try {
		const playerInfoData = yield getPlayerCorrectivesProgram(playerId, token, isProgramDayNew);
		const playerInfo:IPlayerRemote = playerInfoData?.data as IPlayerRemote;

		const valid = yield isUserTokenValid(playerInfo, token);
		if(valid){
			let correctivesProgram:ICorrectiveProgram | undefined;

			if( playerInfo ){
				const {programs:correctivesInfo, assessments, ...otherPlayerInfo} = playerInfo;

				const currentMembers:IPlayer[] | undefined = yield select(selectOrgMembers);
				const oldPlayerData = currentMembers ? currentMembers.find(({id}) => id===playerId) : undefined;
				
				const player:IPlayer = convertPlayerRemoteData({
					...otherPlayerInfo,
					id: playerId
				}, oldPlayerData);

				if( correctivesInfo && Array.isArray(correctivesInfo)){
					const firstProgram = correctivesInfo.length ? correctivesInfo[0] : undefined;
					if( firstProgram ){
						const {days, ...otherProgramInfo} = firstProgram;
						
						const correctiveDaySets = firstProgram.days.map(day => convertRemoteCorrectivesSet(day));

						correctivesProgram = {
							...otherProgramInfo,
							days: correctiveDaySets
						}
					}
				}

				yield put(
					setPlayer(player)
				);

				if(currentMembers && oldPlayerData){
					yield put(
						setOrgMembersCompliance(
							currentMembers.map((oldPlayer) => {
								return oldPlayer.id===player.id ? player : oldPlayer
							})
						)
					);
				}
			}
			
			if( correctivesProgram ){
				
				yield put(
					setCorrectivesProgram({
						correctivesProgram,
						playerId
					})
				);
			} else {
				throw new Error('Get Correctives Program API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			showAllCorrectivesError('Get Correctives Program API data failed. '+er.message)
		);
	}
    
}

export const convertRemoteCorrectivesSet = (day:ICorrectivePlanDayRemote):ICorrectiveSet => {
	const {id, duration, assignmentDate, exerciseMeasurements, status, day:dayNumber, startDate, completeDate} = day;
	const plans:ICorrectiveExercisePlan[] = exerciseMeasurements.map(({
		id,
		measurementKey, 
		measurementName, 
		assessmentGroupStandard, // generate body part from this
		exercises,
		side,
		status,
		...plan
	}) => {
		const convertedExercises:ICorrectiveExercise[] = exercises.map(({targetMeasurementSide, exerciseSide, targetMeasurementKey, ...exercise}) => ({
			...exercise,
			targetMeasurement: targetMeasurementKey ? kebabCase(targetMeasurementKey) : undefined,
			targetMeasurementSide: targetMeasurementSide ? targetMeasurementSide.toLowerCase() as Side : undefined,
			side: exerciseSide ? exerciseSide.toLowerCase() as Side : undefined
		}));

		const bodyPartStandard:BodyPartStandard | undefined = assessmentGroupStandard ? {
			...assessmentGroupStandard,
			id,
			key: convertRemoteBodyPart(assessmentGroupStandard.key) as BodyParts,
		} : undefined;

		side = side ? side.toLowerCase() : undefined;

		return {
			...plan,
			id,
			measurementName,
			measurementKey: measurementKey ? kebabCase(measurementKey) : undefined,
			assignmentDate,
			status,
			progress: (status===CorrectiveStatus.Completed) ? 1 : (status===CorrectiveStatus.InProgress) ? .5 : 0,
			side: side ? side as Side : undefined,
			exercises: convertedExercises,
			bodyPartStandard,
			uid: `${id}_${side}`
		}
	});

	return {
		id,
		plans,
		duration,
		status,
		day: dayNumber,
		startDate,
		completeDate,
		assignmentDate
	}
}

const getExerciseDuration = (sets?:number | null, reps?:number | null, rest?:number | null, tempo?:number | null, duration?:number | null):number => {
	const getVal = (input?:number | null) => isNumber(input) ? (input as number) : 0;
	if( !isNumber(duration) ){
		return (((getVal(tempo) * getVal(reps)) + getVal(rest)) * getVal(sets));
	}
	return duration as number;
}

export const convertCorrectiveRemoteDataDays = (days: ICorrectiveDay[]):ICorrectiveDay[] => {
	// temporarily calculate durations and max a day's session to 30min
	const maxSeconds = 30 * 60;
	const convertedDays:ICorrectiveDay[] = days.map(({duration, exercises, ...day}) => {
		let done = false;
		let totalDayDuration = 0;
		exercises = exercises.reduce((accu, {duration, sets, reps, rest, tempo, bodyPart, side, ...exercise}) => {
			if( !done ){
				duration = getExerciseDuration(sets, reps, rest, tempo, duration);
				if( (totalDayDuration+(duration as number)) <= maxSeconds ){
					totalDayDuration += (duration as number);
					accu.push({
						...exercise,
						sets, 
						reps, 
						rest, 
						tempo,
						duration,
						bodyPart: convertRemoteBodyPart(bodyPart),
						side: side ? side.toLowerCase() as Side : undefined
					});
				} else {
					done = true;
				}
			}
			return accu;
		}, [] as ICorrectiveExercise[]);
		return {
			...day,
			duration: totalDayDuration,
			exercises
		}
	});
	return convertedDays;
}

export const convertCorrectiveRemoteData = ({days, ...correctivedata}:ICorrectiveRemote):ICorrective => {
	// temporarily calculate durations and max a day's session to 30min
	const maxSeconds = 30 * 60;
	const getVal = (input?:number | null) => isNumber(input) ? (input as number) : 0;
	const convertedDays:ICorrectiveDay[] = days.map(({duration, exercises, ...day}) => {
		let done = false;
		let totalDayDuration = 0;
		exercises = exercises.reduce((accu, {duration, sets, reps, rest, tempo, ...exercise}) => {
			if( !done ){
				if( !isNumber(duration) ){
					duration = (((getVal(tempo) * getVal(reps)) + getVal(rest)) * getVal(sets));
				}
				if( (totalDayDuration+(duration as number)) <= maxSeconds ){
					totalDayDuration += (duration as number);
					accu.push({
						...exercise,
						sets, 
						reps, 
						rest, 
						tempo,
						duration
					});
				} else {
					done = true;
				}
			}
			return accu;
		}, [] as ICorrectiveExercise[]);
		return {
			...day,
			duration: totalDayDuration,
			exercises
		}
	});
	return {
		...correctivedata,
		days: convertedDays
	} as ICorrective;
}

export const convertAllCorrectivesRemoteData = (correctives:LoadableCorrectiveRemoteType[]):LoadableCorrectiveType[] => {
	const convertedCorrectives:LoadableCorrectiveType[] = correctives.map(corrective => {
		return !!(corrective as ICorrectiveRemote).days ? convertCorrectiveRemoteData((corrective as ICorrectiveRemote)) : corrective;
	});
	return convertedCorrectives;
}

export function* callStartPlayerCorrectives({payload}:IStartCorrectives) {
	const {playerId, token} = payload;
	try {
		const startInfoData = yield startPlayerCorrectives(playerId, token);
		const valid = yield isUserTokenValid(startInfoData.data, token);
		if(valid){
			if(startInfoData && startInfoData.data && startInfoData.data===true){
				/*yield getCorrectivesSet({
					payload,
					type: GET_CORRECTIVE_SET
				});*/
				yield getCorrectivesProgram({
					type: GET_CORRECTIVE_PROGRAM,
					payload: {
						playerId: playerId,
						token: (typeof valid === 'string') ? valid : token
					}
				});
			} else {
				throw new Error(`Error in startPlayerCorrectives request. ${startInfoData.data}`);
			}
		}
	} catch(er){
		yield put(
			showAllCorrectivesError('Error Starting Program, please check your assessment values.')
		);
	}
    
}

interface IRemoteUpdateStatusWithFeedback extends IFeedbackResponse {
	updated:boolean;
}
export function* callUpdateCorrectiveExerciseStatus({payload}:IUpdateCorrectiveExerciseStatus) {
	const {exerciseId, status, token, withFeedbackResponse, refreshPlayerId, isProgramDayNew, exerciseKey, exerciseSide} = payload;

	try {
		const updateInfoData = withFeedbackResponse ? yield updateCorrectiveExerciseStatusWithResponse(exerciseId, status, token) : yield updateCorrectiveExerciseStatus(exerciseId, status, token);
		const valid = yield isUserTokenValid(updateInfoData.data, token);
		if(valid){
			if(updateInfoData && updateInfoData.data && ((withFeedbackResponse && (updateInfoData.data as IRemoteUpdateStatusWithFeedback).updated===true) || updateInfoData.data===true)){
				let feedbackResponse: IFeedbackResponse | undefined;
				if( withFeedbackResponse ){
					const {updated, ...feedback} = (updateInfoData.data as IRemoteUpdateStatusWithFeedback);
					feedbackResponse = feedback;
				}

				if(refreshPlayerId!==undefined){
					yield getCorrectivesProgram({
						type: GET_CORRECTIVE_PROGRAM,
						payload: {
							playerId: refreshPlayerId,
							isProgramDayNew,
							token: (typeof valid === 'string') ? valid : token
						}
					});
				}
				
				yield put(
					updateCorrectiveExerciseStatusSuccess({
						...payload,
						token: (typeof valid === 'string') ? valid : token,
						feedbackResponse
					})
				);
			} else {
				throw new Error(`Error in request. ${updateInfoData}`);
			}
		}
	} catch(er){
		yield put(
			showAllCorrectivesError('Update Corrective Exercise Status API failed. '+er.message)
		);
	}

	// analytics
	trackExerciseEvent(status, exerciseKey, exerciseSide);
}

export function* callUpdateCorrectiveExerciseFeedback({payload}:IUpdateCorrectiveExerciseFeedback) {
	try {
		if( payload ){
			const {exerciseId, responseId, message, token} = payload;
			const updateInfoData = yield updateCorrectiveExerciseResponse(exerciseId, responseId, token, message);
			const valid = yield isUserTokenValid(updateInfoData.data, token);
			if(valid){
				if(updateInfoData && updateInfoData.data && updateInfoData.data.hasOwnProperty('updated')){
					
					yield put(
						updateCorrectiveExerciseFeedbackSuccess()
					);
				} else {
					throw new Error(`Error in updateCorrectiveExerciseFeedbackSuccess request. ${updateInfoData}`);
				}
			}
		} else {
			// skipped response
			yield put(
				updateCorrectiveExerciseFeedbackSuccess()
			);
		}
	} catch(er){
		yield put(
			showAllCorrectivesError('Update Corrective Exercise Response API data failed. '+er.message)
		);
	}
    
}


export function* onGetCorrective() {
    yield takeEvery(GET_CORRECTIVE, getCorrective);
}

export function* onGetAllCorrectives() {
    yield takeLatest(GET_ALL_CORRECTIVES, getAllCorrectives);
}

export function* onGetCorrectivesSet() {
    yield takeLatest(GET_CORRECTIVE_SET, getCorrectivesSet);
}

export function* onGetCorrectivesProgram() {
    yield takeLatest(GET_CORRECTIVE_PROGRAM, getCorrectivesProgram);
}

export function* onStartPlayerCorrectives() {
    yield takeLatest(START_CORRECTIVES, callStartPlayerCorrectives);
}

export function* onUpdateCorrectiveExerciseStatus() {
    yield takeLatest(UPDATE_CORRECTIVE_EXERCISE_STATUS, callUpdateCorrectiveExerciseStatus);
}

export function* onUpdateCorrectiveExerciseFeedback() {
    yield takeLatest(UPDATE_CORRECTIVE_EXERCISE_FEEDBACK, callUpdateCorrectiveExerciseFeedback);
}

export function* correctivesSagas() {
    yield all([
		call( onGetCorrective ),
		call( onGetAllCorrectives ),
		call( onGetCorrectivesSet ),
		call( onGetCorrectivesProgram ),
		call( onStartPlayerCorrectives ),
		call( onUpdateCorrectiveExerciseStatus ),
		call( onUpdateCorrectiveExerciseFeedback )
    ]);
}