import { BodyPartStandard, BodyParts, Side } from '../assessment/body-parts.types';
import { IDType, IData, UpdateStatus } from '../core.types';
import { IExercise, ISetExerciseCorrectiveAndUpdateStatus } from '../exercise/exercise.types';
import { IGetPlayer, ISetPlayer } from '../player/player.types';
import { ISignOutSuccess, ITokenPayload } from '../user/user.types';

import { MeasurementKey } from '../assessment/assessment.types';

export const SET_CORRECTIVES_LOADING = 'SET_CORRECTIVES_LOADING';
export const GET_ALL_CORRECTIVES = 'GET_ALL_CORRECTIVES';
export const SHOW_ALL_CORRECTIVES_ERROR = 'SHOW_ALL_CORRECTIVES_ERROR';
export const GET_CORRECTIVE = 'GET_CORRECTIVE';
export const GET_CORRECTIVE_SET = 'GET_CORRECTIVE_SET';
export const SET_CORRECTIVE_SET = 'SET_CORRECTIVE_SET';
export const SHOW_CORRECTIVE_ERROR = 'SHOW_CORRECTIVE_ERROR';
export const UPDATE_CORRECTIVE = 'UPDATE_CORRECTIVE';
export const SET_ALL_CORRECTIVES = 'SET_ALL_CORRECTIVES';
export const SET_CORRECTIVES_DAY_INDEX = 'SET_CORRECTIVES_DAY_INDEX';
export const GET_CORRECTIVE_PROGRAM = 'GET_CORRECTIVE_PROGRAM';
export const SET_CORRECTIVE_PROGRAM = 'SET_CORRECTIVE_PROGRAM';
export const START_CORRECTIVES = 'START_CORRECTIVES';
export const UPDATE_CORRECTIVE_EXERCISE_STATUS = 'UPDATE_CORRECTIVE_EXERCISE_STATUS';
export const UPDATE_CORRECTIVE_EXERCISE_STATUS_SUCCESS = 'UPDATE_CORRECTIVE_EXERCISE_STATUS_SUCCESS';
export const UPDATE_CORRECTIVE_EXERCISE_FEEDBACK = 'UPDATE_CORRECTIVE_EXERCISE_FEEDBACK';
export const UPDATE_CORRECTIVE_EXERCISE_FEEDBACK_SUCCESS = 'UPDATE_CORRECTIVE_EXERCISE_FEEDBACK_SUCCESS';
export const SET_CORRECTIVES_PLAN_ORDER = 'SET_CORRECTIVES_PLAN_ORDER';
export const SET_CORRECTIVES_PLAN_EXERCISE_ORDER = 'SET_CORRECTIVES_PLAN_EXERCISE_ORDER';
export const SET_SELECTED_CORRECTIVES_PLAN = 'SET_SELECTED_CORRECTIVES_PLAN';

// Data types
export enum CorrectiveStatus {
	ToDo = 'NEW', // not started
	Started = 'STARTED',
	Skipped = 'SKIPPED',
	InProgress = 'IN_PROGRESS',
	Completed = 'COMPLETE'
}
export enum CorrectiveExerciseStatus {
	ToDo = 'NEW', // not started
	Started = 'STARTED',
	Skipped = 'SKIPPED',
	InProgress = 'IN_PROGRESS',
	Completed = 'DONE'
}
export interface ICorrectiveExercise extends IExercise {
	status: CorrectiveExerciseStatus;
	statusDate?:string | null;
	bodyPart?:BodyParts | null;
	side?:Side | null;
	order?:number | null;
	updateStatus?:UpdateStatus;
}
export interface ICorrectiveDay extends IData {
	exercises:ICorrectiveExercise[];
	duration?:number | null;
	assignmentDate:string;
	status: CorrectiveStatus;
}

export interface ICorrectiveExercisePlan extends ICorrectiveDay {
	progress:number;
	bodyPartStandard?:BodyPartStandard | null;
	side?:Side | null;
	measurementKey?:MeasurementKey | string | null; //measurement key
	measurementName:string;
	exercisesDone?:number | null;
	exercisesSkipped?:number | null;
	totalExercises?:number | null;
	uid:string;
	order?:number | null;
}

export interface ICorrectiveSet extends IData {
	plans:ICorrectiveExercisePlan[];
	duration?:number | null;
	status: CorrectiveStatus;
	day:number;
	startDate?:string | null;
	completeDate?:string | null;
	assignmentDate?:string | null;
}

export enum CorrectiveType {
	Corrective = 'CORRECTIVE',
	Training = 'TRAINING'
}

export interface ICorrectiveLimited extends IData {
	name:string;
	error?:string;
	type:CorrectiveType;
}
export interface ICorrective extends ICorrectiveLimited {
	days:ICorrectiveDay[];
}
export type LoadableCorrectiveType = ICorrectiveLimited | ICorrective;

export interface ICorrectiveProgram extends ICorrectiveLimited {
	status: CorrectiveStatus;
    startDate?:string | null;
	completeDate?:string | null;
	days:ICorrectiveSet[];
}

export interface ICorrectiveErrorsMap {
	[correctiveId:number]:string;
}

export interface ICorrectivesMap {
	[correctiveId:number]:LoadableCorrectiveType;
}

export interface IResponseOption extends IData {
	optionMessage: string | null; 
    optionIconUrl: string | null;  
    allowCustomMessage: boolean | null; 
    order: number | null;
}

export enum FeedbackResponseType {
	SkipExercise = 'SKIP_EXERCISE',
	SkipProgramDay = 'SKIP_PROGRAMDAY', 
	CompleteExercise = 'COMPLETE_EXERCISE',
	CompleteProgramDayKPI = 'COMPLETE_PROGRAMDAYKPI',
	CompleteProgramDay = 'COMPLETE_PROGRAMDAY',
	CompleteProgram = 'COMPLETE_PROGRAM'
}
export interface IFeedbackResponse {
	responseRequired: boolean | null;
	responseType: FeedbackResponseType;
	label: string | null;
	responseOptions: IResponseOption[];
}


// State type
export interface ICorrectivesState {
	isLoading:boolean;
	playerId?:IDType;
	correctivesOrder?:IDType[],
	correctivesMap:ICorrectivesMap;
	error?:(string | null);
	correctiveErrorsMap:ICorrectiveErrorsMap;
	selectedDayIndex?:number;
	correctivesSet?:ICorrectiveSet;
	correctivesProgram?:ICorrectiveProgram;
	selectedPlan?:ICorrectiveExercisePlan;
	correctivesPlanOrder?:string[];
	correctivesPlanScrollPosition?:number;
	correctivesPlanExerciseOrder?:string[];
	correctivesPlanExerciseScrollPosition?:number;
	feedbackResponse?:IFeedbackResponse;
}


// Action types
export interface IGetAllCorrectivesPayload extends ITokenPayload {
	playerId:IDType;
	isProgramDayNew?:boolean;
}
export interface IGetAllCorrectives {
	type: typeof GET_ALL_CORRECTIVES;
	payload: IGetAllCorrectivesPayload;
};
export interface ISetCorrectivesLoading {
	type: typeof SET_CORRECTIVES_LOADING;
	payload: boolean;
};
export interface IShowAllCorrectivesError {
    type: typeof SHOW_ALL_CORRECTIVES_ERROR;
    payload:string;
}


export interface IGetCorrectivePayload {
	id:IDType;
	playerId:IDType;
}
export interface IGetCorrective {
	type: typeof GET_CORRECTIVE;
	payload: IGetCorrectivePayload;
};

export interface IShowGetCorrectiveErrorPayload {
	correctiveId:IDType;
	error:string;
}
export interface IShowGetCorrectiveError {
    type: typeof SHOW_CORRECTIVE_ERROR;
    payload:IShowGetCorrectiveErrorPayload;
}

export interface ISetAllCorrectivesPayload {
	correctives:LoadableCorrectiveType[];
	playerId:IDType;
}
export interface ISetAllCorrectives {
	type: typeof SET_ALL_CORRECTIVES;
	payload:ISetAllCorrectivesPayload;
};

export interface IUpdateCorrectivePayload {
	corrective:ICorrective;
	playerId:IDType;
}
export interface IUpdateCorrective {
	type: typeof UPDATE_CORRECTIVE;
	payload: IUpdateCorrectivePayload;
};

export interface ISetCorrectivesDayIndex {
	type: typeof SET_CORRECTIVES_DAY_INDEX;
	payload: number; // day index
};

export interface IGetCorrectiveSetPayload extends ITokenPayload {
	dateIndex?:number;
	isProgramDayNew?:boolean;
	playerId:IDType;
}
export interface IGetCorrectiveSet {
	type: typeof GET_CORRECTIVE_SET;
	payload: IGetCorrectiveSetPayload;
};

export interface ISetCorrectivesSetPayload {
	correctivesSet?:ICorrectiveSet;
	playerId:IDType;
	dateIndex?:number;
}
export interface ISetCorrectivesSet {
	type: typeof SET_CORRECTIVE_SET;
	payload:ISetCorrectivesSetPayload;
};

export interface IGetCorrectiveProgramPayload extends ITokenPayload {
	isProgramDayNew?:boolean;
	playerId:IDType;
}
export interface IGetCorrectiveProgram {
	type: typeof GET_CORRECTIVE_PROGRAM;
	payload: IGetCorrectiveProgramPayload;
};

export interface ISetCorrectivesProgramPayload {
	correctivesProgram?:ICorrectiveProgram;
	playerId:IDType;
}
export interface ISetCorrectivesProgram {
	type: typeof SET_CORRECTIVE_PROGRAM;
	payload:ISetCorrectivesProgramPayload;
};

export interface IStartCorrectives {
	type: typeof START_CORRECTIVES;
	payload:IGetCorrectiveSetPayload;
};

export interface IUpdateCorrectiveExerciseStatusPayload extends ITokenPayload {
	exerciseId:IDType;
	exerciseKey:string;
	exerciseSide?:Side;
	status:CorrectiveExerciseStatus;
	withFeedbackResponse:boolean;
	refreshPlayerId?:IDType;
	isProgramDayNew?:boolean;
}
export interface IUpdateCorrectiveExerciseStatus {
	type: typeof UPDATE_CORRECTIVE_EXERCISE_STATUS;
	payload:IUpdateCorrectiveExerciseStatusPayload;
};

export interface IUpdateCorrectiveExerciseStatusSuccessPayload extends IUpdateCorrectiveExerciseStatusPayload {
	feedbackResponse?:IFeedbackResponse;
}
export interface IUpdateCorrectiveExerciseStatusSuccess {
	type: typeof UPDATE_CORRECTIVE_EXERCISE_STATUS_SUCCESS;
	payload:IUpdateCorrectiveExerciseStatusSuccessPayload;
};

export interface IUpdateCorrectiveExerciseFeedbackPayload extends ITokenPayload {
	exerciseId:IDType;
	responseId:IDType;
	message?:string;
}
export interface IUpdateCorrectiveExerciseFeedback {
	type: typeof UPDATE_CORRECTIVE_EXERCISE_FEEDBACK;
	payload?: IUpdateCorrectiveExerciseFeedbackPayload;
}

export interface IUpdateCorrectiveExerciseFeedbackSuccess {
	type: typeof UPDATE_CORRECTIVE_EXERCISE_FEEDBACK_SUCCESS;
}

export interface ISetCorrectivesOrderPayload {
	order:string[];
	scrollPosition?:number;
}
export interface ISetCorrectivesPlanOrder {
	type: typeof SET_CORRECTIVES_PLAN_ORDER;
	payload:ISetCorrectivesOrderPayload;
};

export interface ISetCorrectivesPlanExerciseOrder {
	type: typeof SET_CORRECTIVES_PLAN_EXERCISE_ORDER;
	payload?:ISetCorrectivesOrderPayload;
};

export interface ISetSelectedCorrectivesPlan {
	type: typeof SET_SELECTED_CORRECTIVES_PLAN;
	payload?:ICorrectiveExercisePlan;
};

export type TypeCorrectivesAction = IGetCorrectiveProgram | ISetCorrectivesProgram | ISetSelectedCorrectivesPlan | ISetCorrectivesLoading | IGetAllCorrectives | IShowAllCorrectivesError | IGetCorrective | 
IShowGetCorrectiveError | ISetAllCorrectives | IUpdateCorrective | ISetPlayer | IGetPlayer | ISetCorrectivesDayIndex | IGetCorrectiveSet | ISetCorrectivesSet | IStartCorrectives | IUpdateCorrectiveExerciseStatus | IUpdateCorrectiveExerciseStatusSuccess | ISetCorrectivesPlanOrder | 
ISetCorrectivesPlanExerciseOrder | ISetExerciseCorrectiveAndUpdateStatus | ISignOutSuccess | IUpdateCorrectiveExerciseFeedback | IUpdateCorrectiveExerciseFeedbackSuccess;