import { IDType, IData } from '../core.types';
import { OrgRole, OrgRoles } from '../org/org.types';
import { ProfileItem, ProfileItemType, Subscription } from '../player/player.types';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SELECT_USER = 'SELECT_USER';
export const CHECK_USER_SESSION = 'CHECK_USER_SESSION';
export const EMAIL_SIGN_IN_START = 'EMAIL_SIGN_IN_START';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_OUT_START = 'SIGN_OUT_START';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const TEMP_PASSWORD = 'TEMP_PASSWORD';
export const UPDATE_TEMP_PASSWORD = 'UPDATE_TEMP_PASSWORD';
export const TEMP_PASSWORD_SUCCESS = 'TEMP_PASSWORD_SUCCESS';
export const TEMP_PASSWORD_FAILURE = 'TEMP_PASSWORD_FAILURE';

export const GET_TERMS = 'GET_TERMS';
export const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';
export const GET_TERMS_FAILURE = 'GET_TERMS_FAILURE';

export const ACCEPT_TERMS = 'ACCEPT_TERMS';
export const ACCEPT_TERMS_SUCCESS = 'ACCEPT_TERMS_SUCCESS';
export const ACCEPT_TERMS_FAILURE = 'ACCEPT_TERMS_FAILURE';

export const EXTEND_USER_SESSION_START = 'EXTEND_USER_SESSION_START';
export const EXTEND_USER_SESSION_SUCCESS = 'EXTEND_USER_SESSION_SUCCESS';
export const CHECK_LAST_LOGIN = 'CHECK_LAST_LOGIN';
export const CHECK_LAST_LOGIN_SUCCESS = 'CHECK_LAST_LOGIN_SUCCESS';

export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const GET_USER = 'GET_USER';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';

export const GET_ADD_USER_CONFIG = 'GET_ADD_USER_CONFIG';
export const SET_ADD_USER_CONFIG = 'SET_ADD_USER_CONFIG';
export const GET_ADD_USER_CONFIG_ERROR = 'GET_ADD_USER_CONFIG_ERROR';

export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS';

export const SET_USE_PLAYER_TIMEZONE = 'SET_USE_PLAYER_TIMEZONE';
export const SET_SHOW_PLAYER_EXERCISE_FINISHED_TIME = 'SET_SHOW_PLAYER_EXERCISE_FINISHED_TIME';

export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_FAILURE = 'RESET_USER_PASSWORD_FAILURE';


export enum UserLevel {
    Regular,
    Admin
}

export enum UserStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    PENDING = 'PENDING'
}

export enum UserConfigType {
    PROGRAMDAY_MAX_TIME = 'PROGRAMDAY_MAX_TIME',
    LICENSE_TYPE = 'LICENSE_TYPE',
    TIMEZONE = 'TIMEZONE',
    RISK_LEVEL_1 = 'RISK_LEVEL_1',
    RISK_LEVEL_2 = 'RISK_LEVEL_2',
    ALLOW_PARTIAL_ASSESSMENT = 'ALLOW_PARTIAL_ASSESSMENT',
    ALGORITHM_VERSION = 'ALGORITHM_VERSION',
    PROGRAM_STATE_MAX_DAYS_MISSED = 'PROGRAM_STATE_MAX_DAYS_MISSED',
    PROGRAM_STATE_MAX_DAYS_MISSED_SEVERE = 'PROGRAM_STATE_MAX_DAYS_MISSED_SEVERE',
    PROGRAM_STATE_MAX_DAYS_SKIPPED = 'PROGRAM_STATE_MAX_DAYS_SKIPPED',
    PROGRAM_STATE_MIN_DAYS_IDLE = 'PROGRAM_STATE_MIN_DAYS_IDLE',
    PROGRAM_DAYS_TO_ASSESSMENT_LATE = 'PROGRAM_DAYS_TO_ASSESSMENT_LATE',
    PROGRAM_TOTAL_DAYS = 'PROGRAM_TOTAL_DAYS',
    COMPLIANCE_MINIMUM_GREEN = 'COMPLIANCE_MINIMUM_GREEN',
    COMPLIANCE_MINIMUM_YELLOW = 'COMPLIANCE_MINIMUM_YELLOW',
    COMPLIANCE_WORKOUT_PERCENTAGE = 'COMPLIANCE_WORKOUT_PERCENTAGE',
    ROLE_COMPLIANCE = 'ROLE_COMPLIANCE'
}

export type SimpleUserConfig = Record<UserConfigType, string>;
export type UserRoleComplianceKey = string;
export type UserRoleComplianceConfig = Record<UserRoleComplianceKey, SimpleUserConfig>;
export type UserConfig = SimpleUserConfig | Record<UserConfigType, string | UserRoleComplianceConfig>;

export enum Environment {
    Prod = 'prod',
    QA2 = 'qa2',
    Sandbox = 'sandbox',
    Staging = 'staging'
}

// Data types
export interface IUser extends IData { // logged in user
    firstName?:string;
    lastName?:string;
    displayName:string;
    emailAddress:string;
    type:UserLevel;
    title?:string;
    image?:string;
    timestamp:string;
    lastLogin:string;
    orgRoles:OrgRoles;
    needsNewPassword?:boolean;
    tempPassword?:string;
    termsAccepted?:boolean | null;
    termsUrl?:string;
    token:string;
    tokenTTL: number;
    idToken:string;
    profileItems?:ProfileItem[] | null;
    config?:UserConfig | null;
    status?:UserStatus | null;
    showAssessmentInMobile:boolean;
    hasStaffAccess:boolean;
    env:Environment | string;
    showCompliance?:boolean;
    subscription?:Subscription | null;
}

export interface IViewUser extends Pick<IUser, 'id' | 'displayName' | 'image' | 'profileItems' | 'config' | 'orgRoles'> { // other users pulled in via APIs
    firstName:string;
    lastName:string;
    hasConfig?:boolean | null;
    configDefault?:UserConfig | null;
    sportRole?:string | null;
    sportRoleOptions?:string[] | null;
    team?:string | null;
    teamOptions?:string[] | null;
    status:UserStatus;
    email?:string | null;
    password?:string;
    enableLogin:boolean;
    expirationDate?:string | null;
    pushNotificationsMessages?:boolean | null;
    pushNotificationsPriority?:number | null;
}

export type IAddUserConfig = Pick<IViewUser, 'configDefault' | 'sportRoleOptions' | 'team' | 'teamOptions' | 'enableLogin' | 'expirationDate' | 'pushNotificationsMessages' | 'pushNotificationsPriority'> & {
    profileItemOptions?:ProfileItemType[] | null;
    orgRoleOptions?:OrgRole[] | null;
};

// new users that just join have created their password
export interface ITempUser extends Pick<IUser, 'emailAddress'> {
    orgRoles:OrgRole[]
}

export interface ILastLoginInfo {
    username?:string;
}

// user ready to navigate the site
export const isUserReady = (user?:IUser) => !!user && !!user.termsAccepted && !user.needsNewPassword;


// State type
export interface IUserState {
    isLoading:boolean;
    currentUser?:IUser; // logged in user
    selectedUser?:IViewUser; // selected user to view or edit
    error?:(string | null);
    changePasswordLoading:boolean;
    changePasswordSuccess:boolean;
    changePasswordError?:string;
    forgotPasswordLoading:boolean;
    forgotPasswordSuccess:boolean;
    forgotPasswordError?:string;
    resetPasswordLoading:boolean;
    resetPasswordSuccess:boolean;
    resetPasswordError?:string;
    tempPasswordLoading:boolean;
    tempPasswordSuccess:boolean;
    tempPasswordError?:string;
    tempUser?:ITempUser;
    termsIsLoading:boolean;
    termsContent?:string;
    termsError?:string;
    lastLoginInfo?:ILastLoginInfo;
    isUsersLoading:boolean;
    users?:IViewUser[];
    usersError?:string;
    isGetUserLoading:boolean;
    getUserError?:string;
    editUserSuccess?:boolean;
    editUserError?:string;
    isAddUserLoading:boolean;
    addUserSuccess?:boolean;
    addUserError?:string;
    addUserConfig?:IAddUserConfig;
    isAddUserConfigLoading:boolean;
    addUserConfigError?:string;
    //isUserPhotoUploading:boolean;
    usePlayerTimezone?:boolean;
    showExerciseFinishedTime?:boolean;
    resetUserPasswordLoading:boolean;
    resetUserPasswordSuccess:boolean;
    resetUserPasswordError?:string;
}

// Core Payloads
export interface ITokenPayload {
    token:string;
}


// Action types
export interface ISignInSuccess {
    type: typeof SIGN_IN_SUCCESS;
    payload: IUser;
};

interface ISignInFailure {
    type: typeof SIGN_IN_FAILURE;
    payload: string;
};

export interface IEmailSignInStartPayload {
    email:string;
    password:string;
}
interface IEmailSignInStart {
    type: typeof EMAIL_SIGN_IN_START;
    payload: IEmailSignInStartPayload;
};
export type TypeEmailSignInStartPayload = IEmailSignInStartPayload;
export type TypeEmailSignInStartAction = IEmailSignInStart;

interface ICheckUserSession {
    type: typeof CHECK_USER_SESSION;
};

export interface ISignOutStart {
    type: typeof SIGN_OUT_START;
    payload?:ITokenPayload;
};

export interface ISignOutSuccess {
    type: typeof SIGN_OUT_SUCCESS;
};

interface ISignOutFailure {
    type: typeof SIGN_OUT_FAILURE;
    payload: string;
};

export interface IChangePasswordPayload extends ITokenPayload {
    userId:string;
    oldPassword:string;
    newPassword:string;
}
export interface IChangePassword {
    type: typeof CHANGE_PASSWORD;
    payload: IChangePasswordPayload;
};

export interface IChangePasswordSuccess {
    type: typeof CHANGE_PASSWORD_SUCCESS;
};

export interface IChangePasswordFailure {
    type: typeof CHANGE_PASSWORD_FAILURE;
    payload: string;
};

export interface IChangePasswordReset {
    type: typeof CHANGE_PASSWORD_RESET;
};

export interface IForgotPasswordPayload {
    userEmail:string;
    keepUserState?:boolean;
}

export interface IForgotPassword {
    type: typeof FORGOT_PASSWORD;
    payload: IForgotPasswordPayload;
};

export interface IForgotPasswordSuccess {
    type: typeof FORGOT_PASSWORD_SUCCESS;
};

export interface IForgotPasswordFailure {
    type: typeof FORGOT_PASSWORD_FAILURE;
    payload: string;
};

export interface IResetPasswordPayload {
    userId:string;
    resetCode:string;
    newPassword:string;
}
export interface IResetPassword {
    type: typeof RESET_PASSWORD;
    payload: IResetPasswordPayload;
};

export interface IResetPasswordSuccess {
    type: typeof RESET_PASSWORD_SUCCESS;
    payload:ITempUser;
};

export interface IResetPasswordFailure {
    type: typeof RESET_PASSWORD_FAILURE;
    payload: string;
};

export interface ITempPasswordPayload {
    userId:string;
    tempPassword:string;
    newPassword:string;
    isTokenFormat:boolean;
}
export interface ITempPassword {
    type: typeof TEMP_PASSWORD;
    payload: ITempPasswordPayload;
};

export interface ITempPasswordSuccess {
    type: typeof TEMP_PASSWORD_SUCCESS;
    payload?:ITempUser;
};

export interface ITempPasswordFailure {
    type: typeof TEMP_PASSWORD_FAILURE;
    payload: string;
};

export interface IUpdateTempPasswordPayload extends ITokenPayload {
    userId:IDType;
    newPassword:string;
    sendEmail?:boolean;
}
export interface IUpdateTempPassword {
    type: typeof UPDATE_TEMP_PASSWORD;
    payload: IUpdateTempPasswordPayload;
};

export interface IGetTerms {
    type: typeof GET_TERMS;
    payload: string; // url
};

export interface IGetTermsSuccess {
    type: typeof GET_TERMS_SUCCESS;
    payload: string; // content
};

export interface IGetTermsFailure {
    type: typeof GET_TERMS_FAILURE;
    payload: string; // error
};

export interface IAcceptTerms {
    type: typeof ACCEPT_TERMS;
    payload:ITokenPayload;
};

export interface IAcceptTermsSuccess {
    type: typeof ACCEPT_TERMS_SUCCESS;
};

export interface IAcceptTermsFailure {
    type: typeof ACCEPT_TERMS_FAILURE;
    payload: string; // error
};

export interface IExtendUserSessionStart {
    type: typeof EXTEND_USER_SESSION_START;
    payload: ITokenPayload;
};

export interface IExtendUserSessionSuccessPayload extends ITokenPayload {
    lastLogin:string;
    tokenTTL: number;
    idToken:string;
};
export interface IExtendUserSessionSuccess {
    type: typeof EXTEND_USER_SESSION_SUCCESS;
    payload: IExtendUserSessionSuccessPayload;
};

export interface ICheckLastLogin {
    type: typeof CHECK_LAST_LOGIN;
};

export interface ICheckLastLoginSuccessPayload {
    username?:string;
}
export interface ICheckLastLoginSuccess {
    type: typeof CHECK_LAST_LOGIN_SUCCESS;
    payload: ICheckLastLoginSuccessPayload;
};

export interface IGetUsersPayload extends ITokenPayload {
    orgId:IDType;
};

export interface IGetUsers {
    type: typeof GET_USERS;
    payload: IGetUsersPayload;
};

export interface ISetUsers {
    type: typeof SET_USERS;
    payload?: IViewUser[];
};

export interface IGetUsersError {
    type: typeof GET_USERS_ERROR;
    payload: string;
};

export interface ISelectUserPayload {
    user?:IViewUser;
    isLoading?:boolean;
}

export interface ISelectUser {
    type: typeof SELECT_USER;
    payload: ISelectUserPayload;
};

export interface IGetUserPayload extends ITokenPayload {
    userId:IDType;
};

export interface IGetUser {
    type: typeof GET_USER;
    payload: IGetUserPayload;
};

export interface IGetUserError {
    type: typeof GET_USER_ERROR;
    payload: string;
};

export interface IEditUserPayload extends ITokenPayload {
    user:IViewUser;
    sendEmail?:boolean;
};

export interface IEditUser {
    type: typeof EDIT_USER;
    payload: IEditUserPayload;
};

export interface IEditUserSuccess {
    type: typeof EDIT_USER_SUCCESS;
    payload?: IViewUser;
};

export interface IEditUserError {
    type: typeof EDIT_USER_ERROR;
    payload: string;
};

export interface IAddUserPayload extends IEditUserPayload {};
export interface IAddUser {
    type: typeof ADD_USER;
    payload: IAddUserPayload;
};

export interface IAddUserSuccess {
    type: typeof ADD_USER_SUCCESS;
};

export interface IAddUserError {
    type: typeof ADD_USER_ERROR;
    payload: string;
};

export interface IGetAddUserConfigPayload extends ITokenPayload {
    orgId:IDType;
};

export interface IGetAddUserConfig {
    type: typeof GET_ADD_USER_CONFIG;
    payload: IGetAddUserConfigPayload;
};

export interface ISetAddUserConfig {
    type: typeof SET_ADD_USER_CONFIG;
    payload: IAddUserConfig;
};

export interface IGetAddUserConfigError {
    type: typeof GET_ADD_USER_CONFIG_ERROR;
    payload: string;
};

export interface IChangeUserStatusPayload extends ITokenPayload {
    userId:IDType;
    activate:boolean;
    refreshUsers?:Omit<IGetUsersPayload, 'token'>;
};

export interface IChangeUserStatus {
    type: typeof CHANGE_USER_STATUS;
    payload: IChangeUserStatusPayload;
};

export interface ISetUsePlayerTimezone {
    type: typeof SET_USE_PLAYER_TIMEZONE;
    payload?:boolean;
};

export interface ISetShowPlayerExerciseFinishedTime {
    type: typeof SET_SHOW_PLAYER_EXERCISE_FINISHED_TIME;
    payload?:boolean;
};

export interface IResetUserPasswordPayload extends ITokenPayload {
    userId:string;
    newPassword:string;
}
export interface IResetUserPassword {
    type: typeof RESET_USER_PASSWORD;
    payload: IResetUserPasswordPayload;
};

export interface IResetUserPasswordSuccess {
    type: typeof RESET_USER_PASSWORD_SUCCESS;
};

export interface IResetUserPasswordFailure {
    type: typeof RESET_USER_PASSWORD_FAILURE;
    payload: string;
};


export type TypeUserAction = ISignInSuccess  | ISignInFailure  | IEmailSignInStart | ICheckUserSession | 
ISignOutStart | ISignOutSuccess | ISignOutFailure | IChangePassword | IChangePasswordSuccess | IChangePasswordFailure | IChangePasswordReset | 
IForgotPassword | IForgotPasswordSuccess | IForgotPasswordFailure | IResetPassword | IResetPasswordSuccess | IResetPasswordFailure | ITempPassword | 
ITempPasswordSuccess | ITempPasswordFailure | IGetTerms | IGetTermsSuccess | IGetTermsFailure | IExtendUserSessionStart | IExtendUserSessionSuccess | 
ICheckLastLogin | ICheckLastLoginSuccess | IAcceptTerms | IAcceptTermsSuccess | IAcceptTermsFailure | IGetUsers | ISetUsers | IGetUsersError | ISelectUser | 
IGetUser | IGetUserError | IEditUser | IEditUserSuccess | IEditUserError | IAddUser | IAddUserSuccess | IAddUserError | 
IChangeUserStatus | ISetUsePlayerTimezone | ISetShowPlayerExerciseFinishedTime | IGetAddUserConfig | ISetAddUserConfig | IGetAddUserConfigError | 
IResetUserPassword | IResetUserPasswordSuccess | IResetUserPasswordFailure | IUpdateTempPassword;