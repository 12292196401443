import { IOrg, IOrgState } from './org/org.types';

import SrcMap from '../utils/src-map';
import { SrcMapEntryType } from '../utils/src-map';
import { createTransform } from 'redux-persist';
import { ReportTableColumnsMap, ReportTableType } from './report/report.types';

export interface SavedOrg extends Omit<IOrg, 'image' | 'loginImage' | 'backgroundImage' | 'children'> {
    image?:SrcMapEntryType[] | null;
    loginImage?:SrcMapEntryType[] | null;
    backgroundImage?:SrcMapEntryType[] | null;
    children?:SavedOrg[];
}
export const convertOrg = (org:IOrg):SavedOrg => {
    const {image, loginImage, backgroundImage, children} = org;
    return {
        ...org,
        image: image ? SrcMap.convertMapToArray(image) : image,
        loginImage: loginImage ? SrcMap.convertMapToArray(loginImage) : loginImage,
        backgroundImage: backgroundImage ? SrcMap.convertMapToArray(backgroundImage) : backgroundImage,
        children: children ? children.map(childOrg => convertOrg(childOrg)) : children
    };
}
export const parseOrg = (savedOrg:SavedOrg):IOrg => {
    const {image, loginImage, backgroundImage, children} = savedOrg;
    return {
        ...savedOrg,
        image: image ? SrcMap.createSrcMap(image) : image,
        loginImage: loginImage ? SrcMap.createSrcMap(loginImage) : loginImage,
        backgroundImage: backgroundImage ? SrcMap.createSrcMap(backgroundImage) : backgroundImage,
        children: children ? children.map(childOrg => parseOrg(childOrg)) : children
    };
}

export const rootStateTransform = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState:IOrgState, key) => {
        // convert Map to an Array.
        const {org, allOrgs} = inboundState;
        
        return { 
            ...inboundState,
            org: org ? convertOrg(org) : undefined,
            allOrgs: allOrgs ? allOrgs.map(subOrg => convertOrg(subOrg)) : undefined
        };
    },
    // transform state being rehydrated
    (outboundState, key) => {
        // convert Array back to a Map.
        const {org, allOrgs} = outboundState;
        
        return {
            ...outboundState,
            org: org ? parseOrg(org) : undefined,
            allOrgs: allOrgs ? allOrgs.map(subOrg => parseOrg(subOrg)) : undefined
        };
    },
    // define which reducers this transform gets called for.
    { whitelist: ['org'] }
);

export const orgStateTransform = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundProp, key) => {
        // convert Map to an Array.
        switch( key ){
            case 'org' :
                return inboundProp ? convertOrg(inboundProp as IOrg) : undefined;
            case 'allOrgs' :
                return inboundProp ? (inboundProp as IOrg[]).map(subOrg => convertOrg(subOrg)) : undefined;
            default :
                return inboundProp;
        }
    },
    // transform state being rehydrated
    (outboundProp, key) => {
        // convert Array back to a Map.
        switch( key ){
            case 'org' :
                return outboundProp ? parseOrg(outboundProp as SavedOrg) : undefined;
            case 'allOrgs' :
                return outboundProp ? (outboundProp as SavedOrg[]).map(subOrg => parseOrg(subOrg)) : undefined;
            default :
                return outboundProp;
        }
    },
    // define which reducers this transform gets called for.
    { whitelist: ['org', 'allOrgs'] }
);

type ReportTableColumnsConfig = [ReportTableType, string[] | undefined];
type ReportTableColumnsArray = ReportTableColumnsConfig[];
export const reportStateTransform = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundProp, key) => {
        // convert Map to an Array.
        switch( key ){
            case 'columnsMap' :
                const arr = inboundProp ? Array.from(inboundProp as ReportTableColumnsMap) : undefined;
                return arr;
            default :
                return inboundProp;
        }
    },
    // transform state being rehydrated
    (outboundProp, key) => {
        // convert Array back to a Map.
        switch( key ){
            case 'columnsMap' :
                const map = outboundProp ? new Map(outboundProp as ReportTableColumnsArray) : undefined;
                return map;
            default :
                return outboundProp;
        }
    },
    // define which reducers this transform gets called for.
    { whitelist: ['columnsMap'] }
);