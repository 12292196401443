import { supportsCSSVars } from '../utils/PMotionGlobals';
import { useCallback } from "react";
import useWindowSize from './useWindowSize.hook';

const useDocumentSizer = () => {

	const onWindowSize = useCallback((width:number, height:number) => {
		if( supportsCSSVars ){
			// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
			const newVh = height * 0.01;
			// Then we set the value in the --vh custom property to the root of the document
			document.documentElement.style.setProperty('--vh', `${newVh}px`);
		}
	}, []);
	
	const [w, h] = useWindowSize(onWindowSize, 100);
	onWindowSize(w.current, h.current);
}

export default useDocumentSizer;