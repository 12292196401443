import { ACCEPT_TERMS, ACCEPT_TERMS_FAILURE, ACCEPT_TERMS_SUCCESS, ADD_USER, ADD_USER_ERROR, ADD_USER_SUCCESS, CHANGE_USER_STATUS, CHECK_LAST_LOGIN, CHECK_LAST_LOGIN_SUCCESS, EDIT_USER, EDIT_USER_ERROR, EDIT_USER_SUCCESS, EXTEND_USER_SESSION_START, EXTEND_USER_SESSION_SUCCESS, GET_ADD_USER_CONFIG, GET_ADD_USER_CONFIG_ERROR, GET_USER, GET_USERS, GET_USERS_ERROR, GET_USER_ERROR, IAcceptTerms, IAcceptTermsFailure, IAcceptTermsSuccess, IAddUser, IAddUserConfig, IAddUserError, IAddUserPayload, IAddUserSuccess, IChangeUserStatus, IChangeUserStatusPayload, ICheckLastLogin, ICheckLastLoginSuccess, ICheckLastLoginSuccessPayload, IEditUser, IEditUserError, IEditUserPayload, IEditUserSuccess, IExtendUserSessionStart, IExtendUserSessionSuccess, IExtendUserSessionSuccessPayload, IGetAddUserConfig, IGetAddUserConfigError, IGetAddUserConfigPayload, IGetUser, IGetUserError, IGetUserPayload, IGetUsers, IGetUsersError, IGetUsersPayload, IResetUserPassword, IResetUserPasswordFailure, IResetUserPasswordPayload, IResetUserPasswordSuccess, ISelectUser, ISelectUserPayload, ISetAddUserConfig, ISetShowPlayerExerciseFinishedTime, ISetUsePlayerTimezone, ISetUsers, IUpdateTempPassword, IUpdateTempPasswordPayload, IViewUser, RESET_USER_PASSWORD, RESET_USER_PASSWORD_FAILURE, RESET_USER_PASSWORD_SUCCESS, SELECT_USER, SET_ADD_USER_CONFIG, SET_SHOW_PLAYER_EXERCISE_FINISHED_TIME, SET_USERS, SET_USE_PLAYER_TIMEZONE, UPDATE_TEMP_PASSWORD } from './user.types';
import {
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_RESET,
    CHANGE_PASSWORD_SUCCESS,
    CHECK_USER_SESSION,
    EMAIL_SIGN_IN_START,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_SUCCESS,
    GET_TERMS,
    GET_TERMS_FAILURE,
    GET_TERMS_SUCCESS,
    IChangePasswordPayload,
    IForgotPassword,
    IForgotPasswordFailure,
    IForgotPasswordPayload,
    IForgotPasswordSuccess,
    IGetTerms,
    IGetTermsFailure,
    IGetTermsSuccess,
    IResetPassword,
    IResetPasswordFailure,
    IResetPasswordPayload,
    IResetPasswordSuccess,
    ITempPassword,
    ITempPasswordFailure,
    ITempPasswordPayload,
    ITempPasswordSuccess,
    ITempUser,
    ITokenPayload,
    IUser,
    RESET_PASSWORD,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_SUCCESS,
    SIGN_IN_FAILURE,
    SIGN_IN_SUCCESS,
    SIGN_OUT_FAILURE,
    SIGN_OUT_START,
    SIGN_OUT_SUCCESS,
    TEMP_PASSWORD,
    TEMP_PASSWORD_FAILURE,
    TEMP_PASSWORD_SUCCESS,
    TypeEmailSignInStartPayload
} from './user.types';

export const signInSuccess = (user:IUser) => ({
    type: SIGN_IN_SUCCESS,
    payload: user
});

export const signInFailure = (error:string) => ({
    type: SIGN_IN_FAILURE,
    payload: error
});

export const emailSignInStart = (emailAndPassword:TypeEmailSignInStartPayload) => ({
    type: EMAIL_SIGN_IN_START,
    payload: emailAndPassword
});

export const checkUserSession = () => ({
    type: CHECK_USER_SESSION
});

export const signOutStart = (payload?:ITokenPayload) => ({
    type: SIGN_OUT_START,
    payload
});

export const signOutSuccess = () => ({
    type: SIGN_OUT_SUCCESS
});

export const signOutFailure = (error:string) => ({
    type: SIGN_OUT_FAILURE,
    payload: error
});

export const extendUserSessionStart = (payload:ITokenPayload):IExtendUserSessionStart => ({
    type: EXTEND_USER_SESSION_START,
    payload
});

export const extendUserSessionSuccess = (payload:IExtendUserSessionSuccessPayload):IExtendUserSessionSuccess => ({
    type: EXTEND_USER_SESSION_SUCCESS,
    payload
});

export const changePassword = (passwordPayload:IChangePasswordPayload) => ({
    type: CHANGE_PASSWORD,
    payload: passwordPayload
});

export const changePasswordSuccess = () => ({
    type: CHANGE_PASSWORD_SUCCESS
});

export const changePasswordFailure = (error:string) => ({
    type: CHANGE_PASSWORD_FAILURE,
    payload: error
});

export const changePasswordReset = () => ({
    type: CHANGE_PASSWORD_RESET
});

export const forgotPassword = (payload:IForgotPasswordPayload):IForgotPassword => ({
    type: FORGOT_PASSWORD,
    payload
});

export const forgotPasswordSuccess = ():IForgotPasswordSuccess => ({
    type: FORGOT_PASSWORD_SUCCESS
});

export const forgotPasswordFailure = (error:string):IForgotPasswordFailure => ({
    type: FORGOT_PASSWORD_FAILURE,
    payload: error
});

export const resetPassword = (payload:IResetPasswordPayload):IResetPassword => ({
    type: RESET_PASSWORD,
    payload
});

export const resetPasswordSuccess = (tempUser:ITempUser):IResetPasswordSuccess => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: tempUser
});

export const resetPasswordFailure = (error:string):IResetPasswordFailure => ({
    type: RESET_PASSWORD_FAILURE,
    payload: error
});

export const changeTempPassword = (payload:ITempPasswordPayload):ITempPassword => ({
    type: TEMP_PASSWORD,
    payload
});

export const updateTempPassword = (payload:IUpdateTempPasswordPayload):IUpdateTempPassword => ({
    type: UPDATE_TEMP_PASSWORD,
    payload
});

export const changeTempPasswordSuccess = (tempUser?:ITempUser):ITempPasswordSuccess => ({
    type: TEMP_PASSWORD_SUCCESS,
    payload: tempUser
});

export const changeTempPasswordFailure = (error:string):ITempPasswordFailure => ({
    type: TEMP_PASSWORD_FAILURE,
    payload: error
});

export const getTerms = (url:string):IGetTerms => ({
    type: GET_TERMS,
    payload: url
});

export const getTermsSuccess = (content:string):IGetTermsSuccess => ({
    type: GET_TERMS_SUCCESS,
    payload: content
});

export const getTermsError = (error:string):IGetTermsFailure => ({
    type: GET_TERMS_FAILURE,
    payload: error
});

export const acceptTerms = (payload:ITokenPayload):IAcceptTerms => ({
    type: ACCEPT_TERMS,
    payload
});

export const acceptTermsSuccess = ():IAcceptTermsSuccess => ({
    type: ACCEPT_TERMS_SUCCESS
});

export const acceptTermsError = (error:string):IAcceptTermsFailure => ({
    type: ACCEPT_TERMS_FAILURE,
    payload: error
});

export const checkLastLoginInfo = ():ICheckLastLogin =>  ({
    type: CHECK_LAST_LOGIN
});

export const checkLastLoginInfoSuccess = (payload: ICheckLastLoginSuccessPayload):ICheckLastLoginSuccess =>  ({
    type: CHECK_LAST_LOGIN_SUCCESS,
    payload
});

export const getUsers = (payload: IGetUsersPayload):IGetUsers =>  ({
    type: GET_USERS,
    payload
});

export const setUsers = (users?:IViewUser[]):ISetUsers =>  ({
    type: SET_USERS,
    payload: users
});

export const getUsersError = (error:string):IGetUsersError =>  ({
    type: GET_USERS_ERROR,
    payload: error
});

export const selectUser = (payload:ISelectUserPayload):ISelectUser =>  ({
    type: SELECT_USER,
    payload
});

export const getUser = (payload: IGetUserPayload):IGetUser =>  ({
    type: GET_USER,
    payload
});

export const getUserError = (error:string):IGetUserError =>  ({
    type: GET_USER_ERROR,
    payload: error
});

export const editUser = (payload: IEditUserPayload):IEditUser =>  ({
    type: EDIT_USER,
    payload
});

export const editUserSuccess = (user?:IViewUser):IEditUserSuccess =>  ({
    type: EDIT_USER_SUCCESS,
    payload: user
});

export const editUserError = (error:string):IEditUserError =>  ({
    type: EDIT_USER_ERROR,
    payload: error
});

export const addUser = (payload: IAddUserPayload):IAddUser =>  ({
    type: ADD_USER,
    payload
});

export const addUserSuccess = ():IAddUserSuccess =>  ({
    type: ADD_USER_SUCCESS
});

export const addUserError = (error:string):IAddUserError =>  ({
    type: ADD_USER_ERROR,
    payload: error
});

export const getAddUserConfig = (payload: IGetAddUserConfigPayload):IGetAddUserConfig =>  ({
    type: GET_ADD_USER_CONFIG,
    payload
});

export const setAddUserConfig = (config:IAddUserConfig):ISetAddUserConfig =>  ({
    type: SET_ADD_USER_CONFIG,
    payload: config
});

export const getAddUserConfigError = (error:string):IGetAddUserConfigError =>  ({
    type: GET_ADD_USER_CONFIG_ERROR,
    payload: error
});

export const changeUserStatus = (payload: IChangeUserStatusPayload):IChangeUserStatus =>  ({
    type: CHANGE_USER_STATUS,
    payload
});

export const setUsePlayerTimezone = (payload?:boolean):ISetUsePlayerTimezone =>  ({
    type: SET_USE_PLAYER_TIMEZONE,
    payload
});

export const setShowPlayerFinishedTime = (payload?:boolean):ISetShowPlayerExerciseFinishedTime =>  ({
    type: SET_SHOW_PLAYER_EXERCISE_FINISHED_TIME,
    payload
});

export const resetUserPassword = (payload:IResetUserPasswordPayload):IResetUserPassword => ({
    type: RESET_USER_PASSWORD,
    payload
});

export const resetUserPasswordSuccess = ():IResetUserPasswordSuccess => ({
    type: RESET_USER_PASSWORD_SUCCESS
});

export const resetUserPasswordFailure = (error:string):IResetUserPasswordFailure => ({
    type: RESET_USER_PASSWORD_FAILURE,
    payload: error
});