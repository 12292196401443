import { GET_DASH_TOOLTIPS_CONTENT, GET_PSCORE_LEGEND_CONTENT, IContentState, SET_DASH_TOOLTIPS_CONTENT, SET_PSCORE_LEGEND_CONTENT, SHOW_CONTENT_ERROR, TypeContentAction } from './content.types';

import { SIGN_OUT_SUCCESS } from '../user/user.types';

export const INITIAL_STATE:IContentState = {
	isLoading: true,
    pscoreLegend: undefined,
    dashboardTooltips: undefined,
    error: undefined
};

const contentReducer = (state = INITIAL_STATE, action:TypeContentAction):IContentState => {
    switch( action.type ){
        
        case SIGN_OUT_SUCCESS :
            return {
                ...INITIAL_STATE
            };
        
        case GET_PSCORE_LEGEND_CONTENT :
        case GET_DASH_TOOLTIPS_CONTENT :
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        
        case SET_PSCORE_LEGEND_CONTENT :
            return {
                ...state,
                isLoading: false,
                pscoreLegend: action.payload,
                error: undefined
            };
        
        case SET_DASH_TOOLTIPS_CONTENT :
            return {
                ...state,
                isLoading: false,
                dashboardTooltips: action.payload,
                error: undefined
            };
            
        case SHOW_CONTENT_ERROR :
            return {
                ...state,
                isLoading: false,
                error: action.payload
			};

        default :
            return state;
    }
};

export default contentReducer;