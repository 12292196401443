import { EDIT_ORG, EDIT_ORG_ERROR, EDIT_ORG_SUCCESS, GET_ALL_ORGS, GET_ORG_MEMBERS_COMPLIANCE, GET_ORG_PERIODS, GET_ORG_ROLES, GET_ORG_UI, IOrg, SELECT_ORG, SET_ORG_MEMBERS_COMPLIANCE, SET_ORG_PERIODS, SET_ORG_ROLES, SET_PENDING_ORG_MEMBERS, SET_TIMEZONES, SHOW_GET_ALL_ORGS_ERROR, SHOW_ORG_PERIODS_ERROR, SHOW_ORG_ROLES_ERROR } from './org.types';
import {
    GET_ORG,
    GET_ORG_MEMBERS,
    GET_ORG_MEMBERS_MANAGEMENT,
    IOrgState,
    SET_ALL_ORGS,
    SET_ORG,
    SET_ORG_LAST_LOGIN_URL,
    SET_ORG_MEMBERS,
    SHOW_ORG_ERROR,
    SHOW_ORG_MEMBERS_ERROR,
    TypeOrgAction
} from './org.types';

import { IPlayer } from '../player/player.types';
import { SIGN_OUT_SUCCESS } from '../user/user.types';
import { USE_DEV } from '../../utils/PMotionGlobals';

export const INITIAL_STATE:IOrgState = {
    isLoading: true,
    allOrgs: undefined,
	org: undefined,
    error: undefined,
    membersIsLoading: true,
    membersComplianceIsLoading: false,
    membersError: undefined,
    usingDev: false,
    lastLoginUrl:undefined,
    isOrgRolesLoading: true,
    orgRoles: undefined,
    orgRolesError: undefined,
    isAllOrgsLoading: false,
    allOrgsError: undefined,
    selectedOrg: undefined,
    editOrgSuccess: undefined,
    editOrgError: undefined,
    timezones: undefined,
    orgPeriods: undefined,
    orgPeriodsIsLoading: true,
	orgPeriodsError: undefined,
};

const clearOrg = (org:IOrg):IOrg => ({
    ...org,
    members: undefined,
    memberId: undefined
});

const mergeMemberData = (oldData:IPlayer[], newData:IPlayer[]) => newData.map(newPlayerData => {
    const oldPlayerData = oldData.find(({id}) => id===newPlayerData.id);
    return {
        ...oldPlayerData,
        ...newPlayerData
    }
});

const orgReducer = (state = INITIAL_STATE, action:TypeOrgAction):IOrgState => {
    switch( action.type ){
        
        case SIGN_OUT_SUCCESS :
            // partially reset    
            return {
                ...state,
                org: state.org ? clearOrg(state.org) : undefined,
                allOrgs: state.allOrgs ? state.allOrgs.map(clearOrg) : undefined,
                selectedOrg: undefined,
                orgPeriods: undefined,
                orgPeriodsIsLoading: true,
	            orgPeriodsError: undefined
            };
        
        case SET_ORG_LAST_LOGIN_URL :
            return {
                ...state,
                lastLoginUrl: action.payload
            }
        
        case GET_ORG_UI :
            return {
                ...state,
                org: undefined,
                selectedOrg: undefined,
                isLoading: true,
                membersError: undefined,
                error: undefined,
                editOrgSuccess: undefined,
                editOrgError: undefined
            }

        case GET_ORG :
        case EDIT_ORG :
            return {
                ...state,
                isLoading: true,
                error: undefined,
                editOrgSuccess: undefined,
                editOrgError: undefined
            }

        case EDIT_ORG_SUCCESS :
            return {
                ...state,
                isLoading: false,
                error: undefined,
                editOrgSuccess: true,
                editOrgError: undefined
            }

        case EDIT_ORG_ERROR :
            return {
                ...state,
                isLoading: false,
                editOrgSuccess: false,
                editOrgError: action.payload,
            }

        case SET_ALL_ORGS :
            return {
                ...state,
                allOrgs: action.payload.allOrgs,
                org: action.payload.org,
                isAllOrgsLoading: false
            };
            
        case GET_ALL_ORGS :
            return {
                ...state,
                isAllOrgsLoading: true,
                allOrgsError: undefined
            }

        case SHOW_GET_ALL_ORGS_ERROR :
            return {
                ...state,
                isAllOrgsLoading: false,
                allOrgsError: action.payload
            };
            
        case SET_ORG :
            return {
                ...state,
                org: action.payload,
                isLoading: false,
                error: undefined,
                selectedOrg: undefined,
                orgPeriods: undefined,
                orgPeriodsIsLoading: true,
                orgPeriodsError: undefined
			};
        
        case GET_ORG_MEMBERS :
        case GET_ORG_MEMBERS_MANAGEMENT :
            const clearedOrgMembers = state.org ? {
                ...state.org,
                members: undefined,
            } : undefined;
            return {
                ...state,
                membersIsLoading: true,
                membersError: undefined,
                org: clearedOrgMembers,
                usingDev: USE_DEV || action.type===GET_ORG_MEMBERS_MANAGEMENT // temp fix since one is using remote data and the other static
            };

        case GET_ORG_MEMBERS_COMPLIANCE :
            return {
                ...state,
                membersComplianceIsLoading: true
            };
            
        case SET_ORG_MEMBERS :
            const org = state.org ? {
                ...state.org,
                members: action.payload,
            } : undefined;
            return {
                ...state,
                org,
                membersIsLoading: !(org && action.payload),
                membersError: undefined,
                usingDev: (org && action.payload) ? state.usingDev : undefined
			};

        case SET_ORG_MEMBERS_COMPLIANCE :
            const complainceOrg = state.org ? {
                ...state.org,
                members: state.org.members ? mergeMemberData(state.org.members, action.payload) : action.payload,
            } : undefined;
            return {
                ...state,
                org: complainceOrg,
                membersComplianceIsLoading: false
			};
		
        case SET_PENDING_ORG_MEMBERS :
            const org1 = state.org ? {
                ...state.org,
                pendingMembers: action.payload,
            } : undefined;
            return {
                ...state,
                org: org1,
                membersIsLoading: !(org1 && action.payload),
                membersError: undefined,
                usingDev: (org1 && action.payload) ? state.usingDev : undefined
            };

		case SHOW_ORG_ERROR :
			return {
                ...state,
                error: action.payload,
                isLoading: false
            };
            
        case SHOW_ORG_MEMBERS_ERROR :
            return {
                ...state,
                membersError: action.payload,
                membersIsLoading: false,
                membersComplianceIsLoading: false
            }

        case GET_ORG_ROLES :
            return {
                ...state,
                isOrgRolesLoading: true,
                orgRolesError: undefined
            };

        case SET_ORG_ROLES :
            return {
                ...state,
                isOrgRolesLoading: false,
                orgRoles: action.payload,
            };

        case SHOW_ORG_ROLES_ERROR :
            return {
                ...state,
                isOrgRolesLoading: false,
                orgRolesError: action.payload,
            };

        case SELECT_ORG :
            const existingOrg = state.selectedOrg && action.payload.org && state.selectedOrg.id===action.payload.org.id ? state.selectedOrg : undefined;
            return {
                ...state,
                selectedOrg: action.payload.org ? {
                    ...existingOrg,
                    ...action.payload.org
                } : undefined,
                isLoading: action.payload.isLoading!==undefined ? action.payload.isLoading : !action.payload.org
            }

        case SET_TIMEZONES :
            return {
                ...state,
                timezones: action.payload
            }

        case GET_ORG_PERIODS :
            return {
                ...state,
                orgPeriods: undefined,
                orgPeriodsIsLoading: true,
                orgPeriodsError: undefined
            }

        case SET_ORG_PERIODS :
            return {
                ...state,
                orgPeriods: action.payload,
                orgPeriodsIsLoading: false,
                orgPeriodsError: undefined
            }

        case SHOW_ORG_PERIODS_ERROR :
            return {
                ...state,
                orgPeriods: false,
                orgPeriodsIsLoading: false,
                orgPeriodsError: action.payload
            }

        default :
            return state;
    }
};

export default orgReducer;