import { GET_ALL_MESSAGE_THREADS, GET_MESSAGE_THREAD, GET_MESSAGE_THREAD_BY_MEMBERS, GET_NEW_MESSAGE_THREAD_COUNT, IGetAllMessageThreads, IGetAllMessageThreadsPayload, IGetMessageThread, IGetMessageThreadByMembers, IGetMessageThreadByMembersPayload, IGetMessageThreadPayload, IGetNewMessageThreadCount, IGetNewMessageThreadCountPayload, IMarkMessageStatus, IMarkMessageStatusPayload, IMarkMessageStatusSuccess, IMarkMessageStatusTokenPayload, IMessageThread, ISearchThreads, ISendMessage, ISendMessagePayload, ISetAllMessageThreads, ISetMessageThread, ISetNewMessageThreadCount, IShowAllMessageThreadsError, IShowMessageThreadError, MARK_MESSAGE_STATUS, MARK_MESSAGE_STATUS_SUCCESS, SEARCH_THREADS, SEND_MESSAGE, SET_ALL_MESSAGE_THREADS, SET_MESSAGE_THREAD, SET_NEW_MESSAGE_THREAD_COUNT, SHOW_ALL_MESSAGE_THREADS_ERROR, SHOW_MESSAGE_THREAD_ERROR } from './message.types';

export const showAllMessageThreadsError = (error?:string):IShowAllMessageThreadsError => ({
    type: SHOW_ALL_MESSAGE_THREADS_ERROR,
    payload: error
});

export const getAllMessageThreads = (payload:IGetAllMessageThreadsPayload):IGetAllMessageThreads => ({
    type: GET_ALL_MESSAGE_THREADS,
    payload
});

export const setAllMessageThreads = (threads:IMessageThread[]):ISetAllMessageThreads => ({
    type: SET_ALL_MESSAGE_THREADS,
    payload: threads
});

export const getMessageThread = (payload:IGetMessageThreadPayload):IGetMessageThread => ({
    type: GET_MESSAGE_THREAD,
    payload
});

export const getMessageThreadByMembers = (payload:IGetMessageThreadByMembersPayload):IGetMessageThreadByMembers => ({
    type: GET_MESSAGE_THREAD_BY_MEMBERS,
    payload
});

export const setMessageThread = (payload?:IMessageThread):ISetMessageThread => ({
    type: SET_MESSAGE_THREAD,
    payload
});

export const showMessageThreadError = (error?:string):IShowMessageThreadError => ({
    type: SHOW_MESSAGE_THREAD_ERROR,
    payload: error
});

export const searchMessageThreads = (term?:string):ISearchThreads => ({
    type: SEARCH_THREADS,
    payload: term
});

export const sendMessage = (payload:ISendMessagePayload):ISendMessage => ({
    type: SEND_MESSAGE,
    payload
});

export const getNewMessageThreadCount = (payload:IGetNewMessageThreadCountPayload):IGetNewMessageThreadCount => ({
    type: GET_NEW_MESSAGE_THREAD_COUNT,
    payload
});

export const setNewMessageThreadCount = (count:number):ISetNewMessageThreadCount => ({
    type: SET_NEW_MESSAGE_THREAD_COUNT,
    payload: count
});

export const markMessageStatus = (payload:IMarkMessageStatusTokenPayload):IMarkMessageStatus => ({
    type: MARK_MESSAGE_STATUS,
    payload
});

export const markMessageStatusSuccess = (payload:IMarkMessageStatusPayload):IMarkMessageStatusSuccess => ({
    type: MARK_MESSAGE_STATUS_SUCCESS,
    payload
});