import {IPlayer} from '../player/player.types';
import {IRootState} from '../root.types';
import {createSelector} from 'reselect';

const selectAssessment = (state:IRootState) => state.assessment;

export const selectAssessmentIsLoading = createSelector([selectAssessment], assessment => assessment.isLoading);

export const selectAssessmentMap = createSelector([selectAssessment], assessment => assessment.assessments);

export const selectAssessmentByMember = (member:IPlayer) => createSelector(
	[selectAssessment], 
	assessment => assessment.assessments ? assessment.assessments[member.id] : undefined
);

export const selectOrgMembers = createSelector([selectAssessment], assessment => assessment.orgMembers);

export const selectCurrentOrgMember = createSelector([selectAssessment], assessment => assessment.currentOrgMember);

export const selectCurrentAssessmentByMember = createSelector(
	[selectAssessment, selectCurrentOrgMember], 
	(assessment, member) => (assessment.assessments && member) ? assessment.assessments[member.id] : undefined
);

export const selectCurrentAssessmentMemberAllowPartialAssessment = createSelector([selectCurrentOrgMember], currentPlayer => currentPlayer ? currentPlayer.allowPartialAssessment : undefined);

export const selectHasCheckedCache = createSelector([selectAssessment], assessment => assessment.cachedChecked);

export const selectSubmitAssessmentsSuccess = createSelector([selectAssessment], assessment => assessment.saveAssessmentSuccess);

export const selectSubmitAssessmentsError = createSelector([selectAssessment], assessment => assessment.saveAssessmentError);

export const selectMeasurements = createSelector([selectAssessment], assessment => assessment.measurements);