import { IDType, IData } from '../core.types';
import { ISignOutSuccess, ITokenPayload } from '../user/user.types';

import {IPlayer} from '../player/player.types';
import { SrcMapType } from '../../utils/src-map';

export const GET_ALL_ORGS = 'GET_ALL_ORGS';
export const SET_ALL_ORGS = 'SET_ALL_ORGS';
export const SHOW_GET_ALL_ORGS_ERROR = 'SHOW_GET_ALL_ORGS_ERROR';
export const GET_ORG = 'GET_ORG';
export const GET_ORG_UI = 'GET_ORG_UI';
export const SET_ORG = 'SET_ORG';
export const SHOW_ORG_ERROR = 'SHOW_ORG_ERROR';
export const GET_ORG_MEMBERS = 'GET_ORG_MEMBERS';
export const GET_ORG_MEMBERS_MANAGEMENT = 'GET_ORG_MEMBERS_MANAGEMENT';
export const GET_ORG_MEMBERS_COMPLIANCE = 'GET_ORG_MEMBERS_COMPLIANCE';
export const GET_PENDING_ORG_MEMBERS = 'GET_PENDING_ORG_MEMBERS';
export const SET_ORG_MEMBERS = 'SET_ORG_MEMBERS';
export const SET_ORG_MEMBERS_COMPLIANCE = 'SET_ORG_MEMBERS_COMPLIANCE';
export const SET_PENDING_ORG_MEMBERS = 'SET_PENDING_ORG_MEMBERS';
export const SHOW_ORG_MEMBERS_ERROR = 'SHOW_ORG_MEMBERS_ERROR';
export const SET_ORG_LAST_LOGIN_URL = 'SET_ORG_LAST_LOGIN_URL';
export const GET_ORG_ROLES = 'GET_ORG_ROLES';
export const SET_ORG_ROLES = 'SET_ORG_ROLES';
export const SHOW_ORG_ROLES_ERROR = 'SHOW_ORG_ROLES_ERROR';
export const SELECT_ORG = 'SELECT_ORG';

export const EDIT_ORG = 'EDIT_ORG';
export const EDIT_ORG_SUCCESS = 'EDIT_ORG_SUCCESS';
export const EDIT_ORG_ERROR = 'EDIT_ORG_ERROR';

export const GET_TIMEZONES = 'GET_TIMEZONES';
export const SET_TIMEZONES = 'SET_TIMEZONES';

export const GET_ORG_PERIODS = 'GET_ORG_PERIODS';
export const SET_ORG_PERIODS = 'SET_ORG_PERIODS';
export const SHOW_ORG_PERIODS_ERROR = 'SHOW_ORG_PERIODS_ERROR';


// Data types
export enum OrgRole {
	PMotionAdmin = 'PMOTION_ADMIN',
	ClientAdmin = 'CLIENT_ADMIN',
	Executive = 'EXECUTIVE',
	MedicalProvider = 'MEDICAL_PROVIDER',
	Trainer = 'TRAINER',
	Member = 'MEMBER'
}
export type OrgRoles = object & {
    [orgKey:string]: OrgRole[];
}

export enum OrgAccessLevel {
	Level1 = 1,
	Level2,
	Level3
}

const ORG_ACCESS_LEVEL1:OrgRole[] = [OrgRole.Member];
const ORG_ACCESS_LEVEL2:OrgRole[] = [OrgRole.PMotionAdmin, OrgRole.ClientAdmin, OrgRole.Executive, OrgRole.MedicalProvider, OrgRole.Trainer];
const ORG_ACCESS_LEVEL3:OrgRole[] = [OrgRole.PMotionAdmin, OrgRole.ClientAdmin, OrgRole.Executive];

export const OrgRoleAccessLevels = new Map<OrgAccessLevel, OrgRole[]>([
	[OrgAccessLevel.Level1, ORG_ACCESS_LEVEL1],
	[OrgAccessLevel.Level2, ORG_ACCESS_LEVEL2],
	[OrgAccessLevel.Level3, ORG_ACCESS_LEVEL3]
]);

export type OrgRoleData = IData & {
	name:OrgRole;
	userCount:number;
}

export enum LicenseType {
    Enterprise = 'ENTERPRISE'
}

export enum SportType {
	Baseball = 'Baseball',
	Hockey = 'Hockey',
	Soccer = 'Soccer',
	Basketball = 'Basketball',
	Football = 'Football',
	Golf = 'Golf',
	Tennis = 'Tennis'
}

export enum ProgramConfigType {
    PROGRAMDAY_MAX_TIME = 'PROGRAMDAY_MAX_TIME',
    TIMEZONE = 'TIMEZONE',
    PROGRAM_STATE_MAX_DAYS_MISSED = 'PROGRAM_STATE_MAX_DAYS_MISSED',
	PROGRAM_STATE_MAX_DAYS_MISSED_SEVERE = 'PROGRAM_STATE_MAX_DAYS_MISSED_SEVERE',
    PROGRAM_STATE_MAX_DAYS_SKIPPED = 'PROGRAM_STATE_MAX_DAYS_SKIPPED',
	PROGRAM_STATE_MIN_DAYS_IDLE = 'PROGRAM_STATE_MIN_DAYS_IDLE',
	PROGRAM_DAYS_TO_ASSESSMENT_LATE = 'PROGRAM_DAYS_TO_ASSESSMENT_LATE',
    PROGRAM_TOTAL_DAYS = 'PROGRAM_TOTAL_DAYS',
	COMPLIANCE_MINIMUM_GREEN = 'COMPLIANCE_MINIMUM_GREEN',
    COMPLIANCE_MINIMUM_YELLOW = 'COMPLIANCE_MINIMUM_YELLOW',
	COMPLIANCE_WORKOUT_PERCENTAGE = 'COMPLIANCE_WORKOUT_PERCENTAGE',
	ROLE_COMPLIANCE = 'ROLE_COMPLIANCE'
}

export type SimpleProgramConfig = Record<ProgramConfigType, string>;
export type ProgramRoleComplianceKey = string;
export type ProgramRoleComplianceConfig = Record<ProgramRoleComplianceKey, SimpleProgramConfig>;
export type ProgramConfig = SimpleProgramConfig | Record<ProgramConfigType, string | ProgramRoleComplianceConfig>;

export interface IOrg {
	id:IDType;
	key:string;
	displayName:string;
	occupation:string | SportType;
	isTopLevel:boolean;
	licenseType?:LicenseType | null;
	showCompliance?:boolean;
	allowExitAssessment?:boolean;
	image?:SrcMapType | null;
	backgroundImage?:SrcMapType | null;
	//basePath:string;
	colorPrimary?:string | null;
	colorSecondary?:string | null;
	colorAccent?:string | null;
	loginImage?:SrcMapType | null;
	loginQuote?:string | null;
	loginQuoteAuthor?:string | null;
	members?:IPlayer[];
	pendingMembers?:IPlayer[];
	userCount?:number | null;
	children?:IOrg[];
	memberId?:IDType | null;
	shopifyLink?: string | null;

}

export interface IOrgView extends Omit<Partial<IOrg>, 'children'> { // limited Org info from API
	id:IDType;
	displayName:string;
	children?:(IOrgView | IOrg)[];
	config?:ProgramConfig | null;
	configDefault?:ProgramConfig | null;
	expirationDate?:string | null;
}

export type OrgPeriod = IData & {
	name:string;
	startDate:string;
    endDate:string;
}


// State type
export interface IOrgState {
	isLoading:boolean;
	allOrgs?:IOrg[];
	org?:IOrg; // current Org view
	error?:string;
	membersIsLoading:boolean;
	membersComplianceIsLoading:boolean;
	membersError?:string;
	usingDev?:boolean;
	lastLoginUrl?:string;
	isOrgRolesLoading:boolean;
	orgRoles?:OrgRoleData[];
	orgRolesError?:string;
	isAllOrgsLoading:boolean;
	allOrgsError?:string;
	selectedOrg?:IOrgView; // selected org to view or edit
	editOrgSuccess?:boolean;
	editOrgError?:string;
	timezones?:Record<string, string>;
	orgPeriods?:OrgPeriod[] | false;
	orgPeriodsIsLoading:boolean;
	orgPeriodsError?:string;
}


// Action types
export interface ISetAllOrgsPayload {
	allOrgs:IOrg[];
	org:IOrg;
}
/* Set all available orgs and set selected org. */
export interface ISetAllOrgs {
    type: typeof SET_ALL_ORGS;
    payload:ISetAllOrgsPayload;
}

export interface IGetOrgPayload extends ITokenPayload {
	orgId:IDType;
}

export interface IGetOrg {
	type: typeof GET_ORG;
	payload: IGetOrgPayload;
};

export interface IGetOrgUI {
	type: typeof GET_ORG_UI;
	payload: string; // org key from JSON
};

export interface ISetOrg {
    type: typeof SET_ORG;
    payload?:IOrg;
}

export interface IGetOrgMembersPayload extends ITokenPayload {
	orgId: IDType;
	includeRisk?:boolean; // returns assessmentGroupStandards
	includePScore?:boolean; // returns p-Score and assessmentGroupStandards
	includeCompliance?:boolean; // returns compliance data
}
export interface IGetOrgMembers {
	type: typeof GET_ORG_MEMBERS;
	payload: IGetOrgMembersPayload;
};

export interface IGetOrgMembersManagement {
	type: typeof GET_ORG_MEMBERS_MANAGEMENT;
	payload: IGetOrgMembersPayload;
};

export interface IGetOrgMembersCompliancePayload extends ITokenPayload {
	orgId: IDType;
	members?:IPlayer[];
}
export interface IGetOrgMembersCompliance {
	type: typeof GET_ORG_MEMBERS_COMPLIANCE;
	payload: IGetOrgMembersCompliancePayload;
};

export interface IGetPendingOrgMembers {
	type: typeof GET_PENDING_ORG_MEMBERS;
	payload: IGetOrgMembersPayload;
};

export interface ISetOrgMembers {
    type: typeof SET_ORG_MEMBERS;
    payload?:IPlayer[];
}

export interface ISetOrgMembersCompliance {
	type: typeof SET_ORG_MEMBERS_COMPLIANCE;
    payload:IPlayer[];
}

export interface ISetPendingOrgMembers {
	type: typeof SET_PENDING_ORG_MEMBERS;
	payload?:IPlayer[];
}

export interface IShowOrgError {
    type: typeof SHOW_ORG_ERROR;
    payload:string;
}

export interface IShowOrgMembersError {
    type: typeof SHOW_ORG_MEMBERS_ERROR;
    payload:string;
}

export interface ISetLastLoginUrl {
    type: typeof SET_ORG_LAST_LOGIN_URL;
    payload?:string;
}

export interface IGetOrgRoles {
	type: typeof GET_ORG_ROLES;
	payload:ITokenPayload;
}

export interface ISetOrgRoles {
	type: typeof SET_ORG_ROLES;
	payload:OrgRoleData[];
}

export interface IShowOrgRolesError {
    type: typeof SHOW_ORG_ROLES_ERROR;
    payload:string;
}

export interface IGetAllOrgs {
	type: typeof GET_ALL_ORGS;
	payload:ITokenPayload;
}

export interface IShowGetAllOrgsError {
    type: typeof SHOW_GET_ALL_ORGS_ERROR;
    payload:string;
}

export interface ISelectOrgPayload {
    org?:IOrgView;
    isLoading?:boolean;
}

export interface ISelectOrg {
	type: typeof SELECT_ORG;
    payload:ISelectOrgPayload;
}

export interface IEditOrgPayload extends ITokenPayload {
    org:IOrgView;
};

export interface IEditOrg {
    type: typeof EDIT_ORG;
    payload: IEditOrgPayload;
};

export interface IEditOrgSuccess {
    type: typeof EDIT_ORG_SUCCESS;
};

export interface IEditOrgError {
    type: typeof EDIT_ORG_ERROR;
    payload: string;
};

export interface IGetTimezones {
    type: typeof GET_TIMEZONES;
    payload: ITokenPayload;
};

export interface ISetTimezones {
    type: typeof SET_TIMEZONES;
    payload:Record<string, string>;
};


export interface IGetOrgPeriodsPayload extends ITokenPayload {
	orgId: IDType;
}

export interface IGetOrgPeriods {
    type: typeof GET_ORG_PERIODS;
    payload:IGetOrgPeriodsPayload;
};

export interface ISetOrgPeriods {
    type: typeof SET_ORG_PERIODS;
    payload?:OrgPeriod[] | false;
};

export interface IShowOrgPeriodsError {
    type: typeof SHOW_ORG_PERIODS_ERROR;
    payload:string;
};


export type TypeOrgAction = IGetOrg | ISetOrg | IGetOrgMembers | IGetOrgMembersManagement | ISetOrgMembers | IShowOrgError | 
IShowOrgMembersError | ISetAllOrgs | ISetLastLoginUrl | ISignOutSuccess | IGetOrgRoles | ISetOrgRoles | IShowOrgRolesError | 
IGetAllOrgs | IShowGetAllOrgsError | ISelectOrg | IGetOrgUI | IEditOrg | IEditOrgSuccess | IEditOrgError | IGetTimezones | 
ISetTimezones | IGetOrgMembersCompliance | ISetOrgMembersCompliance | IGetOrgPeriods | ISetOrgPeriods | IShowOrgPeriodsError |
IGetPendingOrgMembers | ISetPendingOrgMembers;