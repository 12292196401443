import { ACCEPT_TERMS, ADD_USER, CHANGE_PASSWORD, CHANGE_USER_STATUS, CHECK_LAST_LOGIN, EDIT_USER, EXTEND_USER_SESSION_START, FORGOT_PASSWORD, GET_ADD_USER_CONFIG, GET_TERMS, GET_USER, GET_USERS, IAcceptTerms, IAddUser, IAddUserConfig, IChangePassword, IChangeUserStatus, IEditUser, IExtendUserSessionStart, IForgotPassword, IGetAddUserConfig, IGetTerms, IGetUser, IGetUsers, IResetPassword, ISignOutStart, ITempPassword, ITokenPayload, IUpdateTempPassword, IViewUser, RESET_PASSWORD, TEMP_PASSWORD, UPDATE_TEMP_PASSWORD, UserConfig, UserStatus } from './user.types';
import {
    CHECK_USER_SESSION,
    EMAIL_SIGN_IN_START,
    IUser,
    SIGN_OUT_START,
    TypeEmailSignInStartAction
} from './user.types';
import { IOrg, ISetAllOrgsPayload, LicenseType, OrgRole, OrgRoles } from '../org/org.types';
import { ProfileItem, ProfileItemType, ProfileSideType } from '../player/player.types';
import { SavedOrg, convertOrg, parseOrg } from '../root.transforms';
import { StorageKey, deleteLocal, getLocal, setLocal } from '../../utils/storage';
import { acceptTermsError, acceptTermsSuccess, addUserError, addUserSuccess, changePasswordFailure, changePasswordSuccess, changeTempPasswordFailure, changeTempPasswordSuccess, checkLastLoginInfoSuccess, editUserError, editUserSuccess, extendUserSessionSuccess, forgotPasswordFailure, forgotPasswordSuccess, getAddUserConfigError, getTermsError, getTermsSuccess, getUsers, getUsersError, resetPasswordFailure, resetPasswordSuccess, selectUser, setAddUserConfig, setUsers, signInFailure, signInSuccess, signOutFailure, signOutStart, signOutSuccess } from './user.actions';
import { acceptUserTerms, activateOrgUser, addOrgUser, changeTempUserPassword, changeTempUserToken, changeUserPassword, deactivateOrgUser, editOrgUser, extendUserSession, forgotUserPassword, getAddOrgUserConfig, getOrgUser, getOrgUsers, getTermsDoc, resetUserPassword, signInUserWithEmail, signOutUser, updateTempUserPassword } from '../../services/PMotionApi';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import { capitalize, defaults, isNumber, uniq } from 'lodash';
import { selectAllOrgs, selectOrgInfo } from '../org/org.selectors';
import { selectCurrentUser, selectCurrentUserLastLogin, selectCurrentUserTokenTTL } from './user.selectors';

import { IDType } from '../core.types';
import SrcMap from '../../utils/src-map';
import { TOKEN_EXTEND_TIME } from '../../utils/PMotionGlobals';
import { findOrgById } from '../org/org.sagas';
import moment from 'moment';
import { setAllOrgs } from '../org/org.actions';

interface IRemoteUI {
    colorPrimary?:string | null;
    colorSecondary?:string | null;
    colorAccent?:string | null;
    loginQuote?:string | null;
    loginQuoteAuthor?:string | null;
    image?:string | null;
    backgroundLogo?:string | null;
    loginImage?:string | null;
}
interface IRemoteConfig {
    LICENSE_TYPE?:LicenseType | null;
    SHOW_COMPLIANCE?:boolean | string;
    ALLOW_EXIT_ASSESSMENT?:boolean | string;
}
interface IRemoteOrgInfo {
    topLevel:boolean;
    orgId:IDType;
    orgKey:string;
    name:string;
    genre:string;
    memberId?:IDType | null;
    isTopLevel:boolean;
    uiConfig?:IRemoteUI | null;
    childOrgs?:IRemoteOrgInfo[] | null;
    config?:IRemoteConfig | null;
}

interface IRemoteUserConfig {
    PMOTION_STAFF_ACCESS?:boolean;
    SHOW_ASSESSMENT_IN_MOBILE:boolean;
    SHOW_COMPLIANCE?:boolean | null;
}
interface IRemoteUserInfo extends Omit<IUser, 'showAssessmentInMobile' | 'hasStaffAccess' | 'needsNewPassword' | 'tempPassword'> {
    userId:IDType;
    loggedIn:boolean;
    orgs:IRemoteOrgInfo[];
    accessToken:string;
    userConfig?:IRemoteUserConfig | null;
    loginError?:string | LoginError | null;
}

interface UserStore extends ISetAllOrgsPayload {
    user:IUser;
}
interface UserStoreFlattened {
    user:IUser;
    allOrgs:SavedOrg[];
	org:SavedOrg;
}

enum LoginError {
    NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'
}

export function* signInWithEmail({ payload: {email, password} }:TypeEmailSignInStartAction) {
    try {
        const userInfoData = yield signInUserWithEmail({email, password});
        const user:IRemoteUserInfo = userInfoData?.data as IRemoteUserInfo;
        const hasTempPw = user && !user.loggedIn && user.loginError===LoginError.NEW_PASSWORD_REQUIRED && !!password;
        if( user && (user.loggedIn || hasTempPw) ){
            
            // get org info
            const {orgs, userId, accessToken, userConfig, ...userData} = user;
            const realOrgs = convertAllOrgRemoteData(orgs);

            // check if same user is returning, then set default Org to what was previously set
            const lastLoginEmail:string | null | undefined = yield getLocal(StorageKey.LoginUserName);
            const currentOrg:IOrg | undefined = yield select(selectOrgInfo);
            let defaultOrg:IOrg | undefined;
            if(currentOrg && lastLoginEmail && lastLoginEmail===email){
                defaultOrg = findOrgById(currentOrg.id, realOrgs);
            }
            // otherwise default to top Org
            if( !defaultOrg ){
                const topLevelOrg = realOrgs.length ? realOrgs[0] : undefined; // select first as top-level
                defaultOrg = topLevelOrg && topLevelOrg.children && topLevelOrg.children.length ? topLevelOrg.children[0] : topLevelOrg; // select first child of top-level as default
            }

            if( defaultOrg || hasTempPw ){
                const realUser:IUser = {
                    ...userData,
                    id: userId ? userId : userData.id,
                    token: accessToken,
                    lastLogin: moment(new Date()).format(),
                    showAssessmentInMobile: userConfig ? userConfig.SHOW_ASSESSMENT_IN_MOBILE : false,
                    hasStaffAccess: userConfig ? !!userConfig.PMOTION_STAFF_ACCESS : false,
                    showCompliance: userConfig && typeof userConfig.SHOW_COMPLIANCE ==='boolean' ? userConfig.SHOW_COMPLIANCE : undefined,
                    needsNewPassword: hasTempPw,
                    tempPassword: hasTempPw ? password : undefined,
                }

                if( defaultOrg ){
                    yield put(
                        setAllOrgs({
                            allOrgs: realOrgs,
                            org: defaultOrg
                        })
                    );

                    const storable:UserStoreFlattened = {
                        user:realUser,
                        allOrgs: realOrgs.map(org => convertOrg(org)),
                        org: convertOrg(defaultOrg)
                    }
                    yield setLocal(StorageKey.User, storable);
                }
                
                // store last successful username
                yield setLocal(StorageKey.LoginUserName, email);
                yield put(
                    signInSuccess(realUser)
                );

            } else {
                yield put(signInFailure('User does not have organization data.'));
            }
            
		} else {
            yield put(signInFailure('Incorrect username or password'));
		}

    } catch(error) {
        console.log('ERROR', error)
        yield put(signInFailure(error));
    }
}

const convertJSONStringToArray = (str?:string | null):any[] | undefined => {
    const obj = str ? JSON.parse(str) : undefined;
    if( obj ){
        const results:any[] = [];
        obj.forEach((inner:any) => {
            results.push(inner);
        });
        return results;
    }
    return undefined;
}

type OrgUI = Pick<IOrg, 'image' | 'backgroundImage' | 'colorPrimary' | 'colorSecondary' | 'colorAccent' | 'loginImage' | 'loginQuote' | 'loginQuoteAuthor'>;
export const convertAllOrgRemoteData = (orgs:IRemoteOrgInfo[], parentOrgUI?:OrgUI, licenseType?:LicenseType, showCompliance?:boolean):IOrg[] => {
	return orgs.map(({
        topLevel,
        orgId,
        orgKey,
        name,
        genre,
        uiConfig,
        childOrgs,
        config,
        ...otherOrgData
    }) => {

        // if no UI prop set for the child Org, inherit from the parent Org
        const orgUI = defaults({
            image: (uiConfig && uiConfig.image) ? SrcMap.convertRemoteSizeSources(convertJSONStringToArray(uiConfig.image)) : undefined,
            backgroundImage: (uiConfig && uiConfig.backgroundLogo) ? SrcMap.convertRemoteSizeSources(convertJSONStringToArray(uiConfig.backgroundLogo)) : undefined,
            colorPrimary: uiConfig ? uiConfig.colorPrimary : undefined,
            colorSecondary: uiConfig ? uiConfig.colorSecondary : undefined,
            colorAccent: uiConfig ? uiConfig.colorAccent : undefined,
            loginImage: (uiConfig && uiConfig.loginImage) ? SrcMap.convertRemoteSizeSources(convertJSONStringToArray(uiConfig.loginImage)) : undefined,
            loginQuote: uiConfig ? uiConfig.loginQuote : undefined,
            loginQuoteAuthor: uiConfig ? uiConfig.loginQuoteAuthor : undefined,
        }, parentOrgUI);

        const realLicenseType = (config && config.LICENSE_TYPE) ? config.LICENSE_TYPE : licenseType;
        const realShowCompliance = (config && 'SHOW_COMPLIANCE' in config) ? !!(config.SHOW_COMPLIANCE && config.SHOW_COMPLIANCE!=='false') : showCompliance;
        const realAllowExitAssessment = (config && 'ALLOW_EXIT_ASSESSMENT' in config) ? !!(config.ALLOW_EXIT_ASSESSMENT && config.ALLOW_EXIT_ASSESSMENT!=='false') : false;

        return {
            ...otherOrgData,
            id: orgId,
            key: orgKey,
            displayName: name,
            occupation: genre,
            licenseType: realLicenseType,
            showCompliance: realShowCompliance,
            allowExitAssessment: realAllowExitAssessment,
            ...orgUI,
            children: childOrgs ? convertAllOrgRemoteData(childOrgs, orgUI, realLicenseType, realShowCompliance) : undefined
        }
    });
}

export function* checkLastSuccessfulLoginInfo() {
    try {
        const username:string | null | undefined = yield getLocal(StorageKey.LoginUserName);
        yield put(
            checkLastLoginInfoSuccess({
                username: username ? username : undefined
            })
        );
    } catch(error){
       
    }
}

export function* isUserAuthenticated() {
    
    try {
        yield checkLastSuccessfulLoginInfo();
        // can use api instead
        const storableFlattened:UserStoreFlattened | undefined = getLocal(StorageKey.User);
        const defaultOrg = storableFlattened ? storableFlattened.org : undefined;
        const storable:UserStore | undefined = (storableFlattened && defaultOrg) ? {
            ...storableFlattened,
            allOrgs: storableFlattened.allOrgs.map(org => parseOrg(org)),
            org: parseOrg(defaultOrg)
        } : undefined;
        const user:IUser | undefined = storable ? storable.user : undefined;
        const token = user ? user.token : undefined;
        if( storable && user && token && storable.org ){
            const {allOrgs, org} = storable;
            
            yield put(
                setAllOrgs({
                    allOrgs,
                    org
                })
            );
            yield put(
				signInSuccess(user)
			);
		} else {
            const signoutPayload:ITokenPayload | undefined = token ? {
                token
            } : undefined;
            yield put(
				signOutStart(signoutPayload)
			);
		}
    } catch(error){
        yield put(signInFailure(error));
    }
}

export function* signOut({ payload }:ISignOutStart) {
    try {
        if(payload && payload.token){
            yield signOutUser(payload.token);
        }
        deleteLocal(StorageKey.User);
        yield put(signOutSuccess());
    } catch(error){
        yield put(signOutFailure(error));
    }
}

interface RemoteTokenExtension {
    accessToken:string;
    idToken:string;
    ttl:number;
}
export function* extendTokenSession({ payload: {token} }:IExtendUserSessionStart) {
    let newToken:string | undefined;
    try {
        const tokenInfo = yield extendUserSession(token);
        const tokenInfoData:RemoteTokenExtension | undefined = tokenInfo.data;
        if(tokenInfoData){
            newToken = tokenInfoData.accessToken;
            if( newToken ){
                const newLastLogin = moment(new Date()).format();
                yield put(
                    extendUserSessionSuccess({
                        token: newToken,
                        lastLogin: newLastLogin,
                        tokenTTL: tokenInfoData.ttl,
                        idToken: tokenInfoData.idToken
                    })
                );

                // store user with updated token info
                const user:IUser | undefined = yield select(selectCurrentUser);
                const allOrgs:IOrg[] | undefined = yield select(selectAllOrgs);
                const currentOrg:IOrg | undefined = yield select(selectOrgInfo);
                if(user && allOrgs && currentOrg){
                    const storable:UserStoreFlattened = {
                        user: {
                            ...user,
                            token: newToken,
                            tokenTTL: tokenInfoData.ttl,
                            lastLogin: newLastLogin,
                            idToken: tokenInfoData.idToken
                        },
                        allOrgs: allOrgs.map(org => convertOrg(org)),
                        org: convertOrg(currentOrg)
                    }
                    yield setLocal(StorageKey.User, storable);
                }
            }
        }

    } catch(error) {
        console.log('Failed to extend user token.', error.message);
    }
    return newToken;
}

export type PrivateRemoteData = object & {
    error?:string | null;
}
export function* isUserTokenValid(remoteData:PrivateRemoteData | boolean, token:string, failSilently?:boolean) {
    // Check for error
    const invalid = remoteData!==null && remoteData!==undefined && (typeof remoteData==='object') && remoteData.hasOwnProperty('error') && remoteData.error==='invalid_token';
    if( invalid ){
        if( !failSilently ){
            yield put(
                signOutStart({
                    token
                })
            );
        }
        return false;
    } else {
        const tokenTTL = yield select(selectCurrentUserTokenTTL);
        const lastLogin = yield select(selectCurrentUserLastLogin);
        if(isNumber(tokenTTL) && lastLogin!==undefined){
            const now = moment(new Date());
            const expireTime = moment(lastLogin).add(tokenTTL, 'seconds');
            const diff = expireTime.diff(now, 'seconds', true);
            //console.log('\n\nLast Fresh Token:', moment(lastLogin).format(), '\nNow:', now.format(), '\ntokenTTL', tokenTTL, '\nExpires', expireTime.format(), '\nDIFF before Expiration (seconds)', diff, '\nToken:', token)
            if(diff <= TOKEN_EXTEND_TIME){
                // refresh token
                const newToken = yield extendTokenSession({
                    type: EXTEND_USER_SESSION_START,
                    payload: {
                        token
                    }
                });
                //console.log(`=== Extending token since Diff ${diff} <= ${TOKEN_EXTEND_TIME}]\nNEW Token:`, newToken);
                if(newToken!==undefined){
                    return newToken;
                }
            }
        }

        return true;
    }
}

export function* changePassword({ payload: {userId, oldPassword, newPassword, token} }:IChangePassword) {
    try {
        //console.log(`Changing '${oldPassword}' to '${newPassword}' for user ${userId}`);
        const userInfoData = yield changeUserPassword({userId, oldPassword, newPassword, token});
        const valid = yield isUserTokenValid(userInfoData.data, token);
		if(valid){
            if(userInfoData && userInfoData.data && userInfoData.data.passwordChanged){
                yield put(
                    changePasswordSuccess()
                );
            } else {
                throw new Error('Error in request.');
            }
        }

    } catch(error) {
        yield put(changePasswordFailure(error.message));
    }
}

export function* callForgotPassword({ payload }:IForgotPassword) {
	try {
		const requestData = yield forgotUserPassword( payload.userEmail );
        if(requestData && requestData.data && requestData.data===true){
            yield put(
				forgotPasswordSuccess()
			);
        } else {
			throw new Error('Error in Forgot Password request.');
        }

    } catch(error) {
		yield put(forgotPasswordFailure("Invalid email address."));
    }
}

type RemotePasswordResponse = {
    login:string;
    passwordChanged?:boolean;
    roles:OrgRole[];
    status?:number;
    error?:string;
    message?:string;
}

export function* callResetPassword({ payload }:IResetPassword) {
	try {
		const requestData = yield resetUserPassword( payload );
        if(requestData && requestData.data){
            const remoteResponse = requestData.data as RemotePasswordResponse;
            if(remoteResponse.hasOwnProperty('passwordChanged') && remoteResponse.passwordChanged===true && remoteResponse.hasOwnProperty('login') && remoteResponse.hasOwnProperty('roles')){
                yield put(
                    resetPasswordSuccess({
                        emailAddress: remoteResponse.login,
                        orgRoles: remoteResponse.roles
                    })
                );
            } else {
                throw new Error(`${remoteResponse.error ? remoteResponse.error : 'Error'} ${isNumber(remoteResponse.status) ? remoteResponse.status : ''} ${remoteResponse.message ? remoteResponse.message : ''}`);
            }
        } else {
			throw new Error('Invalid Verification Code.');
        }

    } catch(error) {
		yield put(resetPasswordFailure(error.message));
    }
}

type RemoteTempPasswordResponse = {
    success:boolean;
    message?:string;
}
export function* callChangeTempPassword({ payload }:ITempPassword) {
	try {
		const {isTokenFormat, ...realPayload} = payload;
        const requestData = isTokenFormat ? yield changeTempUserToken( realPayload ) : yield changeTempUserPassword( realPayload );
        if(requestData && requestData.data){
            
            if( isTokenFormat ){ // Change Temp Password with Token

                const remoteResponse = requestData.data as RemotePasswordResponse;
                if(remoteResponse.hasOwnProperty('passwordChanged') && remoteResponse.passwordChanged===true && remoteResponse.hasOwnProperty('login') && remoteResponse.hasOwnProperty('roles')){
                    yield put(
                        changeTempPasswordSuccess({
                            emailAddress: remoteResponse.login,
                            orgRoles: remoteResponse.roles
                        })
                    );
                } else {
                    throw new Error(`${remoteResponse.error ? remoteResponse.error : 'Error'} ${isNumber(remoteResponse.status) ? remoteResponse.status : ''} ${remoteResponse.message ? remoteResponse.message : ''}`);
                }

            } else { // Change Temp Password

                const remoteResponse = requestData.data as RemoteTempPasswordResponse;
                if(remoteResponse.hasOwnProperty('success') && remoteResponse.success===true){
                    yield put(
                        changeTempPasswordSuccess()
                    );
                }else if(remoteResponse.hasOwnProperty('success') && remoteResponse.success===false && remoteResponse.hasOwnProperty('message') && !!remoteResponse.message){
                    yield put(changeTempPasswordFailure(remoteResponse.message));
                } else {
                    throw new Error(`Error: ${remoteResponse.message ? remoteResponse.message : 'Request failed.'}`);
                }
            }
            
        } else {
			throw new Error('Error in Change Temp Password request.');
        }

    } catch(error) {
		yield put(changeTempPasswordFailure('Invalid Temporary Password.'));
    }
}

export function* callUpdateTempPassword({ payload }:IUpdateTempPassword) {
	try {
		const {userId, newPassword, token, sendEmail} = payload;
        type Response = {
            data:boolean;
        }
        const requestData:Response = yield updateTempUserPassword( userId, newPassword, token, sendEmail );
        const valid:boolean = yield isUserTokenValid(requestData.data, token);
		if(valid){
			if(requestData && requestData.data === true){
                yield put(
                    changeTempPasswordSuccess()
                );
			} else {
                throw new Error('Update Temp Password API returned, but cannot read data.')
			}
		}

    } catch(error) {
		yield put(changeTempPasswordFailure('Error: '+error.message));
    }
}

export function* callGetTerms({ payload }:IGetTerms) {
	try {
		const requestData = yield getTermsDoc( payload );
        if(requestData && requestData.status && requestData.status<300 && requestData.data){
            yield put(getTermsSuccess(requestData.data));
        } else {
			throw new Error(`Could not load terms${requestData.status ? '. Status ' + requestData.status : ''}.`);
        }

    } catch(error) {
		yield put(getTermsError(error.message));
    }
}

export function* callAcceptTerms({payload}:IAcceptTerms) {
	const {token} = payload;
	try {
		const termsInfoData = yield acceptUserTerms(token);
		const valid = yield isUserTokenValid(termsInfoData.data, token);
		if(valid){
			if(termsInfoData && termsInfoData.data && termsInfoData.data===true){
                yield put(acceptTermsSuccess());

                // store user with termsAccepted
                const user:IUser | undefined = yield select(selectCurrentUser);
                const allOrgs:IOrg[] | undefined = yield select(selectAllOrgs);
                const currentOrg:IOrg | undefined = yield select(selectOrgInfo);
                if(user && allOrgs && currentOrg){
                    const storable:UserStoreFlattened = {
                        user: {
                            ...user,
                            termsAccepted: true
                        },
                        allOrgs: allOrgs.map(org => convertOrg(org)),
                        org: convertOrg(currentOrg)
                    }
                    yield setLocal(StorageKey.User, storable);
                }
			} else {
                throw new Error(`Error in request${termsInfoData.status ? '. Status ' + termsInfoData.status : ''}.`);
			}
		}
	} catch(er){
		yield put(
			acceptTermsError(er.message)
		);
	}
    
}

/*type RemoteProfileItems = {
    [key:string]: string | number | null | undefined
}*/
type MixedProfileItemType = ProfileItemType | string;
type RemoteProfileItems = Record<MixedProfileItemType, string | number | null | undefined>;

export type RemoteUser = Pick<IViewUser, 'id' | 'firstName' | 'lastName' | 'orgRoles' | 'sportRole' | 'sportRoleOptions' | 'email' | 'password' | 'enableLogin' | 'pushNotificationsMessages' | 'pushNotificationsPriority'> & {
    iconUrl?:string | null;
    profile?:RemoteProfileItems | null;
    hasMemberConfig?:boolean | null;
    memberConfig?:UserConfig | null;
    memberConfigDefault?:UserConfig | null;
    status?:UserStatus | null;
    activeProgramExpirationDate?:string | null;
    sendEmail?:boolean;
}

export type RemoteAddUser = Omit<RemoteUser, 'id'>

export type RemoteUserConfig = Pick<IAddUserConfig, 'sportRoleOptions' | 'teamOptions' | 'enableLogin' | 'pushNotificationsMessages' | 'pushNotificationsPriority'> & {
    memberConfigDefault?:UserConfig | null;
    profile?:Record<MixedProfileItemType, string | number | ProfileSideType | null | undefined> | null;
    orgRoleOptions?:OrgRoles | null;
}

const convertRemoteViewUser = ({firstName, lastName, iconUrl, profile, memberConfig, memberConfigDefault, hasMemberConfig, status, activeProgramExpirationDate, password, ...user}:RemoteUser):IViewUser => ({
    ...user,
    firstName,
    lastName,
    displayName: `${firstName} ${lastName}`,
    image: iconUrl ? iconUrl : undefined,
    profileItems: profile ? (Array.from(Object.keys(profile)) as MixedProfileItemType[]).filter(key => key!=='PMOTION_STAFF_ACCESS').map(key => ({
        key,
	    value: profile[key as ProfileItemType]
    } as ProfileItem)) : undefined,
    config: memberConfig,
    configDefault: memberConfigDefault,
    hasConfig: hasMemberConfig,
    status: status ? status : UserStatus.ACTIVE,
    expirationDate: activeProgramExpirationDate
});

const convertViewUserToRemote = ({firstName, lastName, displayName, image, profileItems, config, configDefault, hasConfig, expirationDate, ...user}:IViewUser):RemoteUser => ({
    ...user,
    firstName,
    lastName,
    iconUrl: image ? image : null,
    profile: profileItems ? profileItems.reduce((accObj, item) => {
        accObj[item.key] = item.value;
        return accObj;
    }, {} as RemoteProfileItems) : null,
    memberConfig: config,
    memberConfigDefault: configDefault,
    hasMemberConfig: hasConfig,
    activeProgramExpirationDate: expirationDate
});

const convertViewUserToRemoteAdd = ({id, displayName, image, profileItems, config, configDefault, hasConfig, expirationDate, ...user}:IViewUser):RemoteAddUser | undefined => ({
    ...user,
    iconUrl: image ? image : null,
    profile: profileItems ? profileItems.reduce((accObj, item) => {
        accObj[item.key] = item.value;
        return accObj;
    }, {} as RemoteProfileItems) : null,
    memberConfig: config,
    memberConfigDefault: configDefault,
    hasMemberConfig: hasConfig
});

export function* callGetUsers({payload}:IGetUsers) {
	const {token, orgId} = payload;
	try {
		const usersInfoData = yield getOrgUsers(orgId, token);
		const valid = yield isUserTokenValid(usersInfoData.data, token);
		if(valid){
			if(usersInfoData && usersInfoData.data && Array.isArray(usersInfoData.data)){
                const users:IViewUser[] = (usersInfoData.data as RemoteUser[]).map(convertRemoteViewUser);
                
                yield put(setUsers(users));
			} else {
                throw new Error('GetOrgUsers API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			getUsersError(er.message)
		);
	}
}

export function* callGetUser({payload}:IGetUser) {
	const {token, userId} = payload;
	try {
		const userInfoData = yield getOrgUser(userId, token);
		const valid = yield isUserTokenValid(userInfoData.data, token);
		if(valid){
			if(userInfoData && userInfoData.data && typeof userInfoData.data === 'object' && (userInfoData.data as {}).hasOwnProperty('id')){
                const user:IViewUser = convertRemoteViewUser(userInfoData.data as RemoteUser);
                
                yield put(selectUser({
                    user,
                    isLoading: false
                }));
			} else {
                throw new Error('GetUsers API returned, but cannot read data.')
			}
		}
	} catch(er){
		yield put(
			getUsersError(er.message)
		);
	}
}

export type RemoteError = object & {
    errorCode?:string;
    message?:string;
}
type RemoteEditUserRequestReturn = RemoteError & {
    data?:boolean | PrivateRemoteData | RemoteError;
}
export function* callEditUser({payload}:IEditUser) {
	const {token, user, sendEmail} = payload;
	try {
        const remoteUser = convertViewUserToRemote(user);
        const {memberConfigDefault, sportRoleOptions, hasMemberConfig, ...limitedRemoteUserData} = remoteUser; // deconstruct off unneeded data for API
        const sendData = sendEmail ? {
            ...limitedRemoteUserData,
            sendEmail
        } : limitedRemoteUserData;
        const userInfoData:RemoteEditUserRequestReturn | undefined = yield editOrgUser(sendData, token);
		const valid:boolean | string = userInfoData && userInfoData.data ? yield isUserTokenValid(userInfoData.data, token) : true;
		if(valid){
			if(userInfoData && userInfoData.data && userInfoData.data === true){
                yield put(
                    selectUser({
                        user,
                        isLoading: true
                    })
                );
                yield put(
                    editUserSuccess(user)
                );
			} else {
                const error = userInfoData && userInfoData.data && typeof userInfoData.data === 'object' && (userInfoData.data as {}).hasOwnProperty('message') ? userInfoData.data as RemoteError : userInfoData && typeof userInfoData === 'object' && (userInfoData as {}).hasOwnProperty('message') ? userInfoData as RemoteError : undefined;
                const errorMessage = error && error.message ? capitalize(error.message) : 'EditUser API returned, but cannot read data.';
                throw new Error(errorMessage)
			}
		}
	} catch(er){
		yield put(
			editUserError(er.message)
		);
	}
}

type RemoteAddUserRequestReturn = RemoteError & {
    data?:RemoteUser | PrivateRemoteData | RemoteError;
}
export function* callAddUser({payload}:IAddUser) {
	const {token, user, sendEmail} = payload;
	try {
        const remoteUser = convertViewUserToRemoteAdd(user);
        if( remoteUser ){
            const {hasMemberConfig, status, ...limitedRemoteUserData} = remoteUser; // deconstruct off unneeded data for API
            const sendData = sendEmail ? {
                ...limitedRemoteUserData,
                sendEmail
            } : limitedRemoteUserData;
            const userInfoData:RemoteAddUserRequestReturn | undefined = yield addOrgUser(sendData, token);
            const valid:boolean | string = userInfoData && userInfoData.data ? yield isUserTokenValid(userInfoData.data, token) : true;
            if(valid){
                if(userInfoData && userInfoData.data && typeof userInfoData.data === 'object' && (userInfoData.data as {}).hasOwnProperty('id')){
                    const user:IViewUser = convertRemoteViewUser(userInfoData.data as RemoteUser);
                    
                    yield put(selectUser({
                        user,
                        isLoading: false
                    }));
                    yield put(
                        addUserSuccess()
                    );
                } else {
                    const error = userInfoData && userInfoData.data && typeof userInfoData.data === 'object' && (userInfoData.data as {}).hasOwnProperty('message') ? userInfoData.data as RemoteError : userInfoData && typeof userInfoData === 'object' && (userInfoData as {}).hasOwnProperty('message') ? userInfoData as RemoteError : undefined;
                    const errorMessage = error && error.message ? capitalize(error.message) : 'AddUser API returned, but cannot read data.';
                    throw new Error(errorMessage)
                }
            }
        } else {
            throw new Error('Could not create an AddUser object.')
        }
        
	} catch(er){
		yield put(
			addUserError(er.message)
		);
	}
}

export function* callChangeUserStatus({payload}:IChangeUserStatus) {
	const {token, userId, activate, refreshUsers} = payload;
	try {
        const userInfoData = yield activate ? activateOrgUser(userId, token) : deactivateOrgUser(userId, token);
		const valid = yield isUserTokenValid(userInfoData.data, token);
		if(valid){
            if(userInfoData && userInfoData.data && userInfoData.data === true){
                if( refreshUsers ){
                    const nextToken = typeof valid==='string' ? valid : token;
                    yield put(
                        getUsers({
                            ...refreshUsers,
                            token: nextToken
                        })
                    );
                }
                yield put(
                    editUserSuccess()
                );
			} else {
                throw new Error(`${activate ? 'Activate' : 'Deactivate'} User API returned, but cannot read data.`)
			}
		}
	} catch(er){
		yield put(
			editUserError(er.message)
		);
	}
}

export function* callGetAddUserConfig({payload}:IGetAddUserConfig) {
	const {token, orgId} = payload;
	try {
		const configInfoData = yield getAddOrgUserConfig(orgId, token);
		const valid = yield isUserTokenValid(configInfoData.data, token);
		if(valid){
			if(configInfoData && configInfoData.data && typeof configInfoData.data === 'object'){
                const {orgRoleOptions, sportRoleOptions, profile, memberConfigDefault, teamOptions, enableLogin/*, pushNotificationsMessages, pushNotificationsPriority*/} = configInfoData.data as RemoteUserConfig;
                
                yield put(
                    setAddUserConfig({
                        configDefault: memberConfigDefault,
                        orgRoleOptions: orgRoleOptions ? Array.from(Object.keys(orgRoleOptions)).reduce((accu, orgIdString) => {
                            const roles = orgRoleOptions[orgIdString];
                            return roles ? uniq(accu.concat(roles)) : accu;
                        }, [] as OrgRole[]) : undefined,
                        sportRoleOptions,
                        teamOptions,
                        profileItemOptions: profile ? (Array.from(Object.keys(profile)) as MixedProfileItemType[]).filter(key => key!=='PMOTION_STAFF_ACCESS') as ProfileItemType[] : undefined,
                        enableLogin,
                        /*pushNotificationsMessages,
                        pushNotificationsPriority*/
                    })
                );
			} else {
                throw new Error(`GetAddUserConfig API error${configInfoData && configInfoData.originalError && configInfoData.originalError.message ? ': '+configInfoData.originalError.message : ''}.`)
			}
		}
	} catch(er){
		yield put(
			getAddUserConfigError(er.message)
		);
	}
}

export function* onEmailSignInStart() {
    yield takeLatest(EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onCheckUserSession() {
    yield takeLatest(CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignOutStart() {
    yield takeLatest(SIGN_OUT_START, signOut);
}

export function* onChangePassword() {
    yield takeLatest(CHANGE_PASSWORD, changePassword);
}

export function* onForgotPassword() {
    yield takeLatest(FORGOT_PASSWORD, callForgotPassword);
}

export function* onResetPassword() {
    yield takeLatest(RESET_PASSWORD, callResetPassword);
}

export function* onChangeTempPassword() {
    yield takeLatest(TEMP_PASSWORD, callChangeTempPassword);
}

export function* onUpdateTempPassword() {
    yield takeLatest(UPDATE_TEMP_PASSWORD, callUpdateTempPassword);
}

export function* onGetTerms() {
    yield takeLatest(GET_TERMS, callGetTerms);
}

export function* onExtendSessionStart() {
    yield takeLatest(EXTEND_USER_SESSION_START, extendTokenSession);
}

export function* onCheckLastLogin() {
    yield takeLatest(CHECK_LAST_LOGIN, checkLastSuccessfulLoginInfo);
}

export function* onAcceptTerms() {
    yield takeLatest(ACCEPT_TERMS, callAcceptTerms);
}

export function* onGetUsers() {
    yield takeLatest(GET_USERS, callGetUsers);
}

export function* onGetUser() {
    yield takeLatest(GET_USER, callGetUser);
}

export function* onEditUser() {
    yield takeLatest(EDIT_USER, callEditUser);
}

export function* onAddUser() {
    yield takeLatest(ADD_USER, callAddUser);
}

export function* onChangeUserStatus() {
    yield takeLatest(CHANGE_USER_STATUS, callChangeUserStatus);
}

export function* onGetAddUserConfig() {
    yield takeLatest(GET_ADD_USER_CONFIG, callGetAddUserConfig);
}

export function* userSagas() {
    yield all([
        call( onEmailSignInStart ),
        call( isUserAuthenticated ),
        call( onSignOutStart ),
        call( onChangePassword ),
        call( onForgotPassword ),
        call( onResetPassword ),
        call( onChangeTempPassword ),
        call( onUpdateTempPassword ),
        call( onGetTerms ),
        call( onExtendSessionStart ),
        call( onCheckLastLogin ),
        call( onAcceptTerms ),
        call( onGetUsers ),
        call( onGetUser ),
        call( onEditUser ),
        call( onAddUser ),
        call( onChangeUserStatus ),
        call( onGetAddUserConfig )
    ]);
}
