// Data types
export type IDType = number;
export enum Fallback {
	NA = 'n/a'
}

export interface IData {
	id:IDType;
}

export enum UpdateStatus {
	Updating = 'updating',
	Updated = 'updated'
}

export enum Order {
	Asc = "asc",
	Desc = "desc"
}