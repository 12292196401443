import { DashboardTooltip, DashboardTooltipContent, GET_DASH_TOOLTIPS_CONTENT, GET_PSCORE_LEGEND_CONTENT, IGetDashboardTooltipsContent, IGetPscoreLegendContent, ISetDashboardTooltipsContent, ISetPscoreLegendContent, IShowContentError, SET_DASH_TOOLTIPS_CONTENT, SET_PSCORE_LEGEND_CONTENT, SHOW_CONTENT_ERROR } from './content.types';

export const getPScoreLegendContent = ():IGetPscoreLegendContent => ({
    type: GET_PSCORE_LEGEND_CONTENT
});

export const setPScoreLegendContent = (payload:string):ISetPscoreLegendContent => ({
    type: SET_PSCORE_LEGEND_CONTENT,
    payload
});

export const getDashboardTooltips = ():IGetDashboardTooltipsContent => ({
    type: GET_DASH_TOOLTIPS_CONTENT
});

export const setDashboardTooltips = (payload:Record<DashboardTooltip | string, DashboardTooltipContent | undefined> | undefined):ISetDashboardTooltipsContent => ({
    type: SET_DASH_TOOLTIPS_CONTENT,
    payload
});

export const showContentError = (error:string):IShowContentError => ({
    type: SHOW_CONTENT_ERROR,
    payload: error
});