import { DashboardTableType, FILTER_DEFAULT_VALUE, IDashboardState, RANGE_DEFAULT_VALUE, SET_DASHBOARD_DAYS_COMPLETED_FILTER, SET_DASHBOARD_ORDER, SET_DASHBOARD_ORDER_BY, SET_DASHBOARD_PAGE, SET_DASHBOARD_POSITION_FILTER, SET_DASHBOARD_ROWS_PER_PAGE, SET_DASHBOARD_SEARCH_FILTER, SET_DASHBOARD_SORT, SET_DASHBOARD_STATE, SET_DASHBOARD_STATUS_FILTER, SET_DASHBOARD_TABLE_TYPE, TypeDashboardAction } from './dashboard.types';

import { SIGN_OUT_SUCCESS } from '../user/user.types';

export const INITIAL_DASHBOARD_STATE:IDashboardState = {
    page: 0,
	rowsPerPage: 10,
	positionFilterSelections: FILTER_DEFAULT_VALUE,
	statusFilterSelections: FILTER_DEFAULT_VALUE,
    daysCompletedRangeFilterSelections: RANGE_DEFAULT_VALUE,
    searchFilter: undefined,
    order: undefined,
    orderBy: undefined,
    complianceOrder: undefined,
	complianceOrderBy: undefined,
    tableType: DashboardTableType.Overview
};

const dashboardReducer = (state = INITIAL_DASHBOARD_STATE, action:TypeDashboardAction):IDashboardState => {
    const {tableType:currentTableType} = state;

    switch( action.type ){
        
        case SIGN_OUT_SUCCESS :
            return {
                ...INITIAL_DASHBOARD_STATE,
                rowsPerPage: state.rowsPerPage
            };
        
        case SET_DASHBOARD_STATE :
            if(action.payload){
                const {tableType, orderBy, order, ...overrideProps} = {
                    ...state,
                    ...action.payload
                };
                return {
                    ...overrideProps,
                    tableType,
                    order: (!tableType || tableType===DashboardTableType.Overview) ? order : state.order,
                    orderBy: (!tableType || tableType===DashboardTableType.Overview) ? orderBy : state.orderBy,
                    complianceOrder: (tableType===DashboardTableType.Compliance) ? order : state.complianceOrder,
                    complianceOrderBy: (tableType===DashboardTableType.Compliance) ? orderBy : state.complianceOrderBy,
                }
            } else {
                return {
                    ...INITIAL_DASHBOARD_STATE,
                    rowsPerPage: state.rowsPerPage
                };
            }

        case SET_DASHBOARD_PAGE :
            return {
                ...state,
                page: action.payload
            }

        case SET_DASHBOARD_ROWS_PER_PAGE :
            return {
                ...state,
                rowsPerPage: action.payload,
                page: 0
            }

        case SET_DASHBOARD_POSITION_FILTER :
            return {
                ...state,
                positionFilterSelections: action.payload
            }

        case SET_DASHBOARD_STATUS_FILTER :
            return {
                ...state,
                statusFilterSelections: action.payload
            }

        case SET_DASHBOARD_DAYS_COMPLETED_FILTER :
            return {
                ...state,
                daysCompletedRangeFilterSelections: action.payload
            }

        case SET_DASHBOARD_SEARCH_FILTER :
            return {
                ...state,
                searchFilter: action.payload
            }

        case SET_DASHBOARD_ORDER :
            return {
                ...state,
                order: (!currentTableType || currentTableType===DashboardTableType.Overview) ? action.payload : state.order,
                complianceOrder: (currentTableType===DashboardTableType.Compliance) ? action.payload : state.complianceOrder
            }

        case SET_DASHBOARD_ORDER_BY :
            return {
                ...state,
                orderBy: (!currentTableType || currentTableType===DashboardTableType.Overview) ? action.payload : state.orderBy,
                complianceOrderBy: (currentTableType===DashboardTableType.Compliance) ? action.payload : state.complianceOrderBy
            }

        case SET_DASHBOARD_SORT :
            return {
                ...state,
                order: (!currentTableType || currentTableType===DashboardTableType.Overview) ? action.payload.order : state.order,
                orderBy: (!currentTableType || currentTableType===DashboardTableType.Overview) ? action.payload.orderBy : state.orderBy,
                complianceOrder: (currentTableType===DashboardTableType.Compliance) ? action.payload.order : state.complianceOrder,
                complianceOrderBy: (currentTableType===DashboardTableType.Compliance) ? action.payload.orderBy : state.complianceOrderBy
            }

        case SET_DASHBOARD_TABLE_TYPE :
            return {
                ...state,
                tableType: action.payload
            }

        default :
            return state;
    }
};

export default dashboardReducer;