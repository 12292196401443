import { Box, Typography, Link } from '@material-ui/core';
import { FontWeight, Sizes } from '../../utils/style';
import React, { ErrorInfo } from 'react';

import PMotionLogoLong from '../../icons/pmotion-logo-long.icon';
import { makeStyles } from '@material-ui/core/styles';
import Routes from '../../utils/routes';

const useStyles = makeStyles(theme => ({
    root: {
        height: theme.viewport.percentOfHeight(75, Sizes.HeaderHeight),
    },
    logo: {
        width: 'auto',
		height: theme.typography.pxToRem(27)
    },
    header: {
        fontSize: theme.typography.pxToRem(30),
        fontWeight: FontWeight.Regular,
        letterSpacing: theme.typography.pxToRem(-.8),
        marginBottom: theme.typography.pxToRem(40)
    },
    subHeader: {
        fontSize: theme.typography.pxToRem(15),
    },
    
}));

const ErrorContent:React.FC = () => {
    const classes = useStyles();
    return (
        <Box flex={1} display="flex" className={classes.root} alignItems="center">
			<Box flex={1} display="flex" flexDirection="row" justifyContent="center">
				<Box>
                    <Link
                        color="inherit"
                        href={Routes.HOME}
                    >
                        <PMotionLogoLong className={classes.logo} />
                    </Link>
                    <Typography className={classes.header} variant="h1">Oops.</Typography>
                    <Typography className={classes.subHeader} variant="body1">Something is not working correctly.</Typography>
				</Box>
			</Box>
		</Box>
    )
}

type Props = {
    onError?:(error:Error, info:ErrorInfo) => void;
};
type State = {
    hasErrored:boolean;
}
class ErrorBoundary extends React.Component<Props, State> {
   
    constructor(props:Props) {
        super(props);
        this.state = {
            hasErrored: false
        };
    }
    
    static getDerivedStateFromError(error:Error){
        // process the error
        return { hasErrored: true };
    }
    
    componentDidCatch(error:Error, info:ErrorInfo) {
        if( process.env.NODE_ENV !== 'test' ){
            console.log('ErrorBoundary Error', error);
        }
        if(this.props.onError){
            this.props.onError(error, info);
        }
    }

    render() {
        if( this.state.hasErrored ){
            return (
                <ErrorContent />
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;