import { Theme as AugmentedTheme, createMuiTheme, createStyles, darken, makeStyles, responsiveFontSizes } from "@material-ui/core/styles";
import {Colors, ComponentColors, FontSize, FontWeight, toRems} from '../utils/style';

import { IOrg } from '../redux/org/org.types';
import { supportsCSSVars } from '../utils/PMotionGlobals';

declare module '@material-ui/core/styles/createMuiTheme' {
	interface Theme {
		viewport: {
			percentOfHeight: (percent:number, pixelOffset?:number) => string;
			proportionateWidth: (aspectRatioWidth:number, aspectRatioHeight:number, percentOfHeight:number, pixelHeightOffset:number) => string;
		};
	}
	// allow configuration using `createMuiTheme`
	interface ThemeOptions {
		viewport: {
			percentOfHeight: (percent:number, pixelOffset?:number) => string;
			proportionateWidth: (aspectRatioWidth:number, aspectRatioHeight:number, percentOfHeight:number, pixelHeightOffset:number) => string;
		};
	}
}

const contrastTextOverride = (backgroundColor:string) => {
	switch( backgroundColor ){
		case Colors.BrandSkyBlue :
			return Colors.White;
	}
	return undefined;
}

const generateAppTheme = (org?:IOrg):AugmentedTheme => {
	const AppTheme:AugmentedTheme = createMuiTheme({
		palette: {
			primary: {
				// light: will be calculated from palette.primary.main,
				main: (org && org.colorPrimary) ? org.colorPrimary : Colors.BlackLight4,
				// dark: will be calculated from palette.primary.main,
				contrastText: contrastTextOverride((org && org.colorPrimary) ? org.colorPrimary : Colors.BlackLight4)
			},
			secondary: {
				//light: "#0066ff",
				main: (org && org.colorSecondary) ? org.colorSecondary : Colors.Black,
				// dark: will be calculated from palette.secondary.main,
				contrastText: contrastTextOverride((org && org.colorSecondary) ? org.colorSecondary : Colors.Black)
			},
			// third accent color, for action buttons
			info: {
				//light: "#0066ff",
				main: (org && org.colorAccent) ? org.colorAccent : Colors.BrandSkyBlue,
				// dark: will be calculated from palette.secondary.main,
				contrastText: contrastTextOverride((org && org.colorAccent) ? org.colorAccent : Colors.BrandSkyBlue),
			},
			error: {
				//light: "#0066ff",
				main: Colors.RedDark, //red
				// dark: will be calculated from palette.secondary.main,
				//contrastText: "#ffcc00"
			},
			success: {
				//light: "#0066ff",
				main: Colors.Green, // green
				// dark: will be calculated from palette.secondary.main,
				//contrastText: "#ffcc00"
			},
			warning: {
				//light: "#0066ff",
				main: Colors.Gold,
				// dark: will be calculated from palette.secondary.main,
				//contrastText: "#ffcc00"
			},
			// Used by `getContrastText()` to maximize the contrast between
			// the background and the text.
			//contrastThreshold: 3,
			// Used by the functions below to shift a color's luminance by approximately
			// two indexes within its tonal palette.
			// E.g., shift from Red 500 to Red 300 or Red 700.
			//tonalOffset: 0.2
		},
		typography: {
			fontFamily: [
				'Open Sans',
				'sans-serif'
			].join(','),
			fontWeightMedium: FontWeight.Medium,
			h1: {
				fontSize: toRems(20),
				color: Colors.BlackLight3,
				fontWeight: FontWeight.Bold
			},
			h2: {
				fontSize: toRems(18),
				color: Colors.BlackLight2,
				fontWeight: FontWeight.Bold
			},
			h3: {
				fontSize: toRems(16),
				fontWeight: FontWeight.Bold
			},
			h4: {
				fontSize: toRems(15),
				fontWeight: FontWeight.Bold
			},
			h5: {
				fontSize: toRems(14.5),
				fontWeight: FontWeight.Bold
			},
			h6: {
				fontSize: toRems(14),
				fontWeight: FontWeight.Bold
			},
			htmlFontSize: FontSize.Desktop
		},
		// global component overrides
		overrides: {
			// Style sheet name ⚛️
			MuiTableCell: {
				paddingCheckbox: {
					padding: 0
				}
			},
			MuiButton: {
				label: {
					textTransform: 'none',
					fontWeight: FontWeight.Regular
				},
				root: {
					padding: '5px 16px',
				},
			},
			MuiAvatar: {
				root: {
					width: toRems(60),
					height: toRems(60),
				}
			},
			MuiListItemAvatar: {
				root: {
					minWidth: toRems(60),
				}
			},
			MuiTypography: {
				colorTextSecondary: {
					color: Colors.BlackLight2
				}
			},
			MuiDialogContent: {
				root: {
					padding: `11px ${toRems(34)}`,

					'&:first-child': {
						paddingTop: toRems(27)
					}
				}
			},
			MuiDialogActions: {
				root: {
					padding: `11px 13px`,
				}
			},
			MuiSelect: {
				root: {
					'&:not([multiple]) option, &:not([multiple]) optgroup': {
						color: Colors.Black
					}
				}
			}
		},
		viewport: {
			/* Set a CSS height by viewport height percentage.
			Utilizes CSS var --vh set by the useDocumentSizer hook in the application root. Falls back to vh if not supported. */
			percentOfHeight: (percent:number, pixelOffset?:number) => {
				let vh = supportsCSSVars ? `var(--vh, 1vh) * ${percent}` : `${percent}vh`;
				let offset = (pixelOffset!==undefined) ? ` - ${pixelOffset}px` : '';
				if( pixelOffset!==undefined || pixelOffset===0 ){
					vh = `(${vh})`;
					offset = ` - ${pixelOffset}px`;
				} else {
					offset = '';
				}
				return `calc(${vh}${offset})`;
			},
			/* Set a CSS width proportionately based on a viewport height percentage and a desired aspect ratio.
			Utilizes CSS var --vh set by the useDocumentSizer hook in the application root. Falls back to vh if not supported. */
			proportionateWidth: (aspectRatioWidth:number, aspectRatioHeight:number, percentOfHeight:number, pixelHeightOffset:number=0) => {
				const vh = supportsCSSVars ? `(var(--vh, 1vh) * ${percentOfHeight})` : `${percentOfHeight}vh`;
				return `calc(((${vh} - ${pixelHeightOffset}px)*${aspectRatioWidth}) / ${aspectRatioHeight})`;
			}
		}
	});
	responsiveFontSizes(AppTheme);
	return AppTheme;
};

export default generateAppTheme;


export const globalStyles = (theme: AugmentedTheme) => ({
	"@global": {
		// MUI typography elements use REMs, so you can scale the global
		// font size by setting the font-size on the <html> element.
		html: {
			fontSize: FontSize.Desktop,
			//overflowX: 'hidden',
			height: '100%',
			maxWidth: '100%',

			[theme.breakpoints.down("sm")]: {
				fontSize: FontSize.Mobile
			},
		},
		body: {
			backgroundColor: `${ComponentColors.BackgroundGray}`,
			overflowX: 'hidden',
			position: 'relative',
			height: '100%',
			maxWidth: '100%',
		},
		figcaption: {
			fontSize: theme.typography.pxToRem(13),
			padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(14)}`,
			color: Colors.GrayDarker,
		},
		a: {
			color: theme.palette.info.main,
			textDecoration: 'none',

			'&:hover': {
				textDecoration: 'underline',
			}
		},
		'.MuiButton-containedPrimary.actionBtn:not(.Mui-disabled)': {
			backgroundColor: theme.palette.info.main,
			color: theme.palette.info.contrastText,

			'&:hover': {
				backgroundColor: darken(theme.palette.info.main, .15),
				color: theme.palette.info.contrastText,
			},

			'& .MuiButton-label': {
				fontWeight: FontWeight.Bold
			},
		}
	}
});


export const useResponsiveStyles = makeStyles((theme: AugmentedTheme) =>
	createStyles({
		responsiveContainer: {
			position: 'relative',
			overflow: 'hidden',
			paddingTop: '56.25%', // defaults to 16:9
			height: 0,
			width: '100%',

			'& > .responsive': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
			},

			'& > .iframe': {
				border: 0,
			}
		},
	})
);