export enum MediaTypes {
	Image = 'IMAGE',
	Video = 'VIDEO',
	VideoLoop = 'VIDEO_LOOP',
	YouTube = 'YOUTUBE',
	Audio = 'AUDIO'
}

export interface Media {
	type:VideoSourceType;
	caption?:string | null;
	order?:number | null;
	title?:string | null;
}

export enum VideoSourceType {
	MP4 = "video/mp4",
	WEBM = "video/webm",
	OGG = "video/ogg",
	MP3 = "audio/mpeg"
}
export interface VideoSource {
	src:string;
	type:VideoSourceType;
}
export interface VideoTrack {
	kind:string;
	src:string;
	srcLang:string;
	label:string;
}
export interface Video extends Omit<Media, 'type'> {
	sources?:VideoSource[];
	tracks?:VideoTrack[];
	poster?:string | null;
	type: MediaTypes.Video | MediaTypes.Audio;
}

export interface VideoLoop extends Omit<Video, 'type'> {
	type: MediaTypes.VideoLoop;
}

export interface YouTube extends Omit<Media, 'type'> {
	embedUrl:string;
	type: MediaTypes.YouTube;
}

type ImageKeyType = number | 'src';
type ImageSource = [ImageKeyType, string];

export interface Image extends Omit<Media, 'type'> {
	sources:ImageSource[];
	alt:string;
	type: MediaTypes.Image;
}

export type RemoteImageSource = {
    key:string;
    path:string;
};

export interface RemoteImage extends Omit<Image, 'sources'> {
	sources:RemoteImageSource[];
}

export const convertRemoteImage = (remote:RemoteImage):Image => {
	const newSources:ImageSource[] = [];
	remote.sources.forEach(({key, path}) => {
		if(key!==undefined && key.trim()!=='' && path!==undefined && path.trim()!==''){
			const keyNum = parseFloat(key);
			const size = !isNaN(keyNum) ? keyNum : (key==='src') ? key : undefined;
			if(size!==undefined){
				newSources.push([size, path]);
			}
		}
	});
	return {
		...remote,
		sources: newSources
	}
}

export enum RemoteVideoSourceType {
	MP4 = "MP4",
	WEBM = "WEBM",
	OGG = "OGG",
	MP3 = "MP3"
}
export type RemoteVideoSource = {
    key:string;
	path:string;
	type:RemoteVideoSourceType;
};
export interface RemoteVideo extends Omit<Video, 'sources'> {
	sources:RemoteVideoSource[];
}
const convertRemoteVideoSourceType = ( type:RemoteVideoSourceType ):VideoSourceType | undefined => {
	switch( type ){
		case RemoteVideoSourceType.MP4 :
			return VideoSourceType.MP4;
		case RemoteVideoSourceType.WEBM :
			return VideoSourceType.WEBM;
		case RemoteVideoSourceType.OGG :
			return VideoSourceType.OGG;
		case RemoteVideoSourceType.MP3 :
			return VideoSourceType.MP3;
		default :
			return undefined;
	}
}
export const convertRemoteVideo = (remote:RemoteVideo):Video => {
	const newSources:VideoSource[] = [];
	remote.sources.forEach(({path, type}) => {
		if(path!==undefined && path.trim()!=='' && type!==undefined && type.trim()!==''){
			const newType = convertRemoteVideoSourceType(type);
			if(newType !== undefined){
				newSources.push({
					src: path,
					type: newType
				});
			}
		}
	});
	return {
		...remote,
		sources: newSources
	}
}

export type RemoteMediaType = MediaType | RemoteImage | RemoteVideo;

export const convertRemoteMedia = (media:RemoteMediaType):MediaType => {
	if(media.type===MediaTypes.Image && media.sources && media.sources.length && !Array.isArray(media.sources[0])){
		return convertRemoteImage( media as RemoteImage );
	} else if(media.type===MediaTypes.Video || media.type===MediaTypes.VideoLoop || media.type===MediaTypes.Audio){
		return convertRemoteVideo( media as RemoteVideo );
	}
	return media as MediaType;
}


export interface MediaAPI {
	isPlaying:boolean;
	isFullscreen:boolean;
	play: () => void;
	pause: () => void;
	goFullscreen: () => void;
	exitFullScreen: () => void;
	seek:(time:number) => void;
	type:MediaTypes; 
	index?:number;
}

type MediaType = Image | YouTube | Video | VideoLoop;
export default MediaType;